
import {
  allDepsContext,
  currentCardContext
} from "@axle/web/src/components/layouts/Authorized/MaybeRender"
import AddressDto from "@axle/web/src/models/address"
import { CardDto } from "@axle/web/src/models/card"
import { CardStatus } from "@axle/web/src/models/enums/card-status"
import LedgerDto from "@axle/web/src/models/ledger"
import PersonDto from "@axle/web/src/models/person"
import {
  usePersonBankAccount,
  usePersonRegisteredAddress
} from "@axle/web/src/store/hooks/person"
import { CurrentCardState } from "@axle/web/src/store/slices/currentCard"
import { DateTime } from "luxon"
import { useDisclose } from "native-base"
import { useEffect, useState, useContext } from "react"

interface UserCardDetails {
  person: PersonDto | null
  cards: CardDto[] | null
  dueDate: string
  currentCard: CurrentCardState
  currentCardIndex: number | null
  isCardActivated: boolean
  isCardPreActive: boolean
  isCardLocked: boolean
  activeateCardDisclosure: UseDiscloseProps
  commercialAddress: AddressDto | null
  bankAccount: LedgerDto | null
}
type UseDiscloseProps = ReturnType<typeof useDisclose>

export const useCardDetails = (): UserCardDetails => {
  const [isCardActivated, setIsCardActivated] = useState<boolean>(false)
  const [isCardPreActive, setIsCardPreActive] = useState<boolean>(false)
  const [isCardLocked, setIsCardLocked] = useState<boolean>(false)

  const activeateCardDisclosure = useDisclose()

  const { person, cards, entity } = useContext(allDepsContext)
  const { card: currentCard, index: currentCardIndex } =
    useContext(currentCardContext)
  const { address: commercialAddress } = usePersonRegisteredAddress()
  const { account: bankAccount } = usePersonBankAccount()

  const dueDate = entity.dueDate
    ? DateTime.fromISO(entity.dueDate).toFormat("d LLLL yyyy")
    : "This date is being generated"

  useEffect(() => {
    if (currentCard) {
      setIsCardActivated(currentCard.status === CardStatus.Active)
      setIsCardPreActive(currentCard.status === CardStatus.AwaitingActivation)
      setIsCardLocked(currentCard.status === CardStatus.Suspended)

      return () => {
        setIsCardActivated(false)
        setIsCardPreActive(false)
        setIsCardLocked(false)
      }
    }
  }, [person, currentCard])

  return {
    person,
    cards,
    dueDate,
    currentCard,
    currentCardIndex,
    isCardActivated,
    isCardPreActive,
    isCardLocked,
    activeateCardDisclosure,
    commercialAddress,
    bankAccount
  }
}
