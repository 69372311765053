import {
  Button,
  Heading,
  Spacer,
  Text
} from "native-base"

import { ReviewableField } from "components/inline/ReviewableField"
import { getNumericDate } from "components/util/format-date"
import { formatPrice } from "components/util/format-price"
import { MakeAPayment } from "network/entity"

interface Confirmed {
  endPayments: () => void
  bankAccountDetails: string
  confirmData: MakeAPayment | undefined
}

const Confirmed = ({ endPayments, bankAccountDetails, confirmData }: Confirmed): JSX.Element | null => {
  const { currency } = formatPrice((confirmData as MakeAPayment).Amount)

  return <>
    <Heading testID="confirmed" size="md">
      Payment confirmed
    </Heading>
    <Spacer h="1rem" />
    <Text fontSize="sm">
      The payment was successfully made.
    </Text>
    <Spacer h="1rem"></Spacer>
    <ReviewableField
      label="Account to be charged"
      value={bankAccountDetails}
      marginBottom="1rem"
    />
    <ReviewableField
      label="Payee"
      value={"Cape"}
      marginBottom="1rem"
    />
    <ReviewableField
      label="Amount"
      value={String(currency)}
      marginBottom="1rem"
    />
    <ReviewableField
      label="Date"
      value={getNumericDate((confirmData as MakeAPayment).ChargeDate)}
      marginBottom="1rem"
    />
    <Spacer h="2rem"></Spacer>
    <Button onPress={endPayments} testID="closePayments">Close</Button>
  </>
}

export default Confirmed
