import { useMemo } from "react"

import {
  EntityCategory,
  Category,
  ExpensePoliciesDto,
  AdminControlsDto,
  BudgetOwnerControlsDto,
  RulesList
} from "models/spendControls"
import {
  selectAdminControls,
  selectEntityCategory,
  selectCategory,
  selectExpensePolicies,
  selectBudgetOwnerControls,
  selectRulesList
} from "store/slices/spendControls"

import { useAppSelector } from "."

export const useAdminControls = (): {
  adminControls: AdminControlsDto | null
} => {
  const adminControls = useAppSelector(selectAdminControls)
  return useMemo(() => ({ adminControls }), [adminControls])
}
export const useRulesList = (): {
  rulesList: RulesList | null
} => {
  const rulesList = useAppSelector(selectRulesList)
  return useMemo(() => ({ rulesList }), [rulesList])
}

export const useEntityCategory = (
  entityCategoryIndex: number
): EntityCategory => {
  const entityCategory = useAppSelector(
    selectEntityCategory(entityCategoryIndex)
  )
  return useMemo(() => entityCategory, [entityCategory])
}

export const useCategory = (
  entityCategoryIndex: number,
  categoryIndex: number
): { category: Category } => {
  const category = useAppSelector(
    selectCategory(entityCategoryIndex, categoryIndex)
  )
  return useMemo(() => ({ category }), [category])
}

export const useExpensePolicies = (): {
  expensePolicies: ExpensePoliciesDto | null
} => {
  const expensePolicies = useAppSelector(selectExpensePolicies)
  return useMemo(() => ({ expensePolicies }), [expensePolicies])
}

export const useBudgetOwnerControls = (): {
  budgetOwnerControls: BudgetOwnerControlsDto | null
} => {
  const budgetOwnerControls = useAppSelector(selectBudgetOwnerControls)
  return useMemo(() => ({ budgetOwnerControls }), [budgetOwnerControls])
}
