import { DateTime } from "luxon"
import { Box, Text, Heading, Row, Divider, Column } from "native-base"

import TransactionDto from "models/transactions"

import useTableRow from "./talons/useTableRow"

interface TableRow {
  transaction: TransactionDto
  showDate: boolean
  showMonth: boolean
  showAvailableCredit: boolean
}

const TableRow = ({
  transaction,
  showDate,
  showMonth,
  showAvailableCredit
}: TableRow): JSX.Element => {
  const {
    isPayment,
    amountSign,
    status,
    isDifferentCurrency,
    cardNumber,
    avaliableCredit,
    balance,
    conversionRate,
    exchageRate,
    crossAmount,
    transactionAmount,
    displayBalance
  } = useTableRow(transaction)

  return (
    <Box flex="1">
      {showMonth && (
        <Row flex="1" bg="gray.50" mb="16px" py="16px" px={{ base: "24px", md: "48px" }}>
          <Heading fontSize={{ base: "xs", sm: "sm" }} background="gray.50">
            {transaction.createdAt &&
              DateTime.fromISO(transaction.createdAt).toFormat("LLLL yyyy")}
          </Heading>
        </Row>
      )}

      {showDate && (
        <Row flex="1" my="8px">
          <Box
            w="240px"
            pl={{ base: "24px", md: "48px" }}
            pr="16px"
            py="4px"
            background="warmGray.100"
            borderRightRadius="100px"
          >
            <Text>
              {transaction.createdAt &&
                DateTime.fromISO(transaction.createdAt).toFormat("cccc d LLLL")}
            </Text>
          </Box>
        </Row>
      )}

      {/* Merchant */}
      <Row
        testID="transactions-record"
        flex="1"
        mt="8px"
        ml={{ base: "24px", md: "48px" }}
        mr={{ base: "24px", md: "48px" }}
      >
        <Box flex="3" pr="16px">
          <Text fontSize={{ base: "xs", sm: "sm", md: "md" }}>
            {isPayment ? "Payment to Cape" : transaction.merchantDetails}
          </Text>
        </Box>

        {/* card number */}
        <Row flex="1" display={{ base: "none", lg: "flex" }}>
          <Text textAlign="center">{cardNumber}</Text>
        </Row>

        {/* status */}
        <Box flex="1" display={{ base: "none", lg: "flex" }}>
          <Box>{status}</Box>
        </Box>

        {/* Amount */}
        <Column flex="1" alignItems="end">
          <Text
            textAlign="center"
            px={{ base: "none", md: "16px" }}
            borderRadius="5px"
            bg={isPayment ? "green.50" : ""}
            textDecorationLine={crossAmount ? "line-through" : ""}
          >
            {amountSign + transactionAmount}
          </Text>
        </Column>

        {/* balance */}
        <Row
          flex={showAvailableCredit ? { base: "2", xl: "1" } : "1"}
          display={{ base: "none", lg: "flex" }}
        >
          <Text textAlign="right" flex="1" bold>
            {displayBalance &&
              (showAvailableCredit ? avaliableCredit : balance)}
          </Text>
        </Row>
      </Row>

      {/* this will not show when lg screen size */}
      <Row display={{ base: "flex", lg: "none" }} mx={{ base: "24px", md: "48px" }} alignItems="center">
        <Text w="60px" fontSize="11px">
          {!isPayment && "Card: " + cardNumber}
        </Text>

        {!isPayment && status}

        <Text ml="auto" textAlign="end" fontSize="11px">
          {displayBalance &&
            (showAvailableCredit
              ? "Available Credit: " + avaliableCredit
              : "Balance: " + balance)}
        </Text>
      </Row>
      <Row display={{ base: "flex", lg: "none" }} mx={{ base: "24px", md: "48px" }} alignItems="center">
        <Text ml="auto" textAlign="end" fontSize="11px">
          {`Available Credit: ${avaliableCredit}`}
        </Text>
      </Row>

      {isDifferentCurrency && (
        <Row flex="1" my="8px" mx={{ base: "24px", md: "48px" }} testID="exchangeRateRow">
          <Row flex="3" display={{ base: "none", lg: "flex" }} />
          <Row flex="2">
            <Text
              textAlign={{ base: "left", lg: "right" }}
              fontSize={{ base: "xs", lg: "sm" }}
            >
              {`Exchange rate: AUD ${conversionRate} = 1 ${transaction.transactionCurrency}`}
            </Text>
          </Row>

          <Row flex="1" justifyContent="end">
            <Text textAlign="right" fontSize={{ base: "xs", lg: "sm" }}>
              {`${amountSign + exchageRate} ${transaction.transactionCurrency}`}
            </Text>
          </Row>

          <Row flex="1" display={{ base: "none", lg: "flex" }} />
        </Row>
      )}

      <Box my="32px" px="32px">
        <Divider />
      </Box>
    </Box>
  )
}

export default TableRow
