/**
 * the one and only public function of this module. It takes any string and munges
 * it according to the options. By default it uses a random encoding.
 *
 *
 * @param {String} str any string to munge, for example 'spacemonkey@moon.com'
 * @return {String} the munged string
 * @api public
 */
export const munger = (str: string) => {
  const lengthToKeep = 3
  const emailParts = str.split("@")
  const emailFirstPart = emailParts[0]
  const emailLastPart = emailParts[1]

  const mungedEmail = []

  for(let index = 0; index < emailFirstPart.length; index++) {
    if(index < emailFirstPart.length - lengthToKeep) {
      mungedEmail.push("*")
    } else {
      mungedEmail.push(emailFirstPart[index])
    }
  }

  return `${mungedEmail.join("")}@${emailLastPart}`
}
