import { BaseSyntheticEvent, Dispatch, SetStateAction, useState } from "react"
import { Control, useForm } from "react-hook-form"

import AddressDto from "models/address"
import { CardDto, cardFromLegacyCard } from "models/card"
import { useReplaceCardMutation as useReplaceLostCard } from "network/card"
import { useAppDispatch } from "store/hooks"
import { useCurrentCard } from "store/hooks/currentCard"
import { useCurrentCardIndex } from "store/hooks/currentCardIndex"
import { useEntity } from "store/hooks/entity"
import {
  usePersonHomeAddress
} from "store/hooks/person"
import { updateCard } from "store/slices/cards"
import { setCurrentCard } from "store/slices/currentCard"

export interface FormValues {
  AddressId: number
  NameOnCard: string
}
type page = "ReplaceCardPage" | "SuccessPage"

interface ReplaceCard {
  submitReplaceCard: (
    cardId: number
  ) => (e?: BaseSyntheticEvent | undefined) => Promise<void>
  isSuccess: boolean
  residentialAddress: AddressDto | null
  commercialAddress: AddressDto | null
  chosenAddressState: string
  setChosenAddressState: Dispatch<SetStateAction<string>>
  control: Control<FormValues>
  currentCard: CardDto | null
  isLoading: boolean
  showPage: page
}

const useReplaceCard = (): ReplaceCard => {
  const [replaceCard, { isSuccess, isLoading, isError }] = useReplaceLostCard()
  const { currentCardIndex } = useCurrentCardIndex()
  const dispatch = useAppDispatch()

  const { entity } = useEntity()
  const { address: residentialAddress } = usePersonHomeAddress()
  const { currentCard } = useCurrentCard()
  const [chosenAddressState, setChosenAddressState] = useState<string>("")
  const [showPage, setShowPage] = useState<page>("ReplaceCardPage")
  const { control, handleSubmit } = useForm<FormValues>()

  const handleReplaceCardWithCardId = async (
    cardId: number,
    data: FormValues
  ) => {
    const dataForReplaceCard = {
      ...data,
      CardId: cardId
    }

    const replaceCardResponse = await replaceCard(dataForReplaceCard).unwrap()
    console.log("isLoading", isError)

    console.log("replaceCardResponse", replaceCardResponse.success)

    if (replaceCardResponse.success) {
      const card = cardFromLegacyCard(replaceCardResponse.data)
      dispatch(setCurrentCard(card))
      dispatch(
        updateCard({
          cardIndex: currentCardIndex,
          card
        })
      )

      setShowPage("SuccessPage")
    } else {
      console.error("There was an issue with the replace card request.")
    }
  }

  const commercialAddress = entity && entity.addresses[0]

  const submitReplaceCard = (cardId: number) =>
    handleSubmit((data) => handleReplaceCardWithCardId(cardId, data))

  return {
    submitReplaceCard,
    isSuccess,
    residentialAddress,
    commercialAddress,
    currentCard,
    chosenAddressState,
    setChosenAddressState,
    control,
    isLoading,
    showPage
  }
}

export default useReplaceCard
