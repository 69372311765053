import { Text, Checkbox, AlertDialog, Button, Heading } from "native-base"
import { useRef } from "react"

export interface RemoveDialogProps {
  isNeverOpenRemoveAuthorityDialog: boolean
  isOpenRemoveAuthorityDialog: boolean
  ownersAmount: number
  onClose: () => void
  onChange: () => void
  onContinue: () => void
}

const RemoveAuthorityDialog = ({ isNeverOpenRemoveAuthorityDialog, isOpenRemoveAuthorityDialog, ownersAmount, onClose, onChange, onContinue }: RemoveDialogProps): JSX.Element => {
  const dialogRef = useRef(null)

  return (<AlertDialog size="lg" leastDestructiveRef={dialogRef} isOpen={isOpenRemoveAuthorityDialog} onClose={onClose} testID="remove-authority-dialog">
    <AlertDialog.Content>
      <AlertDialog.Body my="20px" mx="30px">
        <Heading size="md">Are you sure?</Heading>
        <Text my="20px">{`${ownersAmount} Budgets currently have permission to edit this rule. This will reset all controls back to the default setting for their budgets. This could impact recurring or future payments.`}</Text>
        <Checkbox
          isChecked={isNeverOpenRemoveAuthorityDialog}
          size="sm"
          onChange={onChange}
          value=""
          testID="remove-authority-dialog-checkbox"
          accessibilityLabel="remove-authority-dialog-checkbox"
        >
          Don’t ask me again
        </Checkbox>
        <Button.Group mt="30px" flexDir="row" justifyContent="space-between">
          <Button variant="outline" w="100px" onPress={onClose} testID="remove-authority-dialog-cancel">
            Cancel
          </Button>
          <Button w="100px" onPress={onContinue} testID="remove-authority-dialog-continue">
            Continue
          </Button>
        </Button.Group>
      </AlertDialog.Body>
    </AlertDialog.Content>
  </AlertDialog>)
}

export default RemoveAuthorityDialog
