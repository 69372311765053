import { Button, Link, Box } from "@chakra-ui/react"
import { useState, useRef } from "react"

import { NULL_LEDGER_ID } from "config/constants"
import { transactionsExportEndpoint } from "config/endpoints"
import { useGetOTCMutation } from "network/transactions"

export interface DownloadCSVProps {
  searchParams?: string | null
}

export const DownloadCSV = ({ searchParams = "" }: DownloadCSVProps) => {
  const [getOTC, { isLoading: otcIsLoading }] = useGetOTCMutation()

  const [transactionsDownloadUrl, setTransactionsDownloadUrl] =
    useState<string>(transactionsExportEndpoint)
  const [realClick, setRealClick] = useState<boolean>(true)
  const [downloadCount, setDownloadCount] = useState<number>(0)
  const [downloadReady, setDownloadReady] = useState<boolean>(true)

  const handleCSVClick = (linkRef: React.RefObject<HTMLAnchorElement>) => {
    return async (e: React.SyntheticEvent) => {
      const { target, nativeEvent } = e
      const clonedNativeEvent = new MouseEvent("click", nativeEvent)

      if (!realClick) {
        setRealClick(true)
        return
      }

      e.preventDefault()
      e.stopPropagation()

      function initiateDownload(link: HTMLAnchorElement, downloadURL: string) {
        setRealClick(false)
        link.href = downloadURL
        target.dispatchEvent(clonedNativeEvent)
        setDownloadCount(downloadCount + 1)
        setDownloadReady(false)
      }

      function applyToken() {
        const token = otcToken.data.accessToken

        params = `${token}/${NULL_LEDGER_ID}${searchParams}`
        downloadURL = transactionsExportEndpoint
        downloadURL = `${transactionsExportEndpoint}/${params}`

        setTransactionsDownloadUrl(downloadURL)

        const link = linkRef.current

        link && initiateDownload(link, downloadURL)

        setDownloadReady(true)
      }

      const otcToken = await getOTC().unwrap()
      let downloadURL
      let params

      otcToken.success && applyToken()
    }
  }

  const linkRef = useRef<HTMLAnchorElement>(null)

  return (
    <Box w={{ base: "100%", sm: "40%", lg: "unset" }}>
      <Link
        ref={linkRef}
        target="_blank"
        rel="nofollow noreferrer"
        download="transactions.csv"
        data-testid="download-transactions-link"
        onClick={handleCSVClick(linkRef)}
        href={transactionsDownloadUrl}
        textDecoration="none"
        ml={{ base: "0", lg: "16px" }}
      >
        <Button
          flex="1"
          size="lg"
          disabled={otcIsLoading || !downloadReady}
          mb={{ base: "16px", xl: "0" }}
          px="2px"
          height='48px'
          variant="outline"
          fontFamily="CircularStdBook"
          fontWeight="bold"
          border="2px solid primary.700"
        >
          Download CSV
        </Button>
      </Link>
      <input
        data-counter={downloadCount.toString()}
        data-testid="hidden-download-counter"
        type="hidden"
        value={0}
      />
    </Box>
  )
}
export default DownloadCSV
