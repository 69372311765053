import { DateTime } from "luxon"
import { Box, Button, Divider, Heading, Row, Text } from "native-base"

import { EventTypes, getDisplayTitle } from "models/enums/event-type"
import NotificationDto, { LimitChangeDto } from "models/notification"

import ChangeLimit from "./ChangeLimit"

const GetTemplate = ({
  templateData
}: {
  templateData: NotificationDto
}): JSX.Element => {
  switch (templateData.type) {
  case EventTypes.LimitApplied:
    return <ChangeLimit data={templateData as LimitChangeDto} />

  default:
    return <Box> null</Box>
  }
}

interface ShowTamplateProps {
  templateData: NotificationDto
  setTemplateData: React.Dispatch<
    React.SetStateAction<NotificationDto | undefined>
  >
}

const ShowTamplate = ({ templateData, setTemplateData }: ShowTamplateProps) => {
  const displayTitle = getDisplayTitle(templateData.type)

  return (
    <Box minH="60vh" testID="notification-Template">
      <Row justifyContent={"space-between"}>
        <Heading size="md">{displayTitle}</Heading>
        <Heading display={{ base: "none", md: "block" }} size="md" ml="1rem">
          {DateTime.fromISO(templateData.createdAt).toFormat("dd LLLL yyyy")}
        </Heading>
      </Row>

      <Text p="0.3rem">{templateData.note}</Text>

      <Divider my="16px" />

      <Heading
        display={{ base: "block", md: "none" }}
        size="sm"
        textAlign="end"
      >
        {DateTime.fromISO(templateData.createdAt).toFormat("dd LLLL yyyy")}
      </Heading>
      <GetTemplate templateData={templateData} />

      <Button
        testID="back-to-noti-list"
        w="200px"
        alignSelf="center"
        onPress={() => setTemplateData(undefined)}
      >
        Back
      </Button>
    </Box>
  )
}
export default ShowTamplate
