import jwtDecode from "jwt-decode"
import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useIdleTimer } from "react-idle-timer"
import { useNavigate } from "react-router-dom"

import { useLoginUser } from "components/hooks/useLoginUser"
import { useLogoutUser } from "components/hooks/useLogoutUser"
import {
  sessionExpired as sessionExpiredRoute
} from "components/layouts/Public/routes"
import { useTokenRefreshMutation } from "network/auth"
import { useJwtTokenValid } from "store/hooks/jwtTokenValid"

const defaultIdleSeconds = parseInt(process.env.REACT_APP_IDLE_TIME ?? "300") // 5 minutes

interface SessionPersistence {
  children: JSX.Element,
  idleSeconds?: number,
  autoIgnore?: boolean,
  disableSecureCookie?: boolean
}

// autoIgnore is for testing
//
export default function SessionPersistence ({ children, idleSeconds, autoIgnore = false }: SessionPersistence): JSX.Element {
  const [cookies] = useCookies(["jwt", "username"])
  const [refresh] = useTokenRefreshMutation()
  const { jwtTokenValid } = useJwtTokenValid()
  const { logoutUser } = useLogoutUser()
  const { loginUser } = useLoginUser()
  const navigate = useNavigate()

  const idleInterval = (idleSeconds || defaultIdleSeconds) * 1000

  const handleOnIdle = () => {
    logoutUser()
    navigate(sessionExpiredRoute)
  }

  useEffect(() => {
    if (!jwtTokenValid) {
      logoutUser()
      navigate(sessionExpiredRoute)
    }
  })

  const handleOnAction = async () => {
    // check jwt expiry
    // if within 1 minute, refresh
    // else do nothing

    const decodedJwt: Record<string, unknown> = jwtDecode(cookies.jwt)
    const exp = parseInt(`${decodedJwt["exp"]}`)
    const expMillis = exp * 1000
    const currentTimeMillis = Date.now()
    const expGreaterThanCurTime = expMillis > currentTimeMillis
    const expMinusCurTimeLessThanAMinute = ((expMillis - currentTimeMillis) < (60 * 1000))
    const timeToRefresh = (expGreaterThanCurTime && expMinusCurTimeLessThanAMinute)

    if (!autoIgnore && timeToRefresh) {
      const response = await refresh().unwrap()

      const jwtFromBackend = response.token

      loginUser(jwtFromBackend)
    }
  }

  useIdleTimer({
    startOnMount: true,
    timeout: idleInterval,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500
  })

  return(children)
}
