import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { dashboard } from "components/layouts/Authorized/routes"
import { usePersonSummaryMutation } from "network/person"
import { useAppDispatch } from "store/hooks"
import { setPerson } from "store/slices/person"

type WizardPage = "complete" | "verification"

interface useSetUpTwoFA {
  wizard: WizardPage
  setWizard: (value: React.SetStateAction<WizardPage>) => void
  handleRouteChange: () => Promise<void>
}

export const useSetUpTwoFa = (): useSetUpTwoFA => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [getPersonSummary] = usePersonSummaryMutation()
  const [wizard, setWizard] = useState<WizardPage>("verification")

  useEffect(() => {
    return () => {
      setWizard("verification")
    }
  }, [setWizard])

  const handleRouteChange = async () => {
    const personSummaryResponse = await getPersonSummary().unwrap()

    personSummaryResponse.success && dispatch(setPerson(personSummaryResponse.data))
    personSummaryResponse.success && navigate(dashboard)
  }

  return {
    wizard,
    setWizard,
    handleRouteChange
  }
}
