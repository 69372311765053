
import { removeDuplicateTransaction } from "@axle/web/src/components/util/transactionFilters"
import TransactionDto from "@axle/web/src/models/transactions"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { RootState } from "@axle/web/src/store"

export type TransactionsState = {
  hasMoreTransactions: boolean
  transactions: TransactionDto[]
  currentPage: number
}

const slice = createSlice({
  name: "transactions",
  initialState: {
    hasMoreTransactions: false,
    transactions: [],
    currentPage: 0
  } as TransactionsState,
  reducers: {
    setTransactions: (
      state,
      { payload: transactions }: PayloadAction<TransactionDto[]>
    ) => {
      state.transactions = removeDuplicateTransaction([...state.transactions, ...transactions])
    },
    resetTransactions: (
      state
    ) => {
      state.transactions = []
      state.currentPage = 0
      state.hasMoreTransactions = false
    },
    setHasMoreTransactions: (
      state,
      { payload: hasMoreTransactions }: PayloadAction<boolean>
    ) => { state.hasMoreTransactions = hasMoreTransactions },
    incrementCurrentPage: (
      state
    ) => { state.currentPage++ }
  }
})

export const {
  setTransactions,
  resetTransactions,
  setHasMoreTransactions,
  incrementCurrentPage
} = slice.actions
export default slice.reducer
export const fetchTransactions = (state: RootState): TransactionsState => state.transactions
