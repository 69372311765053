import { Column, Heading, Spacer, Text } from "native-base"

import BoxCard from "components/inline/BoxCard/NbBoxCard"

const PolicyHeader = () => {
  return (
    <BoxCard p="0" testID="spend-control-header">
      <Column>
        <Heading size="md">Expense Policy Set up</Heading>
        <Spacer h="1.5rem" />
        <Text>
          This policy covers expenses paid directly by your company. As a
          company Admin, you can set default spend controls for all users in
          your company.
        </Text>
        <Spacer h="1rem" />
        <Text>
          Spend controls will restrict the type of merchants employees can spend
          at with their company charge card.
        </Text>
        <Spacer h="1rem" />
        <Text>
          These limits will be applied to all cards that are ordered. You can
          choose whether or not some controls can be edited by budget owners.
          (Budget owners will only have access to spend controls for the budgets
          they own)
        </Text>
      </Column>
    </BoxCard>
  )
}

export default PolicyHeader
