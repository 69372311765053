import { people as personsRoute } from "@axle/web/src/components/layouts/Authorized/routes"
import {
  FormControl,
  Heading,
  Input,
  Button,
  Box,
  Text,
  Row,
  Column,
  Spacer,
  Flex,
  Select
} from "native-base"
import { useEffect } from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import DatePick from "components/inline/DatePicker"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { countryData } from "components/util/country-data"
import {
  isEmail,
  isPostCode,
  isTelephone,
  getCountry
} from "components/util/form-validators"
import PersonDto from "models/person"

import FormInput from "./talons/FormInput"
import { useAddPersonForm } from "./talons/useAddPersonForm"

// can be extracted for di in a non web context (eg native)
export const DefaultRedirector = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(personsRoute)
  })

  return <></>
}

export const AddPersonForm = ({
  person,
  Redirector = DefaultRedirector
}: {
  person: PersonDto
  Redirector?: typeof DefaultRedirector
}): JSX.Element => {
  const navigate = useNavigate()

  const {
    showPage,
    setShowPage,
    validateDetail,
    submitAddPersonRequest,
    isChecking,
    isSuccess,
    createPersonInFlight,
    control,
    handleSubmit,
    getValues,
    errors,
    setSelectedCountry,
    selectedCountry
  } = useAddPersonForm(person)

  const PersonForm = (
    <Column>
      <Heading size="md">Full name and contact details </Heading>
      <Spacer h="16px" flexGrow="0" />
      <Flex flexDir={{ base: "column", md: "row" }}>
        <Box flex="1">
          <FormInput
            control={control}
            errors={errors.first}
            name={"first"}
            label={"First Name"}
            placeholder="First name"
            testID="first-name-field"
            isRequired
          />
        </Box>
        <Spacer flexGrow="0" w="16px" />
        <Box flex="1">
          <FormControl isInvalid={"middle" in errors}>
            <FormControl.Label>Middle Name</FormControl.Label>
            <Controller
              control={control}
              name={"middle"}
              rules={{ required: false, minLength: 1 }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  testID="middle-name-field"
                  onBlur={onBlur}
                  onChangeText={(val) => onChange(val)}
                  value={value}
                  px="16px"
                  placeholder="Middle name"
                />
              )}
            />
            <Box minH={{ base: "60px", md: "20px" }}>
              <Text
                fontSize="xs"
                mt="8px"
                w={{ base: "unset", lg: "400px" }}
              >
                Required for identification, leave blank if the person does not
                have one.
              </Text>
            </Box>
          </FormControl>
        </Box>
      </Flex>

      <Flex flexDir={{ base: "column", md: "row" }}>
        <Box flex="1">
          <FormInput
            control={control}
            errors={errors.last}
            name={"last"}
            label={"Last Name"}
            placeholder="Last name"
            testID="last-name-field"
            isRequired
          />
        </Box>
        <Spacer flexGrow="0" w="16px" />

        <Box flex="1">
          <FormControl isInvalid={"DateOfBirth" in errors}>
            <FormControl.Label>Date of Birth</FormControl.Label>
            <Controller
              control={control}
              name="DateOfBirth"
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, value } }) => (
                <DatePick
                  testID="dob-field"
                  value={value}
                  onChangeText={onChange}
                  selectYear
                />
              )}
            />

            <Box minH="20px">
              <FormControl.ErrorMessage testID={"$dob-error"}>
                {errors.DateOfBirth && errors.DateOfBirth.message}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>
      </Flex>

      <Flex flexDir={{ base: "column", lg: "row" }}>
        <Box flex="1">
          <FormInput
            control={control}
            errors={errors.email}
            name={"email"}
            label={"Email"}
            placeholder="Email"
            isRequired
            testID="email-field"
            validate={(value) => isEmail(value)}
          />
        </Box>

        <Spacer flexGrow="0" w="16px" />

        <Box flex="1">
          <FormControl isRequired isInvalid={"mobile" in errors}>
            <FormControl.Label>Mobile</FormControl.Label>
            <Row>
              <Box flex="1">
                <Controller
                  control={control}
                  name={"mobile"}
                  rules={{
                    required: "This field is required",
                    validate: (value) => {
                      const mobileCountryCode = countryData.find(
                        (item) =>
                          item.dial_code === getValues("mobileCountryCode")
                      )?.code
                      return mobileCountryCode
                        ? isTelephone(value, mobileCountryCode)
                        : "Please enter a valid country code"
                    }
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      testID="mobile-field"
                      onBlur={onBlur}
                      onChangeText={(val) => onChange(val)}
                      value={value}
                      px="25px"
                      placeholder="0000 000 000"
                      leftElement={<Box w="44px" />}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={"mobileCountryCode"}
                  render={({
                    field: {
                      onChange: onCodeChange,
                      onBlur: onCodeBlur,
                      value: codeValue
                    }
                  }) => (
                    <Input
                      testID="mobile-country-code-field"
                      position="absolute"
                      onBlur={onCodeBlur}
                      onChangeText={(countryCode) => onCodeChange(countryCode)}
                      value={codeValue}
                      ml="16px"
                      leftElement={<Box>{"("}</Box>}
                      rightElement={<Box>{")"}</Box>}
                      backgroundColor="none"
                      borderColor="transparent"
                      px="5px"
                      w="44px"
                      borderWidth="0"
                      placeholder="+61"
                      _stack={{
                        style: { borderWidth: 0 }
                      }}
                    />
                  )}
                />
              </Box>
            </Row>

            <Box minH="20px">
              <FormControl.ErrorMessage testID="mobile-field-error">
                {errors.mobile && errors.mobile.message}
              </FormControl.ErrorMessage>
            </Box>
          </FormControl>
        </Box>
      </Flex>
    </Column>
  )

  const AddressForm = (
    <Box flexDir="column" testID="AddressForm">
      <Heading size="md">Home address</Heading>
      <Spacer h="24px" flexGrow="0" />
      <Text fontSize="lg">
        The users home address will be used to verify their identity. Please do
        not enter your company address.
      </Text>
      <Spacer h="48px" flexGrow="0" />

      <FormInput
        control={control}
        errors={errors.address1}
        name={"address1"}
        label={"Unit and House number"}
        placeholder="Unit and House number"
        isRequired
        testID="address1-field"
      />

      <FormInput
        control={control}
        errors={errors.address2}
        name={"address2"}
        label={"Street name"}
        placeholder="Street name"
        isRequired
        testID="address2-field"
      />

      <FormInput
        control={control}
        errors={errors.postTown}
        name={"postTown"}
        label={"City/Suburb"}
        placeholder="City/Suburb"
        isRequired
        testID="postTown-field"
      />

      <Flex flexDir={{ base: "column", md: "row" }} flex="1">
        <Box flex="1">
          <FormInput
            control={control}
            errors={errors.postCode}
            name={"postCode"}
            label={"Post code"}
            placeholder="Post code"
            isRequired
            testID="postCode-field"
            validate={(value) => isPostCode(value)}
          />
        </Box>
        <Spacer flexGrow="0" w="16px" />
        <Box flex="1">
          <FormInput
            control={control}
            errors={errors.state}
            name={"state"}
            label={"State/Territory/Region"}
            placeholder="State/Territory/Region"
            isRequired
            testID="state-field"
          />
        </Box>
      </Flex>

      <FormControl isRequired isInvalid={"country" in errors}>
        <FormControl.Label>Country</FormControl.Label>
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, value } }) => (
            <Select
              testID="country-field"
              placeholder="Select country"
              selectedValue={value}
              onValueChange={(countryCode) => {
                setSelectedCountry(getCountry(countryCode))
                onChange(countryCode)
              }}
            >
              {countryData.map(({ name, code }) => (
                <Select.Item key={code} label={name} value={code}></Select.Item>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )

  const page = {
    addPersonFrom: (
      <BoxCard key="people-key" testID="add-person">
        <Flex flexDir="column">
          <Text fontSize="lg">
            Enter the details of the person you’d like to add to Cape. You can
            set their monthly limit in your People dashboard once the user has
            been added.
          </Text>
        </Flex>

        <Text fontSize="lg">
          All fields are required unless marked optional.
        </Text>
        <Spacer h="48px" flexGrow="0" />

        <Box w={{ base: "100%", sm: "70%", lg: "60%", xl: "40%" }}>
          {PersonForm}
          <Spacer h="32px" flexGrow="0" />
          {AddressForm}
        </Box>

        <Row justifyContent="end" mt="4">
          <Button
            testID="add-person-form-cancel-button"
            onPress={() => navigate(-1)}
            px="16px"
            w="150px"
            variant="outline"
          >
            Cancel
          </Button>

          <Button
            ml="16px"
            isLoading={isChecking}
            testID="add-person-form-validate"
            onPress={handleSubmit(validateDetail)}
            px="16px"
            w="150px"
          >
            Next
          </Button>
        </Row>
      </BoxCard>
    ),
    confirm: (
      <BoxCard flexDir="column" testID="confirm-page">
        <Heading> Confirm</Heading>

        <Box p="16px">
          <Text >
            Please confirm the persons details are correct.
          </Text>
          <Spacer h="16px" flexGrow="0" />

          <Text >Full name</Text>
          <Text>{`${getValues("first")} ${getValues("middle")} ${getValues(
            "last"
          )}`}</Text>
          <Spacer h="16px" flexGrow="0" />

          <Text >Date of birth</Text>
          <Text>{getValues("DateOfBirth")}</Text>
          <Spacer h="16px" flexGrow="0" />

          <Flex
            flexDir={{ base: "column", sm: "row" }}
            flex="1"
            w={{ base: "100%", lg: "60%" }}
          >
            <Column flex="1">
              <Text >Email</Text>
              <Text>{getValues("email")}</Text>
            </Column>
            <Spacer h="16px" flexGrow="0" />

            <Column flex="1">
              <Text >Mobile</Text>
              <Text>{getValues("mobile")}</Text>
            </Column>
          </Flex>

          <Spacer h="16px" flexGrow="0" />

          <Text >Address:</Text>

          <Text>{getValues("address1")}</Text>
          <Text>{getValues("address2")}</Text>
          <Text>{`${getValues("postTown")} ${getValues("postCode")}`}</Text>
          <Text>{getValues("state")}</Text>
          <Text>{selectedCountry}</Text>

          <Spacer h="16px" flexGrow="0" />

          <Row flex="1" justifyContent="end">
            <Button
              disabled={createPersonInFlight}
              testID="confirm-cancel-button"
              onPress={() => setShowPage("addPersonFrom")}
              px="16px"
              w="150px"
              variant="outline"
            >
              Cancel
            </Button>

            <Button
              disabled={createPersonInFlight}
              ml="16px"
              testID="add-person-form-submit"
              w="150px"
              onPress={handleSubmit(submitAddPersonRequest)}
              px="16px"
            >
              Add person
            </Button>
          </Row>
        </Box>
      </BoxCard>
    )
  }

  return (
    <>
      <PageHeading  showBackBtn testID="add-person-back">
        Add Employee
      </PageHeading>

      {page[showPage]}

      {isSuccess && <Redirector />}
    </>
  )
}
