import { Factory } from "fishery"

import { CardDto } from "models/card"
import { CardStatus } from "models/enums/card-status"

export const CardFactory = Factory.define<CardDto>(({ sequence, associations }) => ({
  id: sequence,
  internalLedgerId: sequence,
  status: associations.status || CardStatus.Active,
  name: "card name",
  truncatedPan: "card pan",
  expiryDate: "04/23",
  token: ""
}))

export default CardFactory
