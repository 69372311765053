import {
  Box,
  Heading,
  Image,
  Flex,
  useBreakpointValue
} from "native-base"
import { useLocation } from "react-router-dom"

import ribbonImage from "assets/images/ribbon.png"
import { TwoFAForm } from "components/inline/TwoFAForm/TwoFaForm"
import PublicLayout from "components/layouts/Public"

export interface TwoFAVerifcationProps {
  username: string
  emailTruncated: string
  phoneTruncated: string
  communicationType: number
}

export const TwoFAVerifcation = () => {
  const location = useLocation()
  const locationState = location.state as TwoFAVerifcationProps
  const { username, emailTruncated, phoneTruncated, communicationType } = locationState

  const flexDirection = useBreakpointValue({ base: "column", md: "row" })

  return <PublicLayout>
    <Box
      width="100%"
      maxWidth={"960px"}
      marginX={"auto"}
      marginY={"1rem"}
      testID="TwoFAVerification"
    >
      <Box marginX={4} bg="white" p={6} borderRadius="6px">
        <Heading fontSize={"3xl"} marginBottom={8}>Enter your verification code</Heading>
        <Flex flexDirection={flexDirection} justifyContent="space-between" flexWrap="wrap">
          <Box maxWidth="650px" w={{ base: "100%", lg: "50%" }}>
            <TwoFAForm
              email={username}
              emailTruncated={emailTruncated}
              phoneTruncated={phoneTruncated}
              communicationType={communicationType}
            />
          </Box>
          <Box mt="25px" alignSelf="flex-end">
            <Image
              width={{ base: "250px", md: "400px" }}
              height={{ base: "205px", md: "355px" }}
              src={ribbonImage}
              alt="ribbonImage"
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  </PublicLayout>
}
