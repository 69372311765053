import {
  Box,
  Heading,
  Row,
  Column,
  Text,
  Button,
  Spacer,
  Divider,
  Switch,
  Radio,
  Input,
  FormControl,
  WarningOutlineIcon
} from "native-base"
import { useEffect, useState } from "react"

import { receiveSignalrEvents } from "components/providers/Events/signalR"
import { EventTypes } from "models/enums/event-type"
import Notification from "models/notification"
import {
  useFetchXeroAccountsMutation,
  usePostXeroAccountSelectMutation,
  usePostXeroAccountCreateMutation,
  XeroAccount
} from "network/connections"

import { ConnectionMode } from "."

type FormMode = "create" | "select"

export const XeroAccounts = ({ setConnectionMode }: { setConnectionMode: (mode: number) => void }): JSX.Element => {
  const [selectedAccount, setSelectedAccount] = useState<string|null>(null)
  const [newAccountName, setNewAccountName] = useState<string>("")
  const [listedAccounts, setListedAccounts] = useState<XeroAccount[]>([])

  const [isXeroSelecting, setIsXeroSelecting] = useState<boolean>(false)
  const [isNameInvalid, setIsNameInvalid] = useState<boolean>(false)
  const [formMode, setFormMode] = useState<FormMode>("select")

  const [fetchXeroAccounts] = useFetchXeroAccountsMutation()
  const [postXeroAccountSelect] = usePostXeroAccountSelectMutation()
  const [postXeroAccountCreate] = usePostXeroAccountCreateMutation()

  useEffect(() => {
    async function listAccounts() {
      const listAccountsResponse = await fetchXeroAccounts().unwrap()

      // console.log("listAccountsResponse", listAccountsResponse.data.items)
      setListedAccounts(listAccountsResponse.data.items)
    }

    listAccounts()
  }, [])

  useEffect(() => {
    return () => {
      setSelectedAccount(null)
      setNewAccountName("")
      setListedAccounts([])
      setIsXeroSelecting(false)
      setFormMode("select")
    }
  }, [])

  const confirmChoices = async () => {
    formMode === "select" && selectedAccount && postAccountSelect(selectedAccount)
    formMode === "create" && newAccountName && postAccountCreate(newAccountName)
  }

  const postAccountSelect = async (externalRef: string) => {
    setIsXeroSelecting(true)
    await postXeroAccountSelect({ externalRef }).unwrap()
  }

  const postAccountCreate = async (newAccountName: string) => {
    setIsXeroSelecting(true)
    await postXeroAccountCreate(`"${newAccountName}"`).unwrap()
  }

  const [toggleValues, setToggleValues] = useState<Record<string, boolean>>({})

  function confirmDisabled(): boolean {
    let disabled = false
    formMode === "create" && !newAccountName && (disabled = true)
    formMode === "select" && !selectedAccount && (disabled = true)
    return disabled
  }

  receiveSignalrEvents(
    (notification: Partial<Notification>) => {
      if(notification.type === EventTypes.BankAccountAlreadyExists) {
        setIsNameInvalid(true)
        setIsXeroSelecting(false)
      }
    }
  )

  const BankAccount = ({
    accountName,
    externalRef
  }: {
    accountName: string
    externalRef: string
  }): JSX.Element => {
    const toggleInclusion = (
      externalRef: string
    ): ((included: boolean) => void) => {
      return (included: boolean) => {
        if (included) {
          setSelectedAccount(externalRef)
          setToggleValues({ [externalRef]: included })
        }
      }
    }

    return (
      <Row alignItems="center" justifyContent="flex-start">
        <Column mr={4}>
          <Switch
            testID={`switch-${externalRef}`}
            isChecked={toggleValues[externalRef]}
            onValueChange={toggleInclusion(externalRef)}
          />
        </Column>
        <Column>
          <Row>
            <Heading size="sm">{accountName}</Heading>
          </Row>
          <Row>{externalRef}</Row>
        </Column>
      </Row>
    )
  }

  return (
    <Box>
      <Radio.Group accessibilityLabel="formMode" name="formMode" value={formMode} onChange={nextValue => setFormMode(nextValue as FormMode)}>
        <Row flexDirection="column">
          <Heading size="md" mb={4}>
            Choose a Xero account
          </Heading>
          <Text>
            Please choose a Xero account for your bank feed connection. This is
            where we’ll send your Cape transactions.
          </Text>
        </Row>
        <Divider mt={4} mb={8} />
        <Row>
          <Column mr={4}>
            <Radio accessibilityLabel="formMode.select" value="select" />
          </Column>
          <Column flexShrink={2} opacity={formMode === "create" ? 50 : 100}>
            <Heading size="md">Connect to an existing Xero account</Heading>
            <Text>
              If you’ve already created an account for Cape in Xero, please
              select that from the list below. Do not select an account that’s
              connected to another bank feed.
            </Text>
            <Divider mt={4} mb={8} />
            <Spacer h={6} />
            {listedAccounts.map((account) => (
              <Box key={account.externalRef}>
                <BankAccount
                  accountName={account.name}
                  externalRef={account.externalRef}
                />
                <Divider mt={2} mb={8} />
              </Box>
            ))}
          </Column>
        </Row>
        <Row>
          <Column mr={4}>
            <Radio accessibilityLabel="formMode.create" value="create" />
          </Column>
          <Column flexShrink={2} opacity={formMode === "select" ? 50 : 100}>
            <Heading size="md">Create a new Xero account</Heading>
            <Text>
              This will create a new account in Xero under your organisation.
            </Text>
            <Spacer h={4} />
            <FormControl isInvalid={isNameInvalid}>
              <Input
                accessibilityLabel="formMode.create.nameInput"
                value={newAccountName}
                onChangeText={setNewAccountName}
                type="text"
                placeholder="Account name" />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                This account name already exists
              </FormControl.ErrorMessage>
            </FormControl>
          </Column>
        </Row>
      </Radio.Group>
      <Spacer h={4} />
      <Row w-full justifyContent="space-between">
        <Column>
          <Button testID="cancel-select-button" onPress={() => setConnectionMode(ConnectionMode.General)}>Cancel</Button>
        </Column>
        <Column>
          <Button
            isLoading={isXeroSelecting}
            isLoadingText={"Confirming"}
            testID="confirm-choices-button"
            isDisabled={confirmDisabled()}
            onPress={confirmChoices}
          >
            Confirm
          </Button>
        </Column>
      </Row>
    </Box>
  )
}
