import {
  Box,
  Button,
  FormControl,
  Heading,
  Image,
  Spacer,
  Text,
  VStack,
  Flex
} from "native-base"
import * as React from "react"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import intersectImage from "assets/images/intersect.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import DatePick from "components/inline/DatePicker"
import PublicLayout from "components/layouts/Public"
import { login as loginRoute } from "components/layouts/Public/routes"
import { isPassword, isPasswordMatch } from "components/util/form-validators"

import { PasswordInput } from "../../inline/PasswordInput"

import { useResetPassword } from "./talons/useResetPassword"

export interface PasswordInputProps {
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ResetPassword = (): JSX.Element => {
  const { getValues, submitResetPassword, isSuccess, errors, control } =
    useResetPassword()

  const navigate = useNavigate()

  return (
    <PublicLayout>
      <BoxCard
        w={{ base: "90%", md: "60%" }}
        testID="reset-password"
        alignSelf="center"
      >
        {isSuccess ? (
          <Box
            margin={{ sm: "auto", md: "inherit" }}
            width={{ sm: "70%", md: "100%" }}
            justifyContent="center"
          >
            <Heading size="2xl" testID="forgot-passowrd-header">
              Thank you!
            </Heading>
            <Spacer h="4rem" />
            <Text >Your password has been reset.</Text>
            <Spacer h="3rem" />
            <Button
              testID="login-return"
              maxW="240px"
              onPress={() => {
                navigate(loginRoute)
              }}
            >
              Log in
            </Button>
          </Box>
        ) : (
          <Box w={{ base: "100%", md: "50%" }} zIndex={2}>
            <Heading size="2xl" testID="forgot-passowrd-header">
              Reset password
            </Heading>
            <Spacer h="2.5rem" />
            <VStack
              maxWidth="100%"
              alignItems="flex-start"
              minWidth={{ lg: "300px" }}
              width={{
                base: "100%",
                sm: "85%",
                md: "80%",
                lg: "70%",
                xl: "60%"
              }}
            >
              <Controller
                control={control}
                name={"newPassword"}
                rules={{
                  required: "required",
                  validate: (value) => isPassword(value).isValid
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    showRules
                    label={"New Password"}
                    name={"newPassword"}
                    errors={errors}
                    autoFocus
                    onBlur={onBlur}
                    onChangeText={(val) => onChange(val)}
                    value={value}
                    testID="newPassword"
                    placeholder={"New Password"}
                    color={"gray.400"}
                  />
                )}
              />
              <Controller
                control={control}
                name={"confirmPassword"}
                rules={{
                  required: "required",
                  validate: (value) =>
                    isPasswordMatch(value, getValues("newPassword"))
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    label={"Confirm Password"}
                    name={"confirmPassword"}
                    errors={errors}
                    onBlur={onBlur}
                    onChangeText={(val: string) => onChange(val)}
                    value={value}
                    testID="confirmPassword"
                    placeholder={"Confirm Password"}
                    color={"gray.400"}
                  />
                )}
              />

              <FormControl isInvalid={!!errors?.dateOfBirth} zIndex={2}>
                <FormControl.Label _text={{ color: "red.300" }} mb="8px">
                  Date of birth
                </FormControl.Label>

                <Controller
                  control={control}
                  name="dateOfBirth"
                  rules={{
                    required: "required"
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePick
                      selectYear
                      isInvalid={!!errors?.["dateOfBirth"]}
                      testID="date-of-birth"
                      value={value}
                      onChangeText={onChange}
                    />
                  )}
                />

                <FormControl.ErrorMessage _text={{ fontSize: "14px" }}>
                  {errors?.dateOfBirth && errors.dateOfBirth.message}
                </FormControl.ErrorMessage>
              </FormControl>

              <Spacer h="32px" />

              <Button
                testID="save-password"
                w="240px"
                onPress={submitResetPassword}
              >
                Save
              </Button>
            </VStack>
          </Box>
        )}

        <Flex testID="burt" alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }} mt="16px">
          <Image
            width={{ base: "266px", md: "300px", xl: "400px" }}
            height={{ base: "250px", md: "286px", xl: "380px" }}
            src={intersectImage}
            alt="intersectImage"
          />
        </Flex>
      </BoxCard>
    </PublicLayout>
  )
}
