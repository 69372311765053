export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN
export const REACT_APP_API_BASE = process.env.REACT_APP_API_BASE
export const REACT_APP_LOGGLY_API_BASE = process.env.REACT_APP_LOGGLY_API_BASE
export const REACT_APP_LOGGLY_CUSTOMER_TOKEN = process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN
export const REACT_APP_COMPILE_MODE = process.env.REACT_APP_COMPILE_MODE
export const REACT_APP_GTM = process.env.REACT_APP_GTM

export const REACT_APP_FF__RESET_PASSWORD = process.env.REACT_APP_FF__RESET_PASSWORD
export const REACT_APP_FF__UPDATE_PROFILE = process.env.REACT_APP_FF__UPDATE_PROFILE
export const REACT_APP_FF__SETTINGS_PAGE = process.env.REACT_APP_FF__SETTINGS_PAGE
export const REACT_APP_FF__GOOGLE_OAUTH = process.env.REACT_APP_FF__GOOGLE_OAUTH
export const REACT_APP_FF__TRANSACTIONS_DOWNLOAD = process.env.REACT_APP_FF__TRANSACTIONS_DOWNLOAD
export const REACT_APP_FF__SPEND_CONTROLS = process.env.REACT_APP_FF__SPEND_CONTROLS
