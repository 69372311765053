import Card from "@axle/web/src/components/inline/CardVisual"
import useCardsNav from "@axle/web/src/components/inline/CardVisual/talons/useCardsNav"
import { currentCardContext } from "@axle/web/src/components/layouts/Authorized/MaybeRender"
import {
  activateCard as activateCardRoute,
  myCards as myCardsRoute
} from "@axle/web/src/components/layouts/Authorized/routes"
import { CardDto } from "@axle/web/src/models/card"
import { Button, Flex, Link, Text } from "native-base"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  cards: CardDto[]
  currentCard: CardDto
  cardIndex: number
  showManageLink?: boolean
}

const CardsNav: React.FC<Props> = ({
  showManageLink = true
}: Props): JSX.Element | null => {
  const {
    card,
    cardEnabled,
    cardLocked,
    cardActivating,
    cardReactivating,
    cardSuspending,
    cardOrdering,
    cardPreActive
  } = useContext(currentCardContext)

  const navigate = useNavigate()
  const { refreshCard } = useCardsNav()

  return (
    <Flex testID="cards-nav" flexDirection="column">
      <Flex direction="column" alignItems="center" position="relative">
        {cardEnabled ? (
          <Link
            testID="card-management"
            onPress={() => {
              navigate(myCardsRoute)
            }}
          >
            <Card
              enabled={cardEnabled}
              locked={cardLocked}
              fullName={card?.name}
              lastFour={card?.truncatedPan}
              expiryDate={card?.expiryDate}
            />
          </Link>
        ) : (
          <Link pointerEvents="none">
            <Card
              enabled={cardEnabled}
              locked={cardLocked}
              fullName={card?.name}
              lastFour={card?.truncatedPan}
              expiryDate={card?.expiryDate}
            />
          </Link>
        )}
      </Flex>
      {(cardActivating ||
        cardSuspending ||
        cardOrdering ||
        cardReactivating) && (
        <Button
          testID="refresh-button"
          mt="24px"
          onPress={refreshCard(card.id)}
        >
            Check Status
        </Button>
      )}
      {cardPreActive ? (
        <Button
          testID="activate-card-button"
          mt="24px"
          onPress={() => navigate(activateCardRoute)}
        >
          Activate your card
        </Button>
      ) : (
        showManageLink && (
          <Flex w="full" mt="32px">
            <Link
              display="flex"
              alignItems="center"
              justifyContent={{ base: "center", md: "flex-start" }}
              testID="card-management-button"
              onPress={() => {
                navigate(myCardsRoute)
              }}
            >
              <Text fontWeight="bold" color="primary.600" marginRight="16px">
                {"Manage card >"}
              </Text>
            </Link>
          </Flex>
        )
      )}
    </Flex>
  )
}

export default CardsNav
