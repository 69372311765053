import { Column, Heading, Spacer, Text } from "native-base"

import BoxCard from "components/inline/BoxCard/NbBoxCard"

const PolicyHeader = () => {
  return (
    <BoxCard p="0" testID="spend-control-header">
      <Column>
        <Heading size="md">Expense Policy</Heading>
        <Spacer h="1.5rem" />
        <Text>
          Merchant spend controls that have been set by your company Admin(s)
          are shown here. As a budget owner, your company Admin may grant
          delegate authority to you, to edit these controls for your budgets.
        </Text>
        <Spacer h="1rem" />
        <Text>
          If you have been granted authority, you will be able to add employees
          to the blocked or allowed spenders list.
        </Text>
      </Column>
    </BoxCard>
  )
}

export default PolicyHeader
