import {
  Box,
  Heading,
  Text,
  Flex,
  Spacer,
  useBreakpointValue,
  Image
} from "native-base"

import waveImage from "assets/images/wave.png"
import PublicLayout from "components/layouts/Public"

interface IssuePageTemplateProps {
  actionArea?: JSX.Element
  titleText: string
  contentText?: string
}

export const IssuePageTemplate = ({
  actionArea,
  titleText,
  contentText
}: IssuePageTemplateProps) => {
  const flexDirection = useBreakpointValue({ base: "column", lg: "row" })
  const boxWidth = useBreakpointValue({ sm: "100%", md: "75%" })

  return <PublicLayout>
    <Box marginX="auto" width={boxWidth}>
      <Flex
        w="100%"
        mt={6}
        p={4}
        alignItems="center"
        justifyContent="space-between"
        flexDirection={flexDirection}
      >
        <Box marginBottom={6} w={{ base: "100%", lg: "50%" }}>
          <Heading
            testID="expired-heading"
            pb={4}
            fontSize="32px"
            fontFamily="GTPressuraPro"
          >
            {titleText}
          </Heading>

          <Text fontSize="sm">
            {contentText}
          </Text>
          <Spacer marginY={4} />
          {actionArea}
        </Box>
        <Flex testID="expired-icon" alignItems="center" justify="center" alignSelf={{ base: "unset", lg: "flex-end" }} mt="16px">
          <Image
            width={{ base: "285px", md: "400px", xl: "440px" }}
            height={{ base: "85px", md: "118", xl: "129px" }}
            src={waveImage}
            alt="waveImage"
          />
        </Flex>
      </Flex>
    </Box>
  </PublicLayout>
}
