import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  Text,
  Flex,
  FormControl,
  Row,
  Spacer
} from "native-base"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import activateCardImage from "assets/images/activateCard.png"
import intersectImage from "assets/images/intersect.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { pinReminder as getPinPath } from "components/layouts/Authorized/routes"
import { CardStatus } from "models/enums/card-status"

import { useActivateCard, text } from "./talons/useActivateCard"

export const ActivateCard = (): JSX.Element => {
  const {
    submitActivateCard,
    success,
    isLoading,
    errors,
    currentCard,
    cardIsLoading,
    control
  } = useActivateCard()

  const navigate = useNavigate()
  const isCardAwaitingActivation =
    currentCard && currentCard.status === CardStatus.AwaitingActivation

  return (
    <Box testID="activate-card">
      <PageHeading  showBackBtn testID="activate-card-header">
        {text.header}
      </PageHeading>

      <BoxCard>
        {currentCard ? (
          !success && isCardAwaitingActivation ? (
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              mt="4"
              p={{ base: "1rem 1rem", md: "2rem 2rem" }}
              testID="box"
            >
              <Flex flex="1" maxW="600px" minW="300px">
                <Text>{text.content}</Text>

                <Text mt="6" fontWeight="bold">
                  Token number
                </Text>
                <FormControl isInvalid={!!errors.token}>
                  <Controller
                    control={control}
                    name="token"
                    rules={{
                      required: "required",
                      minLength: { value: 9, message: text.tokenErr },
                      maxLength: { value: 9, message: text.tokenErr }
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        onChangeText={(val) => onChange(val)}
                        onBlur={onBlur}
                        value={value}
                        testID="activate-card-token"
                        mt="4"
                      />
                    )}
                  />

                  <FormControl.ErrorMessage>
                    {errors.token && errors.token.message}
                  </FormControl.ErrorMessage>
                </FormControl>
                <Box
                  display={{ base: "flex", md: "none" }}
                  alignItems="center"
                >
                  <Image
                    width="288px"
                    height="288px"
                    src={activateCardImage}
                    alt="activateCardImage"
                    resizeMode="contain"
                  />
                </Box>

                <Row mt="48px">
                  <Button
                    flex="1"
                    testID="activate-card-cancel"
                    onPress={() => navigate(-1)}
                    disabled={isLoading || cardIsLoading}
                    variant="outline"
                  >
                    {text.cancelBtn}
                  </Button>

                  <Spacer w="16px" flexGrow="0" />

                  <Button
                    flex="1"
                    testID="activate-card-submit"
                    onPress={submitActivateCard(currentCard.id)}
                    disabled={isLoading || cardIsLoading}
                    isLoading={isLoading || cardIsLoading}
                  >
                    {"Activate your card"}
                  </Button>
                </Row>
              </Flex>

              <Box
                flex="1"
                ml="48pxpx"
                testID="activate-card-image"
                alignItems="center"
                justifyContent="center"
                display={{ base: "none", md: "flex" }}
              >
                <Image
                  width="400px"
                  height="400px"
                  src={activateCardImage}
                  resizeMode="contain"
                  alt="cardToken"
                />
              </Box>
            </Flex>
          ) : (
            <Box
              minH="50vh"
              display="flex"
              flexDir={{ base: "column", xl: "row" }}
              justifyContent="space-between"
              alignItems={{ base: "center", lg: "flex-start" }}
            >
              <Box testID="hitest">
                <Heading size="xl" isTruncated>
                  {text.completeHeader}
                </Heading>
                <Text mt="16px">{text.completeContent}</Text>

                <Button
                  testID="activate-close-button"
                  w="150px"
                  mt="48px"
                  onPress={() => navigate(getPinPath)}
                >
                  {text.getPinBtn}
                </Button>
              </Box>

              <Flex testID="activate-card-image" alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }} mt="16px">
                <Image
                  width={{ base: "250px", md: "300px", xl: "400px" }}
                  height={{ base: "250px", md: "300px", xl: "400px" }}
                  src={intersectImage}
                  resizeMode="contain"
                  alt="intersectImage"
                />
              </Flex>
            </Box>
          )
        ) : (
          <>{text.notCardActivate}</>
        )}
      </BoxCard>
    </Box>
  )
}
