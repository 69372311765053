import { Box, Button, Flex, Image } from "native-base"
import { Controller } from "react-hook-form"

import capeImage from "assets/images/cape.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { isPassword, isPasswordMatch } from "components/util/form-validators"

import { PasswordInput } from "../../inline/PasswordInput"

import { useChangePassword } from "./talons/useChangePassword"

export const ChangePassword = (): JSX.Element => {
  const {
    updateHandler,
    handleSubmit,
    getValues,
    errors,
    control,
    isSubmitting
  } = useChangePassword()

  return (
    <Box>
      <PageHeading  showBackBtn testID="change-password-page">
        Change Password
      </PageHeading>
      <BoxCard>
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "center", md: "flex-start" }}>
          <Box
            minWidth={{ sm: "320px" }}
            width={{ base: "100%", md: "50%" }}
            mb="2rem"
          >
            <Box maxWidth="300px" mb="2rem" testID="oldPasswordWrapper">
              <Controller
                control={control}
                name={"oldPassword"}
                rules={{ required: "required" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    label={"Old Password"}
                    name={"oldPassword"}
                    errors={errors}
                    onBlur={onBlur}
                    onChangeText={(val) => onChange(val)}
                    value={value}
                    testID="oldPassword"
                    placeholder={"Old Password"}
                    backgroundColor="transparent"
                    color={"warmGray.600"}
                  />
                )}
              />
            </Box>
            <Box maxWidth="300px" mb="2rem" testID="newPasswordWrapper">
              <Controller
                control={control}
                name={"newPassword"}
                rules={{
                  required: "required",
                  validate: (value) => isPassword(value).isValid
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    showRules
                    label={"New Password"}
                    name={"newPassword"}
                    errors={errors}
                    onBlur={onBlur}
                    onChangeText={(val) => onChange(val)}
                    value={value}
                    testID="newPassword"
                    placeholder={"New Password"}
                    backgroundColor="transparent"
                    color={"warmGray.600"}
                  />
                )}
              />
            </Box>
            <Box maxWidth="300px" mb="2rem" testID="passwordConfirmedWrapper">
              <Controller
                control={control}
                name={"passwordConfirmed"}
                rules={{
                  required: "required",
                  validate: (value) =>
                    isPasswordMatch(value, getValues("newPassword"))
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    label={"Confirm New Password"}
                    name={"passwordConfirmed"}
                    errors={errors}
                    onBlur={onBlur}
                    onChangeText={(val) => onChange(val)}
                    value={value}
                    onSubmitEditing={handleSubmit(updateHandler)}
                    testID="passwordConfirmed"
                    placeholder={"Confirm New Password"}
                    backgroundColor="transparent"
                    color={"warmGray.600"}
                  />
                )}
              />
            </Box>
            <Button
              testID="contact-form-submit"
              width="210px"
              mt={4}
              isLoading={isSubmitting}
              onPress={handleSubmit(updateHandler)}
            >
              Save
            </Button>
          </Box>
          <Flex>
            <Image
              mt="32px"
              width={{ base: "325px", md: "410px", xl: "480px" }}
              height={{ base: "325px", md: "410px", xl: "480px" }}
              src={capeImage}
              alt="capeImage"
            />
          </Flex>
        </Flex>
      </BoxCard>
    </Box>
  )
}
