import { Text, ITextProps, Row } from "native-base"

import { formatPrice } from "components/util/format-price"

export interface Props extends ITextProps {
  amount: number
  decimalSize?: string
  isDecimalRequired?: boolean
}

export const Currency = ({
  amount,
  size,
  decimalSize,
  color = "warmGray.600",
  isDecimalRequired = false,
  fontFamily = "CircularStdMedium",
  ...props
}: Props): JSX.Element => {
  const { currencyArray } = formatPrice(amount)

  const decimal =
    isDecimalRequired || currencyArray[1] !== "00" ? `.${currencyArray[1]}` : ""

  return (
    <Row testID="currency-element" alignItems="baseline">
      <Text fontFamily={fontFamily} fontSize={size} color={color} {...props}>
        {currencyArray[0]}
      </Text>

      <Text
        fontFamily={fontFamily}
        fontSize={decimalSize ? decimalSize : size}
        color={color}
        {...props}
      >
        {decimal}
      </Text>
    </Row>
  )
}

export default Currency
