import ReceiptsDto from "@axle/web/src/models/receipts"
import prepareHeaders from "@axle/web/src/network/util/prepare-headers"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { RECEIPTS_API_BASE } from "./apiNamespaces"

export interface AddReceiptResponse {
  success: boolean
}

export interface AddReceiptNoteRequest {
  note: string
}

export interface AddReceiptNoteResponse {
  success: boolean
}

export interface GetReceiptRequest {
  id: string
}

export interface GetReceiptResponse {
  success: boolean
  data: ReceiptsDto
}

export interface UpdateReceiptResponse {
  success: boolean
}

export interface UpdateReceiptRequest {
  id: string
}

export const receipts = createApi({
  reducerPath: "receiptsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: RECEIPTS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    addReceipt: builder.mutation<
      AddReceiptResponse,
      FormData
    >({
      query: (data) => ({
        url: "/addReceipt",
        method: "POST",
        body: data
      })
    }),
    addReceiptNote: builder.mutation<
      AddReceiptResponse,
      AddReceiptNoteRequest
    >({
      query: (data) => ({
        url: "/receiptNote",
        method: "POST",
        body: data
      })
    }),
    updateReceipt: builder.mutation<
      UpdateReceiptResponse,
      UpdateReceiptRequest
    >({
      query: (data) => ({
        url: "/updateReceipt",
        method: "PUT",
        body: data
      })
    }),
    getReceipt: builder.mutation<
      GetReceiptResponse,
      GetReceiptRequest
    >({
      query: (data) => ({
        url: "/receipt",
        method: "POST",
        body: data
      })
    }),
    removeReceipt: builder.mutation<
      { success: boolean },
      { id: string }
    >({
      query: (data) => ({
        url: "/removeReceipt",
        method: "POST",
        body: data
      })
    })
  })
})

export const {
  useAddReceiptMutation,
  useAddReceiptNoteMutation,
  useUpdateReceiptMutation,
  useRemoveReceiptMutation,
  useGetReceiptMutation,
  reducer,
  middleware
} = receipts
