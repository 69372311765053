import { useToast } from "native-base"
import { useState, useEffect } from "react"
import {
  useForm,
  Control,
  UseFormHandleSubmit
} from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useTimer } from "react-timer-hook"

import { useLoginUser } from "components/hooks/useLoginUser"
import { useLogoutUser } from "components/hooks/useLogoutUser"
import { dashboard } from "components/layouts/Authorized/routes"
import { lockedAccount, tooManyCodes } from "components/layouts/Public/routes"
import { getErrorMessageObject } from "components/util/handleCatchError"
import { CommunicationType } from "models/enums/communication-type"
import { ErrorCodes } from "models/enums/error-codes"
import {
  useVerifyVerificationMutation,
  useRequestVerificationMutation
} from "network/verification"

import {
  phoneCodeText,
  emailCodeText,
  phoneButtonText,
  emailButtonText,
  codeNotRecent,
  thereWasAnIssue,
  timerText as getTimerText
} from "./copyText"

export interface FormValues {
  OTPCode: string
}

type UseTwoFAForm = {
  control: Control<FormValues, object>
  handleSubmit: UseFormHandleSubmit<FormValues>
  submit: (data: FormValues) => Promise<void>
  submitForNewCode: (type: number) => Promise<void>
  phoneButtonText: string
  emailButtonText: string
  timerText: string
  isVerifying: boolean
  isLoadingOTPCode: boolean
  timerTime: string
  isRunning: boolean
}

export const useTwoFAForm = (email: string, phoneTruncated: string, emailTruncated: string, communicationType: number, timeoutInterval: number, callback?: () => void): UseTwoFAForm => {
  const [timerText, setTimerText] = useState("")
  const toast = useToast()
  const time = new Date()
  const { logoutUser } = useLogoutUser()
  const { loginUser } = useLoginUser()
  time.setSeconds(time.getSeconds() + timeoutInterval)
  const {
    minutes,
    seconds,
    restart,
    isRunning
  } = useTimer({ expiryTimestamp: time })

  const timerTime = isRunning ? `Your code will expire in ${minutes}:${seconds}` : "Your code has expired. Please request a new code to continue."
  const [verifyTwoFA, { isLoading: isVerifying }] = useVerifyVerificationMutation()
  const [requestVerification, { isLoading: isLoadingOTPCode }] = useRequestVerificationMutation()
  const navigate = useNavigate()

  useEffect(() => {
    setTimerText(getTimerText(communicationType, phoneTruncated, emailTruncated))
    return () => {
      setTimerText("")
      toast.closeAll()
    }
  }, [getTimerText(communicationType, phoneTruncated, emailTruncated)])

  const {
    control,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: {
      OTPCode: ""
    }
  })

  const submit = async (data: FormValues) => {
    const { OTPCode } = data
    const emailEncoded = encodeURIComponent(email)

    if(OTPCode) {
      try {
        const response = await verifyTwoFA({ email: emailEncoded, otpCode: OTPCode }).unwrap()

        if(response.code === ErrorCodes.AccountSuspended) {
          logoutUser()
          navigate(lockedAccount)
        }

        if(response.data && response.data.token) {
          const jwtFromBackend = response.data.token
          loginUser(jwtFromBackend)

          if(callback) {
            callback()
          } else {
            navigate(dashboard)
          }
        }
      } catch(error) {
        const errorObject = getErrorMessageObject(error)

        if(errorObject && errorObject.data.code === ErrorCodes.AccountSuspended) {
          logoutUser()
          navigate(lockedAccount)
        } else {
          toast.show({ description: thereWasAnIssue, backgroundColor: "red.300" })
        }
      }
    } else {
      toast.show({ description: "Please enter your code", backgroundColor: "red.300" })
    }
  }

  const submitForNewCode = async (type: number) => {
    const emailEncoded = encodeURIComponent(email)

    try {
      const response = await requestVerification({ username: emailEncoded, communicationType: type }).unwrap()

      setTimerText(getTimerText(type, phoneTruncated, emailTruncated))

      const time = new Date()
      time.setSeconds(time.getSeconds() + 600)
      response && restart(time)

      const toastMessage = type === CommunicationType.Email ? emailCodeText : phoneCodeText(phoneTruncated)

      toast.show({ description: toastMessage, backgroundColor: "green.600" })
    } catch (error) {
      const errorObject = getErrorMessageObject(error)

      if(errorObject && errorObject.data.code === ErrorCodes.NotAdded) {
        logoutUser()
        navigate(tooManyCodes)
      } else {
        toast.show({ description: codeNotRecent, backgroundColor: "red.300" })
      }
    }
  }

  return {
    control,
    handleSubmit,
    submit,
    submitForNewCode,
    isVerifying,
    phoneButtonText: phoneButtonText(phoneTruncated),
    emailButtonText: emailButtonText(emailTruncated),
    timerText,
    isLoadingOTPCode,
    timerTime,
    isRunning
  }
}
