// This will block the template from rendering while a loading dependency is null or in a loading state
//
// Once the dependencies are loaded it makes them available either as props or via contexts
//
// Be careful to use the right context for the dependencies you specified in the MaybeRender's props,
// imo it's preferable just to inject the loaded dependencies as props into the component you want to render, eg:
/*

  <Route path={ authRoutes.orderCard }>
    <Maybe>
      {resolved => <OrderCard person={resolved.person} />}
    </Maybe>
  </Route>

*/
//
// ** if you must use an exported context, check which one was used for your specified dependencies in the conditional blocks in the code below **
//
// This gets around the nulls problem so that pages and nested components can stay simple!
//
// Right now it lives in layouts but if we need to make specific use cases it might be better to have
// a maybe for each page in the page folder that is composed of parts in a lib
//
// This is part of the three phase architecture and enforces a separation between step two and step three.
//
// Phase 1 - Authentication
// Phase 2 - Load page dependencies
//  \- MAYBEEE
// Phase 3 - Post load (rendering, updating)
//
// Custom templates
// You can specify a ~~ loadingTemplate ~~ to render something other than a loading spinner (eg a template)
//
// You can specify an ~~ errorTemplate ~~ and set ~~ showErrorState to true ~~ to render something other than a loading spinner (eg a template)
//
// You can set ~~ showErrorState to true ~~ to render the default error on load
//
// Refactoring welcome!
//

import { dashboard as dashboardRoute } from "@axle/web/src/components/layouts/Authorized/routes"
import AuthedLoadingStatesCtxt from "@axle/web/src/components/providers/Authenticated/contexts/loadingStates"
import { CardDto } from "@axle/web/src/models/card"
import { EntityDto } from "@axle/web/src/models/entity"
import { CardStatus } from "@axle/web/src/models/enums/card-status"
import { Roles } from "@axle/web/src/models/enums/roles"
import PersonDto from "@axle/web/src/models/person"
import { useCards } from "@axle/web/src/store/hooks/cards"
import { useCurrentCard } from "@axle/web/src/store/hooks/currentCard"
import { useCurrentCardIndex } from "@axle/web/src/store/hooks/currentCardIndex"
import { useEntity } from "@axle/web/src/store/hooks/entity"
import { usePerson } from "@axle/web/src/store/hooks/person"
import { Spinner, Center } from "native-base"
import { createContext } from "react"
import { useNavigate } from "react-router-dom"

import { ErrorPage } from "./ErrorPage"

export type AllDepsCtxtProps = {
  person: PersonDto
  entity: EntityDto
  cards: CardDto[]
  hasCurrentCard: boolean
}
export const allDepsContext = createContext<AllDepsCtxtProps>(
  {} as AllDepsCtxtProps
)
export const Consumer = allDepsContext.Consumer
export const Provider = allDepsContext.Provider

export type CurrentCardCtxProps = {
  card: CardDto
  index: number
  cardLocked: boolean
  cardPreActive: boolean
  cardReactivating: boolean
  cardActivating: boolean
  cardOrdering: boolean
  cardActivated: boolean
  cardSuspending: boolean
  cardEnabled: boolean
}

// decoupling the current card context is necessary because it can be null for users that don't have a card
// be sure to use the ~~ hasCurrentCard ~~ value from allDepsContext if you think it might render null!
//
export const currentCardContext = createContext<CurrentCardCtxProps>(
  {} as CurrentCardCtxProps
)
export const CurrentCardConsumer = currentCardContext.Consumer
export const CurrentCardProvider = currentCardContext.Provider

export type ResolvedChildren = (resolved: AllDepsCtxtProps) => JSX.Element

interface MaybeProps {
  showErrorState?: boolean
  children: ResolvedChildren
  person?: PersonDto | null
  entity?: EntityDto | null
  cards?: CardDto[] | null
  currentCard?: CardDto | null
  currentCardIndex?: number | null
  loadingTemplate?: JSX.Element
  errorTemplate?: JSX.Element
  authorizedRoles?: Roles[]
}

const MaybeRender = ({
  children,
  loadingTemplate,
  showErrorState = true,
  errorTemplate,
  authorizedRoles
}: MaybeProps): JSX.Element => {
  const { person } = usePerson()
  const { entity } = useEntity()

  const navigate = useNavigate()

  const { cards } = useCards()
  const { currentCard: card } = useCurrentCard()
  const { currentCardIndex: index } = useCurrentCardIndex()

  if (authorizedRoles && person) {
    let allow = false
    person.roles.map((personRole) => {
      if (authorizedRoles.indexOf(personRole) > -1) {
        allow = true
      }
    })
    if (!allow) {
      navigate(dashboardRoute)
    }
  }

  return (
    <AuthedLoadingStatesCtxt.Consumer>
      {(loadingStates) => {
        if (showErrorState && loadingStates.isLoadingError) {
          return errorTemplate || <ErrorPage />
        }

        if (entity && person && cards && cards.length === 0 && !loadingStates.isLoading) {
          return (
            <Provider value={{ person, entity, cards, hasCurrentCard: false }}>
              <Consumer>{(context) => children(context)}</Consumer>
            </Provider>
          )
        }

        if (entity && person && cards && card && index !== null && !loadingStates.isLoading) {
          const cardActivated = card.status === CardStatus.Active
          const cardLocked = card.status === CardStatus.Suspended
          const cardPreActive = card.status === CardStatus.AwaitingActivation
          const cardActivating = card.status === CardStatus.Activating
          const cardReactivating = card.status === CardStatus.Reactivating
          const cardOrdering =
            card.truncatedPan === null &&
            card.status === CardStatus.AwaitingActivation
          const cardSuspending = card.status === CardStatus.Suspending
          const cardEnabled = cardPreActive || cardActivated || cardLocked

          return (
            <Provider value={{ person, entity, cards, hasCurrentCard: true }}>
              <CurrentCardProvider
                value={{
                  card,
                  index,
                  cardActivated,
                  cardLocked,
                  cardPreActive,
                  cardReactivating,
                  cardActivating,
                  cardOrdering,
                  cardSuspending,
                  cardEnabled
                }}
              >
                <Consumer>{(context) => children(context)}</Consumer>
              </CurrentCardProvider>
            </Provider>
          )
        }

        return (
          loadingTemplate || (
            <Center>
              <Spinner accessibilityLabel="Maybe Spinner" />
            </Center>
          )
        )
      }}
    </AuthedLoadingStatesCtxt.Consumer>
  )
}

export default MaybeRender
