import { Flex, Box, IFlexProps } from "native-base"
import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import IconButton from "../IconButton"

export interface IconLinkProps extends IFlexProps {
  path?: string
  color?: string
  activeColor?: string
  icon?: JSX.Element
  text?: string
}

const NavLink = ({
  path,
  color = "tertiary.50",
  activeColor = "tertiary.50",
  icon,
  text,
  testID,
  ...props
}: IconLinkProps): JSX.Element => {
  const [isHovered, setIsHover] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = location.pathname === path
  const textColor = isActive ? activeColor : isHovered ? "primary.900" : color

  return (
    <Flex
      position="relative"
      w="220px"
      h="44px"
      bg={isActive ? "primary.700" : "none"}
      pl="28px"
      alignItems="center"
      flexDir="row"
      {...props}
    >
      <IconButton
        testID={testID}
        color={textColor}
        activeColor={textColor}
        isActive={isActive}
        onHoverIn={() => {
          setIsHover(true)
        }}
        onHoverOut={() => {
          setIsHover(false)
        }}
        onPress={() => {
          path && navigate(path)
        }}
        icon={icon}
        text={text}
        fontSize="20px"
        fontFamily="GTPressuraPro"
      />

      <Box
        position="absolute"
        bg={isActive ? "primary.700" : "none"}
        right="-12px"
        w="16px"
        top={0}
        h="44px"
        alignItems="end"
      >
        <Box
          w={0}
          h={0}
          borderTopWidth="11px"
          borderTopStyle="solid"
          borderTopColor="transparent"
          borderBottomWidth="11px"
          borderBottomStyle="solid"
          borderBottomColor="transparent"
          borderRightWidth="8px"
          borderRightStyle="solid"
          borderRightColor="tertiary.50"
        />
        <Box
          w={0}
          h={0}
          borderTopWidth="11px"
          borderTopStyle="solid"
          borderTopColor="transparent"
          borderBottomWidth="11px"
          borderBottomStyle="solid"
          borderBottomColor="transparent"
          borderRightWidth="8px"
          borderRightStyle="solid"
          borderRightColor="tertiary.50"
        />
      </Box>
    </Flex>
  )
}

export default NavLink
