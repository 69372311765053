import {
  Box,
  Heading,
  Spacer,
  Flex,
  Image,
  useBreakpointValue
} from "native-base"

import intersectImage from "assets/images/intersect.png"
import { TwoFAForm } from "components/inline/TwoFAForm/TwoFaForm"
import { useEnableTwoFA } from "components/pages/EnableTwoFA/talons/useEnableTwoFA"
import PersonDto from "models/person"

import { SetUpComplete } from "./SetUpComplete"
import { useSetUpTwoFa } from "./talons/useSetUpTwoFa"

export interface SetupTwoFAProps {
  person: PersonDto
}

export const SetupTwoFA = ({ person }: SetupTwoFAProps) => {

  const {
    wizard,
    setWizard
  } = useSetUpTwoFa()

  const {
    lastFourPhoneNumber,
    mungedEmail
  } = useEnableTwoFA(person)

  const flexDirection = useBreakpointValue({ base: "column", md: "row" })

  return wizard === "verification" ? <Box
    width="100%"
    maxWidth={"960px"}
    marginX={"auto"}
    marginY={"1rem"}
    testID="SetupTwoFA"
  >
    <Box marginX={4} bg="white" p={6} borderRadius="6px">
      <Heading fontSize={"3xl"}>Setup two-factor authentication</Heading>
      <Spacer my={"20px"} />
      <Flex flexDirection={flexDirection} justifyContent="space-between" flexWrap="wrap">
        <Box maxWidth="650px" w={{ base: "100%", lg: "50%" }}>
          <TwoFAForm
            email={person.email}
            emailTruncated={mungedEmail}
            phoneTruncated={lastFourPhoneNumber}
            communicationType={person.communicationType}
            callback={() => setWizard("complete")}
          />
        </Box>

        <Flex testID="burt" alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }} mt="16px">
          <Image
            width={{ base: "266px", md: "300px", xl: "400px" }}
            height={{ base: "250px", md: "286px", xl: "380px" }}
            src={intersectImage}
            alt="intersectImage"
          />
        </Flex>
      </Flex>
    </Box>
  </Box> : <SetUpComplete />
}
