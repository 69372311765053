import {
  Box,
  Button,
  Heading,
  Image,
  Text,
  Row,
  Spacer,
  Flex
} from "native-base"
import { useNavigate } from "react-router-dom"

import unfreezeCard from "assets/images/unfreeze.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { useCurrentCard } from "store/hooks/currentCard"

import useUnfreezeCard from "./talons/useUnfreezeCard"

export const UnfreezeCard = (): JSX.Element => {
  const { isLoading, isSuccess, cardIsLoading, handleUnfreezeCard } =
    useUnfreezeCard()

  const { currentCard } = useCurrentCard()
  const navigate = useNavigate()

  const token = currentCard?.token ?? false

  return (
    <Box testID="unfreeze-card">
      <PageHeading  showBackBtn testID="unfreeze-card-header">
        UnFreeze Card
      </PageHeading>

      <BoxCard>
        {currentCard && token ? (
          <Box>
            <Heading fontSize="24px" testID="unfreeze-card-heading">
              What happens when I unfreeze my card?
            </Heading>

            <Flex mt="4" display="flex" flexDir={{ base: "column", lg: "row" }}>
              <Box minW="500px">
                <Text>
                  {"Your card is immediately ready to use."}
                </Text>

                <Row mt="48px">
                  <Button

                    testID="close-button"
                    w="150px"
                    onPress={() => navigate(-1)}
                    disabled={
                      isLoading || isSuccess || cardIsLoading ? true : false
                    }
                    variant="outline"
                  >
                    Cancel
                  </Button>

                  <Spacer w="16px" flexGrow="0" />

                  <Button

                    testID="unfreeze-card-submit"
                    w="150px"
                    onPress={() => handleUnfreezeCard(currentCard.id, token)}
                    disabled={isLoading || isSuccess}
                    isLoading={isLoading || cardIsLoading}
                  >
                    UnFreeze my card
                  </Button>
                </Row>
              </Box>

              <Flex flex="1" justifyContent="center" alignItems="center" mt="32px">
                <Image
                  size="18rem"
                  src={unfreezeCard}
                  alt="unfreezeCardImg"
                  resizeMode={"contain"}
                />
              </Flex>
            </Flex>
          </Box>
        ) : (
          <>{"There is no card to unfreeze"}</>
        )}
      </BoxCard>
    </Box>
  )
}
