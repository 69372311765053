import "./Statement.css"

import { ReactComponent as LogoIcon } from "@axle/web/src/assets/svgs/public-logo.svg"
import { Box, Divider, Heading, Text, Flex, Row, Column } from "native-base"
import { useParams } from "react-router-dom"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { Currency } from "components/inline/Currency"
import { FormatAddress } from "components/inline/FormatAddress"
import PageHeading from "components/inline/PageHeading/PageHeading"
import {
  getNumericNextDate,
  getSlashNumericDate
} from "components/util/format-date"

import useStatement, {
  GETCAPE_IO,
  CAPE_PHONE,
  CAPE_EMAIL,
  CAPE_NAME,
  CAPE_ABN,
  CAPE_ADDRESS
} from "./talons"

type Params = {
  id: string
}

export const Statement = (): JSX.Element => {
  const { id } = useParams<keyof Params>() as Params
  const statementId = parseInt(id)
  const { statement, formatter } = useStatement(statementId)

  return (
    <>
      {statement && (
        <>
          <PageHeading showBackBtn testID="statement-header">
            Monthly statement
          </PageHeading>
          <BoxCard testID="statement" display="flex" flexDir="column">
            <Column>
              <Box testID="statement-cap">
                <Flex flexDir="row" alignItems="center" justify="space-between">
                  <Text color="red.300" fontWeight="bold">
                    Monthly statement
                  </Text>

                  <Text>{getNumericNextDate(statement.to)}</Text>
                </Flex>
                <Text mt="0.5rem" mb="1rem">
                  Review your Cape Charge card statement
                </Text>

                <Divider />
              </Box>

              {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

              <Column
                testID="statement-body"
                px={{ base: "1rem", md: "3rem", lg: "5rem" }}
                py="5rem"
              >
                <Row justifyContent="space-between">
                  <Box ml="-5px">
                    <LogoIcon height="70px" width="42px" />
                  </Box>

                  <Column alignItems="flex-end">
                    <Text fontWeight="bold" fontSize="xs">
                      {GETCAPE_IO}
                    </Text>
                    <Text fontSize="xs">{CAPE_PHONE}</Text>
                    <Text fontSize="xs">{CAPE_EMAIL}</Text>
                  </Column>
                </Row>

                {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

                <Flex
                  testID="account-summary-header"
                  flexDir={{ base: "column", lg: "row" }}
                  mt="3rem"
                  mb="1rem"
                  alignItems={{ base: "flex-start", lg: "baseline" }}
                  justifyContent="space-between"
                  py="5px"
                  borderBottomWidth="2px"
                >
                  <Heading color="black">Your account summary</Heading>
                  <Text fontSize="sm">
                    {`${statement.fromString}  -  ${statement.toString}`}
                  </Text>
                </Flex>

                <Flex
                  mt="1rem"
                  testID="account-summary-body"
                  flexDir={{ base: "column", lg: "row" }}
                >
                  <Column flex="1" pr={{ base: "0", lg: "1rem" }}>
                    <Text
                      fontSize="md"
                      borderBottomColor="gray.400"
                      borderBottomWidth="1px"
                      pb="8px"
                      mb="8px"
                      flexGrow="1"
                    >
                      Opening balance
                    </Text>

                    <Box alignSelf="flex-end">
                      <Currency
                        color="black"
                        amount={statement.openingBalance}
                        size="2xl"
                        decimalSize="md"
                        fontFamily="CircularStdBook"
                        isDecimalRequired={true}
                      />
                    </Box>
                  </Column>

                  <Column flex="1" px={{ base: "0", lg: "1rem" }}>
                    <Text
                      fontSize="md"
                      borderBottomColor="gray.400"
                      borderBottomWidth="1px"
                      pb="8px"
                      mb="8px"
                      flexGrow="1"
                    >
                      New credits
                    </Text>

                    <Box alignSelf="flex-end">
                      <Currency
                        color="black"
                        amount={statement.newCredits}
                        size="2xl"
                        decimalSize="md"
                        fontFamily="CircularStdBook"
                        isDecimalRequired={true}
                      />
                    </Box>
                  </Column>

                  <Column flex="1" px={{ base: "0", lg: "1rem" }}>
                    <Text
                      fontSize="md"
                      borderBottomColor="gray.400"
                      borderBottomWidth="1px"
                      pb="8px"
                      mb="8px"
                      flexGrow="1"
                    >
                      New debits
                    </Text>

                    <Box alignSelf="flex-end">
                      <Currency
                        color="black"
                        amount={statement.newDebits}
                        size="2xl"
                        decimalSize="md"
                        fontFamily="CircularStdBook"
                        isDecimalRequired={true}
                      />
                    </Box>
                  </Column>

                  <Column flex="1" px={{ base: "0", lg: "1rem" }}>
                    <Text
                      fontWeight="bold"
                      fontSize="md"
                      borderBottomColor="black"
                      borderBottomWidth="2px"
                      pb="8px"
                      mb="8px"
                      flexGrow="1"
                    >
                      Closing balance
                    </Text>
                    <Box alignSelf="flex-end">
                      <Currency
                        amount={statement.closingBalance}
                        color="black"
                        fontFamily="CircularStdMedium"
                        fontWeight="bold"
                        size="2xl"
                        decimalSize="md"
                        isDecimalRequired={true}
                      />
                    </Box>
                  </Column>

                  <Column flex="1" pl={{ base: "0", lg: "1rem" }}>
                    <Text
                      fontWeight="bold"
                      fontSize="md"
                      borderBottomColor="black"
                      borderBottomWidth="2px"
                      pb="8px"
                      mb="8px"
                      flexGrow="1"
                    >
                      Amount due
                    </Text>

                    <Box alignSelf="flex-end">
                      <Currency
                        amount={statement.closingBalance}
                        color="black"
                        fontFamily="CircularStdMedium"
                        fontWeight="bold"
                        size="2xl"
                        decimalSize="md"
                        isDecimalRequired={true}
                      />
                    </Box>
                  </Column>
                </Flex>

                <Column flex="1" alignItems="flex-end" mt="1rem">
                  <Text fontWeight="bold">Due by</Text>
                  <Text fontWeight="bold">{statement.dueString}</Text>
                </Column>

                {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

                <Row mt="3rem" mb="1rem" py="5px" borderBottomWidth="2px">
                  <Heading color="black">Your details</Heading>
                </Row>

                <Flex
                  testID="your-detail-body"
                  flexDir={{ base: "column", md: "row" }}
                  mt="1rem"
                >
                  <Box
                    flex={{ base: "unset", md: "1" }}
                    pr={{ base: "0", md: "3rem" }}
                  >
                    <Column>
                      <Text
                        fontSize="md"
                        borderBottomColor="gray.400"
                        borderBottomWidth="1px"
                        pb="8px"
                        mb="8px"
                      >
                        Business name
                      </Text>

                      <Text fontSize="xl">{statement.legalName}</Text>
                    </Column>

                    <Column mt="3rem">
                      <Text
                        fontSize="md"
                        borderBottomColor="gray.400"
                        borderBottomWidth="1px"
                        pb="8px"
                        mb="8px"
                      >
                        Business ABN
                      </Text>

                      <Text fontSize="xl">{statement.registrationNumber}</Text>
                    </Column>

                    <Column mt="3rem">
                      <Text
                        fontSize="md"
                        borderBottomColor="gray.400"
                        borderBottomWidth="1px"
                        pb="8px"
                        mb="8px"
                      >
                        Direct Debit details
                      </Text>

                      <Row
                        flexDir={{ base: "column", xl: "row" }}
                        justifyContent="space-between"
                      >
                        <Row alignItems="center">
                          <Text mr="0.5rem">Account number:</Text>
                          <Text fontSize="xl">
                            ******
                            {statement.accountNumber.slice(
                              statement.accountNumber.length - 2
                            )}
                          </Text>
                        </Row>

                        <Row alignItems="center">
                          <Text mr="0.5rem">BSB:</Text>
                          <Text fontSize="xl">{statement.routingNumber}</Text>
                        </Row>
                      </Row>
                    </Column>
                  </Box>

                  <Box
                    mt={{ base: "2rem", md: "0" }}
                    flex={{ base: "unset", md: "1" }}
                  >
                    <Column>
                      <Text
                        fontSize="md"
                        borderBottomColor="gray.400"
                        borderBottomWidth="1px"
                        pb="8px"
                        mb="8px"
                      >
                        Business address
                      </Text>

                      <FormatAddress address={statement.address} />
                    </Column>
                  </Box>
                </Flex>

                {/* --------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
                <Row mt="3rem" mb="1rem" py="5px" borderBottomWidth="2px">
                  <Heading color="black"> Your transactions</Heading>
                </Row>

                <Column borderBottomWidth="1px" pb="5rem">
                  {statement.ledgers.map((ledger, index) => (
                    <Column key={index}>
                      <Row>
                        <Text
                          mt="1rem"
                          mb="2rem"
                          fontWeight="bold"
                          fontFamily="CircularStdMedium"
                        >
                          {ledger.accountName}
                        </Text>
                      </Row>

                      <Column flex="1" overflowX="auto">
                        <Box testID="transaction-box" flex="1" minW="30rem">
                          <Row mb="8px">
                            <Box flex="1">Date</Box>
                            <Box flex="2" px="1rem">
                              Merchant details
                            </Box>
                            <Box flex="1" textAlign="end">
                              Debits
                            </Box>
                            <Box flex="1" textAlign="end">
                              Credits
                            </Box>
                            <Box flex="1" textAlign="end">
                              Balance
                            </Box>
                          </Row>

                          {ledger.rows.map((transaction, recordIndex) => (
                            <Row mb="1rem" key={recordIndex}>
                              <Box flex="1">
                                {getSlashNumericDate(transaction.createdAt)}
                              </Box>
                              <Box flex="2" px="1rem">
                                {transaction.description}
                              </Box>
                              <Box flex="1" textAlign="end">
                                {transaction.debit !== 0 &&
                                  "-" + formatter.format(transaction.debit)}
                              </Box>
                              <Box flex="1" textAlign="end">
                                {transaction.credit !== 0 &&
                                  formatter.format(transaction.credit)}
                              </Box>
                              <Box flex="1" textAlign="end">
                                {formatter.format(transaction.amount)}
                              </Box>
                            </Row>
                          ))}
                        </Box>
                      </Column>
                    </Column>
                  ))}
                </Column>

                <Flex
                  testID="footer"
                  w="100%"
                  flexDir={{ base: "column", lg: "row" }}
                  justifyContent="space-between"
                  mt="1rem"
                >
                  <Text fontSize="xs">{CAPE_NAME}</Text>
                  <Text fontSize="xs">ABN: {CAPE_ABN}</Text>
                  <Text fontSize="xs">{CAPE_ADDRESS}</Text>
                </Flex>
              </Column>
            </Column>
          </BoxCard>
        </>
      )}
    </>
  )
}
