import BoxCard from "@axle/web/src/components/inline/BoxCard/NbBoxCard"
import { useCardDetails } from "@axle/web/src/components/inline/CardDetails/talons/useCardDetails"
import CardTemplate from "@axle/web/src/components/inline/CardVisual"
import CardsNav from "@axle/web/src/components/inline/CardVisual/CardsNav"
import { Currency } from "@axle/web/src/components/inline/Currency"
import {
  allDepsContext,
  currentCardContext
} from "@axle/web/src/components/layouts/Authorized/MaybeRender"
import { orderCard as orderCardRoute } from "@axle/web/src/components/layouts/Authorized/routes"
import {
  Box,
  Button,
  Column,
  Flex,
  Heading,
  Slider,
  Spacer,
  Text
} from "native-base"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

export const CardDetails = (): JSX.Element => {
  const { dueDate } = useCardDetails()
  const navigate = useNavigate()
  const { person, cards, hasCurrentCard } = useContext(allDepsContext)
  const { card: currentCard, index: currentCardIndex } =
    useContext(currentCardContext)

  return (
    <BoxCard mb="1rem">
      <Flex flexDirection={{ base: "column", xl: "row" }}>
        <Flex flexDirection={{ base: "column", lg: "row" }} flex="2">
          <Column
            alignItems="center"
            marginBottom={{ base: "16px", lg: "0" }}
            testID="card-wrapper"
          >
            {hasCurrentCard ? (
              <>
                <CardsNav
                  cards={cards}
                  currentCard={currentCard}
                  cardIndex={currentCardIndex}
                />
              </>
            ) : (
              <>
                <CardTemplate
                  template={true}
                  fullName={person.fullName}
                  lastFour=""
                  expiryDate=""
                  enabled={true}
                  locked={false}
                />
                <Button
                  w="258px"
                  mt="16px"
                  testID="order-card-button"
                  onPress={() => {
                    navigate(orderCardRoute)
                  }}
                >
                  Order New Card
                </Button>
              </>
            )}
          </Column>

          <Spacer px="24px" py="8px" flexGrow="0" />

          <Flex flex="1">
            <Flex alignSelf="flex-end">
              <Heading size="sm" letterSpacing="-0.2px" textAlign="right">
                Available
              </Heading>
              <Currency amount={person.ledger.amount} size="2xl" decimalSize="md" />
            </Flex>

            <Slider
              testID="spent-slider"
              value={100 - (person.ledger.amount / person.ledger.limit) * 100}
              my="6px"
              colorScheme="primary"
              pointerEvents="none"
              isReadOnly
            >
              <Slider.Track borderRadius="0.25rem" size="0.5rem">
                <Slider.FilledTrack size="0.5rem" background="primary.600" />
              </Slider.Track>
            </Slider>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDir="row"
            >
              <Box>
                <Text fontSize="sm" fontFamily="CircularStdMedium">Balance</Text>
                <Currency
                  testID="current-balance"
                  amount={person.ledger.balance}
                  size="lg" decimalSize="sm"
                />

              </Box>
              <Flex flexDir="column" alignItems="flex-end">
                <Text fontSize="sm" fontFamily="CircularStdMedium">Credit limit</Text>
                <Currency amount={person.ledger.limit} size="lg" decimalSize="sm" />
              </Flex>
            </Box>
          </Flex>
        </Flex>

        <Spacer px="24px" py="8px" flexGrow="0" />
        <Flex flex="1">
          <Heading size="sm" letterSpacing="-0.2px">
            Balance payable
          </Heading>

          <Spacer h="8px" flexGrow="0" />
          <Text fontSize="xs">{"In full on:  "}</Text>
          <Spacer h="8px" flexGrow="0" />
          <Text fontSize="md" fontWeight="900">
            {dueDate}
          </Text>
          <Spacer h="8px" flexGrow="0" />
          <Text fontSize="xs">
            Payment is taken from your account 1 working day after your statement end date and will reflect on your balance after 2-3 business days.
          </Text>
        </Flex>
      </Flex>
    </BoxCard>
  )
}
