import { DateTime } from "luxon"
import {
  Box,
  Divider,
  Heading,
  Text,
  Button,
  Flex,
  Spacer,
  IButtonProps
} from "native-base"

import { EventTypes } from "models/enums/event-type"
import NotificationDto from "models/notification"

import useNotificationRow from "./talons/useNotificationRow"

interface NotificationRow {
  notification: NotificationDto
  setTemplateData: React.Dispatch<
    React.SetStateAction<NotificationDto | undefined>
  >
}

const NotificationRow = ({
  notification,
  setTemplateData
}: NotificationRow): JSX.Element => {
  const { handleViewStatement, handleView, displayTitle, isLoading } =
    useNotificationRow(notification, setTemplateData)

  const ViewButton = ({ ...props }: IButtonProps): JSX.Element => {
    switch (notification.type) {
    case EventTypes.StatementReady:
      return (
        <Button
          testID="notification-view-statement-button"
          onPress={() => handleViewStatement()}
          isLoading={isLoading}
          {...props}
        >
            View
        </Button>
      )

    case EventTypes.LimitApplied:
      return (
        <Button
          testID="notification-view-button"
          onPress={() => handleView()}
          {...props}
        >
            View
        </Button>
      )

    default:
      return <></>
    }
  }

  return (
    <Box testID="notification-item">
      {displayTitle && (
        <Box pb="4rem">
          <Box pb="1rem">
            <Heading size="md">
              {DateTime.fromISO(notification.createdAt).toFormat(
                "dd LLLL yyyy"
              )}
            </Heading>
          </Box>

          <Divider></Divider>

          <Flex
            flexDir={{ base: "column", sm: "row" }}
            justifyContent="space-between"
            py="1rem"
            alignItems={{ base: "start", sm: "center" }}
          >
            <Box>
              <Heading size="xs" color="red.300" p="0.3rem">
                {displayTitle}
              </Heading>
              <Text p="0.3rem">{notification.note}</Text>
            </Box>

            <Spacer h="10px" />

            <ViewButton
              w="100px"
              size="sm"
              alignSelf={{ base: "end", sm: "center" }}
            />
          </Flex>
          <Divider></Divider>
        </Box>
      )}
    </Box>
  )
}

export default NotificationRow
