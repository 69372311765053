
import { currentCardContext } from "@axle/web/src/components/layouts/Authorized/MaybeRender"
import { CardDto, cardFromLegacyCard } from "@axle/web/src/models/card"
import { useGetCardDetailsMutation } from "@axle/web/src/network/card"
import { useAppDispatch } from "@axle/web/src/store/hooks"
import { useCards } from "@axle/web/src/store/hooks/cards"
import { updateCard } from "@axle/web/src/store/slices/cards"
import { CurrentCardState, setCurrentCard } from "@axle/web/src/store/slices/currentCard"
import { useContext } from "react"


interface UseCardsNav {
  currentCard: CurrentCardState
  currentCardIndex: number|null
  cards: CardDto[]|null
  refreshCard: (cardId: number) => () => void
}

export default (): UseCardsNav => {
  const { card, index } = useContext(currentCardContext)
  const { cards } = useCards()
  const dispatch = useAppDispatch()

  const [getCardDetails] = useGetCardDetailsMutation()
  const refreshCard = (cardId: number) => {
    return async () => {
      const cardDetails = await getCardDetails({
        id: cardId
      }).unwrap()

      dispatch(setCurrentCard(cardFromLegacyCard(cardDetails.data)))
      dispatch(updateCard({
        cardIndex: index,
        card: cardFromLegacyCard(cardDetails.data)
      }))
    }
  }

  return {
    currentCard: card,
    currentCardIndex: index,
    cards,
    refreshCard
  }
}
