import { Box, useBreakpointValue } from "native-base"

import { ReactComponent as DashboardIcon } from "assets/svgs/icons/home.svg"
import { ReactComponent as MycardIcon } from "assets/svgs/icons/my-card.svg"
import { ReactComponent as TransactionIcon } from "assets/svgs/icons/transactions.svg"
import IconLink from "components/inline/IconLink/MobileNavLink"
import {
  dashboard as dashboardRoute,
  transactions as transactionsRoute,
  myCards as myCardsRoute
} from "components/layouts/Authorized/routes"

const StickyNav = (): JSX.Element => {
  const iconSize = {
    width: "1.25rem",
    height: "2rem"
  }

  const display = useBreakpointValue({ base: "flex", md: "none" })

  return (
    <Box
      testID="sticky-nav"
      zIndex="3"
      w="100%"
      position="sticky"
      bottom="0"
      left="0"
      bg="primary.600"
      display={display}
      h="100px"
      flexDir="row"
    >
      <IconLink
        path={dashboardRoute}
        testID="sticky-dashboard-link"
        icon={<DashboardIcon {...iconSize} />}
        text="Dashboard"
        fontSize="0.8rem"
        flex={1}
      />
      <IconLink
        path={transactionsRoute}
        icon={<TransactionIcon {...iconSize} />}
        text="Transactions"
        fontSize="0.8rem"
        flex={1}
      />
      <IconLink
        testID="my-cards-footer-link"
        path={myCardsRoute}
        icon={<MycardIcon {...iconSize} />}
        text="My Cards"
        fontSize="0.8rem"
        flex={1}
      />
    </Box>
  )
}

export default StickyNav
