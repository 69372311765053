import { supportEmail, supportEmailHref, supportPhone } from "@axle/web/src/components/util/cape-details"
import { useResetPasswordMutation } from "@axle/web/src/network/auth"
import {
  Grid,
  GridItem,
  Heading,
  Link,
  Spacer,
  Text,
  useToast,
  Flex,
  Image
} from "@chakra-ui/react"
import * as React from "react"

import waveImage from "assets/images/wave.png"

export interface ForgotPasswordProps {
  username: string;
}

export const ForgotPassword = ({
  username
}: ForgotPasswordProps): JSX.Element => {
  const toast = useToast()
  const [resetPassword] = useResetPasswordMutation()

  React.useEffect(() => {
    (async () => {
      await resetPassword({ Username: username })
    })()
  }, [resetPassword, toast, username])

  return (
    <>
      <Grid
        p="1rem"
        pt={{ base: "0rem", sm: "4rem", md: "6rem" }}
        data-testid="forgot-password"
        templateAreas={{ sm: "'card' 'form'", md: "'margin form card'" }}
        templateColumns={{
          sm: "1fr",
          md: "8rem 2fr 2fr",
          lg: "10rem 4fr 4fr",
          xl: "14rem 4fr 7fr"
        }}
      >
        <GridItem gridArea="margin"></GridItem>
        <GridItem
          opacity={{ base: "0%", md: "100%" }}
          position={{ sm: "absolute", md: "inherit" }}
          gridArea="card"
        >
          <Flex data-testid="expired-icon" alignItems="center" justify="center" alignSelf={{ base: "unset", lg: "flex-end" }} mt="16px">
            <Image
              width={{ base: "285px", md: "400px", xl: "440px" }}
              height={{ base: "85px", md: "118", xl: "129px" }}
              src={waveImage}
              alt="waveImage"
            />
          </Flex>
        </GridItem>
        <GridItem
          margin={{ sm: "auto", md: "inherit" }}
          width={{ sm: "70%", md: "100%" }}
          gridArea="form"
        >
          <Heading
            variant="error"
            data-testid="forgot-passowrd-header"
          >
            {"We've sent you an email..."}
          </Heading>
          <Spacer h="4rem" />
          <Text data-testid="forgot-passowrd-p1">
            Your request to reset your password has been sent to{" "}
            <strong style={{ color: "#EC696A" }}>{username}</strong>.
          </Text>
          <Spacer h="1rem" />
          <Text data-testid="forgot-passowrd-p2">
            Any questions, please call us on {supportPhone}, or email us at{" "}
            <Link href={supportEmailHref} textDecoration="underline">
              {supportEmail}
            </Link>
            .
          </Text>
        </GridItem>
      </Grid>
    </>
  )
}
