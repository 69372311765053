import { Flex, Text, ITextProps } from "native-base"

import Address from "models/address"

interface FormatAddressProps extends ITextProps {
  address: Address
}
export const FormatAddress = ({
  address,
  fontSize = "xl"
}: FormatAddressProps): JSX.Element => (
  <Flex direction="column">
    {address.address1 && <Text fontSize={fontSize}>{address.address1}</Text>}
    {address.address2 && <Text fontSize={fontSize}>{address.address2}</Text>}
    {address.address3 && <Text fontSize={fontSize}>{address.address3}</Text>}
    {address.address4 && <Text fontSize={fontSize}>{address.address4}</Text>}
    {address.address5 && <Text fontSize={fontSize}>{address.address5}</Text>}
    {address.postTown && <Text fontSize={fontSize}>{address.postTown}</Text>}
    {address.countyField && (
      <Text fontSize={fontSize}>{address.countyField}</Text>
    )}
    {address.postCode && <Text fontSize={fontSize}>{address.postCode}</Text>}
  </Flex>
)
