import {
  Box,
  Spacer,
  Flex,
  Button,
  Heading,
  Image,
  InputGroup,
  Text,
  VStack,
  Input
} from "native-base"
import { Controller } from "react-hook-form"

import capeImage from "assets/images/cape.png"
import intersectImage from "assets/images/intersect.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import GoogleOauth from "components/inline/Oauth/Google"
import { PasswordInput } from "components/inline/PasswordInput"
import PublicLayout from "components/layouts/Public"
import { isPassword, isPasswordMatch } from "components/util/form-validators"
import { FF__GOOGLE_OAUTH } from "config/environment"

import { useSetCredentials, text } from "./talons/useSetCredentials"

interface Props {
  enableGoogleOauth?: boolean
}

const googleOauthEnabled = FF__GOOGLE_OAUTH

const SetCredentials = ({
  enableGoogleOauth = googleOauthEnabled
}: Props): JSX.Element => {
  const {
    guid,
    email,
    onSubmit,
    handleSubmit,
    getValues,
    isLoading,
    errors,
    showPage,
    onComplete,
    control
  } = useSetCredentials()

  const page = {
    setUpForm: (
      <Box w="100%">
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Box mb="1rem" flex={6}>
            <Heading size="xl" marginBottom="2rem" fontFamily="GTPressuraPro">
              {text.header}
            </Heading>

            <VStack>
              <Text >
                {enableGoogleOauth ? text.contentWithGoogle : text.content}
              </Text>
              <Spacer my={"6px"} />
              <Text >{text.contentB}</Text>
              <Spacer my={"12px"} />
              {enableGoogleOauth && <GoogleOauth guid={guid} />}
              <Spacer my={"6px"} />
              {enableGoogleOauth ? (
                <Text >{text.instruction}</Text>
              ) : null}
              <Spacer my={"6px"} />
              <InputGroup>
                <Input value={email} />
              </InputGroup>

              <Spacer my={"6px"} />

              <Controller
                control={control}
                name="password"
                rules={{
                  required: "required",
                  validate: (value) => isPassword(value).isValid
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <PasswordInput
                    showRules
                    name="password"
                    errors={errors}
                    onBlur={onBlur}
                    onChangeText={(val) => onChange(val)}
                    value={value}
                    testID="password"
                    placeholder={"Password"}
                    backgroundColor="transparent"
                    color={"gray.400"}
                  />
                )}
              />
              <Box width={"100%"} mb="2rem" testID="confirmPasswordWrapper">
                <Controller
                  control={control}
                  name="confirmPassword"
                  rules={{
                    required: "required",
                    validate: (value) =>
                      isPasswordMatch(value, getValues("password"))
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PasswordInput
                      name={"confirmPassword"}
                      errors={errors}
                      onBlur={onBlur}
                      onChangeText={(val) => onChange(val)}
                      value={value}
                      onSubmitEditing={handleSubmit(onSubmit)}
                      testID="confirmPassword"
                      placeholder={"Confirm Password"}
                      backgroundColor="transparent"
                      color={"gray.400"}
                    />
                  )}
                />
              </Box>
              <Button
                testID="submit"
                width="180px"
                alignSelf="flex-start"
                isLoading={isLoading}
                onPress={handleSubmit(onSubmit)}
              >
                {text.submitBtn}
              </Button>
            </VStack>
          </Box>
          <Flex>
            <Image
              mt="32px"
              width={{ base: "325px", md: "480px" }}
              height={{ base: "325px", md: "480px" }}
              src={capeImage}
              alt="capeImage"
            />
          </Flex>
        </Flex>
      </Box>
    ),
    confirmation: (
      <Box>
        <Heading size="xl" marginBottom="2rem">
          {text.successHeader}
        </Heading>

        <Text >{text.successContent}</Text>
        <Button
          testID="completeBtn"
          mt="2rem"
          maxWidth="180px"
          alignSelf="flex-start"
          onPress={onComplete}
        >
          {text.completeBtn}
        </Button>

        <Image
          ml="auto"
          mt="4"
          width="353px"
          height="320px"
          src={intersectImage}
          alt={"intersectImage"}
        />
      </Box>
    )
  }

  return (
    <PublicLayout>
      <BoxCard
        alignSelf="center"
        testID="setCredentials"
        maxWidth="1200px"
        margin="0 auto"
        minH="60vh"
      >
        {page[showPage]}
      </BoxCard>
    </PublicLayout>
  )
}
export default SetCredentials
