import { Box, Spinner } from "native-base"
import { useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import PersonDto from "models/person"

import NotificationRow from "./NotificationRow/NotificationRow"
import ShowTamplate from "./ShowTamplate"
import useNotifications from "./talons/useNotifications"

export interface NotificationProps {
  InfiniteScroller: typeof InfiniteScroll
  person: PersonDto
}

export const Notifications = ({
  InfiniteScroller,
  person
}: NotificationProps): JSX.Element => {
  const {
    notificationList,
    hasMoreNotification,
    handleScroll,
    isLoading,
    templateData,
    setTemplateData
  } = useNotifications({ person })

  const scrollerRef = useRef<HTMLDivElement | null>(null)
  const isNotification = notificationList.length > 0

  const isLoadingAndNoNotification = isLoading && notificationList.length === 0

  return (
    <Box testID="notification">
      <PageHeading pl="16px" showBackBtn testID="notification-header">
        Notifications
      </PageHeading>

      <BoxCard testID="notification-content">
        {templateData && (
          <ShowTamplate
            templateData={templateData}
            setTemplateData={setTemplateData}
          />
        )}

        <Box display={templateData ? "none" : "flex"} ref={scrollerRef}>
          <InfiniteScroller
            pageStart={0}
            threshold={25}
            hasMore={hasMoreNotification}
            getScrollParent={() => scrollerRef.current}
            useWindow={true}
            initialLoad={false}
            data-testid="notification-scroller"
            loadMore={() => handleScroll()}
            loader={
              <Box key={0} testID="notification-loading">
                {isNotification && <Spinner />}
              </Box>
            }
          >
            {isLoadingAndNoNotification ? (
              <Spinner />
            ) : isNotification ? (
              notificationList.map((notification, index) => {
                return (
                  <NotificationRow
                    key={index}
                    notification={notification}
                    setTemplateData={setTemplateData}
                  />
                )
              })
            ) : (
              <>{"You don't have any notifications yet"}</>
            )}
          </InfiniteScroller>
        </Box>
      </BoxCard>
    </Box>
  )
}
