import { VStack } from "native-base"
import { useState, useEffect, useContext } from "react"

import { allDepsContext } from "components/layouts/Authorized/MaybeRender"
import { Roles } from "models/enums/roles"

import AdminControl from "./SpendControls/AdminControl/AdminControl"
import OwnerControl from "./SpendControls/OwnerControl/OwnerControl"
import ReadOnlySpendControl from "./SpendControls/ReadOnlySpendControl/ReadOnlySpendControl"

export const SpendControls = (): JSX.Element => {
  const { person } = useContext(allDepsContext)
  const [showPage, setShowPage] = useState<
    "admin" | "budgetOwner" | "readOnly"
  >("readOnly")

  useEffect(() => {
    person.roles.includes(Roles.testOwnerControl) && setShowPage("budgetOwner")
    person.roles.includes(Roles.testAdminControl) && setShowPage("admin")
    person.roles.includes(Roles.Owner) && setShowPage("readOnly")
  }, [person])

  const page = {
    admin: <AdminControl />,
    budgetOwner: <OwnerControl />,
    readOnly: <ReadOnlySpendControl />
  }

  return <VStack>{page[showPage]}</VStack>
}
