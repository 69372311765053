import { Button, Flex, Heading, Input, Row } from "native-base"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import DatePick from "components/inline/DatePicker"

import { DownloadCSV as DownloadCSVComponent } from "../DownloadCSV/DownloadCSV"

export interface SearchFormValues {
  searchTerm: string
  fromDate: string
  toDate: string
}

interface SearchFormProps {
  enableDownload: boolean
  DownloadCSV?: typeof DownloadCSVComponent
  downloadParams: string | null
  handleSearch: (searchFormValue: SearchFormValues) => void
}

const SearchFrom = ({
  enableDownload,
  downloadParams,
  DownloadCSV,
  handleSearch
}: SearchFormProps) => {
  const { handleSubmit, control } = useForm<SearchFormValues>({
    defaultValues: {
      fromDate: "",
      toDate: "",
      searchTerm: ""
    }
  })

  const onSubmit: SubmitHandler<SearchFormValues> = (data) => {
    handleSearch(data)
  }

  return (
    <BoxCard mb="1rem" py="0">
      <Flex flexDir={{ base: "column", xl: "row" }}>
        <Flex
          flex="1"
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          my="1.5rem"
        >
          <Heading
            alignSelf={{ base: "flex-start", lg: "center" }}
            textAlign="center"
            size="sm"
            testID="search-bar-heading"
            mb={{ base: "8px", lg: "0" }}
          >
            Search
          </Heading>

          <Controller
            control={control}
            name="searchTerm"
            render={({ field: { onChange, value } }) => (
              <Input
                flex={{ base: "unset", lg: "1" }}
                ml="1rem"
                size="lg"
                mr={{ base: "1rem", lg: "0", xl: "1rem" }}
                testID="search-input"
                value={value}
                placeholder="Enter search term"
                maxWidth={{ base: "none", xl: "500px" }}
                onChange={(text) => onChange(text)}
              />
            )}
          />
        </Flex>

        <Flex
          minW={{ base: "0", xl: "44rem" }}
          flex="1"
          alignItems={{ base: "start", lg: "center" }}
          flexDir={{ base: "column", lg: "row" }}
        >
          <Flex flex="1" flexDir={{ base: "column", sm: "row" }} w="100%">
            <Row alignItems="center" flex="1" mb={{ base: "1rem", xl: "0" }}>
              <Heading
                fontSize="md"
                mr="0.5rem"
              >
                From
              </Heading>

              <Controller
                control={control}
                name="fromDate"
                render={({ field: { onChange, value } }) => (
                  <DatePick
                    testID="from-date-input"
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </Row>

            <Row
              alignItems="baseline"
              flex="1"
              mr={{ base: "0", lg: "1rem" }}
              mb={{ base: "1rem", xl: "0" }}
            >
              <Heading
                fontSize="md"
                mr="0.5rem"
                width="36px"
                textAlign={{ base: "left", sm: "right" }}
              >
                To
              </Heading>

              <Controller
                control={control}
                name="toDate"
                render={({ field: { onChange, value } }) => (
                  <DatePick
                    testID="to-date-input"
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </Row>
          </Flex>

          <Button
            w={{ base: "100%", sm: "40%", lg: "unset" }}
            mb={{ base: "16px", xl: "0" }}
            px="2rem"
            testID="search-submit"
            onPress={handleSubmit(onSubmit)}
          >
            Search
          </Button>

          {enableDownload && DownloadCSV && (
            <DownloadCSV searchParams={downloadParams} />
          )}
        </Flex>
      </Flex>
    </BoxCard>
  )
}

export default SearchFrom
