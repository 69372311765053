export const labelData = [
  {
    category: "Accomodation",
    description: "Hotels, motels, B&Bs, resorts and other lodgings."
  },
  {
    category: "Commercial Travel",
    description: "All airlines, airports, travel agents and cruise lines."
  },
  {
    category: "Public Transport and Taxis",
    description:
      "Includes public transport via rail, ferry, bus or shuttle, and taxis and limousines."
  },
  {
    category: "Car Rentals",
    description: "All car rental companies"
  },

  {
    category: "Fuel and Service Stations",
    description:
      "Service stations that provide fuel. Note: Cape cards can not be used at an Automated Fuel Dispenser."
  },
  {
    category: "Fees and tolls",
    description: "All fees associated with toll roads, highways and bridges."
  },
  {
    category: "Food and Grocery",
    description:
      "Supermarkets, convenience and speciality food stores such as bakeries, butchers)"
  },
  {
    category: "Beer, wine and liqour stores",
    description:
      "Stores selling packaged alcoholic beverages for consumption off the premises."
  },
  {
    category: "Cigar stores and stands",
    description:
      "Retailers that sell tobacco, cigarettes, cigars, pipes, and related supplies."
  },
  {
    category: "Pubs, bars and nightclubs",
    description: "Bars, Cocktail Lounges, Nightclubs, and Taverns/Pubs."
  },
  {
    category: "Cafes, restaurants and catering",
    description: "All cafes, restaurants, fast food and catering companies."
  },
  {
    category: "Membership clubs",
    description: "Country, sporting and recreational clubs."
  },
  {
    category: "Cash withdrawals",
    description:
      "Inlcudes manual (over the counter) and automatic (ATM) cash withdrawals"
  },
  {
    category: "Computer network services",
    description:
      "Providers of computer network, information services, and other online services such as e-mail, web hosting services, or Internet."
  },
  {
    category: "Jewellery",
    description:
      "Retail and wholesale distributors of jewellery, precious stones and metals and watches."
  },
  {
    category: "Pharmacies",
    description:
      "Merchants that sell prescription and proprietary drugs and over-the-counter medicines."
  },
  {
    category: "Pawn shops",
    description:
      "Merchants that lend money in exchange for personal property left with the merchants as security."
  },
  {
    category: "Stamp and Coin stores",
    description: "Retailers selling stamps, coins, and related items."
  },
  {
    category: "Dating and Personal Services",
    description: "Dating and escort services, including online."
  },
  {
    category: "Counselling services",
    description:
      "Personal counselling services (incl. marriage, family and financial and others)"
  },
  {
    category: "Other financial purchases",
    description:
      "Fees charged for broker securities, stocks, bonds, commodities, and mutual funds."
  },
  {
    category: "Bail and Bond payments",
    description: "Merchants that post bail to the court system."
  }
]
