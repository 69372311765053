import { useEffect, useState } from "react"

import { FF__SIMPLE_NOTIFICATIONS } from "config/environment"
import { EventTypes } from "models/enums/event-type"
import NotificationDto from "models/notification"
import PersonDto from "models/person"
import { useGetNotificationsMutation } from "network/notifications"

interface useNotification {
  notificationList: NotificationDto[]
  isLoading: boolean
  hasMoreNotification: boolean
  handleScroll: () => Promise<void>
  templateData: NotificationDto | undefined
  setTemplateData: React.Dispatch<
    React.SetStateAction<NotificationDto | undefined>
  >
}

interface UseNotificationProps {
  person: PersonDto
  hasSimpleNotifications?: boolean
}

const simpleNotifications = FF__SIMPLE_NOTIFICATIONS

const useNotifications = ({ person, hasSimpleNotifications = simpleNotifications }: UseNotificationProps): useNotification => {
  const [getNotifications, { isLoading }] = useGetNotificationsMutation()
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [notificationList, setNotificationList] = useState<NotificationDto[]>(
    []
  )
  const [hasMoreNotification, setHasMoreNotification] = useState<boolean>(true)

  const [templateData, setTemplateData] = useState<NotificationDto | undefined>(
    undefined
  )

  /* istanbul ignore next */ // tempory for now until we publish the notifications updates
  const fetchSize = hasSimpleNotifications ? 100 : 10 // tempory for now until we publish the notifications updates

  const loadNotification = async () => {
    const res = await getNotifications({
      Page: 0,
      PageSize: fetchSize
    }).unwrap()

    /* istanbul ignore next */ // tempory for now until we publish the notifications updates
    const newNotifications = hasSimpleNotifications ? res.data.items.filter((notification) => {
      if(notification.type === EventTypes.StatementReady) {
        return notification
      }
    }) : res.data.items

    setHasMoreNotification(res.data.total > notificationList.length)
    setNotificationList(newNotifications)
  }

  useEffect(() => {
    loadNotification()

    return () => {
      setPageIndex(0)
      setNotificationList([])
      setHasMoreNotification(true)
    }
  }, [
    setNotificationList,
    getNotifications,
    setPageIndex,
    setHasMoreNotification,
    person
  ])

  const handleScroll = async () => {
    setHasMoreNotification(false)
    const page = pageIndex + 1

    const res = await getNotifications({
      Page: page,
      PageSize: fetchSize
    }).unwrap()
    setHasMoreNotification(res.data.items.length === fetchSize)
    setNotificationList([...notificationList, ...res.data.items])
    setPageIndex(page)
  }

  return {
    notificationList,
    isLoading,
    hasMoreNotification,
    handleScroll,
    templateData,
    setTemplateData
  }
}

export default useNotifications
