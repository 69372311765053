import { DateTime } from "luxon"
import { rest } from "msw"

import { transactionsEndpoint } from "config/endpoints"
import { TransactionFactory } from "mocks/factories/transaction"

import { base64ToString } from "../../components/util/base64"

const getTransactions = (searchTerm = "", namedTransactions = 5, total = 100) => {
  const doQuery = (transaction: string, search: string) => transaction.toLowerCase().indexOf(search) !== -1
  const transactionsList = [
    ...TransactionFactory.buildList(namedTransactions, { merchantDetails: "LinkedIn" }),
    ...TransactionFactory.buildList(total, { ledgerId: 1 }),
    ...TransactionFactory.buildList(namedTransactions, {
      merchantDetails: "Apple",
      conversionDate: DateTime.now().minus({ day: 1 }).toISO()
    })
  ]
  const filteredList = transactionsList.filter(transaction => doQuery(transaction.merchantDetails || "", base64ToString(searchTerm).toLowerCase()))
  // .filter(transaction => doQuery(transaction.settlementDate || "", base64ToString(from)))

  return {
    total: filteredList.length,
    items: filteredList
  }
}

const csvText = `
*Date,*Amount,Payee,Description,Reference,Cheque Number
14/1/2022,-2829.99,Dell Australia Pty Ltd ,Computers Computer Peripheral Equipment Software,Card 5632,Cape Settlment - 17  Jan 2022
1/11/2022,-35.3,Banana Blossom,Fast Food Resturants,Card 4816,Cape Settlment - 17  Jan 2022
17/1/2022,19.83,Mastercard ,Interchange,Card 5632,Cape Settlment - 17  Jan 2022
17/1/2022,-3345.64,Mastercard ,MCI Invoice ,ICA 24527,Cape Settlment - 17  Jan 2022
14/1/2022,-6.44,Transport NSW,Local/Suburban Commuter Passenger Transportation Ð Railroads Feries Local Water Transportation,,Cape Settlment - 17  Jan 2022
14/1/2022,-36.29,Adobe ,,Card 5632,Cape Settlment - 17  Jan 2022
`

export const endpoints = [
  rest.post(transactionsEndpoint, (req, res, ctx) => {
    /* eslint-disable */
    // @ts-ignore
    const { SearchTerm, From, To } = req.body

    if (!!SearchTerm || !!From || !!To) {
      return res(
        ctx.status(200),
        ctx.set("Content-Type", "application/json"),
        ctx.json({ success: true, data: getTransactions(SearchTerm) })
      )
    }
    return res(
      ctx.status(200),
      ctx.set("Content-Type", "application/json"),
      ctx.json({ success: true, data: getTransactions() })
    )
  }),
  rest.get(transactionsEndpoint,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.set("Content-Type", "application/json"),
        ctx.json({
          success: true,
          data: getTransactions()
        })
      )
    }),
    rest.get(`${transactionsEndpoint}.csv`,
      async (req, res, ctx) => {
        return res(
          ctx.set("Content-disposition", "attachment; filename=transactions.csv"),
          // Could potentially use this type instead, keeping here for easy testing
          //
          // ctx.set("Content-Type", "text/html; charset=utf-8"),
          ctx.set("Content-Type", "application/octet-stream"),
          ctx.body(csvText))
      })
]
