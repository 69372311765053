import { Box, Center, Heading, Flex, Image } from "@chakra-ui/react"

import waveImage from "assets/images/wave.png"
import PublicLayout from "components/layouts/Public"

export const Expired = (): JSX.Element => (
  <PublicLayout>
    <Center>
      <Box
        display="flex"
        flexDirection={{ sm: "column", md: "row" }}
        pt={{ sm: "2rem", md: "4rem" }}
        alignItems={{ sm: "center", md: "flex-start" }}
      >
        <Heading
          data-testid="expired-heading"
          pb={{ sm: "2rem", md: "0" }}
          pr={{ sm: "0", md: "4rem" }}
        >
          Sorry, your link has expired...
        </Heading>
        <Flex data-testid="expired-icon" alignItems="center" justify="center" alignSelf={{ base: "unset", lg: "flex-end" }} mt="16px">
          <Image
            width={{ base: "285px", md: "400px", xl: "440px" }}
            height={{ base: "85px", md: "118", xl: "129px" }}
            src={waveImage}
            alt="waveImage"
          />
        </Flex>
      </Box>
    </Center>
  </PublicLayout>
)
