import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  useToast
} from "@chakra-ui/react"

interface ErrLogProps {
  title: string
  description: string
}

export const ErrLog = ({ title, description }: ErrLogProps): JSX.Element => {
  const toast = useToast()
  return (
    <Alert status="error" variant="errorToast">
      <AlertIcon />
      <Box>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription mt="0.5rem">{description}</AlertDescription>
      </Box>
      <CloseButton
        data-testid="closeToast"
        pl="0.5rem"
        alignSelf="flex-start"
        ml="auto"
        position="relative"
        color="white"
        fontSize="0.8rem"
        onClick={() => toast.closeAll()}
      />
    </Alert>
  )
}

export default ErrLog
