type errorData = {
  data:    null
  success: boolean
  code:    number
  message: string
  details: string[]
  errors:  string[]
  changes: string[]
}

type CustomErrorObject = {
  data: errorData
  status: number
}

function isErrorWithObject(error: unknown): error is CustomErrorObject {
  return (
    typeof error === "object" &&
    error !== null &&
    typeof (error as CustomErrorObject).data === "object"
  )
}

function toErrorWithMessage(maybeError: unknown): CustomErrorObject | null {
  if (isErrorWithObject(maybeError)) return maybeError

  return null
}

export const getErrorMessageObject = (error: unknown) => {
  const errorObject: CustomErrorObject | null = toErrorWithMessage(error)

  if(errorObject) {
    return errorObject
  }
}
