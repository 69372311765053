import { DISABLE_SECURE_COOKIE } from "@axle/web/src/config/environment"
import { useAppDispatch } from "@axle/web/src/store/hooks"
import { setJwtTokenValidity } from "@axle/web/src/store/slices/jwtTokenValid"
import { useCookies } from "react-cookie"

export interface useLogoutUser {
  logoutUser: () => void
}

export const useLogoutUser = (): useLogoutUser => {
  const disableSecureCookie = DISABLE_SECURE_COOKIE
  const dispatch = useAppDispatch()
  const [, , removeCookie] = useCookies(["jwt", "username", "ddSkip"])

  const logoutUser = () => {
    removeCookie("jwt", {
      path: "/",
      secure: !disableSecureCookie,
      domain: process.env.REACT_APP_DOMAIN
    })
    removeCookie("username", {
      path: "/",
      secure: !disableSecureCookie,
      domain: process.env.REACT_APP_DOMAIN
    })

    removeCookie("ddSkip", {
      path: "/",
      domain: process.env.REACT_APP_DOMAIN
    })

    dispatch(setJwtTokenValidity(true))
    dispatch({ type: "reset" })
  }

  return {
    logoutUser
  }
}
