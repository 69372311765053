import { Box, Column, Spacer } from "native-base"
import { useEffect, useState } from "react"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { BudgetOwnerControlsDto } from "models/spendControls"
import { useGetSpendControlsMutation } from "network/spendControls"
import { useAppDispatch } from "store/hooks"
import { useBudgetOwnerControls } from "store/hooks/spendControls"
import { setBudgetOwnerControl } from "store/slices/spendControls"

import PolicyHeader from "./PolicyHeader"

const OwnerControl = () => {
  const dispatch = useAppDispatch()
  const [spendControlsLoading, setAdminControlLoading] =
    useState<boolean>(false)

  const { budgetOwnerControls } = useBudgetOwnerControls()

  const [getSpendControls, { isLoading, isSuccess }] =
    useGetSpendControlsMutation()

  const loadSpendControls = async () => {
    setAdminControlLoading(true)
    const spendControlsResults = await getSpendControls().unwrap()
    dispatch(
      setBudgetOwnerControl(spendControlsResults.data as BudgetOwnerControlsDto)
    )
  }

  useEffect(() => {
    !spendControlsLoading && loadSpendControls()
  }, [isLoading, isSuccess, budgetOwnerControls])
  return (
    <Box testID="ownerControl">
      <Column>
        <PolicyHeader />

        <Spacer h="1rem" />
        <BoxCard>
          {budgetOwnerControls && (
            <Box testID="budgetOwnerContent">{budgetOwnerControls.id}</Box>
          )}
        </BoxCard>
      </Column>
    </Box>
  )
}

export default OwnerControl
