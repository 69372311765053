import "./Dashboard.css"
import logo from "@axle/web/src/assets/svgs/public-logo-white.svg"
import { Box, Flex, Image, VStack } from "native-base"

import NavLink from "components/inline/IconLink/NavLink"
import AuthorizedLayout from "components/layouts/Authorized"
import { usePerson } from "store/hooks/person"

import StickyNav from "../../inline/StickyNav"

import BurgerNav from "./BurgerNav"
import useDashboard from "./talons/useDashboard"

export interface DashboardLayoutProps {
  children: JSX.Element | JSX.Element[] | null
  settingsPageDisabled?: boolean
}

const DashboardLayout = ({ children }: DashboardLayoutProps): JSX.Element => {
  const { person } = usePerson()

  const { navList, bottomNavList } = useDashboard()

  const topNavList = navList.map((props) => (
    <NavLink key={props.testID} {...props} mb="16px" />
  ))

  return (
    <AuthorizedLayout burgerNav={<BurgerNav />} roles={person && person.roles}>
      <Flex flexDirection="row" width="100%">
        {/* Nav Menu  */}
        <Box
          testID="nav-links"
          w="230px"
          display={{ base: "none", md: "flex" }}
        >
          <Flex
            position="fixed"
            h="100%"
            top="0"
            left="0"
            pb="48px"
            pt="24px"
            flexDirection={{ base: "unset", md: "column" }}
            bg="primary.600"
          >
            {/* icon */}
            <Flex alignItems="center">
              <Image width="60px" height="99px" src={logo} alt="logo" />
            </Flex>
            {/* Top Nav */}
            <VStack alignItems="flex-start" flex="1" mt="40px">
              {topNavList}
            </VStack>

            {/* Bottom  Nav*/}
            <Box testID="global-nav-items">
              {bottomNavList.map((props) => (
                <NavLink key={props.testID} {...props} mb="16px" />
              ))}
            </Box>
          </Flex>
        </Box>

        {/* Main Content */}
        <Box testID="dashboard-hub" flex="1" >
          <Box
            mt={{ base: "0", md: "48px" }}
            px={{ base: "2", md: "10" }}
            minH="100vh"
            paddingBottom="112px"
          >
            {children}
          </Box>
          <StickyNav />
        </Box>
      </Flex>
    </AuthorizedLayout>
  )
}

export default DashboardLayout
