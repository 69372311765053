import { ReactComponent as EnvelopeOpenedIcon } from "@axle/web/src/assets/svgs/icons/envelope-opened.svg"
import { ReactComponent as EnvelopeIcon } from "@axle/web/src/assets/svgs/icons/envelope.svg"
import { ReactComponent as NotificationIcon } from "@axle/web/src/assets/svgs/icons/notification.svg"
import IconLink from "@axle/web/src/components/inline/IconLink"
import { notification as notificationRoute } from "@axle/web/src/components/layouts/Authorized/routes"
import { getDisplayTitle } from "@axle/web/src/models/enums/event-type"
import { Tag, Box, Menu, Button, Row, Spacer, useToken, Text, Link, Divider } from "native-base"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import useNotificationLink from "./talons/useNotificationLink"

const NotificationLink = ({ showIcon }: { showIcon?: boolean }): JSX.Element => {
  const { unreadNotiCount, notificationList, loadNotification } = useNotificationLink()

  const navigate = useNavigate()

  const svgSize = {
    width: "28px",
    height: "28px"
  }
  const [activeIconColor, baseIconColor] = useToken("colors", ["primary.700", "warmGray.600"])

  const [isHovered, setIsHover] = useState<boolean>(false)
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  return (
    <Box testID="notification-link">
      {showIcon ? (
        <Box display={{ base: "flex", md: "none" }}>
          <IconLink
            mr={4}
            testID="notification-link-tag"
            icon={<NotificationIcon {...svgSize} />}
            text=""
            path={notificationRoute}
            activeColor="tertiary.50"
            color="tertiary.50"
          />
          <Tag
            testID="unread-notification-tag"
            bgColor="red.300"
            _text={{ color: "gray.50", fontSize: "11px" }}
            borderColor="gray.50"
            position="absolute"
            display={unreadNotiCount ? "flex" : "none"}
            borderRadius="11px"
            px={1}
            h={4}
            minH={0}
            minW={0}
            mt={1}
            ml={4}
          >
            {unreadNotiCount}
          </Tag>
        </Box>
      ) : (
        <Box>
          <Button
            testID="notification-link-button"
            isHovered={isHovered || menuOpen}
            alignSelf="center"
            variant="outline"
            minW="160px"
            fontSize="sm"
            onPress={() => {
              loadNotification()
              setMenuOpen(true)
            }}
            onHoverIn={() => {
              setIsHover(true)
            }}
            onHoverOut={() => {
              setIsHover(false)
            }}
          >
            <Row alignItems="center">
              Notifications
              <Spacer w="8px" />
              <NotificationIcon
                width="16px"
                height="16px"
                fill={isHovered || menuOpen ? activeIconColor : baseIconColor}
              />
            </Row>
            <Box
              p="4px"
              right="-4px"
              top="2px"
              borderRadius="100px"
              borderWidth="1px"
              borderColor="tertiary.50"
              bgColor="red.300"
              position="absolute"
              display={unreadNotiCount ? "flex" : "none"}
            />
          </Button>

          <Menu
            w="300px"
            isOpen={menuOpen}
            _backdrop={{ testID: "backdrop" }}
            onClose={() => {
              setMenuOpen(false)
            }}
            trigger={(triggerProps) => {
              return <Box {...triggerProps}></Box>
            }}
          >
            <Row justifyContent="space-between" px="12px" py="16px" testID="noti-dropDown">
              <Text>{unreadNotiCount} New Notifications</Text>

              <Link
                display="flex"
                alignItems="center"
                justifyContent="center"
                testID="view-all-link"
                onPress={() => {
                  navigate(notificationRoute)
                }}
              >
                <Text fontWeight="bold" color="primary.600" marginRight="16px">
                  View All
                </Text>
              </Link>
            </Row>
            <Divider />

            {notificationList.map((item, index) => {
              return (
                <Box key={index} testID="noti-item-id">
                  <Menu.Item key={index} isDisabled flexDir="row" alignItems="center" my="8px">
                    <Box bg="warmGray.200" p="8px" borderRadius="100px">
                      {item.createdAt ? (
                        <EnvelopeOpenedIcon width="16px" height="16px" />
                      ) : (
                        <EnvelopeIcon width="16px" height="16px" />
                      )}
                    </Box>
                    <Spacer w="16px" flexGrow="0" />
                    <Box>{getDisplayTitle(item.type)}</Box>
                  </Menu.Item>
                  <Divider />
                </Box>
              )
            })}
          </Menu>
        </Box>
      )}
    </Box>
  )
}

export default NotificationLink
