import { useAppSelector } from "@axle/web/src/store/hooks"
import { selectCurrentCardIndex } from "@axle/web/src/store/slices/currentCardIndex"
import { useMemo } from "react"



export const useCurrentCardIndex = (): { currentCardIndex: number|null } => {
  const currentCardIndex = useAppSelector(selectCurrentCardIndex)

  return useMemo(() => ({ currentCardIndex }), [currentCardIndex])
}
