import { capeWWW } from "@axle/web/src/config/endpoints"

// Address

export const fullAddress = [
  "Cape Pty Ltd",
  "WOTSO Manly",
  "19-21 S Sleyne Rd",
  "Manly",
  "NSW 2095"
]
export const addressWithoutCompany = fullAddress.slice(1)
export const addressOneLiner = `${fullAddress[0]}: ` + addressWithoutCompany.join(", ")
export const poBoxOneLiner = "PO Box 775, Manly, NSW 1655"


// Phone Number(s)

export const supportPhone = "+61 1300 062 273"
export const supportPhoneHref = "tel:+611300062273"


// Email(s)

export const supportEmail = "support@getcape.io"
export const supportEmailHref = `mailto:${supportEmail}`


// URL(s)

export const faqsURL = `${capeWWW}/faqs`
export const termsAndConditionsURL = `${capeWWW}/application-terms-conditions`
