import { middleware as authApiMiddleware } from "@axle/web/src/network/auth"
import { middleware as cardApiMiddleware } from "@axle/web/src/network/card"
import { middleware as connectionsApiMiddleware } from "@axle/web/src/network/connections"
import { middleware as entityApiMiddleware } from "@axle/web/src/network/entity"
import { errorLogger } from "@axle/web/src/network/errorLogger"
import { listenerMiddleware } from "@axle/web/src/network/jwtExpire"
// import { middleware as logglyApiMiddleware } from "network/loggly"
import { middleware as limitsApiMiddleware } from "@axle/web/src/network/limits"
import { middleware as notificationApiMiddleware } from "@axle/web/src/network/notification"
import { middleware as notificationsApiMiddleware } from "@axle/web/src/network/notifications"
import { middleware as oauthApiMiddleware } from "@axle/web/src/network/oauth"
import { middleware as personApiMiddleware } from "@axle/web/src/network/person"
import { middleware as receiptsApiMiddleware } from "@axle/web/src/network/receipts"
import { middleware as signUpApiMiddleware } from "@axle/web/src/network/signUp"
import { middleware as spendControlsApiMiddleware } from "@axle/web/src/network/spendControls"
import { middleware as statementApiMiddleware } from "@axle/web/src/network/statement"
import { middleware as transactionsApiMiddleware } from "@axle/web/src/network/transactions"
import { middleware as twoFAApiMiddleware } from "@axle/web/src/network/twoFA"
import { middleware as verificationApiMiddleware } from "@axle/web/src/network/verification"
import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import reducer from "./reducer"

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
      .concat(authApiMiddleware)
      .concat(oauthApiMiddleware)
      .concat(signUpApiMiddleware)
      .concat(spendControlsApiMiddleware)
      .concat(personApiMiddleware)
      .concat(cardApiMiddleware)
      .concat(connectionsApiMiddleware)
      .concat(transactionsApiMiddleware)
      .concat(twoFAApiMiddleware)
      .concat(limitsApiMiddleware)
      .concat(notificationsApiMiddleware)
      .concat(notificationApiMiddleware)
      .concat(entityApiMiddleware)
      .concat(receiptsApiMiddleware)
      .concat(statementApiMiddleware)
      .concat(verificationApiMiddleware)
      .concat(listenerMiddleware.middleware)
      .concat(errorLogger)
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch)
