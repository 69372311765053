
import { CardDto } from "@axle/web/src/models/card"
import { useAppSelector } from "@axle/web/src/store/hooks"
import { fetchCards } from "@axle/web/src/store/slices/cards"
import { useMemo } from "react"

export const useCards = (): { cards: CardDto[]|null } => {
  const cards = useAppSelector(fetchCards)

  return useMemo(() => ({ cards }), [cards])
}
