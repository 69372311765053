import { Switch, Row, Text, Box, Checkbox } from "native-base"
import { useEffect, useState } from "react"

import { AdminControlsDto, Budget, Category } from "models/spendControls"
import { useAppDispatch } from "store/hooks"
import {
  setCategoryAubByPosition,
  setCategoryAllowByPosition
} from "store/slices/spendControls"

import MultiSelect from "./MultiSelect"
import RemoveAuthorityDialog from "./RemoveAuthorityDialog"
import RemoveOwnersDialog from "./RemoveOwnersDialog"

const TabRow = ({
  cIndex,
  eCIndex,
  category,
  adminControls
}: {
  cIndex: number
  eCIndex: number
  category: Category
  adminControls: AdminControlsDto
}) => {
  const initIsNeverOpenRemoveOwnersDialog = localStorage.getItem("isNeverOpenRemoveOwnersDialog") || "false"
  const initIsNeverOpenRemoveAuthorityDialog = localStorage.getItem("isNeverOpenRemoveAuthorityDialog") || "false"

  const dispatch = useAppDispatch()
  const [isOpenRemoveOwnersDialog, setIsOpenRemoveOwnersDialog] = useState<boolean>(false)
  const [isOpenRemoveAuthorityDialog, setIsOpenRemoveAuthorityDialog] = useState<boolean>(false)
  const [isDelegateChecked, setIsDelegateChecked] = useState<boolean>(true)
  const [aubId, setAubId] = useState<number | undefined>(undefined)
  const [isNeverOpenRemoveOwnersDialog, setIsNeverOpenRemoveOwnersDialog] = useState<boolean>(initIsNeverOpenRemoveOwnersDialog === "true")
  const [isNeverOpenRemoveAuthorityDialog, setIsNeverOpenRemoveAuthorityDialog] = useState<boolean>(initIsNeverOpenRemoveAuthorityDialog === "true")

  useEffect(() => {
    if (category.authorisedBudgetOwners.items.length === 0) {
      setIsDelegateChecked(false)
    }
  }, [])

  const onCloseRemoveOwnersDialog = () => {
    setIsOpenRemoveOwnersDialog(false)
  }

  const onCloseRemoveAuthorityDialog = () => {
    setIsOpenRemoveAuthorityDialog(false)
  }

  const onChangeRemoveOwnersDialog = () => {
    setIsNeverOpenRemoveOwnersDialog(!isNeverOpenRemoveOwnersDialog)
  }

  const onChangeRemoveAuthorityDialog = () => {
    setIsNeverOpenRemoveAuthorityDialog(!isNeverOpenRemoveAuthorityDialog)
  }

  const setAubs = (adminControls: AdminControlsDto, category: Category, aubId: number): { preLength: number, curLength: number, authorisedBudgetOwners: Budget[] } => {
    const authorisedBudgetOwners = [...category.authorisedBudgetOwners.items]
    const preLength = authorisedBudgetOwners.length

    const isExistIndex = authorisedBudgetOwners.findIndex(
      (item) => item.id === aubId
    )

    if (isExistIndex > -1) {
      authorisedBudgetOwners.splice(isExistIndex, 1)
    } else {
      authorisedBudgetOwners.push(
        adminControls.budgetOwnerOptions.items.filter(
          (item) => item.id === aubId
        )[0]
      )
    }
    const curLength = authorisedBudgetOwners.length

    return { preLength, curLength, authorisedBudgetOwners }
  }

  const onContinueRemoveOwnersDialog = (
    category: Category,
    aubId: number
  ) => {
    const entityCategoryIndex = eCIndex
    const categoryIndex = cIndex
    const { authorisedBudgetOwners } = setAubs(adminControls, category, aubId)

    setIsOpenRemoveOwnersDialog(false)
    onCloseRemoveOwnersDialog()
    isNeverOpenRemoveOwnersDialog && localStorage.setItem("isNeverOpenRemoveOwnersDialog", "true")

    dispatch(
      setCategoryAubByPosition({
        entityCategoryIndex,
        categoryIndex,
        authorisedBudgetOwners
      })
    )
  }

  const setdelegateAuthority = () => {
    const entityCategoryIndex = eCIndex
    const categoryIndex = cIndex
    setIsDelegateChecked(!isDelegateChecked)

    const authorisedBudgetOwners = [] as Budget[]

    if (isDelegateChecked) {
      dispatch(
        setCategoryAubByPosition({
          entityCategoryIndex,
          categoryIndex,
          authorisedBudgetOwners
        })
      )
    }
  }

  const onContinueRemoveAuthorityDialog = () => {
    setdelegateAuthority()
    onCloseRemoveAuthorityDialog()
    isNeverOpenRemoveAuthorityDialog && localStorage.setItem("isNeverOpenRemoveAuthorityDialog", "true")
  }

  const handleDelegateChecked = () => {
    if (!isNeverOpenRemoveAuthorityDialog && isDelegateChecked && initIsNeverOpenRemoveAuthorityDialog !== "true") {
      setIsOpenRemoveAuthorityDialog(true)
    } else {
      setdelegateAuthority()
    }
  }

  const handleSelect = (
    category: Category,
    aubId: number
  ) => {
    const entityCategoryIndex = eCIndex
    const categoryIndex = cIndex
    const { preLength, curLength, authorisedBudgetOwners } = setAubs(adminControls, category, aubId)
    if (!isNeverOpenRemoveOwnersDialog && preLength > curLength && initIsNeverOpenRemoveOwnersDialog !== "true") {
      setIsOpenRemoveOwnersDialog(true)
    } else {
      dispatch(
        setCategoryAubByPosition({
          entityCategoryIndex,
          categoryIndex,
          authorisedBudgetOwners
        })
      )
    }

    setAubId(aubId)
  }

  const CategoryAllowSwitch = ({
    allow,
    eCIndex,
    cIndex
  }: {
    allow: boolean
    eCIndex: number
    cIndex: number
  }): JSX.Element => {
    return (
      <Switch
        size="sm"
        testID={`switch-${eCIndex}-${cIndex}`}
        value={allow}
        onValueChange={(allow) => {
          const entityCategoryIndex = eCIndex
          const categoryIndex = cIndex

          dispatch(
            setCategoryAllowByPosition({
              entityCategoryIndex,
              categoryIndex,
              allow
            })
          )
        }}
      />
    )
  }

  return (
    <>
      <Row
        data-category-index={cIndex}
        justifyContent="space-between"
        background="gray.50"
        alignItems="center"
        h="80px"
        px="16px"
      >
        <Box flex={1} alignItems="center" textAlign="right">
          <Text bold style={{ flexWrap: "wrap", maxWidth: 120 }}>
            {category.name}
          </Text>
        </Box>
        <Box flex={1} alignItems="center">
          <CategoryAllowSwitch
            allow={category.allow}
            eCIndex={eCIndex}
            cIndex={cIndex}
          />
        </Box>

        <Box flex={1} alignItems="center">
          <Checkbox
            isChecked={isDelegateChecked}
            onChange={() => handleDelegateChecked()}
            value=""
            testID={`delegate-checkbox-${eCIndex}-${cIndex}`}
            accessibilityLabel={`delegate-checkbox-${eCIndex}-${cIndex}`}
          />
        </Box>

        <Box flex={1} alignItems="center">
          <MultiSelect
            isDisabled={!isDelegateChecked}
            budgetOwnerOptions={adminControls.budgetOwnerOptions.items}
            eCIndex={eCIndex}
            cIndex={cIndex}
            handleSelect={handleSelect}
          />
        </Box>
      </Row>
      {aubId && <RemoveOwnersDialog isNeverOpenRemoveOwnersDialog={isNeverOpenRemoveOwnersDialog} isOpenRemoveOwnersDialog={isOpenRemoveOwnersDialog} onClose={onCloseRemoveOwnersDialog} onChange={onChangeRemoveOwnersDialog} onContinue={() => onContinueRemoveOwnersDialog(category, aubId)} />}
      <RemoveAuthorityDialog isNeverOpenRemoveAuthorityDialog={isNeverOpenRemoveAuthorityDialog} isOpenRemoveAuthorityDialog={isOpenRemoveAuthorityDialog} ownersAmount={category.authorisedBudgetOwners.items.length} onClose={onCloseRemoveAuthorityDialog} onChange={onChangeRemoveAuthorityDialog} onContinue={onContinueRemoveAuthorityDialog} />
    </>
  )
}

export default TabRow
