import {
  Text,
  Menu,
  Pressable,
  ChevronDownIcon,
  Input,
  Checkbox,
  Spacer
} from "native-base"
import { useState } from "react"

import { Category, Budget } from "models/spendControls"
import { useCategory } from "store/hooks/spendControls"

interface MultiSelectProps {
  budgetOwnerOptions: Budget[]
  eCIndex: number
  cIndex: number
  isDisabled: boolean
  handleSelect: (
    category: Category,
    aubId: number
  ) => void
}

const MultiSelect = ({
  // category,
  budgetOwnerOptions,
  handleSelect,
  eCIndex,
  cIndex,
  isDisabled
}: MultiSelectProps): JSX.Element => {
  const [aubSearch, setAubSearch] = useState<string>("")
  const { category } = useCategory(eCIndex, cIndex)

  return (
    category && (
      <Menu
        testID={`menu-select-${eCIndex}-${cIndex}`}
        maxHeight="10rem"
        closeOnSelect={false}
        trigger={(triggerProps) => (
          <Pressable
            testID={`menu-select-${eCIndex}-${cIndex}-pressable`}
            accessibilityLabel="More options menu"
            isDisabled={isDisabled}
            {...triggerProps}
          >
            <Input
              isDisabled={isDisabled}
              testID={`menu-select-${eCIndex}-${cIndex}-pressable-input`}
              // w={maxW}
              isReadOnly
              rightElement={<ChevronDownIcon mr="1rem" />}
              value={`${category.authorisedBudgetOwners.items.length} Selected`}
              borderRadius="5px"
            />
          </Pressable>
        )}
      >
        <Menu.Item
          flexDir="row"
          alignItems="center"
          borderBottomWidth="1px"
          borderBottomColor="gray.100"
        >
          <Input
            size="xs"
            h="32px"
            testID={`menu-select-${eCIndex}-${cIndex}-search`}
            borderRadius="5px"
            placeholder="Search"
            onChangeText={(value) => {
              setAubSearch(value.toLowerCase())
            }}
            value={aubSearch}
          />
        </Menu.Item>
        {budgetOwnerOptions
          .filter((aub) => aub.name.toLocaleLowerCase().includes(aubSearch))
          .map((aub, aubIndex) => {
            const isExist =
              category.authorisedBudgetOwners.items.findIndex(
                (item) => item.id === aub.id
              ) > -1

            return (
              <Menu.Item
                flexDir="row"
                alignItems="center"
                key={`owner-mitems-${aubIndex}`}
                borderBottomWidth="1px"
                borderBottomColor="gray.100"
                testID={`menu-select-${eCIndex}-${cIndex}-${aubIndex}-checkbox`}
                onPress={() => handleSelect(category, aub.id)}
              >
                <Checkbox
                  accessibilityLabel={`${aub.name}`}
                  isChecked={isExist}
                  value={`${aub.id}`}
                />

                <Spacer flexGrow="0" p="1rem" />
                <Text textAlign="left">{aub.name}</Text>
              </Menu.Item>
            )
          })}
      </Menu>
    )
  )
}

export default MultiSelect
