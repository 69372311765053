import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  Link,
  Spacer,
  Text
} from "native-base"
import { Controller } from "react-hook-form"

import intersectImage from "assets/images/intersect.png"
import { BoxCard } from "components/inline/BoxCard"
import { ReviewableField } from "components/inline/ReviewableField"
import { isAccountNumber, isSortCode } from "components/util/form-validators"
import { getCurrentDate } from "components/util/format-date"
import Person from "models/person"

import { useDirectDebit } from "./talons/"
import * as textItems from "./talons/text"

export interface DirectDebitProps {
  person: Person
}

const DirectDebit = ({ person }: DirectDebitProps): JSX.Element => {
  const {
    onSkip,
    onSubmit,
    onNext,
    wizard,
    setWizard,
    handleSubmit,
    submission,
    isChecking,
    isSubmitting,
    personIsLoading,
    formErrors,
    control
  } = useDirectDebit(person)

  const pages = {
    request: (
      <Box testID="dd-setup">
        <Heading color="warmGray.600" fontFamily="GTPressuraPro">
          {textItems.ddRequestHeading}
        </Heading>
        <Text mt="3" color="warmGray.600">
          {textItems.ddRequestSubheading}
        </Text>

        <Box mt="16px">
          <Heading
            fontSize="28px"
            fontFamily="CircularStdMedium"
            color="warmGray.600"
          >
            Existing accounts
          </Heading>

          <ReviewableField
            my="16px"
            value={textItems.noAccAvailable}
            testID="noAccAvailable"
          />
        </Box>

        <Flex mt="6" flexDir={{ base: "column", sm: "row" }}>
          <Button
            flex="1"
            testID="add-new-account-button"
            onPress={() => setWizard("details")}
          >
            {textItems.addAccountButton}
          </Button>

          <Spacer p="16px" flexGrow="0" />

          <Button
            flex="1"
            testID="skip-button"
            variant="outline"
            onPress={onSkip}
          >
            Skip for now
          </Button>
        </Flex>
      </Box>
    ),
    details: (
      <Box>
        <Heading color="warmGray.600" fontFamily="GTPressuraPro">
          {textItems.ddDetailsHeading}
        </Heading>
        <Text my="32px">{textItems.ddDetailsSubHeading}</Text>

        <Flex flexDir="column">
          <FormControl mb="5" isInvalid={!!formErrors.accountHolder}>
            <Controller
              control={control}
              name="accountHolder"
              rules={{
                required: "required"
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChangeText={(val) => onChange(val)}
                  onBlur={onBlur}
                  value={value}
                  type="text"
                  testID="field-accountHolder"
                  placeholder="Account holder (this is usually the business name)"
                  minW="250px"
                  color="gray.500"
                  px="24px"
                  fontSize="sm"
                />
              )}
            />

            <FormControl.ErrorMessage testID="field-accountHolder-error">
              {textItems.accountHolderValidationMessage}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl mb="5" isInvalid={!!formErrors.BSB}>
            <Controller
              control={control}
              name="BSB"
              rules={{
                required: "required",
                validate: (val) => isSortCode(val)
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChangeText={(val) => onChange(val)}
                  onBlur={onBlur}
                  value={value}
                  type="text"
                  testID="field-BSB"
                  placeholder="BSB"
                  minW="250px"
                  color="gray.500"
                  px="24px"
                  fontSize="sm"
                />
              )}
            />

            <FormControl.ErrorMessage testID="field-BSB-error">
              {formErrors.BSB && formErrors.BSB.message}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl mb="5" isInvalid={!!formErrors.accountNumber}>
            <Controller
              control={control}
              name="accountNumber"
              rules={{
                required: "required",
                validate: (val) => isAccountNumber(val)
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onChangeText={onChange}
                  onBlur={onBlur}
                  value={value}
                  type="text"
                  testID="field-accountNumber"
                  placeholder="Account number - 6-10 characters"
                  minW="250px"
                  color="gray.500"
                  px="24px"
                  fontSize="sm"
                />
              )}
            />

            <FormControl.ErrorMessage testID="field-accountNumber-error">
              {formErrors.accountNumber && formErrors.accountNumber.message}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formErrors.isAuthorizedChecked}>
            <Controller
              control={control}
              name="isAuthorizedChecked"
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  mt="16PX"
                  mb="16px"
                  isChecked={value}
                  onChange={() => onChange(!value)}
                  value=""
                  testID="no-multisig-checkbox"
                  display="flex"
                  alignItems="start"
                >
                  <Text mt="-2">{textItems.signatoryConsentCopy}</Text>
                </Checkbox>
              )}
            />

            <FormControl.ErrorMessage>
              {textItems.signatoryValidationMessage}
            </FormControl.ErrorMessage>
          </FormControl>

          <Spacer padding="32px" flexGrow="0" />

          <Button
            w={{ base: "100%", lg: "40%" }}
            minW="240px"
            onPress={handleSubmit(onSubmit)}
            isLoading={isChecking}
            testID="add-account-button"
          >
            {textItems.ddDetailsButton}
          </Button>
        </Flex>
      </Box>
    ),
    confirm: (
      <Box>
        <Heading mb="5" color="warmGray.600" fontFamily="GTPressuraPro">
          {textItems.ddConfirmationHeading}
        </Heading>
        <Text my="2.5rem">{`Direct Debit set up on ${getCurrentDate()}`}</Text>
        <Flex flexDir="column">
          <ReviewableField
            label="Account Holder"
            value={submission.accountHolder}
            testID="account-holder"
          />

          <ReviewableField
            label="Email address"
            value={submission.email}
            testID="email"
          />

          <ReviewableField label="BSB" value={submission.BSB} testID="bsb" />

          <ReviewableField
            label="Account Number"
            value={submission.accountNumber}
            testID="account-number"
          />

          {submission.institutionName && (
            <ReviewableField
              label="Institution"
              value={submission.institutionName}
              testID="institution"
            />
          )}
        </Flex>

        <Box my="2rem">
          <Text>{textItems.ddConfirmationSubHeading}</Text>
        </Box>

        <FormControl isInvalid={!!formErrors.ddrConsent}>
          <Controller
            control={control}
            name="ddrConsent"
            rules={{
              required: true
            }}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                testID="ddr-consent-checkbox"
                isChecked={value}
                onChange={() => onChange(!value)}
                display="flex"
                alignItems="start"
                my="16px"
                value=""
              >
                <Text>{textItems.ddrConsentCopy}</Text>
              </Checkbox>
            )}
          />

          <FormControl.ErrorMessage>
            {textItems.ddrConsentValidation}
          </FormControl.ErrorMessage>
        </FormControl>

        <Flex mt="6" flexDir={{ base: "column", sm: "row" }}>
          <Button
            flex="1"
            testID="btn-back"
            variant="outline"
            onPress={() => setWizard("details")}
          >
            Back
          </Button>
          <Spacer p="16px" flexGrow="0" />
          <Button
            flex="1"
            onPress={handleSubmit(onSubmit)}
            testID="btn-confirm"
            isLoading={isSubmitting}
          >
            Confirm details
          </Button>
        </Flex>
      </Box>
    ),
    thanks: (
      <Box testID="thanks-page">
        <Heading color="warmGray.600" fontFamily="GTPressuraPro">
          {textItems.thanksHeading}
        </Heading>
        <Spacer h="32px" />
        {submission.pdfLink && (
          <Link testID="mandate-pdf" href={submission.pdfLink}>
            <Text color="red.300" fontSize="lg">
              {textItems.mandateDownloadLink}
            </Text>
          </Link>
        )}
        <Spacer h="48px" />
        <Button
          onPress={onNext}
          isLoading={personIsLoading}
          testID="thank-next-btn"
          w={{ base: "100%", sm: "30%" }}
        >
          {textItems.thanksButton}
        </Button>
      </Box>
    )
  }

  return (
    <BoxCard>
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        testID="dd-container"
        minH="50vh"
        w="100%"
        justifyContent="space-between"
        alignItems={{ base: "center", lg: "flex-start" }}
      >
        <Box mb="32px" w={{ base: "100%", lg: "50%" }}>
          {pages[wizard]}
        </Box>
        <Flex testID="burt" alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }} mt="16px">
          <Image
            width={{ base: "266px", md: "300px", xl: "400px" }}
            height={{ base: "250px", md: "286px", xl: "380px" }}
            src={intersectImage}
            alt="intersectImage"
          />
        </Flex>
      </Flex>
    </BoxCard>
  )
}

export default DirectDebit
