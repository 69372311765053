import { Button } from "native-base"
import { useNavigate } from "react-router-dom"

import { IssuePageTemplate } from "components/inline/IssuePageTempale"
import { login as loginRoute } from "components/layouts/Public/routes"

import { contentText } from "./talons/useSessionExpire"

export const SessionExpired = (): JSX.Element => {
  const navigate = useNavigate()

  const templateAction = <Button testID="back-button" width="fit-content" minW="240px" onPress={() => navigate(loginRoute)}>
    Sign back in
  </Button>

  return (
    <IssuePageTemplate
      titleText={"Session expired..."}
      actionArea={templateAction}
      contentText={contentText}
    />
  )
}
