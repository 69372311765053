import { useAppSelector } from "@axle/web/src/store/hooks"
import { TransactionsState, fetchTransactions } from "@axle/web/src/store/slices/transactions"
import { useMemo } from "react"

export const useTransactions = (): TransactionsState => {
  const transactions = useAppSelector(fetchTransactions)

  return useMemo(() => ({
    hasMoreTransactions: transactions.hasMoreTransactions,
    transactions: transactions.transactions,
    currentPage: transactions.currentPage
  }), [transactions])
}
