import BoxCard from "@axle/web/src/components/inline/BoxCard/NbBoxCard"
import { Platform } from "@axle/web/src/models/platform"
import {
  Box,
  Heading,
  Row,
  Column,
  Text,
  Button,
  Divider,
  AlertDialog
} from "native-base"
import { useEffect, useState, useRef } from "react"

import { ReactComponent as ApproveIcon } from "assets/svgs/icons/approve.svg"
import { ReactComponent as XeroIcon } from "assets/svgs/icons/connections/xero.svg"
import { ReactComponent as DeclineIcon } from "assets/svgs/icons/decline.svg"
import { ReactComponent as SyncIcon } from "assets/svgs/icons/sync.svg"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { receiveSignalrEvents } from "components/providers/Events/signalR"
import { getDateFromISO, getTimeFromISO } from "components/util/format-date"
import { XeroConnectionStatus } from "models/enums/connections/xero"
import { EventTypes } from "models/enums/event-type"
import Notification from "models/notification"
import {
  usePlatformsMutation,
  useGenerateXeroUrlMutation,
  useDisconnectXeroMutation,
  usePostXeroSyncMutation
} from "network/connections"


import { XeroAccounts } from "./xeroAccounts"

export enum ConnectionMode {
  General = 1,
  Xero = 2
}

export const Connections = (): JSX.Element => {
  const [fetchPlatforms, { isLoading, isSuccess }] = usePlatformsMutation()
  const [generateXeroUrl] = useGenerateXeroUrlMutation()
  const [disconnectXero] = useDisconnectXeroMutation()
  const [syncXero, { isLoading: isLoadingSync }] = usePostXeroSyncMutation()

  const [connectionMode, setConnectionMode] = useState<ConnectionMode>(
    ConnectionMode.General
  )
  const [isXeroConnecting, setIsXeroConnecting] = useState<boolean>(false)
  const [isXeroConfirmedOpen, setIsXeroConfirmedOpen] = useState<boolean>(false)
  const [isDisconnectOpen, setIsDisconnectOpen] = useState(false)
  const [xero, setXero] = useState<Platform | null>()
  const [, setXeroLink] = useState<string>()
  const [platforms, setPlatforms] = useState<Platform[]>([])

  const xeroConfirmed = (): void => {
    setConnectionMode(ConnectionMode.General)
    setIsXeroConfirmedOpen(true)
    buildPlatforms()
  }

  const onDisconnectClose = () => setIsDisconnectOpen(false)
  const cancelDisconnectRef = useRef(null)

  const onXeroConfirmedClose = () => {
    setIsXeroConfirmedOpen(false)
    setConnectionMode(ConnectionMode.General)
  }
  const cancelXeroCOnfirmedtRef = useRef(null)

  async function buildPlatforms() {
    const platformsResponse = await fetchPlatforms().unwrap()
    const platformItems = platformsResponse.data.items
    setPlatforms(platformItems)

    platformItems.forEach((platform) => {
      if (platform.platformType === "xero") {
        setXero(platform)
      }
    })
  }

  async function generateXeroLink() {
    const genUrlResponse = await generateXeroUrl().unwrap()

    setIsXeroConnecting(true)
    setXeroLink(genUrlResponse.data.redirectUrl)
    window.open(genUrlResponse.data.redirectUrl)
  }

  async function handleDisconnectXero() {
    await disconnectXero(JSON.stringify("xero")).unwrap()
    buildPlatforms()
  }

  async function handleSyncXero() {
    await syncXero()
    buildPlatforms()
  }

  useEffect(() => {
    platforms.length === 0 && !isLoading && !isSuccess && buildPlatforms()
  }, [])

  const [connectionNotifications, setConnectionNotification] = useState<
    Partial<Notification>[]
  >([])

  useEffect(() => {
    return () => {
      setConnectionMode(ConnectionMode.General)
      setXero(null)
      setXeroLink(undefined)
      setIsDisconnectOpen(false)
      setIsXeroConnecting(false)
      setIsXeroConfirmedOpen(false)
      setConnectionNotification([])
    }
  }, [])

  receiveSignalrEvents((notification: Partial<Notification>) => {
    if (notification.type === EventTypes.CodatPlatFormConnected) {
      setIsXeroConnecting(false)
      buildPlatforms()
      setConnectionMode(ConnectionMode.Xero)
      setConnectionNotification([...connectionNotifications, notification])
    }

    if (notification.type === EventTypes.BankAccountConfirmed) {
      xeroConfirmed()
    }

    if (notification.type === EventTypes.CodatPlatFormDisconnected) {
      buildPlatforms()
    }
  })

  const xeroAccountsMode = xero && connectionMode === ConnectionMode.Xero
  const xeroGeneralMode = xero && connectionMode === ConnectionMode.General
  const xeroNotConnected =
    xero && xero.connectionStatus.name === XeroConnectionStatus.NotConnected
  const xeroConnected =
    xero && xero.connectionStatus.name === XeroConnectionStatus.Connected
  const xeroAwaitingSelection =
    xero &&
    xero.connectionStatus.name === XeroConnectionStatus.AwaitingAccountSelection
  const xeroAwaitingAuth =
    xero &&
    xero.connectionStatus.name === XeroConnectionStatus.AwaitingAuthorisation
  const xeroDisconnected =
    xero && xero.connectionStatus.name === XeroConnectionStatus.Disconnected

  return (
    <Box>
      <PageHeading testID="connections-header">Connections</PageHeading>
      <BoxCard testID="connections">
        <AlertDialog
          leastDestructiveRef={cancelXeroCOnfirmedtRef}
          isOpen={isXeroConfirmedOpen}
          onClose={onXeroConfirmedClose}
        >
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>Connection successful</AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your Cape transactions will now automatically be synced to Xero.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button
                  testID="close-xero-confirmed"
                  onPress={onXeroConfirmedClose}
                >
                  Close
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>

        {xeroAccountsMode && <XeroAccounts setConnectionMode={setConnectionMode}/>}

        {xero && (
          <Row
            flexDirection={ { sm: "column", lg: "row" } }
            display={xeroGeneralMode ? "flex" : "none"}
            justifyContent="space-between"
          >
            <Column>
              <Row>
                <Column pr="16px">
                  <XeroIcon />
                </Column>
                <Column>
                  <Row alignItems="center" space={2}>
                    <Heading>Xero</Heading>
                    {xeroConnected && <ApproveIcon fill="#5A89AC" />}
                    {xeroDisconnected && <DeclineIcon fill="#D87B80" />}
                  </Row>
                  <Text display={xeroNotConnected ? "block" : "none"}>
                    Not connected
                  </Text>
                  <Box display={xeroConnected ? "block" : "none"}>
                    <Text>Connected: </Text>
                    <Text bold>
                      {xero.connectedAt && getDateFromISO(xero.connectedAt)}
                    </Text>
                  </Box>
                  <Box display={xeroConnected ? "block" : "none"}>
                    <Text>Last sent: </Text>
                    <Text bold>
                      {xero.lastSyncAt && getDateFromISO(xero.lastSyncAt)} at{" "}
                      {xero.lastSyncAt && getTimeFromISO(xero.lastSyncAt)}
                    </Text>
                  </Box>
                  <Text display={xeroAwaitingSelection ? "block" : "none"}>
                    Awaiting config
                  </Text>
                  <Text display={xeroAwaitingAuth ? "block" : "none"}>
                    Awaiting authorisation
                  </Text>
                  <Box display={xeroDisconnected ? "block" : "none"}>
                    <Text>Disconnected: </Text>
                    <Text bold>
                      {xero.connectedAt && getDateFromISO(xero.connectedAt)}
                    </Text>
                  </Box>
                </Column>
              </Row>
            </Column>
            <Column alignSelf="end" mb={1}>
              <Row justifyContent="space-between" space={1}>
                {xero.connectionStatus.name ===
                  XeroConnectionStatus.Connected &&
                    <Button
                      testID="sync-xero"
                      isLoading={isLoadingSync}
                      onPress={handleSyncXero}
                    >
                      <Box flexDirection="row">
                        <Text color="white">Sync now </Text><SyncIcon />
                      </Box>
                    </Button>
                }
                {(xero.connectionStatus.name ===
                  XeroConnectionStatus.Connected ||
                  xero.connectionStatus.name ===
                    XeroConnectionStatus.AwaitingAccountSelection) && (
                  <Box>

                    <Button
                      colorScheme="primary"
                      testID="disconnect-xero-dialog"
                      onPress={() => setIsDisconnectOpen(!isDisconnectOpen)}
                    >
                      Disconnect
                    </Button>

                    <AlertDialog
                      leastDestructiveRef={cancelDisconnectRef}
                      isOpen={isDisconnectOpen}
                      onClose={onDisconnectClose}
                    >
                      <AlertDialog.Content>
                        <AlertDialog.CloseButton />
                        <AlertDialog.Header>
                          Revoke access to Xero?
                        </AlertDialog.Header>
                        <AlertDialog.Body>
                          <Text>
                            Disconnecting from Xero will end your bank feed
                            between Cape and Xero.
                          </Text>

                          <Text>
                            Your Cape transactions will no longer be synced to
                            Xero. You can reconnect at any time.
                          </Text>
                        </AlertDialog.Body>
                        <AlertDialog.Footer>
                          <Button.Group space={2}>
                            <Button
                              testID="disconnect-xero-cancel"
                              onPress={onDisconnectClose}
                              ref={cancelDisconnectRef}
                            >
                              Cancel
                            </Button>
                            <Button
                              testID="disconnect-xero-confirm"
                              onPress={handleDisconnectXero}
                            >
                              Disconnect
                            </Button>
                          </Button.Group>
                        </AlertDialog.Footer>
                      </AlertDialog.Content>
                    </AlertDialog>
                  </Box>
                )}
                {(xero.connectionStatus.name ===
                  XeroConnectionStatus.Disconnected ||
                  xero.connectionStatus.name ===
                  XeroConnectionStatus.AwaitingAuthorisation ||
                  xero.connectionStatus.name ===
                    XeroConnectionStatus.NotConnected) && (
                  <Button
                    colorScheme="primary"
                    isLoading={isXeroConnecting}
                    isLoadingText="Connecting"
                    testID="connect-xero"
                    onPress={generateXeroLink}
                  >
                    Connect
                  </Button>
                )}
                {xero.connectionStatus.name ===
                  XeroConnectionStatus.AwaitingAccountSelection && (
                  <Button
                    testID="configure-xero"
                    onPress={() => setConnectionMode(ConnectionMode.Xero)}
                  >
                    Configure
                  </Button>
                )}

                {xero.connectionStatus.name ===
                  XeroConnectionStatus.AwaitingAuthorisation && (
                  <Button
                    isDisabled={true}
                    testID="configure-xero-awaiting-auth"
                  >
                    Configure
                  </Button>
                )}
              </Row>
            </Column>
          </Row>
        )}
        <Divider my={2} />
      </BoxCard>
    </Box>
  )
}
