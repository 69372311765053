import { FF__SIMPLE_NOTIFICATIONS } from "@axle/web/src/config/environment"
import { EventTypes } from "@axle/web/src/models/enums/event-type"
import NotificationDto from "@axle/web/src/models/notification"
import { useGetNotificationsMutation } from "@axle/web/src/network/notifications"
import { useUnreadNotiCount } from "@axle/web/src/store/hooks/unreadNotificationCount"
import { useState } from "react"


interface useNotificationLink {
  unreadNotiCount: number

  notificationList: NotificationDto[]

  loadNotification: () => Promise<void>
}

const simpleNotifications = FF__SIMPLE_NOTIFICATIONS

const useNotificationLink = (hasSimpleNotifications = simpleNotifications): useNotificationLink => {
  const { unreadNotiCount } = useUnreadNotiCount()

  const [getNotifications] = useGetNotificationsMutation()
  const [notificationList, setNotificationList] = useState<NotificationDto[]>(
    []
  )

  const loadNotification = async () => {
    const res = await getNotifications({
      Page: 0,
      PageSize: 3
    }).unwrap()

    /* istanbul ignore next */ // tempory for now until we publish the notifications updates
    const newNotifications = hasSimpleNotifications ? res.data.items.filter((notification) => {
      if(notification.type === EventTypes.StatementReady) {
        return notification
      }
    }) : res.data.items

    setNotificationList(newNotifications)
  }

  return {
    unreadNotiCount,
    notificationList,
    loadNotification
  }
}
export default useNotificationLink
