import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  Heading,
  Input,
  Radio,
  Spacer,
  Spinner,
  Text,
  VStack
} from "native-base"
import { Controller } from "react-hook-form"

import { ReactComponent as DollarIcon } from "assets/svgs/icons/dollar.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { CardDetails } from "components/inline/CardDetails"
import PageHeading from "components/inline/PageHeading/PageHeading"
import PersonDto from "models/person"

import DatePick from "../../inline/DatePicker"

import Confirmation from "./Confirmation"
import Confirmed from "./Confirmed"
import usePayments from "./talons/usePayments"

export interface PaymentsProps {
  person: PersonDto
}

export const Payments = ({ person }: PaymentsProps): JSX.Element | null => {
  const {
    wizard,
    currentCard,
    confirmData,
    usageLoading,
    isSubmitting,
    bankAccountDetails,
    errors,
    handleSubmit,
    onSubmit,
    switchChargeDateMode,
    submitFunction,
    endPayments,
    control,
    maxPayment,
    swichSelectAmount,
    selectPayDate,
    selectPayAmount,
    selectPayAmountValue,
    selectPayDateValue
  } = usePayments(person)

  if (usageLoading && currentCard && person.ledger) {
    return (
      <Center>
        <Spinner testID="payments-loading-spinner" />
      </Center>
    )
  }

  const page = {
    confirmation: (
      <Confirmation
        confirmData={confirmData}
        bankAccountDetails={bankAccountDetails}
        isSubmitting={isSubmitting}
        submitFunction={submitFunction}
      />
    ),
    confirmed: (
      <Confirmed
        endPayments={endPayments}
        bankAccountDetails={bankAccountDetails}
        confirmData={confirmData}
      />
    ),
    payment: (
      <>
        <Heading size="md">Payment details</Heading>
        <Spacer height="2rem" />
        <Heading size="sm">How much do you want to pay?</Heading>
        <Spacer h="1rem" />

        <FormControl isInvalid={!!errors.Amount}>
          <Radio.Group
            onChange={(value) => {
              swichSelectAmount(value)
            }}
            name="select_payment_amount"
            accessibilityLabel="select_payment_amount"
          >
            <Radio
              testID="select_maxAmount"
              value={selectPayAmountValue.max}
              _text={{ color: "warmGray.600" }}
            >
              {"Total outstanding balance: $" + maxPayment}
            </Radio>
            <Spacer h="1rem" w="1rem" />

            <Flex
              flexDir={{ base: "column", md: "row" }}
              flexShrink="1"
              alignItems={{ base: "start", md: "center" }}
            >
              <Radio
                testID="select_customAmount"
                value={selectPayAmountValue.custom}
                _text={{ color: "warmGray.600" }}
              >
                Other amount:
              </Radio>
              <Spacer h="1rem" w="1rem" />

              {selectPayAmount === selectPayAmountValue.custom && (
                <Controller
                  control={control}
                  name="Amount"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      testID="amountInput"
                      maxW="15rem"
                      size="lg"
                      onChangeText={(val) => {
                        const patternNumber = val
                          .split(/ /)[0]
                          .replace(/[^\d.]/g, "")
                        onChange(patternNumber)
                      }}
                      value={value}
                      backgroundColor="transparent"
                      keyboardType="numeric"
                      leftElement={
                        <Box pl="1rem">
                          <DollarIcon height="18px" width="10px" />
                        </Box>
                      }
                      justifyContent="start"
                    />
                  )}
                />
              )}
            </Flex>

            <FormControl.ErrorMessage>
              {errors.Amount && errors.Amount.message}
            </FormControl.ErrorMessage>
          </Radio.Group>
        </FormControl>
        <Spacer height="2rem" />

        <Heading size="sm">When do you want to pay?</Heading>
        <Spacer h="0.5rem" />

        <Text fontSize="xs">
          If the day you select is a weekend or bank holiday, then the Direct
          Debit will be taken from your bank account on the next business day.
        </Text>
        <Spacer h="1rem" />

        <FormControl zIndex={5} isInvalid={!!errors.ChargeDate}>
          <Radio.Group
            onChange={(value) => {
              switchChargeDateMode(value)
            }}
            name="ChargeDateChooser"
            accessibilityLabel="ChargeDateChooser"
            alignItems={{ base: "start" }}
            justifyContent="start"
          >
            <Radio
              testID="chargeDate_current"
              value={selectPayDateValue.today}
              _text={{ color: "warmGray.600" }}
            >
              Now
            </Radio>

            <Spacer h="1rem" w="1rem" />

            <Flex
              alignItems={{ base: "start", md: "center" }}
              flexDir={{ base: "column", md: "row" }}
            >
              <Radio
                testID="chargeDate_choose"
                value={selectPayDateValue.selectDate}
                _text={{ color: "warmGray.600" }}
              >
                On this date:
              </Radio>

              <Spacer h="1rem" w="1rem" />

              {selectPayDate === selectPayDateValue.selectDate && (
                <VStack zIndex={5}>
                  <Controller
                    control={control}
                    name="ChargeDate"
                    render={({ field: { onChange, value } }) => (
                      <DatePick
                        testID="chargeDateInput"
                        value={value}
                        onChangeText={onChange}
                      />
                    )}
                  />

                  <FormControl.ErrorMessage
                    position="absolute"
                    ml="1rem"
                    mt="2.5rem"
                  >
                    {errors.ChargeDate && errors.ChargeDate.message}
                  </FormControl.ErrorMessage>
                </VStack>
              )}
            </Flex>
            <Spacer />
          </Radio.Group>
        </FormControl>

        <Spacer height="2rem" />
        <VStack>
          <Heading size="sm">Bank account</Heading>
          <Spacer h="1rem" />
          <Text>{bankAccountDetails}</Text>
        </VStack>

        <Spacer height="2rem" />

        <Button
          isDisabled={!selectPayDate || !selectPayAmount}
          w="15rem"
          variant="solid"
          testID="make-payment-submit"
          onPress={handleSubmit(onSubmit)}
        >
          Make payment
        </Button>
      </>
    )
  }

  return (
    <Box testID="payments">
      <PageHeading testID="payments-header">Payments</PageHeading>

      <CardDetails />

      <BoxCard pb="10rem">
        <Box>
          <Box w={{ base: "100%", sm: "68%", lg: "50%" }}>
            {wizard === "confirmed" && page.confirmed}
            {wizard === "confirmation" && page.confirmation}
            <Box display={wizard === "payment" ? "block" : "none"}>
              {page.payment}
            </Box>
          </Box>
        </Box>
      </BoxCard>
    </Box>
  )
}
