import { Box, IBoxProps } from "native-base"

const BoxCard = ({ children, ...props }: IBoxProps): JSX.Element => {
  return (
    <Box
      display="block"
      bg="white"
      borderRadius="8"
      py={{ base: "24px", lg: "32px" }}
      px={{ base: "24px", lg: "48px" }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default BoxCard
