import { Text, Divider, Box, Flex, Heading, Spinner } from "native-base"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { formatPrice } from "components/util/format-price"

import { UsersForm } from "./Form"
import usePersons from "./talons/usePersons"

export const Users = (): JSX.Element => {
  const {
    companyLimit,
    allocated,
    unallocated,
    currentBalance,
    pending,
    available,
    entityPerson,
    isOpenEdit,
    setIsOpenEdit,
    setEntityPerson,
    setEntityUsageStats
  } = usePersons()

  return (
    <Box testID="people">
      <PageHeading testID="people-header">People</PageHeading>

      <BoxCard
        key="summary-dashboard"
        testID="people-summary-dashboard"
        mb="48px"
      >
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          p={{ base: "12px", lg: "0" }}
        >
          <Box
            w={{ base: "100%", lg: "1/6" }}
            display="flex"
            flexDir={{ base: "row", lg: "column" }}
            justifyContent={{ base: "space-between", lg: "flex-start" }}
            alignItems={{ base: "center", lg: "flex-start" }}
          >
            <Heading size="sm" flexGrow={1}>
              Company Limit
            </Heading>
            <Divider mt="2" mb="6" display={{ base: "none", lg: "block" }} />
            <Text fontSize="md" color="warmGray.600">
              {formatPrice(companyLimit).currency}
            </Text>
          </Box>

          <Divider my="4" display={{ base: "block", lg: "none" }} />

          <Box
            w={{ base: "100%", lg: "1/6" }}
            display="flex"
            flexDir={{ base: "row", lg: "column" }}
            justifyContent={{ base: "space-between", lg: "flex-start" }}
            alignItems="center"
          >
            <Heading size="sm" flexGrow={1}>
              Allocated
            </Heading>
            <Divider mt="2" mb="6" display={{ base: "none", lg: "block" }} />
            <Text fontSize="md" color="warmGray.600">
              {formatPrice(allocated).currency}
            </Text>
          </Box>

          <Divider my="4" display={{ base: "block", lg: "none" }} />

          <Box
            w={{ base: "100%", lg: "1/6" }}
            display="flex"
            flexDir={{ base: "row", lg: "column" }}
            justifyContent={{ base: "space-between", lg: "flex-start" }}
            alignItems="center"
          >
            <Heading size="sm" flexGrow={1}>
              Unallocated
            </Heading>
            <Divider mt="2" mb="6" display={{ base: "none", lg: "block" }} />
            <Text fontSize="md" color="warmGray.600">
              {formatPrice(unallocated).currency}
            </Text>
          </Box>

          <Divider my="4" display={{ base: "block", lg: "none" }} />

          <Box
            w={{ base: "100%", lg: "1/6" }}
            display="flex"
            flexDir={{ base: "row", lg: "column" }}
            justifyContent={{ base: "space-between", lg: "flex-start" }}
            alignItems="center"
          >
            <Heading size="sm" flexGrow={1}>
              Balance
            </Heading>
            <Divider mt="2" mb="6" display={{ base: "none", lg: "block" }} />
            <Text fontSize="md" color="warmGray.600">
              {formatPrice(currentBalance).currency}
            </Text>
          </Box>

          <Divider my="4" display={{ base: "block", lg: "none" }} />

          <Box
            w={{ base: "100%", lg: "1/6" }}
            display="flex"
            flexDir={{ base: "row", lg: "column" }}
            justifyContent={{ base: "space-between", lg: "flex-start" }}
            alignItems="center"
          >
            <Heading size="sm" flexGrow={1}>
              Pending
            </Heading>
            <Divider mt="2" mb="6" display={{ base: "none", lg: "block" }} />
            <Text fontSize="md" color="warmGray.600">
              {formatPrice(pending).currency}
            </Text>
          </Box>

          <Divider my="4" display={{ base: "block", lg: "none" }} />

          <Box
            w={{ base: "100%", lg: "1/6" }}
            display="flex"
            flexDir={{ base: "row", lg: "column" }}
            justifyContent={{ base: "space-between", lg: "flex-start" }}
            alignItems={{ base: "center", lg: "flex-end" }}
          >
            <Heading size="sm" flexGrow={1}>
              Available
            </Heading>
            <Divider mt="2" mb="6" display={{ base: "none", lg: "block" }} />
            <Text fontSize="md" color="warmGray.600">
              {formatPrice(available).currency}
            </Text>
          </Box>
        </Flex>
      </BoxCard>

      {entityPerson.length === 0 ? (
        <Spinner accessibilityLabel="Loading persons" />
      ) : (
        <UsersForm
          entityPerson={entityPerson}
          setIsOpenEdit={setIsOpenEdit}
          isOpenEdit={isOpenEdit}
          companyLimit={companyLimit}
          setEntityPerson={setEntityPerson}
          setEntityUsageStats={setEntityUsageStats}
        />
      )}
    </Box>
  )
}
