import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  useBreakpointValue
} from "native-base"

import intersectImage from "assets/images/intersect.png"

import { useSetUpTwoFa } from "./talons/useSetUpTwoFa"

export const SetUpComplete = () => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" })
  const { handleRouteChange } = useSetUpTwoFa()

  return <Box
    width="100%"
    maxWidth={"960px"}
    marginX={"auto"}
    marginY={"1rem"}
    testID="2FASetUpComplete"
  >
    <Box marginX={4} bg="white" p={6} borderRadius="6px">
      <Flex flexDirection={flexDirection} flexWrap="wrap">
        <Box maxWidth="650px" width="100%">
          <Heading
            fontSize={"3xl"}
            testID="completeHeader"
            mb="28px"
          >
            Set up complete
          </Heading>
          <Text mb="14px" >Your account is now protected by two factor authentication.</Text>
          <Text >From now on, if we notice any unfamiliar activity, we'll prompt you to complete two factor authentication when you login.</Text>
          <Button
            testID="toDashLink"
            onPress={() => handleRouteChange()}
            maxWidth={"209px"}
            mt="28px"
          >
            Dashboard
          </Button>
        </Box>
        <Image
          ml="auto"
          mt="4"
          width="353px"
          height="320px"
          src={intersectImage}
          alt={"intersectImage"}
        />
      </Flex>
    </Box>
  </Box>
}
