import { Button, Heading, Spacer, Text } from "native-base"

import { Currency } from "components/inline/Currency"
import { getNumericDate } from "components/util/format-date"
import { MakeAPayment } from "network/entity"

interface Confirmation {
  confirmData: MakeAPayment | undefined
  bankAccountDetails: string
  isSubmitting: boolean
  submitFunction: () => Promise<void>
}

const Confirmation = ({
  confirmData,
  bankAccountDetails,
  isSubmitting,
  submitFunction
}: Confirmation): JSX.Element => {
  return (
    <>
      <Heading size="md" testID="confirm-payment-heading">
        Confirm payment details
      </Heading>
      <Spacer h="1rem" />
      <Text fontSize="sm">
        Please check that the following details are correct
      </Text>
      <Currency
        color="red.300"
        amount={(confirmData as MakeAPayment).Amount}
        size="3xl"
      />
      <Heading size="sm" fontWeight="1000">
        to Cape paid on the{" "}
        {getNumericDate((confirmData as MakeAPayment).ChargeDate)}
      </Heading>
      <Spacer h="3rem" />
      <Heading size="xs" isTruncated testID="tbc-heading" mb="1rem">
        Account to be charged
      </Heading>
      <Text borderColor="gray.100" fontWeight="200">
        {bankAccountDetails}
      </Text>
      <Spacer h="2rem" />
      <Button
        w="15rem"
        testID="confirmPayment"
        isLoading={isSubmitting}
        onPress={submitFunction}
      >
        Confirm payment
      </Button>
      <Spacer h="1rem" />
    </>
  )
}

export default Confirmation
