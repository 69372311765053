
import { ResponseCode } from "@axle/web/src/network/util/api-response"
import { setJwtTokenValidity } from "@axle/web/src/store/slices/jwtTokenValid"
import {
  createListenerMiddleware
} from "@reduxjs/toolkit"

export const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  predicate: (action) => {
    // return true when the listener should run
    return action.payload && action.payload.success === false &&
        action.payload.code === ResponseCode.JwtTokenExpired
  },
  effect: (action, listenerApi) => {
    listenerApi.dispatch(setJwtTokenValidity(false))
  }
})
