import BoxCard from "@axle/web/src/components/inline/BoxCard/NbBoxCard"
import {
  Center,
  Spacer,
  Heading,
  Button,
  VStack
} from "native-base"
import { useNavigate } from "react-router-dom"

export const ErrorPage = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <>
      <Heading
        textAlign={{ base: "center", md: "unset" }}
        size="xl"
        mb="1.5rem"
        testID="load-error-header">
          Page Loading Error
      </Heading>
      <BoxCard>
        <Center>
          <VStack>
            <Spacer h="1rem" />
            <Heading size="md" paddingLeft="1rem" testID="error-page">
        Oops, there was a problem
            </Heading>
            <Spacer h="1rem" />
            <Button testID="try-again" onPress={() => navigate(0)}>Try Again</Button>
          </VStack>
        </Center>
      </BoxCard>
    </>
  )
}
