import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  Row,
  Column
} from "native-base"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import heartImage from "assets/images/heart.png"
import ribbonSmileImage from "assets/images/ribbon-smile.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import CardAddressChooser from "components/inline/OrderCardAddressChooser"
import PageHeading from "components/inline/PageHeading/PageHeading"

import useReplaceCard from "./talons/useReplaceCard"

export const ReplaceCard = (): JSX.Element => {
  const {
    submitReplaceCard,
    isSuccess,
    residentialAddress,
    commercialAddress,
    chosenAddressState,
    currentCard,
    setChosenAddressState,
    control,
    isLoading,
    showPage
  } = useReplaceCard()
  const navigate = useNavigate()

  const page = {
    ReplaceCardPage: (
      <Flex flexDir={{ base: "column", lg: "row" }} testID="replace-card-form">
        <Column maxW="25rem">
          <Text>
            {
              "Current one looking a little shabby? Lost your card? It happens to the best of us. Lets sort this out. Your card will be sent to the following address:"
            }
          </Text>

          <Spacer height="1.5em" flexGrow="0" />

          {commercialAddress && (
            <Controller
              name="AddressId"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange: controllerOnChange } }) => (
                <CardAddressChooser
                  residential={residentialAddress?.addressX}
                  commercial={commercialAddress.addressX}
                  value={chosenAddressState}
                  onChange={(newValue) => {
                    setChosenAddressState(newValue)

                    if (newValue === "residential") {
                      controllerOnChange(residentialAddress?.id)
                    } else {
                      controllerOnChange(commercialAddress.id)
                    }
                  }}
                />
              )}
            />
          )}

          <Row mt="48px">
            <Button
              testID="replace-card-back-button"
              flex="1"
              onPress={() => navigate(-1)}
              disabled={isLoading}
              variant="outline"
            >
              Cancel
            </Button>
            <Spacer w="16px" flexGrow="0" />

            {currentCard && (
              <Button
                flex="1"
                testID="replace-card-submit"
                onPress={submitReplaceCard(currentCard.id)}
                disabled={!chosenAddressState || isLoading || isSuccess}
                isLoading={isLoading}
              >
                Order new card
              </Button>
            )}
          </Row>
        </Column>

        <Box flex="1" alignItems="center" justifyContent="center">
          <Image
            mt="32px"
            width={{ base: "250px", sm: "250px", md: "250px", xl: "315px" }}
            height={{ base: "325px", sm: "325px", md: "325px", xl: "410px" }}
            src={heartImage}
            alt="heartImage"
          />
        </Box>
      </Flex>
    ),
    SuccessPage: (
      <Box
        minH="50vh"
        display="flex"
        flexDir={{ base: "column", xl: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "center", lg: "flex-start" }}
      >
        <Box testID="success-modal">
          <Heading size="xl" isTruncated>
            Thank you!
          </Heading>
          <Text mt="32px" mb="16px">
            {"Your new card is on its way to "}
            {chosenAddressState === "commercial"
              ? commercialAddress?.addressX
              : residentialAddress?.addressX}
          </Text>
          <Text>{"Please allow 3-5 business days for delivery."}</Text>

          <Button
            testID="close-button"
            w="150px"
            mt="48px"
            onPress={() => navigate(-1)}
          >
            Close
          </Button>
        </Box>

        <Flex alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }}>
          <Image
            width={{ base: "200px", md: "286px", xl: "400px" }}
            height={{ base: "88px", md: "124px", xl: "176px" }}
            src={ribbonSmileImage}
            alt="ribbonSmile"
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box testID="replace-card">
      <PageHeading  showBackBtn testID="help-centre-header">
        Report card lost or stolen
      </PageHeading>

      <BoxCard>
        {currentCard ? page[showPage] : <>There is no card on your account</>}
      </BoxCard>
    </Box>
  )
}

export default ReplaceCard
