import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { ReactComponent as PaymenyIcon } from "assets/svgs/icons/payment.svg"
import { ReactComponent as PeopleIcon } from "assets/svgs/icons/people.svg"
import { ReactComponent as ProfileIcon } from "assets/svgs/icons/profile.svg"
import { ReactComponent as SettingsIcon } from "assets/svgs/icons/settings.svg"
import { ReactComponent as SpendControlIcon } from "assets/svgs/icons/spendControlIcon.svg"
import {
  profile as profileRoute,
  settings as settingsRoute,
  people as peopleRoute,
  dashboardHelpCentre as helpCentreRoute,
  payments as paymentRoute,
  spendControls as spendControlRoute
} from "components/layouts/Authorized/routes"
import { FF__SPEND_CONTROLS } from "config/environment"
import { Roles } from "models/enums/roles"
import { usePerson } from "store/hooks/person"

export interface BtnLinkprops {
  testID: string
  path: string
  icon?: JSX.Element
  text: string
  color?: string
}
const svgSize = {
  width: "2.25rem",
  height: "2.25rem"
}

const spendControlLink: BtnLinkprops = {
  testID: "dashboard-spendControls-link",
  path: spendControlRoute,
  icon: <SpendControlIcon {...svgSize} />,
  text: "Spend Controls",
  color: "gray.400"
}

const helpCenterLink: BtnLinkprops = {
  testID: "bur-help-center",
  path: helpCentreRoute,
  text: "Help Centre"
}

export const settingLink: BtnLinkprops = {
  testID: "bur-setting",
  path: settingsRoute,
  icon: <SettingsIcon {...svgSize} />,
  text: "Settings"
}

export const paymentLink: BtnLinkprops = {
  testID: "bur-payment",
  path: paymentRoute,
  icon: <PaymenyIcon {...svgSize} />,
  text: "Payments"
}

export const profileLink: BtnLinkprops = {
  testID: "bur-profile",
  path: profileRoute,
  icon: <ProfileIcon {...svgSize} />,
  text: "Profile"
}

export const peopleLink: BtnLinkprops = {
  testID: "bur-people",
  path: peopleRoute,
  icon: <PeopleIcon {...svgSize} />,
  text: "People"
}

interface useBurgerNav {
  navList: BtnLinkprops[]

  personNavList: BtnLinkprops[]

  menuOpen: boolean
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const useBurgerNav = (): useBurgerNav => {
  const locationPath = useLocation().pathname

  const { person } = usePerson()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    setMenuOpen(false)
  }, [locationPath])

  const personNavList = [settingLink, profileLink]

  let navList: BtnLinkprops[] = [helpCenterLink]

  const roleNavList = {
    [Roles.Owner]: [paymentLink],
    [Roles.Administrator]: [] as BtnLinkprops[]
  }

  roleNavList[Roles.Owner].push(peopleLink)
  FF__SPEND_CONTROLS && roleNavList[Roles.Owner].push(spendControlLink)

  person &&
    person.roles.map((role) => {
      switch (role) {
      case Roles.Administrator:
        navList = [...roleNavList[Roles.Administrator], ...navList]
        break

      case Roles.Owner:
        navList = [...roleNavList[Roles.Owner], ...navList]
        break

      default:
        break
      }
    })

  // Remove Duplicated in nav List
  navList = navList.filter(
    (item, index, self) => index === self.findIndex((selfItem) => selfItem.testID === item.testID)
  )

  return {
    personNavList,
    navList,
    menuOpen,
    setMenuOpen
  }
}

export default useBurgerNav
