import { Box, Button, Heading, Spacer, Text, Spinner, Link, Checkbox, Row } from "native-base"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { Currency } from "components/inline/Currency"
import { IssuePageTemplate } from "components/inline/IssuePageTempale"
import PublicLayout from "components/layouts/Public"
import { useLoggly } from "components/providers/Loggly/context"
import { supportPhone, supportPhoneHref } from "components/util/cape-details"
import { useStartQuery } from "network/signUp"

interface ErrorProps {
  title: string
  description?: string
}

const ErrorPage = (props: ErrorProps): JSX.Element => {
  return (
    <Box>
      <IssuePageTemplate
        titleText={props.title}
        contentText={props.description}
        actionArea={
          <Text>
            {"Please call us on "}
            <Link href={supportPhoneHref}>{supportPhone}</Link> {"or "}
            <Link href="#">chat with us</Link> now.
          </Text>
        }
      />
    </Box>
  )
}

type Params = {
  guid: string
}

const CreditLimitConfirmation = (): JSX.Element => {
  const { guid } = useParams<keyof Params>() as Params
  const { data, isSuccess, isLoading, isError } = useStartQuery(guid)
  const { error } = useLoggly()
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const navigate = useNavigate()

  if (isLoading)
    return (
      <PublicLayout>
        <Row w="100%" alignItems="center" justifyContent="center" p="16px">
          <Spinner m="5" /> <Text>{"Checking your activation link..."}</Text>
        </Row>
      </PublicLayout>
    )

  if (isError || !data || !data.data || Number.isNaN(data.data.limit)) {
    error(new Error("error checking activation link"), data && data.message ? JSON.stringify(data.message) : "no data")
    return <ErrorPage title="Sorry, there was a problem with your invite." />
  }

  if (isSuccess && data && data.success === false) {
    return (
      <ErrorPage
        title="Sorry, your link has expired..."
        description={
          "For your security, the link in the email has expired to keep your account protected. We're sorry for any inconvenience but account security is our priority."
        }
      />
    )
  }

  const { limit } = data.data

  return (
    <PublicLayout>
      <Box w="100%" alignItems="center" justifyContent="center" p="16px">
        <Box>
          <Heading flex="1" fontSize={{ base: "38px", md: "48px" }} fontFamily="GTPressuraPro">
            Accept your agreement to set up your account
          </Heading>

          <BoxCard testID="creditLimitConfirmation">
            <Heading size="sm">Credit Limit</Heading>
            {limit !== undefined && <Currency amount={limit} size="3xl" />}
            <Spacer h={3} />
            <Heading size="sm">{"Interest rate "}</Heading>
            <Text>{"0% APR (annual percentage rate) on all purchases"}</Text>
            <Spacer h={3} />

            <Text fontWeight="bold">
              {"Review and accept the "}
              <Link
                textDecoration="underline"
                color="primary.600"
                href="https://uploads-ssl.webflow.com/5f6eeaba957f1a4ab7f8d4c0/61c39cf5dccfe63810321466_CAPE%20-%20User%20Terms%20-%2016%20Dec%20FINAL.pdf"
              >
                User Terms
              </Link>
            </Text>
            <Spacer h={6} />

            <Checkbox
              value=""
              color="warmGray.600"
              testID="accept"
              isChecked={acceptedTerms}
              onChange={() => setAcceptedTerms(!acceptedTerms)}
            >
              As part of your Cape Charge Card application we need you to confirm you have read and accept the User
              Terms
            </Checkbox>
            <Spacer mb="16px" />
            <Button
              w="fit-content"
              testID="confirm"
              onPress={() => {
                navigate(`/account/setcredentials/${guid}`)
              }}
              isDisabled={!acceptedTerms}
            >
              Accept agreement and set-up account
            </Button>
          </BoxCard>
        </Box>
      </Box>
    </PublicLayout>
  )
}
export default CreditLimitConfirmation
