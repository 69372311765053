import { Box, Heading, Flex } from "native-base"
import { useContext } from "react"

import { ReactComponent as ConnectionsIcon } from "assets/svgs/icons/connections.svg"
import { ReactComponent as PaymentIcon } from "assets/svgs/icons/payment.svg"
import { ReactComponent as ProfileIcon } from "assets/svgs/icons/profile.svg"
import { ReactComponent as ChangePasswordIcon } from "assets/svgs/icons/settings-change-password.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import CardsNav from "components/inline/CardVisual/CardsNav"
import MenuList from "components/inline/MenuList"
import PageHeading from "components/inline/PageHeading/PageHeading"
import {
  allDepsContext,
  currentCardContext
} from "components/layouts/Authorized/MaybeRender"
import {
  payments as paymentsRoute,
  changePassword as changePasswordRoute,
  profile as profileRoute,
  connections as connectionsRoute
} from "components/layouts/Authorized/routes"
import { FF__CONNECTIONS_PAGE } from "config/environment"
import { Roles } from "models/enums/roles"

const connectionsEnabledFlag = FF__CONNECTIONS_PAGE
export const Settings = ({ connectionsEnabled = connectionsEnabledFlag }: { connectionsEnabled?: boolean }): JSX.Element => {
  const { cards, hasCurrentCard, person } = useContext(allDepsContext)
  const { card, index } = useContext(currentCardContext)
  const iconSize = {
    width: "1.75rem",
    height: "1.75rem"
  }

  const paymentLink = {
    icon: <PaymentIcon fill="var(--chakra-colors-red-300)" {...iconSize} />,
    path: paymentsRoute,
    testID: "settings-payments-link",
    text: "Make a payment"
  }

  const connectionsLink = {
    icon: <ConnectionsIcon {...iconSize} />,
    path: connectionsRoute,
    text: "Connections"
  }

  let showList = [
    {
      icon: <ProfileIcon {...iconSize} />,
      path: profileRoute,
      text: "Profile"
    },
    {
      icon: <ChangePasswordIcon {...iconSize} />,
      path: changePasswordRoute,
      text: "Change Password"
    }
  ]

  person.roles.map((role) => {
    switch (role) {
    case Roles.Owner:
      showList = [...showList, paymentLink]
      connectionsEnabled && (showList = [...showList, connectionsLink])
      break

    default:
      break
    }
  })

  return (
    <Box>
      <PageHeading testID="settings-header">Settings</PageHeading>
      <BoxCard testID="settings" display="flex" flexDirection="column">
        <Heading
          textAlign={{ base: "center", md: "left" }}
          mb="32px"
          fontSize="24px"
        >
          Personal Settings
        </Heading>
        <Flex
          maxWidth={{ md: "180px", lg: "360px" }}
          width="100%"
          flexDir={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-start" }}
        >
          {hasCurrentCard && (
            <CardsNav cards={cards} currentCard={card} cardIndex={index} />
          )}
          <Flex flexDir="column" alignItems="flex-start" ml={{ base: "unset", md: "32px" }} mt={{ base: "32px", md: "unset" }}>
            <MenuList menuLinks={showList} />
          </Flex>
        </Flex>
      </BoxCard>
    </Box>
  )
}
