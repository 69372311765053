import IconLink from "@axle/web/src/components/inline/IconLink"
import { Flex, Box, Heading, Link, Divider, Image } from "native-base"

import capeImage from "assets/images/cape.png"
import { ReactComponent as CallCenterIcon } from "assets/svgs/icons/ear-phone.svg"
import { ReactComponent as EmailIcon } from "assets/svgs/icons/email.svg"
import { ReactComponent as FAQIcon } from "assets/svgs/icons/faq.svg"
import { ReactComponent as NewMapIcon } from "assets/svgs/icons/map.svg"
import { ReactComponent as TermsAndConditionsIcon } from "assets/svgs/icons/t-and-c.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import {
  faqsURL,
  poBoxOneLiner,
  supportEmailHref,
  supportPhone,
  supportPhoneHref,
  termsAndConditionsURL
} from "components/util/cape-details"

export const HelpCentre = (): JSX.Element => {

  return (
    <Box testID="help-centre">
      <PageHeading  testID="help-centre-header">Help Centre</PageHeading>

      <BoxCard>
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems={{ base: "center", lg: "flex-start" }}
        >
          <Box width={{ base: "100%", md: "50%" }}>
            <Box>
              <Heading
                mb="32px"
                fontSize="24px"
              >
                Cape Support
              </Heading>

              <Link href={supportEmailHref} isUnderlined={false}>
                <IconLink
                  fontSize="14px"
                  text="Email us directly"
                  icon={<EmailIcon />}
                />
              </Link>

              <Divider my="12px" />

              <Link href={supportPhoneHref} isUnderlined={false}>
                <IconLink
                  fontSize="14px"
                  text={`Call us on ${supportPhone}`}
                  icon={<CallCenterIcon />}
                />
              </Link>

              <Divider my="12px" />

              <IconLink
                fontSize="14px"
                text={`Write to us at ${poBoxOneLiner}`}
                icon={<NewMapIcon />}
                activeColor="warmGray.600"
              />

              <Divider mt="12px" />
            </Box>

            <Box mt="80px">
              <Heading
                mb="32px"
                fontSize="24px"
              >
                Knowledge Bank
              </Heading>

              <Link isExternal href={faqsURL} isUnderlined={false}>
                <IconLink
                  fontSize="14px"
                  text="FAQs"
                  icon={<FAQIcon />}
                />
              </Link>

              <Divider my="12px" />

              <Link isExternal href={termsAndConditionsURL} isUnderlined={false}>
                <IconLink
                  fontSize="14px"
                  text="Terms &amp; Conditions"
                  icon={<TermsAndConditionsIcon />}
                />
              </Link>

            </Box>
          </Box>
          <Flex>
            <Image
              mt={{ base: "32px", lg: "unset" }}
              width={{ base: "390px", md: "480px" }}
              height={{ base: "390px", md: "480px" }}
              src={capeImage}
              alt="capeImage"
            />
          </Flex>
        </Flex>
      </BoxCard>
    </Box>
  )
}
