import { Box, Heading, Row, CheckIcon, CloseIcon, Tooltip } from "native-base"

import { labelData } from "components/util/spendControlDictionary"

import { MappedRuleCategory } from "./talons/useReadOnlySpendControl"

interface ReadOnlyTabProps {
  data: {
    categoryId: string
    category: string | undefined
    rules: MappedRuleCategory[]
  }
}

const ReadOnlyTab = ({ data }: ReadOnlyTabProps) => {
  const RowLabel = ({ name }: { name: string }) => {
    const label = labelData.find((item) => item.category === name)?.description

    return label ? (
      <Tooltip label={label} openDelay={100} w="300px" p="20px" borderRadius="5px" placement="top" bg="white" _text={{ color: "#171717" }}>
        <Box flex="1" textAlign="left">
          {name}
        </Box>
      </Tooltip>
    ) : (
      <Box flex="1" textAlign="left">
        {name}
      </Box>
    )
  }

  return (
    <Box w="100%">
      <Heading size="md" pb="16px">
        {data.category}
      </Heading>
      <Box pr="32px">
        <Row display={{ base: "none", md: "flex" }} px="32px">
          <Box flex="1"></Box>
          <Box flex="1" alignItems="center">
            <Heading size="sm" color="gray.500">
              Approved
            </Heading>
          </Box>
          <Box flex="1" alignItems="center">
            <Heading size="sm" color="gray.500">
              Blocked
            </Heading>
          </Box>
        </Row>

        {data.rules.map(({ description, body }, index) => (
          <Row testID={description} bg="gray.50" py="16px" px="32px" mt="16px" key={`${description}${index}`}>
            <RowLabel name={description} />

            <Box flex="1" alignItems="center" justifyContent="center" display={{ base: body ? "flex" : "none", md: "flex" }}>
              {body && <CheckIcon color="green.100" size="lg" />}
            </Box>

            <Box flex="1" alignItems="center" justifyContent="center" display={{ base: !body ? "flex" : "none", md: "flex" }}>
              {!body && <CloseIcon color="red.300" size="md" />}
            </Box>
          </Row>
        ))}
      </Box>
    </Box>
  )
}

export default ReadOnlyTab
