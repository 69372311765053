import { useState, useEffect } from "react"
import { Control, FieldError, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { CardStatus } from "models/enums/card-status"
import {
  useActivateCardMutation as _useActivateCard,
  useActivatingCardSagaMutation
} from "network/card"
import { useAppDispatch } from "store/hooks"
import { useCurrentCard } from "store/hooks/currentCard"
import { useCurrentCardIndex } from "store/hooks/currentCardIndex"
import { updateCard } from "store/slices/cards"
import { setCurrentCard } from "store/slices/currentCard"

import { CardDto, cardFromLegacyCard } from "../../../../models/card"

export interface FormValues {
  token: string
}

export interface ActivateCard {
  submitActivateCard: (
    cardId: number
  ) => (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  success: boolean
  isLoading: boolean
  cardIsLoading: boolean
  control: Control<FormValues>
  errors: { token?: FieldError | undefined }
  currentCard: CardDto | null
}

export const useActivateCard = (): ActivateCard => {
  const [activateCard, { isLoading }] = _useActivateCard()
  const [getCardDetails, { isLoading: cardIsLoading }] =
    useActivatingCardSagaMutation()
  const { currentCardIndex } = useCurrentCardIndex()
  const dispatch = useAppDispatch()
  const { currentCard } = useCurrentCard()
  const navigate = useNavigate()
  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      setSuccess(false)
    }
  }, [])

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues: { token: "" } })

  const onSubmit = async (data: FormValues, cardId: number) => {
    const dataForActivateCard = {
      ...data,
      cardId: cardId
    }
    const activateCardresponse = await activateCard(
      dataForActivateCard
    ).unwrap()

    if (activateCardresponse.success) {
      const cardDetails = await getCardDetails({
        id: cardId
      }).unwrap()

      cardDetails.success &&
        (dispatch(setCurrentCard(cardFromLegacyCard(cardDetails.data))),
        dispatch(
          updateCard({
            cardIndex: currentCardIndex,
            card: cardFromLegacyCard(cardDetails.data)
          })
        ))

      if (cardDetails.data.status === CardStatus.Activating) {
        navigate(-1)
      }

      setSuccess(true)
    }
  }

  const submitActivateCard = (cardId: number) =>
    handleSubmit((data) => onSubmit(data, cardId))

  return {
    submitActivateCard,
    success,
    isLoading,
    control,
    errors,
    currentCard,
    cardIsLoading
  }
}

export const text = {
  header: "Activate your card",
  content:
    "You’ve just received your Cape card. Now what? Simply fill in your card token number (this can be found above the magnetic strip on the back of your card) and we’ll activate:",

  tokenErr: "Token is required and must be 9 digit characters or numbers.",
  completeHeader: "Congratulations!",
  completeContent:
    "Your Cape card is now active. Just request your PIN below and you're ready to start using your card.",
  getPinBtn: "Get PIN",
  notCardActivate: "There is no activatable card",
  cancelBtn: "Cancel"
}
