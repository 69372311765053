import { Box, Heading, VStack, Spacer, Radio, Text, Column } from "native-base"

export type AddressType = "commercial" | "residential"

export interface AddressChoiceRadioProps {
  residential: string | undefined
  commercial: string
  value: string
  onChange: (value: string) => void
}

const OrderCardAddressChooser = ({
  onChange,
  residential,
  commercial
}: AddressChoiceRadioProps) => {
  return (
    <VStack testID="order-card-address-chooser">
      <Radio.Group
        accessibilityLabel="address-radio-group"
        name="address-radio-group"
        w="100%"
        onChange={(AddressType) => {
          onChange(AddressType)
        }}
      >
        <Column>
          {residential && (
            <Box>
              <Heading
                fontWeight="900"
                width="100%"
                textAlign="left"
                size="sm"
                mb="16px"
              >
                Home address
              </Heading>

              <Radio
                accessibilityLabel="radio-card-residential"
                size="sm"
                testID="radio-card-residential"
                value="residential"
              >
                <Text>{residential}</Text>
              </Radio>
            </Box>
          )}

          <Spacer py="24px" />

          <Heading
            fontWeight="900"
            width="100%"
            textAlign="left"
            size="sm"
            mb="16px"
          >
            Registered trading address
          </Heading>

          <Radio
            accessibilityLabel="radio-card-commercial"
            size="sm"
            testID="radio-card-commercial"
            value="commercial"
          >
            <Text>{commercial}</Text>
          </Radio>

        </Column>
      </Radio.Group>
    </VStack>
  )
}

export default OrderCardAddressChooser
