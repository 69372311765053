import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import {
  Control,
  FieldError,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister
} from "react-hook-form"
import { useNavigate } from "react-router-dom"

import {
  dashboard as dashboardRoute,
  obOrderCard as orderCardRoute
} from "components/layouts/Authorized/routes"
import { isUserHasState } from "components/util/isUserHasState"
import { States } from "models/enums/states"
import Person from "models/person"
import { useBankAccountMutation, useMandateMutation } from "network/entity"
import { usePersonSummaryMutation } from "network/person"
import { useAppDispatch } from "store/hooks"
import { setPerson } from "store/slices/person"

type WizardPage = "confirm" | "details" | "request" | "thanks"

interface FormValues {
  accountHolder: string
  email: string
  accountNumber: string
  BSB: string
  isAuthorizedChecked: boolean
  ddrConsent: boolean
}

interface ConfirmData extends FormValues {
  institutionName?: string
  pdfLink?: string
}

export interface useDirectDebit {
  onSkip: () => void
  onSubmit: (formData: FormValues) => Promise<void>
  onNext: () => Promise<void>
  wizard: WizardPage
  setWizard: React.Dispatch<React.SetStateAction<WizardPage>>
  register: UseFormRegister<FormValues>
  handleSubmit: UseFormHandleSubmit<FormValues>
  submission: ConfirmData
  isChecking: boolean
  isSubmitting: boolean
  personIsLoading: boolean
  formErrors: Record<string, FieldError>
  control: Control<FormValues, object>
}

export const useDirectDebit = (person: Person): useDirectDebit => {
  const navigate = useNavigate()

  const initWizard = isUserHasState(person, States.BankAccountSetup) && !isUserHasState(person, States.MandateSetup) ? "details" : "request"
  const [wizard, setWizard] = useState<WizardPage>(initWizard)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors }
  } = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      accountHolder: "",
      email: person.email,
      accountNumber: "",
      BSB: "",
      isAuthorizedChecked: false,
      ddrConsent: false
    }
  })

  const [submission, setSubmission] = useState<ConfirmData>({} as ConfirmData)
  const [bankAccount, { isLoading: isChecking }] = useBankAccountMutation()
  const [mandate, { isLoading: isSubmitting }] = useMandateMutation()
  const [{ ddComplete }, setCookie] = useCookies([
    "username",
    "ddSkip",
    "ddComplete"
  ])

  const [personSummary, { isLoading: personIsLoading }] =
    usePersonSummaryMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    ddComplete && setWizard("thanks")
  }, [ddComplete, setWizard])

  const normalise = ({
    accountHolder,
    accountNumber,
    BSB,
    email,
    isAuthorizedChecked
  }: FormValues) => {
    const MultiSignatoryAccount = !isAuthorizedChecked
    return {
      AccountHolderName: accountHolder.trim(),
      AccountNumber: accountNumber.trim(),
      SortCode: BSB.trim(),
      EmailAddress: email,
      MultiSignatoryAccount: MultiSignatoryAccount
    }
  }

  const onSkip = () => {
    setCookie("ddSkip", true, {
      path: "/",
      expires: DateTime.utc().plus({ hours: 3 }).toJSDate(),
      domain: process.env.REACT_APP_DOMAIN
    })
    navigate(dashboardRoute)
  }

  const onSubmit = async (formData: FormValues) => {
    const proccessedData = {
      ...formData,
      BSB: formData.BSB.replace(/-/g, "")
    }

    if (!formData.ddrConsent) {
      const { data } = await bankAccount(normalise(proccessedData)).unwrap()

      setSubmission({
        ...formData,
        accountHolder: data.accountHolderName,
        accountNumber: data.accountNumber,
        BSB: data.sortCode,
        pdfLink: data.pdfLink,
        institutionName: data.institutionName
      })

      setWizard("confirm")
    } else {
      await mandate(normalise(submission)).unwrap()
      setWizard("thanks")
    }
  }

  const onNext = async () => {
    const personDetailResponse = await personSummary().unwrap()

    personDetailResponse.success &&
      dispatch(setPerson(personDetailResponse.data))
    setCookie("ddComplete", true, {
      path: "/",
      expires: DateTime.utc().plus({ hours: 3 }).toJSDate(),
      domain: process.env.REACT_APP_DOMAIN
    })
    navigate(orderCardRoute)
  }

  return {
    onSkip,
    onSubmit,
    onNext,
    wizard,
    setWizard,
    register,
    handleSubmit,
    submission,
    isChecking,
    isSubmitting,
    personIsLoading,
    formErrors,
    control
  }
}
