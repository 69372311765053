import { useNavigate } from "react-router-dom"

import { cardFromLegacyCard } from "models/card"
import { useActivateCardMutation as useActivateCard, useActivatingCardSagaMutation } from "network/card"
import { useAppDispatch } from "store/hooks"
import { useCurrentCardIndex } from "store/hooks/currentCardIndex"
import { updateCard } from "store/slices/cards"
import { setCurrentCard } from "store/slices/currentCard"



interface useUnfreezeCard {
  isSuccess: boolean,
  isLoading: boolean,
  cardIsLoading: boolean,
  handleUnfreezeCard: (cardId: number, token: string) => void
}

const useUnfreezeCard = (): useUnfreezeCard => {
  const [activateCard, { isLoading, isSuccess }] = useActivateCard()
  const [getCardDetails, { isLoading: cardIsLoading }] = useActivatingCardSagaMutation()
  const { currentCardIndex } = useCurrentCardIndex()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleUnfreezeCard = async (cardId: number, token: string) => {

    const activateCardResponse = await activateCard({
      cardId: cardId,
      token: token
    }).unwrap()

    if (activateCardResponse.success) {
      const cardDetails = await getCardDetails({
        id: cardId
      }).unwrap()
      const card = cardFromLegacyCard(cardDetails.data)

      dispatch(setCurrentCard(card))
      dispatch(updateCard({ cardIndex: currentCardIndex, card: cardFromLegacyCard(cardDetails.data) }))
      navigate(-1)
    }
  }

  return {
    isSuccess,
    isLoading,
    cardIsLoading,
    handleUnfreezeCard
  }
}

export default useUnfreezeCard
