import _ from "lodash"
import { useEffect, useState } from "react"

import { CardRules } from "models/enums/cardRule-Type"
import { RuleCategory, RulesList } from "models/spendControls"
import { useGetSpendControlsMutation } from "network/spendControls"
import { useAppDispatch } from "store/hooks"
import { useRulesList } from "store/hooks/spendControls"
import { setRulesList } from "store/slices/spendControls"

export type Limit = {
  [key in CardRules]?: string
}

export interface MappedRuleCategory extends RuleCategory {
  body: boolean | number | string
}

export interface MappedCategory {
  categoryId: string
  category: string | undefined
  rules: MappedRuleCategory[]
}

interface useReadOnlySpendControl {
  limit: Limit
  entityList: MappedCategory[]
}

const useReadOnlySpendControl = () => {
  const { rulesList } = useRulesList()
  const dispatch = useAppDispatch()
  const [spendControlsLoading, setAdminControlLoading] = useState<boolean>(false)

  const [getSpendControls, { isLoading, isSuccess }] = useGetSpendControlsMutation()

  const loadSpendControls = async () => {
    setAdminControlLoading(true)
    const spendControlsResults = await getSpendControls().unwrap()

    dispatch(setRulesList(spendControlsResults.data as RulesList))
  }

  useEffect(() => {
    !spendControlsLoading && loadSpendControls()
  }, [isLoading, isSuccess, rulesList])

  const [limitRules, rules] = _.partition(rulesList && rulesList.items, (rule) => {
    return !rule.category
  })

  const limit = limitRules.reduce<Limit>((acc, cur) => {
    return {
      ...acc,
      [cur.type]: cur.body
    }
  }, {})

  const [childCategorysList] = _.partition(rulesList && rulesList.categories.items, (rule) => {
    return rule.parentCategoryId
  })

  const mappedDataList = childCategorysList.map<MappedRuleCategory>((childCategory) => {
    const temp = rules.find((rule) => rule.categoryId === childCategory.id)
    const res = {
      ...childCategory,
      body: !temp?.body
    }
    return res
  })

  const groupedRuleData = _(mappedDataList)
    .groupBy("parentCategoryId")
    .map((items, parentCategoryId) => {
      return {
        categoryId: parentCategoryId,
        category: items[0].parent,
        rules: items
      }
    })
    .value()

  return {
    entityList: groupedRuleData,
    limit
  }
}

export default useReadOnlySpendControl
