import { Factory } from "fishery"

import PersonDto from "models/person"

import { CardStatus } from "../../models/enums/card-status"
import { Roles } from "../../models/enums/roles"

import { AddressFactory } from "./address"
import { CardFactory } from "./card"
import { EntityFactory } from "./entity"
import { LedgerFactory } from "./ledger"

export const PersonFactory = Factory.define<PersonDto>(
  ({ sequence, associations }) => ({
    id: sequence,
    uid: `${sequence}-xxx-yyy-zzz`,
    first: "Burt",
    ownedBudgets: [],
    memberBudgets: [],
    fullName: `Burt Smith ${sequence}`,
    email: "burt@getcape.io",
    mobile: "+61406252332",
    dateOfBirth: "01-01-1990",
    addresses: associations.addresses || [AddressFactory.build()],
    cards: associations.cards || [CardFactory.build()],
    entity: associations.entity || EntityFactory.build(),
    roles: associations.roles || [Roles.Owner, Roles.Administrator, Roles.None],
    isTwoFactor: associations.isTwoFactor === undefined ? true : false,
    communicationType: associations.communicationType === undefined ? 0 : associations.communicationType,
    ledger: associations.ledger || LedgerFactory.build(),
    bankAccounts: associations.bankAccounts || [LedgerFactory.build()],
    unreadNotifications: 10
  })
)

export const personTwoFASetUp = PersonFactory.build({ isTwoFactor: true })

export const personWithCommunicationType = PersonFactory.build({ communicationType: 2 })

export const personWithNoCard = PersonFactory.build({ cards: [] })

export const personWithMultiCard = PersonFactory.build({
  cards: [
    {
      id: 0,
      status: CardStatus.Active,
      name: "PersonCard",
      truncatedPan: "tpan",
      expiryDate: "string",
      token: ""
    },
    {
      id: 1,
      status: CardStatus.Active,
      name: "PersonCard",
      truncatedPan: "tpan",
      expiryDate: "string",
      token: ""
    },
    {
      id: 2,
      status: CardStatus.Active,
      name: "PersonCard",
      truncatedPan: "tpan",
      expiryDate: "string",
      token: ""
    },
    {
      id: 3,
      status: CardStatus.Active,
      name: "PersonCard",
      truncatedPan: "tpan",
      expiryDate: "string",
      token: ""
    }
  ]
})
