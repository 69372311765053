import {
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  Link,
  Spacer,
  View,
  useBreakpointValue,
  VStack,
  HStack,
  Box,
  Column
} from "native-base"

import GoogleOauth from "components/inline/Oauth/Google"
import { PasswordInput } from "components/inline/PasswordInput"
import { capeWWW } from "config/endpoints"
import { FF__RESET_PASSWORD } from "config/environment"

import { ForgotPassword } from "../ResetPassword/ForgotPassword"

import { useLogin, text } from "./talons/useLogin"

export interface PasswordInputProps {
  resetPasswordEnabled?: boolean
  capeCardImage: () => JSX.Element
}

const resetPasswordFlagEnabled = FF__RESET_PASSWORD

export const Login = ({
  resetPasswordEnabled = resetPasswordFlagEnabled,
  capeCardImage
}: PasswordInputProps): JSX.Element => {
  const {
    username,
    guid,
    wizard,
    forgotPasswordFlow,
    setForgotPasswordFlow,
    handleChange,
    startClickHandler,
    passwordClickHandler,
    isLoading,
    startIsLoading
  } = useLogin()

  const viewWidth = useBreakpointValue({ sm: "70%", md: "100%" })
  const viewMaxWidth = useBreakpointValue({
    sm: "100%",
    md: "866",
    lg: "1025",
    xl: "1328"
  })

  const VStackMinWidth = useBreakpointValue({ sm: "250px" })
  const VStackMarginLeft = useBreakpointValue({ base: "auto", sm: "unset" })
  const headingFontSize = useBreakpointValue({
    base: "2rem",
    sm: "2rem",
    md: "2rem",
    xl: "4rem"
  })
  const headingTextAlign = useBreakpointValue({ base: "center", sm: "left" })
  const headingJustifyContent = useBreakpointValue({
    base: "center",
    sm: "flex-start"
  })
  const flexChildrenSize = useBreakpointValue({ base: "auto", sm: "1" })
  const wrapperPaddingX = useBreakpointValue({ base: "1rem", sm: "4rem" })
  const wrapperFlexDirection = useBreakpointValue({ base: "column", sm: "row" })

  return (
    <>
      {forgotPasswordFlow ? (
        <ForgotPassword username={username as string} />
      ) : (
        <Flex
          testID="Login"
          paddingY="1rem"
          paddingX={wrapperPaddingX}
          width={viewWidth}
          maxWidth={viewMaxWidth}
          justifyContent="center"
          alignItems="center"
          margin="auto"
          flexDirection={wrapperFlexDirection}
        >
          <Box flex={flexChildrenSize} justifyContent="end" maxW="405px" mt={{ base: "64px", md: "unset" }}>
            <Column>
              <Heading
                mt="0rem"
                fontWeight="bold"
                display="flex"
                justifyContent={headingJustifyContent}
                fontSize={headingFontSize}
                textAlign={headingTextAlign}
                mb="3rem"
                width="100%"
                position="relative"
                fontFamily="GTPressuraPro"
              >
                {text.heading}
              </Heading>
              <VStack
                minWidth={VStackMinWidth}
                width="100%"
                ml={VStackMarginLeft}
                mr="auto"
              >
                {wizard === "start" && (
                  <View>
                    <InputGroup>
                      <Input
                        autoFocus
                        onChangeText={(value) =>
                          handleChange(value, "username")
                        }
                        onSubmitEditing={startClickHandler}
                        testID="username"
                        type="text"
                        placeholder="Email address"
                        autoCapitalize="none"
                      />
                    </InputGroup>

                    <Spacer h="1rem" />
                    <Button
                      w={{ base: "100%", md: "50%" }}
                      testID="start"
                      onPress={() => startClickHandler()}
                      isLoading={startIsLoading}
                    >
                      {text.nextBtn}
                    </Button>
                  </View>
                )}
                {wizard === "password" && (
                  <View>
                    <PasswordInput
                      autoFocus={true}
                      name="password"
                      testID="password"
                      placeholder={"Password"}
                      color={"gray.400"}
                      onSubmitEditing={passwordClickHandler}
                      onChangeText={(value) => handleChange(value, "password")}
                    />

                    {resetPasswordEnabled && (
                      <HStack justifyContent="flex-end">
                        <Link
                          testID="forgot-password"
                          onPress={() => {
                            setForgotPasswordFlow(true)
                          }}
                        >
                          {text.ForgotPasswordLink}
                        </Link>
                      </HStack>
                    )}

                    <Spacer h="1rem" />
                    <Button
                      w={{ base: "100%", md: "50%" }}
                      testID="submit"
                      isLoading={isLoading}
                      onPress={() => passwordClickHandler()}
                    >
                      {text.signInBtn}
                    </Button>
                  </View>
                )}

                {wizard === "oauth" && guid && <GoogleOauth guid={guid} />}
                <Spacer />
                <Link
                  display="block"
                  mt="2rem"
                  color="primary.600"
                  href={`${capeWWW}/sign-up`}
                  _text={{
                    textDecoration: "none",
                    color: "primary.600"
                  }}
                  _hover={{
                    _text: {
                      _light: {
                        textDecoration: "underline"
                      },
                      textDecoration: "underline"
                    }
                  }}
                >
                  {text.noAccContext}
                  <b style={{ fontFamily: "CircularStdMedium" }}>
                    {text.signUpHere}
                  </b>
                </Link>
              </VStack>
            </Column>
          </Box>

          <Spacer w={12} flexGrow="0" />

          <Box
            display={{ base: "none", md: "flex" }}
            justifyContent="center"
            alignItems="center"
          >
            {capeCardImage()}
          </Box>
        </Flex>
      )}
    </>
  )
}

export default Login
