import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber"
import { DateTime } from "luxon"
import { useState, useEffect, Dispatch, SetStateAction } from "react"
import {
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  Control,
  FieldError,
  UseFormReset,
  UseFormGetValues
} from "react-hook-form"

import AddressDto, { EntityAddressDto } from "models/address"
import { EntityDto } from "models/entity"
import { AddressTypes } from "models/enums/address-types"
import Person from "models/person"
import {
  usePersonSummaryMutation,
  usePersonAddressUpdateMutation,
  usePersonDetailsUpdateMutation
} from "network/person"
import { useAppDispatch } from "store/hooks"
import { setPerson } from "store/slices/person"

interface Profile {
  updateHandler: (data: PersonalAddressInputs) => Promise<void>
  handleSubmit: UseFormHandleSubmit<PersonalAddressInputs>
  contactDetailsUpdateHandler: (data: ContactDetailsInputs) => Promise<void>
  handleSubmitContactDetails: UseFormHandleSubmit<ContactDetailsInputs>
  register: UseFormRegister<PersonalAddressInputs>
  registerContactDetails: UseFormRegister<ContactDetailsInputs>
  reset: UseFormReset<PersonalAddressInputs>
  resetContactDetails: UseFormReset<ContactDetailsInputs>
  getValues: UseFormGetValues<PersonalAddressInputs>
  getContactDetails: UseFormGetValues<ContactDetailsInputs>
  control: Control<PersonalAddressInputs, object> // eslint-disable-line
  controlContactDetails: Control<ContactDetailsInputs, object> // eslint-disable-line
  legalName: string
  tradingName: string
  businessAddress: EntityAddressDto | undefined
  personalDetails: Record<string, string>
  isAddressDisabled: boolean
  setIsAddressDisabled: Dispatch<SetStateAction<boolean>>
  isContactDetailsDisabled: boolean
  setIsContactDetailsDisabled: Dispatch<SetStateAction<boolean>>
  errors: Record<string, FieldError>
  errorsContactDetails: Record<string, FieldError>
  isSubmitting: boolean
  isSubmittingContactDetails: boolean
}

export interface PersonalAddressInputs {
  address: string
  city: string
  region: string
  country: string
  postcode: string
}

export interface ContactDetailsInputs {
  email: string
  mobile: string
  countryCode: string
}

export const formatDateOfBirth = (dateOfBirth?: string | undefined): string =>
  dateOfBirth
    ? DateTime.fromISO(dateOfBirth).toLocaleString(DateTime.DATE_SHORT)
    : ""

export const useProfile = (person: Person, entity: EntityDto): Profile => {
  const [isAddressDisabled, setIsAddressDisabled] = useState(true)
  const [isContactDetailsDisabled, setIsContactDetailsDisabled] = useState(true)
  const [personSummary] = usePersonSummaryMutation()
  const [updateAddress] = usePersonAddressUpdateMutation()
  const [updatePerson] = usePersonDetailsUpdateMutation()
  const dispatch = useAppDispatch()

  const phoneUtil = PhoneNumberUtil.getInstance()
  const PNF = PhoneNumberFormat

  const { legalName, tradingName, addresses } = entity

  const {
    fullName,
    dateOfBirth,
    addresses: personAddresses,
    mobile,
    email
  } = { ...person }

  const getAddress = (addressList: AddressDto[]): AddressDto => {
    const add = addressList.filter(
      (cur) => cur.addressType === AddressTypes.Home
    )
    return add.length > 0 ? add[0] : addressList[0]
  }

  const address = getAddress(personAddresses)

  const {
    address1,
    address2,
    postTown: city,
    countyField: region,
    char2Code: country,
    postCode: postcode
  } = { ...address }

  const personalDetails = {
    fullName,
    dateOfBirth: formatDateOfBirth(dateOfBirth)
  }

  const personalAddressFormDefaultValues = {
    defaultValues: {
      address: `${address1}, ${address2}`,
      city,
      region,
      country,
      postcode
    }
  }

  const mobileNumberObject = phoneUtil.parseAndKeepRawInput(mobile)
  const mobileNumber = phoneUtil.format(mobileNumberObject, PNF.NATIONAL)

  const dialCode = mobileNumberObject.getCountryCode()
  const mobileCountryCode = `+${dialCode}`

  const updateHandler = async (data: PersonalAddressInputs) => {
    const newAddress = { ...address, ...data }
    await updateAddress(newAddress).unwrap()
    const personDetailResponse = await personSummary().unwrap()
    dispatch(setPerson(personDetailResponse.data))
    setIsAddressDisabled(true)
  }

  const {
    handleSubmit,
    register,
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<PersonalAddressInputs>({ ...personalAddressFormDefaultValues })

  useEffect(() => {
    reset({
      address: `${address1}, ${address2}`,
      city,
      region,
      country,
      postcode
    })

    return () => {
      setIsAddressDisabled(true)
    }
  }, [reset, address1, address2, city, region, country, postcode])

  const contactDetailsDefaultValue = {
    defaultValues: {
      countryCode: mobileCountryCode,
      mobile: mobileNumber,
      email
    }
  }

  const {
    handleSubmit: handleSubmitContactDetails,
    register: registerContactDetails,
    reset: resetContactDetails,
    control: controlContactDetails,
    getValues: getContactDetails,
    formState: {
      errors: errorsContactDetails,
      isSubmitting: isSubmittingContactDetails
    }
  } = useForm<ContactDetailsInputs>({
    ...contactDetailsDefaultValue
  })

  const contactDetailsUpdateHandler = async (data: ContactDetailsInputs) => {
    const { mobile, countryCode } = data
    const cleanMobileNumber = `${countryCode}${mobile.replace(/^0| /g, "")}`
    const newPersonalDetails = {
      ...person,
      ...data,
      ...{ mobile: cleanMobileNumber }
    }

    await updatePerson(newPersonalDetails).unwrap()
    const personDetailResponse = await personSummary().unwrap()
    dispatch(setPerson(personDetailResponse.data))
    setIsContactDetailsDisabled(true)
  }

  useEffect(() => {
    resetContactDetails({
      countryCode: mobileCountryCode,
      mobile: mobileNumber,
      email
    })
  }, [resetContactDetails, mobileNumber, email, mobileCountryCode])

  const firstAddress = addresses[0]
  const businessAddress = {
    ...firstAddress,
    code: firstAddress.char2Code, // TODO: This could be incorrect
    addressId: firstAddress.id,
    entityId: 0 // TODO: Do we need this?
  }

  return {
    updateHandler,
    handleSubmit,
    handleSubmitContactDetails,
    register,
    registerContactDetails,
    contactDetailsUpdateHandler,
    reset,
    resetContactDetails,
    getValues,
    getContactDetails,
    control,
    legalName,
    tradingName,
    businessAddress,
    personalDetails,
    isAddressDisabled,
    setIsAddressDisabled,
    isContactDetailsDisabled,
    setIsContactDetailsDisabled,
    errors,
    errorsContactDetails,
    isSubmitting,
    isSubmittingContactDetails,
    controlContactDetails
  }
}
