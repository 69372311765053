import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  Row,
  Spacer
} from "native-base"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import armImage from "assets/images/arm.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { currentCardContext } from "components/layouts/Authorized/MaybeRender"

import useFreezeCard from "./talons/useFreezeCard"

export const FreezeCard = (): JSX.Element => {
  const { card: currentCard } = useContext(currentCardContext)
  const navigate = useNavigate()
  const { isSuccess, isLoading, cardIsLoading, handleFreezeCard } =
    useFreezeCard()

  return (
    <Box testID="freeze-card">
      <PageHeading  showBackBtn testID="freeze-card-header">
        Freeze Card
      </PageHeading>

      <BoxCard>
        {currentCard && currentCard.id ? (
          <>
            <Heading fontSize="24px" testID="freeze-card-heading" maxWidth="500px">
              What happens when I freeze my card?
            </Heading>

            <Flex mt="16px" flexDir={{ base: "column", xl: "row" }} minH="50vh">
              <Box maxW="30rem">
                <Text>
                  {
                    "Once the card is frozen, for the majority of banks all transactions made on your card will be blocked, including cash withdrawals, in-store payments and online transactions."
                  }
                </Text>
                <Text mt="4">
                  {"You also have the option to un-freeze your card."}
                </Text>

                <Row mt="48px">
                  <Button
                    flex="1"
                    testID="close-button"
                    onPress={() => navigate(-1)}
                    disabled={isLoading}
                    variant="outline"
                  >
                    Cancel
                  </Button>

                  <Spacer w="16px" flexGrow="0" />

                  <Button
                    flex="1"
                    testID="freeze-card-submit"
                    onPress={() => {
                      handleFreezeCard(currentCard.id)
                    }}
                    disabled={isLoading || isSuccess}
                    isLoading={isLoading || cardIsLoading}
                  >
                    Freeze my card
                  </Button>
                </Row>
              </Box>
              <Box flex="1" justifyContent="end" alignItems={{ base: "center", lg: "end" }}>
                <Image
                  mt={{ base: "32px", xl: "0" }}
                  width={{ base: "250px", sm: "250px", md: "250px", xl: "315px" }}
                  height={{ base: "325px", sm: "325px", md: "325px", xl: "410px" }}
                  src={armImage}
                  alt="arm"
                  resizeMode="contain"
                />
              </Box>
            </Flex>
          </>
        ) : (
          <>{"There is no card to freeze"}</>
        )}
      </BoxCard>
    </Box>
  )
}
