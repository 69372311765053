import { Box, Column, Flex, Heading, Spacer } from "native-base"
import { useEffect, useMemo, useState } from "react"
import { SceneMap } from "react-native-tab-view"

import { AdminControlsDto } from "models/spendControls"
import { useGetSpendControlsMutation } from "network/spendControls"
import { useAppDispatch } from "store/hooks"
import { useAdminControls } from "store/hooks/spendControls"
import { setAdminControl } from "store/slices/spendControls"

import Policy from "./Policy"
import PolicyHeader from "./PolicyHeader"
import TabForm, { RoutesState } from "./TabForm"
import TabPage from "./TabPage"

const AdminControl = () => {

  const dispatch = useAppDispatch()

  const [spendControlsLoading, setAdminControlLoading] = useState<boolean>(false)
  const [getSpendControls, { isLoading, isSuccess }] = useGetSpendControlsMutation()

  const { adminControls } = useAdminControls()

  const loadSpendControls = async () => {
    setAdminControlLoading(true)
    const spendControlsResults = await getSpendControls().unwrap()
    dispatch(setAdminControl(spendControlsResults.data as AdminControlsDto))
  }

  useEffect(() => {
    !spendControlsLoading && loadSpendControls()
  }, [isLoading, isSuccess, adminControls])

  return (
    <Box testID="adminControl">
      <PolicyHeader />
      <Spacer h="64px" />
      <Heading size="md" testID="spend-control-heading">
        Spend Controls
      </Heading>
      <Spacer h="24px" />
      <Flex testID="spend-controls" flexDir={{ base: "column", lg: "row" }}>
        <Column flex="2">
          {adminControls && (
            <SpendControlViewWrapper adminControls={adminControls} />
          )}
        </Column>
        <Spacer w="2rem" flexGrow="0" />
        <Column flex="1">
          <Policy />
        </Column>
      </Flex>
    </Box>
  )
}

const SpendControlViewWrapper = ({
  adminControls
}: {
  adminControls: AdminControlsDto
}): JSX.Element => {
  const routesState: RoutesState = []

  useMemo(
    () =>
      adminControls.categories.items.map((entityCategory, eCIndex) => {
        const item = {
          key: `${eCIndex}`,
          title: entityCategory.name
        }
        routesState.push(item)
      }),
    [adminControls.categories.total]
  )

  const routesObject = {}
  adminControls.categories.items.map((entityCategory, eCIndex) => {
    const item = useMemo(
      () => ({
        [eCIndex]: () => (
          <Column
            style={[
              {
                backgroundColor: "white",
                height: "100%",
                paddingVertical: "48px",
                paddingHorizontal: "32px",
                borderRadius: 2
              }
            ]}
          >
            <Heading size="sm" mb="32px">
              {entityCategory.name}
            </Heading>
            <TabPage eCIndex={eCIndex} />
          </Column>
        )
      }),
      [eCIndex]
    )
    Object.assign(routesObject, item)
  })

  const renderScene = SceneMap(routesObject)
  return <TabForm renderScene={renderScene} routesState={routesState} />
}

export default AdminControl
