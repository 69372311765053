import { DateTime } from "luxon"
import { useContext, useEffect, useState } from "react"
import {
  SubmitHandler,
  useForm,
  FieldError,
  UseFormHandleSubmit,
  Control
} from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { currentCardContext } from "components/layouts/Authorized/MaybeRender"
import { dashboard as dashboardRoute } from "components/layouts/Authorized/routes"
import { getCurrentDate } from "components/util/format-date"
import { Roles } from "models/enums/roles"
import PersonDto from "models/person"
import {
  MakeAPayment,
  usePaymentMutation,
  useUsageMutation
} from "network/entity"
import { CurrentCardState } from "store/slices/currentCard"

import { copyText } from "./copyText"

export interface FormValues {
  // need string to handle empty
  Amount: string
  ChargeDate: string
}

type WizardPage = "confirmation" | "confirmed" | "payment"
interface UsePayment {
  wizard: WizardPage
  currentCard: CurrentCardState
  confirmData: MakeAPayment | undefined
  isSubmitting: boolean
  bankAccountDetails: string
  errors: {
    Amount?: FieldError | undefined
    ChargeDate?: FieldError | undefined
  }
  usageLoading: boolean
  handleSubmit: UseFormHandleSubmit<FormValues>
  onSubmit: SubmitHandler<FormValues>
  submitFunction: () => Promise<void>
  endPayments: () => void
  control: Control<FormValues, object>
  maxPayment: number

  swichSelectAmount: (value: string) => void
  switchChargeDateMode: (value: string) => void

  selectPayDate: string | false
  selectPayAmount: string | false

  selectPayAmountValue: {
    max: string
    custom: string
  }

  selectPayDateValue: {
    today: string
    selectDate: string
  }
}

const usePayment = (person: PersonDto): UsePayment => {
  const navigate = useNavigate()
  const { card: currentCard } = useContext(currentCardContext)
  const [maxPayment, setMaxPayment] = useState<number>(0)
  const [wizard, setWizard] = useState<WizardPage>("payment")
  const [confirmData, setConfirmData] = useState<MakeAPayment>()
  const [makePayment, { isLoading: isSubmitting }] = usePaymentMutation()
  const [getUsage, { isLoading: usageLoading }] = useUsageMutation()

  const [selectPayAmount, setSelectPayAmount] = useState<string | false>(false)
  const [selectPayDate, setSelectPayDate] = useState<string | false>(false)

  const selectPayAmountValue = { max: "maxAmount", custom: "customAmount" }
  const selectPayDateValue = { today: "today", selectDate: "selectDate" }

  const startingDate = getCurrentDate()

  const {
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: {
      ChargeDate: "",
      Amount: ""
    }
  })

  useEffect(() => {
    if (!person.roles.includes(Roles.Owner)) navigate(dashboardRoute)
  }, [person])

  useEffect(() => {
    (async () => {
      const response = await getUsage().unwrap()
      setMaxPayment(response.data.maximum)
    })()
    return () => {
      reset()
      setSelectPayDate(false)
      setConfirmData(undefined)
      setWizard("payment")
    }
  }, [getUsage, setMaxPayment])

  const swichSelectAmount = (value: string) => {
    setSelectPayAmount(value)
  }

  const switchChargeDateMode = (value: string) => {
    setSelectPayDate(value)
  }

  const validate = (data: FormValues) => {
    const { Amount: amount, ChargeDate } = data
    const prasedAmount = +parseFloat(amount).toFixed(2)

    let pass = true

    if (selectPayAmount === selectPayAmountValue.custom) {
      if (prasedAmount < 1 || isNaN(prasedAmount)) {
        setError("Amount", { message: copyText.minimumPaymentResponse })
        pass = false
      } else if (prasedAmount > maxPayment) {
        setError("Amount", { message: copyText.maxPaymentResponse(maxPayment) })
        pass = false
      }
    }

    if (selectPayDate === selectPayDateValue.selectDate) {
      const formattedChargeDate = DateTime.fromFormat(ChargeDate, "dd/MM/yyyy")
      const today = DateTime.now()

      if (ChargeDate === "") {
        setError("ChargeDate", { message: "Please add a valid charge date!" })
        pass = false
      } else if (formattedChargeDate < today) {
        setError("ChargeDate", { message: "Charge date must after today!" })
        pass = false
      } else if (
        formattedChargeDate.toFormat("c") === "6" ||
        formattedChargeDate.toFormat("c") === "7"
      ) {
        setError("ChargeDate", { message: "Please select a bussiness day" })
        pass = false
      }
    }

    return pass
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!validate(data)) return

    const { Amount: amount, ChargeDate } = data
    const prasedAmount = +parseFloat(amount).toFixed(2)
    const chosenDate =
      selectPayDate === selectPayDateValue.selectDate
        ? DateTime.fromFormat(ChargeDate, "dd/MM/yyyy").toISO()
        : startingDate
    const reference = person.bankAccounts[0].goCardlessReference

    setConfirmData({
      Amount:
        selectPayAmount === selectPayAmountValue.max
          ? maxPayment
          : prasedAmount,
      ChargeDate: chosenDate,
      Reference: reference,
      Description: "TBA"
    })

    setWizard("confirmation")
  }

  const bankAccountFirstItem = person.bankAccounts[0]
  const bankAccountDetails =
    bankAccountFirstItem &&
    `${bankAccountFirstItem.accountName} **** ${String(
      bankAccountFirstItem.accountNumber
    ).slice(-4)}`

  const submitFunction = async () => {
    const makePaymentRequest = await makePayment(
      confirmData as MakeAPayment
    ).unwrap()
    makePaymentRequest.success && setWizard("confirmed")
    setWizard("confirmed")
  }

  const endPayments = () => {
    navigate("/dashboard")
  }

  return {
    wizard,
    usageLoading,
    currentCard,
    confirmData,
    isSubmitting,
    bankAccountDetails,
    errors,
    handleSubmit,
    onSubmit,
    switchChargeDateMode,
    submitFunction,
    endPayments,
    control,
    maxPayment,
    swichSelectAmount,
    selectPayDate,
    selectPayAmount,
    selectPayAmountValue,
    selectPayDateValue
  }
}

export default usePayment
