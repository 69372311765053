import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Spacer,
  Text,
  FormControl,
  Row,
  Column
} from "native-base"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import activateCardImage from "assets/images/activateCard.png"
import ribbonSmileImage from "assets/images/ribbon-smile.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { myCards as myCardPath } from "components/layouts/Authorized/routes"

import useRetrievePin, { text } from "./talons/useRetrievePin"

export interface FormValues {
  token: string
}

export const PinCard = (): JSX.Element => {
  const navigate = useNavigate()
  const {
    isSuccess,
    isLoading,
    handleRetrievePin,
    handleSubmit,
    control,
    pin,
    errors,
    page: showPage
  } = useRetrievePin()

  const page = {
    PinForm: (
      <Flex flexDir={{ base: "column", lg: "row" }} h="100%">
        <Flex flex="1" maxW={{ base: "100%", lg: "500px" }}>
          <Heading
            fontSize="24px"
            color="warmGray.600"
            testID="reveal-pin-heading"
          >
            Get PIN
          </Heading>
          <Spacer h="32px" flexGrow="0" />

          <Text fontSize="xs">{text.retrivePinText}</Text>
          <Spacer h="32px" flexGrow="0" />

          <FormControl isInvalid={!!errors.token}>
            <Controller
              name="token"
              control={control}
              rules={{
                required: true,
                minLength: 9,
                maxLength: 9
              }}
              render={({ field: { onBlur, onChange, value } }) => (
                <Input
                  value={value}
                  testID="reveal-pin-token"
                  onChangeText={(text) => onChange(text)}
                  onBlur={onBlur}
                  autoFocus
                />
              )}
            />
            {errors.token && (
              <FormControl.ErrorMessage
                fontSize="xs"
                testID="token-alert"
                mt="2"
              >
                {text.pinErrorText}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <Spacer h="16px" flexGrow="0" />
          <Row>
            <Button
              flex="1"
              variant="outline"
              testID="reveal-pin-cancel"
              onPress={() => navigate(myCardPath)}
            >
              Cancel
            </Button>

            <Spacer w="32px" flexGrow="0" />

            <Button
              flex="1"
              disabled={isLoading || isSuccess}
              testID="reveal-pin-submit"
              onPress={handleSubmit(handleRetrievePin)}
              isLoading={isLoading}
            >
              Continue
            </Button>
          </Row>
        </Flex>

        <Spacer w="48px" flexGrow="0" />

        <Flex
          flex="1"
          testID="image-block"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={activateCardImage}
            alt="activateCardImage"
            size={{ base: "320px", xl: "480px" }}
            resizeMode="contain"
            resizeMethod="scale"
          />
        </Flex>
      </Flex>
    ),
    SuccessPage: (
      <Flex flexDir={{ base: "column", lg: "row" }} h="100%">
        <Column>
          <Heading
            fontSize="32px"
            testID="success-pin-heading"
            fontFamily="CircularStdMedium"
            color="warmGray.600"
          >
            Get PIN
          </Heading>
          <Spacer h="32px" flexGrow="0" />

          <Box>
            <Text fontSize="xs">{text.pinText}</Text>
            <Spacer h="32px" />
            <Heading fontSize="32px" isTruncated color="primary.600">
              {pin}
            </Heading>
            <Spacer h="32px" flexGrow="0" />
            <Flex w={{ base: "100%", sm: "80%" }}>
              <Button
                variant="solid"
                testID="close-button"
                maxW="128px"
                onPress={() => navigate(myCardPath)}
              >
                Close
              </Button>
            </Flex>
          </Box>
        </Column>
        <Spacer p="32px" flexGrow="0" />

        <Flex flex="1" justifyContent="center" alignItems="center">
          <Image
            w={{ base: "280px", md: "400px", lg: "480px" }}
            h={{ base: "140px", md: "200px", lg: "280px" }}
            src={ribbonSmileImage}
            alt="ribbontSmile"
            resizeMode="contain"
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <Box testID="pin-card">
      <PageHeading  showBackBtn testID="pin-card-header">
        PIN reminder
      </PageHeading>

      <BoxCard minH="65vh">{page[showPage]}</BoxCard>
    </Box>
  )
}
