import AuthedLoadingStatesCtxt from "@axle/web/src/components/providers/Authenticated/contexts/loadingStates"
import {
  removeDuplicateTransaction,
  removeHiddenTransaction
} from "@axle/web/src/components/util/transactionFilters"
import { CardDto } from "@axle/web/src/models/card"
import PersonDto from "@axle/web/src/models/person"
import { usePersonSummaryMutation } from "@axle/web/src/network/person"
import { useGetTransactionsMutation } from "@axle/web/src/network/transactions"
import { useAppDispatch } from "@axle/web/src/store/hooks"
import { useCards } from "@axle/web/src/store/hooks/cards"
import { usePerson } from "@axle/web/src/store/hooks/person"
import { setCards } from "@axle/web/src/store/slices/cards"
import { setCurrentCard } from "@axle/web/src/store/slices/currentCard"
import { setCurrentCardIndex } from "@axle/web/src/store/slices/currentCardIndex"
import { setEntity } from "@axle/web/src/store/slices/entity"
import { setPerson } from "@axle/web/src/store/slices/person"
import {
  setTransactions,
  setHasMoreTransactions,
  incrementCurrentPage
} from "@axle/web/src/store/slices/transactions"
import { setUnreadNotiCount } from "@axle/web/src/store/slices/unreadNotificationCount"
import { ReactNode, useEffect } from "react"

interface Props {
  children: ReactNode
}

export default function AuthenticatedProvider({
  children
}: Props): JSX.Element {
  const [
    personSummary,
    {
      isError: isLoadingError,
      isLoading: isLoading,
      isSuccess: personLoadingSuccess
    }
  ] = usePersonSummaryMutation()
  const [getTransactions, {
    isLoading: isLoadingTransactions,
    isError: isLoadingErrorTransactions,
    isSuccess: transactionsLoadingSuccess
  }] = useGetTransactionsMutation()

  const dispatch = useAppDispatch()
  const { person } = usePerson()
  const { cards } = useCards()
  const fetchSize = 10

  function dispatchSetPersonCards(cards: CardDto[]) {
    dispatch(setCurrentCard(cards[0]))
    dispatch(setCurrentCardIndex(0))
    dispatch(setCards(cards))
  }

  useEffect(() => {
    async function getPerson() {
      const response = await personSummary().unwrap()
      const person = response.data

      if (response.success) {
        dispatch(setPerson(person))
        dispatch(setEntity(person.entity))
        dispatchSetPersonCards(person.cards)
        dispatch(setUnreadNotiCount(person.unreadNotifications))
        loadTransactions(person)
      }
    }

    const loadTransactions = async (person: PersonDto) => {
      const res = await getTransactions({
        LedgerId: person.ledger.id,
        Page: 0,
        PageSize: fetchSize,
        CardId: null,
        SearchTerm: null,
        From: null,
        To: null
      }).unwrap()
      const newTransaction = removeDuplicateTransaction(
        removeHiddenTransaction(res.data.items)
      )
      dispatch(setHasMoreTransactions(res.data.items.length === fetchSize))
      dispatch(setTransactions(newTransaction))
      dispatch(incrementCurrentPage())
    }

    !person &&
      Array.isArray(cards) &&
      cards.length === 0 &&
      !isLoading &&
      !isLoadingTransactions &&
      !personLoadingSuccess &&
      !transactionsLoadingSuccess &&
      !isLoadingError &&
      !isLoadingErrorTransactions &&
      getPerson()
  })

  return (
    <AuthedLoadingStatesCtxt.Provider
      value={{
        isLoading: isLoading || isLoadingTransactions,
        isLoadingError: isLoadingError || isLoadingErrorTransactions
      }}
    >
      {children}
    </AuthedLoadingStatesCtxt.Provider>
  )
}
