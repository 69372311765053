import {
  setTransactions,
  setHasMoreTransactions,
  incrementCurrentPage,
  resetTransactions
} from "@axle/web/src/store/slices/transactions"
import { useState } from "react"

import { stringToBase64 } from "components/util/base64"
import {
  getISODateWithTStamp,
  getISOLastMinuteDateWithTStamp
} from "components/util/format-date"
import { formatPrice } from "components/util/format-price"
import { removeDuplicateTransaction, removeHiddenTransaction } from "components/util/transactionFilters"
import TransactionDto from "models/transactions"
import { useGetTransactionsMutation } from "network/transactions"
import { useAppDispatch } from "store/hooks"
import { usePerson } from "store/hooks/person"
import { useTransactions as useTransactionsList } from "store/hooks/transactions"

import { SearchFormValues } from "../Search/SearchForm"

interface UseTransactionsType {
  handleSearch: ({
    fromDate,
    toDate,
    searchTerm
  }: SearchFormValues) => void
  handleScroll: () => Promise<void>
  handleFreshSearch: (ledgerId: number) => Promise<void>
  transactions: TransactionDto[]
  isLoading: boolean
  downloadParams: string | null
  hasMoreTransactions: boolean
  showAvailableCredit: boolean
  setshowAvailableCredit: React.Dispatch<React.SetStateAction<boolean>>
  isHovered: boolean
  setIsHover: React.Dispatch<React.SetStateAction<boolean>>
  isSearching: boolean
  pendingTransactionList: TransactionDto[]
  totalPending: string
  currentLedger: {
    id: number
    name: string
  }
  setCurrentLedger: React.Dispatch<
    React.SetStateAction<{
      id: number
      name: string
    }>
  >
}

interface SearchData {
  searchTerm: string | null
  fromDate: string | null
  toDate: string | null
}

const useTransactions = (): UseTransactionsType => {
  const dispatch = useAppDispatch()

  const { person } = usePerson()
  const [getTransactions, { isLoading }] = useGetTransactionsMutation()
  const transactionsObject = useTransactionsList()
  const {
    hasMoreTransactions,
    transactions,
    currentPage
  } = transactionsObject
  const [showAvailableCredit, setshowAvailableCredit] = useState<boolean>(false)
  const [downloadParams, setDownloadParams] = useState<string>("")
  const [isHovered, setIsHover] = useState<boolean>(false)

  const [searchData, setSearchData] = useState<SearchData>({} as SearchData)

  const [isSearching, setIsSearching] = useState<boolean>(false)

  const [currentLedger, setCurrentLedger] = useState<{
    id: number
    name: string
  }>({ id: person.ledger.id, name: person.fullName })

  const fetchSize = 10

  const pendingTransactionList = transactions.filter(
    (transaction) => transaction.displayStatus === "Pending"
  )

  const { currency: totalPending } = formatPrice(
    pendingTransactionList.reduce((acc, transaction) => {
      return transaction.amount ? acc + transaction.amount : acc
    }, 0)
  )

  const handleSearch = ({
    fromDate: fDate,
    toDate: tDate,
    searchTerm: sTerm
  }: SearchFormValues) => {
    dispatch(setHasMoreTransactions(false))
    let searchParams = "/"
    const fromDate = getISODateWithTStamp(fDate)
    const toDate = getISOLastMinuteDateWithTStamp(tDate)
    const searchTerm = sTerm ? stringToBase64(sTerm) : null

    !!fromDate || !!fromDate || !!searchTerm
      ? setIsSearching(true)
      : setIsSearching(false)

    // trigger useeffect
    setSearchData({
      fromDate,
      toDate,
      searchTerm
    })

    if (fromDate) {
      const encodedFromDate = stringToBase64(fromDate as string)
      searchParams += `${encodedFromDate}/`
    } else {
      const formattedFromDate = new Date(
        "January 1, 1970 00:00:00 UTC"
      ).toISOString()
      const encodedFromDate = stringToBase64(formattedFromDate as string)
      searchParams += `${encodedFromDate}/`
    }

    if (toDate) {
      const encodedToDate = stringToBase64(toDate as string)
      searchParams += `${encodedToDate}/`
    } else {
      const formattedToDate = new Date().toISOString()
      const encodedToDate = stringToBase64(formattedToDate as string)
      searchParams += `${encodedToDate}/`
    }
    searchParams += stringToBase64(sTerm)
    setDownloadParams(searchParams)
    handleFreshSearch(undefined, searchTerm, fromDate, toDate)
  }

  const handleScroll = async () => {
    dispatch(setHasMoreTransactions(false))
    const page = currentPage
    const { fromDate, toDate, searchTerm } = searchData
    const res = await getTransactions({
      LedgerId: currentLedger.id,
      Page: page,
      PageSize: fetchSize,
      SearchTerm: searchTerm,
      From: fromDate,
      To: toDate,
      CardId: null
    }).unwrap()
    const newTransaction = removeDuplicateTransaction(
      removeHiddenTransaction(res.data.items)
    )
    dispatch(setHasMoreTransactions(res.data.items.length === fetchSize))
    dispatch(setTransactions(newTransaction))
    dispatch(incrementCurrentPage())
  }

  const handleFreshSearch = async (
    ledgerId?: number,
    searchPhrase?: string|null,
    searchFromDate?: string,
    searchToDate?: string
  ) => {
    dispatch(setHasMoreTransactions(false))
    dispatch(resetTransactions())

    const { fromDate, toDate, searchTerm } = searchData

    const res = await getTransactions({
      LedgerId: ledgerId || currentLedger.id,
      Page: 0,
      PageSize: fetchSize,
      SearchTerm: searchPhrase || searchTerm,
      From: searchFromDate || fromDate,
      To: searchToDate || toDate,
      CardId: null
    }).unwrap()
    const newTransaction = removeDuplicateTransaction(
      removeHiddenTransaction(res.data.items)
    )
    dispatch(setHasMoreTransactions(res.data.items.length === fetchSize))
    dispatch(setTransactions(newTransaction))
    dispatch(incrementCurrentPage())
  }

  return {
    transactions,
    isLoading,
    handleSearch,
    downloadParams,
    handleScroll,
    handleFreshSearch,
    hasMoreTransactions,
    showAvailableCredit,
    setshowAvailableCredit,
    isSearching,
    pendingTransactionList,
    totalPending,
    currentLedger,
    setCurrentLedger,
    isHovered,
    setIsHover
  }
}

export default useTransactions
