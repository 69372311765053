import { useCookies } from "react-cookie"
import { Navigate } from "react-router-dom"

import { ddSetup as ddSetupRoute } from "components/layouts/Authorized/routes"
import { isUserHasState } from "components/util/isUserHasState"
import { Roles } from "models/enums/roles"
import { States } from "models/enums/states"
import Person from "models/person"

type RouteByPersonStateProps = {
  person: Person
  children: JSX.Element
}

export function RouteByPersonState({
  person,
  children
}: RouteByPersonStateProps): JSX.Element {
  const [cookies] = useCookies(["ddSkip"])

  const isUserWithoutBankAccoutSetup = !isUserHasState(person, States.BankAccountSetup)
  const primaryWithoutBankAccount = person.roles.find(r => r === Roles.Owner)
    && isUserWithoutBankAccoutSetup && !cookies["ddSkip"]

  return primaryWithoutBankAccount ? (
    <Navigate
      to={{
        pathname: ddSetupRoute
      }}
    />
  ) : (
    children
  )
}
