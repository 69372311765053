import { Text, Checkbox, AlertDialog, Button, Heading } from "native-base"
import { useRef } from "react"

export interface RemoveDialogProps {
    isNeverOpenRemoveOwnersDialog: boolean
    isOpenRemoveOwnersDialog: boolean
    onClose: () => void
    onChange: () => void
    onContinue: () => void
}

const RemoveOwnersDialog = ({ isNeverOpenRemoveOwnersDialog, isOpenRemoveOwnersDialog, onClose, onChange, onContinue }: RemoveDialogProps): JSX.Element => {
  const dialogRef = useRef(null)

  return (<AlertDialog size="lg" leastDestructiveRef={dialogRef} isOpen={isOpenRemoveOwnersDialog} onClose={onClose} testID="remove-owners-dialog">
    <AlertDialog.Content>
      <AlertDialog.Body my="20px" mx="30px">
        <Heading size="md">Are you sure?</Heading>
        <Text my="20px">Removing a budget owner will reset any rules back to the default setting for their budgets. This could impact recurring or future payments.</Text>
        <Checkbox
          isChecked={isNeverOpenRemoveOwnersDialog}
          size="sm"
          onChange={onChange}
          value=""
          testID="remove-owner-dialog-checkbox"
          accessibilityLabel="remove-owner-dialog-checkbox"
        >
                    Don’t ask me again
        </Checkbox>
        <Button.Group mt="30px" flexDir="row" justifyContent="space-between">
          <Button variant="outline" w="100px" onPress={onClose} testID="remove-owner-dialog-cancel">
                        Cancel
          </Button>
          <Button w="100px" onPress={onContinue} testID="remove-owner-dialog-continue">
                        Continue
          </Button>
        </Button.Group>
      </AlertDialog.Body>
    </AlertDialog.Content>
  </AlertDialog>)
}

export default RemoveOwnersDialog
