import { useToast } from "@chakra-ui/react"
import { useCookies } from "react-cookie"
import { useForm, SubmitHandler, UseFormHandleSubmit, UseFormGetValues, FieldError, Control } from "react-hook-form"

import { useChangePasswordMutation } from "network/auth"

interface PasswordUpdateInputs {
  oldPassword: string,
  newPassword: string,
  passwordConfirmed: string
}

interface UseChangePassword {
  updateHandler: SubmitHandler<PasswordUpdateInputs>
  handleSubmit: UseFormHandleSubmit<PasswordUpdateInputs>
  getValues: UseFormGetValues<PasswordUpdateInputs>
  errors: Record<string, FieldError>
  isDirty: boolean
  isValid: boolean
  isSubmitting: boolean
  control: Control<PasswordUpdateInputs>
}

export const useChangePassword = (): UseChangePassword => {
  const {
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors, isSubmitting, isDirty, isValid }
  } = useForm<PasswordUpdateInputs>({
    mode: "onSubmit",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      passwordConfirmed: ""
    }
  })

  const [cookies] = useCookies(["username"])
  const toast = useToast()
  const [changePassword] = useChangePasswordMutation()

  const updateHandler: SubmitHandler<PasswordUpdateInputs> = async (data, event) => {
    event?.preventDefault()

    await changePassword({
      Username: cookies.username,
      OldPassword: data.oldPassword,
      NewPassword: data.newPassword
    }).unwrap()

    reset()

    toast({
      status: "success",
      title: "Success",
      description: "Your password has been updated",
      isClosable: true
    })
  }

  return {
    updateHandler,
    handleSubmit,
    getValues,
    errors,
    isDirty,
    isValid,
    isSubmitting,
    control
  }
}
