import {
  API_BASE_URL as apiBase,
  LOGGLY_API_BASE_URL as logglyApiBase
} from "@axle/web/src/config/environment"

export const USERS_API_BASE = `${apiBase}/users`
export const SIGN_UP_API_BASE = `${apiBase}/signup`
export const PERSON_API_BASE = `${apiBase}/person`
export const ENTITY_API_BASE = `${apiBase}/entity`
export const EVENTS_API_BASE = `${apiBase}/events`
export const BUDGETS_API_BASE = `${apiBase}/budgets`
export const OAUTH_API_BASE = `${apiBase}/oauth`
export const CARD_API_BASE = `${apiBase}/card`
export const EXPENSE_API_BASE = `${apiBase}/accounting`
export const TRANSACTIONS_API_BASE = `${apiBase}/transactions`
export const VERIFICATION_API_BASE = `${apiBase}/verification`
export const NOTIFICATIONS_API_BASE = `${apiBase}/notifications`
export const NOTIFICATION_API_BASE = `${apiBase}/notification`
export const LOGGLY_API_BASE = `${logglyApiBase}`
export const STATEMENT_API_BASE = `${apiBase}/statement`
export const LIMITS_API_BASE = `${apiBase}/limits`
export const RECEIPTS_API_BASE = `${apiBase}/receipts`

export default apiBase
