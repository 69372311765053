export enum Roles {
  None = 0,
  Administrator = 1,
  Billing = 2,
  Owner = 3,
  IT = 8,
  Management = 10,
  Customer = 12,
  TeleSales = 21,
  Sales = 22,
  Client = 23,
  SalesManager = 24,
  SalesDirector = 25,
  SalesOperations = 26,
  CreditControl = 27,
  PaymentServiceProvider = 28,
  Compliance = 29,
  Individuals = 30,
  Operations = 31,
  ComplianceManager = 32,
  Transactions = 33,
  Settings = 34,
  PublicApi = 35,
  User = 36,
  BudgetOwner = 37,

  // enum for testing
  testAdminControl = 500,
  testOwnerControl = 501
}
