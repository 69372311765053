import { useEffect, useState } from "react"
import {
  Control,
  useForm,
  UseFormHandleSubmit,
  UseFormGetValues,
  FieldError
} from "react-hook-form"

import { getISODate } from "components/util/format-date"
import PersonDto from "models/person"
import {
  usePersonCreateMutation,
  usePersonIsUniqueMutation
} from "network/person"

export type FormValue = {
  first: string
  middle: string
  last: string
  email: string
  DateOfBirth: string
  mobileCountryCode: string
  mobile: string
  address1: string
  address2: string
  postTown: string
  postCode: string
  state: string
  country: string
  char2Code: string
}

export interface useAddPersonForm {
  showPage: "addPersonFrom" | "confirm"
  setShowPage: React.Dispatch<React.SetStateAction<"addPersonFrom" | "confirm">>
  validateDetail: (data: FormValue) => Promise<void>
  submitAddPersonRequest: (data: FormValue) => Promise<void>
  isChecking: boolean
  isSuccess: boolean
  createPersonInFlight: boolean
  control: Control<FormValue, object>
  handleSubmit: UseFormHandleSubmit<FormValue>
  getValues: UseFormGetValues<FormValue>
  errors: {
    first?: FieldError | undefined
    middle?: FieldError | undefined
    last?: FieldError | undefined
    email?: FieldError | undefined
    DateOfBirth?: FieldError | undefined
    mobileCountryCode?: FieldError | undefined
    mobile?: FieldError | undefined
    address1?: FieldError | undefined
    address2?: FieldError | undefined
    postTown?: FieldError | undefined
    postCode?: FieldError | undefined
    state?: FieldError | undefined
    country?: FieldError | undefined
  }
  setSelectedCountry: React.Dispatch<React.SetStateAction<string>>
  selectedCountry: string
}

export const useAddPersonForm = (person: PersonDto): useAddPersonForm => {
  const [createPerson, { isSuccess, isLoading: createPersonInFlight }] =
    usePersonCreateMutation()
  const [personIsUnique, { isLoading: isChecking }] =
    usePersonIsUniqueMutation()
  const [showPage, setShowPage] = useState<"addPersonFrom" | "confirm">(
    "addPersonFrom"
  )

  const [selectedCountry, setSelectedCountry] = useState<string>("Australia")

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setError,
    formState: { errors }
  } = useForm<FormValue>({
    mode: "onSubmit",
    defaultValues: {
      first: "",
      middle: "",
      last: "",
      email: "",
      DateOfBirth: "",
      mobileCountryCode: "+61",
      mobile: "",
      address1: "",
      address2: "",
      postTown: "",
      postCode: "",
      state: "",
      country: "AU",
      char2Code: ""
    }
  })

  const validateDetail = async ({
    email,
    mobileCountryCode,
    mobile,
    middle,
    first,
    last
  }: FormValue) => {
    const personIsUniqueParams = {
      uid: person.email,
      first: first,
      last: last,
      email: email.trim(),
      mobile: `${mobileCountryCode}${mobile.replace(/^0| /g, "")}`,
      middle: middle,
      fullName: `${first} ${middle} ${last}`,
      suffix: ""
    }

    const testResult = await personIsUnique(personIsUniqueParams).unwrap()

    const { isEmailUnique, isMobileUnique } = testResult.data
    if (isEmailUnique && isMobileUnique) {
      setShowPage("confirm")
    } else {
      !isEmailUnique &&
        setError("email", {
          message:
            "This email is already in use. Please use an unique email for each person."
        })

      !isMobileUnique &&
        setError("mobile", {
          message:
            "This number is already in use. Please use an unique number for each person."
        })
    }
  }

  const submitAddPersonRequest = async ({
    mobileCountryCode,
    mobile,
    first,
    middle,
    last,
    email,
    address1,
    address2,
    postTown,
    DateOfBirth,
    postCode,
    state,
    country
  }: FormValue) => {
    const createPersonParams = {
      personUid: person.email,
      first: first,
      middle: middle,
      fullName: `${first} ${middle} ${last}`,
      suffix: "",
      DateOfBirth: getISODate(DateOfBirth),
      last: last,
      email: email.trim(),
      mobile: `${mobileCountryCode}${mobile.replace(/^0| /g, "")}`,
      address: {
        address1: address1,
        address2: address2,
        postTown: postTown,
        postCode: postCode,
        state: state,
        country: selectedCountry,
        char2Code: country
      }
    }

    await createPerson(createPersonParams).unwrap()
  }

  useEffect(() => {
    return () => {
      reset()
    }
  }, [isSuccess])

  return {
    showPage,
    validateDetail,
    submitAddPersonRequest,
    isChecking,
    isSuccess,
    createPersonInFlight,
    control,
    handleSubmit,
    getValues,
    errors,
    setShowPage,
    setSelectedCountry,
    selectedCountry
  }
}
