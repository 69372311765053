import { Column, Row, Text, Box, Tooltip, Button } from "native-base"

import { AdminControlsDto } from "models/spendControls"
import { useUpdateSpendControlsMutation } from "network/spendControls"
import { useAppDispatch } from "store/hooks"
import { useAdminControls, useEntityCategory } from "store/hooks/spendControls"
import { setExpensePolicies } from "store/slices/spendControls"

import TabRow from "./TabRow"

export default ({ eCIndex }: { eCIndex: number }) => {
  const { adminControls } = useAdminControls()

  const dispatch = useAppDispatch()

  const [updateSpendControls] = useUpdateSpendControlsMutation()

  const saveSpendControls = async (adminControls: AdminControlsDto) => {
    const { id, categories } = adminControls
    await updateSpendControls(adminControls).unwrap()
    // dispatch(setAdminControl(spendControlsResults.data))
    dispatch(setExpensePolicies({ id, categories }))
  }

  if (!adminControls) return <></>

  return (
    <Row key={`entity-category-${eCIndex}`}>
      <Column space={3} w="100%">
        <Row justifyContent="space-between">
          <Box flex={1}>
            <Text></Text>
          </Box>
          <Tooltip
            label="These settings will automatically be applied to cards in your organisation. These can be overwritten if the option delegate authority is selected."
            openDelay={200}
            w={"300px"}
            p="20px"
            borderRadius="5px"
            placement="top"
            bg="white"
            _text={{ color: "#171717" }}
          >
            <Box
              flex={1}
              textAlign="center"
              flexDir="row"
              justifyContent="center"
              alignItems="center"
              testID="default-setting"
            >
              <Text bold>Default Setting</Text>
              <Box
                w="20px"
                h="20px"
                bg="#E7E7E7"
                ml="2"
                borderRadius="50%"
                _text={{ fontWeight: "bold" }}
              >
                ?
              </Box>
            </Box>
          </Tooltip>
          <Tooltip
            label="By checking ‘delegate authority’, you can authorise budget owners to set spend controls for the budgets they own."
            openDelay={200}
            w={"300px"}
            p="20px"
            borderRadius="5px"
            placement="top"
            bg="white"
            _text={{ color: "#171717" }}
          >
            <Box
              flex={1}
              textAlign="center"
              flexDir="row"
              justifyContent="center"
              alignItems="center"
            >
              <Text bold>Delegate authority</Text>
              <Box
                w="20px"
                h="20px"
                bg="#E7E7E7"
                ml="2"
                borderRadius="50%"
                _text={{ fontWeight: "bold" }}
              >
                ?
              </Box>
            </Box>
          </Tooltip>
          <Tooltip
            label="Select the budgets that you want to delegate authority to."
            openDelay={200}
            w={"200px"}
            p="20px"
            placement="top"
            bg="white"
            _text={{ color: "#171717" }}
          >
            <Box
              flex={1}
              textAlign="center"
              flexDir="row"
              justifyContent="center"
              alignItems="center"
              testID="authorized-budget-owners"
            >
              <Text bold>Authorized Budget Owners</Text>
              <Box
                w="20px"
                h="20px"
                bg="#E7E7E7"
                ml="2"
                borderRadius="50%"
                _text={{ fontWeight: "bold" }}
              >
                ?
              </Box>
            </Box>
          </Tooltip>
        </Row>
        {useEntityCategory(eCIndex).categories.items.map((category, cIndex) => {
          return (
            <TabRow
              key={`category-${cIndex}`}
              cIndex={cIndex}
              eCIndex={eCIndex}
              category={category}
              adminControls={adminControls}
            />
          )
        })}

        <Row flexDir="row-reverse">
          <Button w="100px" onPress={() => saveSpendControls(adminControls)}>
            Save
          </Button>
        </Row>
      </Column>
    </Row>
  )
}
