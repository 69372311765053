import { Image } from "native-base"
import { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import capeImage from "assets/images/cape.png"
import { PrivateRoute } from "components/inline/PrivateRoute"
import AuthorizedRouter from "components/layouts/Authorized/Router"
import PublicLayout from "components/layouts/Public"
import { DISABLE_SECURE_COOKIE } from "config/environment"

import { useLogoutUser } from "./hooks/useLogoutUser"
import * as publicRoutes from "./layouts/Public/routes"
import CreditLimitConfirmation from "./pages/Account/CreditLimitConfirmation"
import { AccountLocked } from "./pages/AccountLocked"
import Login from "./pages/Login"
import { ResetPassword } from "./pages/ResetPassword/ResetPassword"
import { SessionExpired } from "./pages/SessionExpired"
import SetCredentials, { Expired } from "./pages/SetCredentials"
import { TooManyCodes } from "./pages/TooManyCodes"
import { TwoFAVerifcation } from "./pages/TwoFAVerification"
import VersionSyncComp from "./providers/VersionSync"

const secureCookieDisabled = DISABLE_SECURE_COOKIE

interface App {
  disableSecureCookie?: boolean
  VersionSync?: typeof VersionSyncComp
}

function App({
  disableSecureCookie = secureCookieDisabled,
  VersionSync = VersionSyncComp
}: App): JSX.Element {
  const { logoutUser } = useLogoutUser()
  // logout user on browser tab close
  window &&
    window.addEventListener("unload", function () {
      const currentTime = new Date().getTime().toString()
      localStorage.setItem("sessionClosedAt", currentTime)
    })

  useEffect(() => {
    const tenSecondsInMills = 10 * 1000
    const currentTime = Number(new Date().getTime().toString())
    const lastSessionTime = Number(localStorage.getItem("sessionClosedAt"))

    if (lastSessionTime === 0) return

    const sessionClosedDuration = currentTime - lastSessionTime

    if (sessionClosedDuration > tenSecondsInMills) {
      localStorage.removeItem("sessionClosedAt")
      logoutUser()
    }
  }, [disableSecureCookie])

  return (
    <BrowserRouter>
      <VersionSync>
        <Routes>
          <Route
            path={publicRoutes.login}
            element={
              <PublicLayout>
                <Login
                  capeCardImage={() => (
                    <Image
                      width={{ base: "325px", xl: "480px" }}
                      height={{ base: "325px", xl: "480px" }}
                      src={capeImage}
                      alt="capeImage"
                    />
                  )}
                />
              </PublicLayout>
            }
          />
          <Route
            path={publicRoutes.resetPassword(":guid")}
            element={<ResetPassword />}
          />
          <Route
            path={publicRoutes.setCredentials(":guid")}
            element={<SetCredentials />}
          />
          <Route path={publicRoutes.linkExpired} element={<Expired />} />
          <Route
            path={publicRoutes.sessionExpired}
            element={<SessionExpired />}
          />
          <Route path={publicRoutes.twoFA} element={<TwoFAVerifcation />} />
          <Route
            path={publicRoutes.lockedAccount}
            element={<AccountLocked />}
          />
          <Route path={publicRoutes.tooManyCodes} element={<TooManyCodes />} />
          <Route
            path={publicRoutes.creditLimitConfirm(":guid")}
            element={<CreditLimitConfirmation />}
          />
          <Route
            path={publicRoutes.creditLimitConfirm(":guid")}
            element={<CreditLimitConfirmation />}
          />

          <Route
            path="/*"
            element={
              <PrivateRoute>
                <AuthorizedRouter data-testid="App" />
              </PrivateRoute>
            }
          />

        </Routes>
      </VersionSync>
    </BrowserRouter>
  )
}

export default App
