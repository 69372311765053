import { Heading, Flex } from "native-base"
import { useContext } from "react"

import { ReactComponent as ChangePasswordIcon } from "assets/svgs/icons/settings-change-password.svg"
import { ReactComponent as TermsAndConditionsIcon } from "assets/svgs/icons/t-and-c.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import CardsNav from "components/inline/CardVisual/CardsNav"
import MenuList from "components/inline/MenuList"
import PageHeading from "components/inline/PageHeading/PageHeading"
import {
  allDepsContext,
  currentCardContext
} from "components/layouts/Authorized/MaybeRender"
import { changePassword } from "components/layouts/Authorized/routes"
import { termsAndConditionsURL } from "components/util/cape-details"

export const ProfileHeader = (): JSX.Element => {
  const { cards, hasCurrentCard } = useContext(allDepsContext)

  const { card: currentCard, index: currentCardIndex } =
    useContext(currentCardContext)

  return (
    <>
      <PageHeading>Profile</PageHeading>
      <BoxCard
        testID="settings"
        display="flex"
        flexDirection="column"
        marginBottom="1rem"
      >
        <Heading
          textAlign={{ base: "center", md: "left" }}
          mb="32px"
          fontSize="24px"
        >
          Personal Settings
        </Heading>

        <Flex
          maxWidth={{ md: "180px", lg: "360px" }}
          width="100%"
          flexDir={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-start" }}
        >
          {hasCurrentCard && (
            <CardsNav
              cards={cards}
              currentCard={currentCard}
              cardIndex={currentCardIndex}
            />
          )}
          <Flex
            flexDir="column"
            h="138px"
            alignItems={{ base: "center", md: "flex-start" }}
            ml={{ base: "unset", md: "32px" }}
            mt={{ base: "32px", md: "unset" }}
          >
            <MenuList
              menuLinks={[
                {
                  icon: <ChangePasswordIcon />,
                  path: changePassword,
                  text: "Change Password"
                },
                {
                  icon: (
                    <TermsAndConditionsIcon fill="var(--chakra-colors-red-300)" />
                  ),
                  href: termsAndConditionsURL,
                  isExternal: true,
                  text: "Terms and Conditions"
                }
              ]}
            />
          </Flex>
        </Flex>
      </BoxCard>
    </>
  )
}
