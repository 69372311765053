
import { allDepsContext, currentCardContext } from "@axle/web/src/components/layouts/Authorized/MaybeRender"
import { CardDto } from "@axle/web/src/models/card"
import { CurrentCardState } from "@axle/web/src/store/slices/currentCard"
import { useContext } from "react"

interface useMyCard {
  currentCard: CurrentCardState
  currentCardIndex: number | null
  cards: CardDto[] | null
  userHasCardAndIsActive: boolean | null
  currentCardActivated: boolean | null
  cardIsActive: boolean | null
  cardIsSuspended: boolean | null
  iconSize: string;
  cardPinText: string
  cardFreezingText: string
  cardUnfreezingText: string
  cardActivatingText: string
}

const useMyCard = (): useMyCard => {
  const { cards, hasCurrentCard } = useContext(allDepsContext)
  const {
    card: currentCard,
    index: currentCardIndex,
    cardActivated: currentCardActivated,
    cardLocked: currentCardLocked,
    cardPreActive:
    currentCardPreActive
  } = useContext(currentCardContext)

  const cardIsActive = hasCurrentCard && currentCardActivated
  const userHasCardAndIsActive = hasCurrentCard && currentCardActivated
  const cardIsSuspended = currentCard && currentCardLocked
  const cardPinText = cardIsActive ? "PIN reminder" : currentCardPreActive ? "Please activate your card in order to view your PIN" : "Your card is frozen. Please unfreeze your card to see the PIN reminder"
  const pendingStateText = (status: string) => (`We're ${status} card. This usually takes less than a minute, but could be up to 2 hours. You can check the status of your request at any time by selecting Check Status.`)
  const cardFreezingText = pendingStateText("freezing")
  const cardUnfreezingText = pendingStateText("unfreezing")
  const cardActivatingText = pendingStateText("activating")

  const iconSize = "lg"

  return {
    currentCard,
    currentCardIndex,
    cards,
    cardIsActive,
    currentCardActivated,
    userHasCardAndIsActive,
    cardIsSuspended,
    iconSize,
    cardPinText,
    cardFreezingText,
    cardUnfreezingText,
    cardActivatingText
  }
}

export default useMyCard
