import { useState, useEffect } from "react"

import { StatementDto } from "models/newStatement"
import { useGetStatementMutation } from "network/statement"

interface UseStatement {
    statement: StatementDto | undefined
    formatter: Intl.NumberFormat
}

const useStatement = (id: number): UseStatement => {
  const [statement, setStatement] = useState<StatementDto | undefined>()
  const [getStatement] = useGetStatementMutation()
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  })

  useEffect(() => {
    (async () => {
      const response = await getStatement({
        id
      }).unwrap()
      response.success && setStatement(response.data)
    })()

    return () => {
      setStatement(undefined)
    }
  }, [getStatement, id])

  return {
    statement,
    formatter
  }
}

export const GETCAPE_IO = "getcape.io"
export const CAPE_PHONE = "+61 1300 062 273"
export const CAPE_EMAIL = "support@getcape.io"
export const CAPE_NAME = "Cape Technologies Pty Ltd"
export const CAPE_ABN = "30284739271"
export const CAPE_ADDRESS = "WOTSO Manly, 19-21 South Steyne, Manly, NSW 2095"

export default useStatement
