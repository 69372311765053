import LogglyTracker from "@axle/web/src/components/providers/Loggly/tracker"
import { ErrorCodes } from "@axle/web/src/models/enums/error-codes"
import { errorTitle } from "@axle/web/src/network/util/constants"
import theme from "@axle/web/src/theme"
import { createStandaloneToast } from "@chakra-ui/react"
import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit"

import { ErrorCodeText } from "./error-code-text"
import ErrToast from "./errorLoggerTemplate"

const toast = createStandaloneToast({ theme: theme })

const tracker = new LogglyTracker()
tracker.config({
  key: true,
  session_id: "",
  inputUrl: "",
  logglyKey: process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN,
  sendConsoleErrors: true,
  tag: process.env.REACT_APP_LOGGLY_TAG,
  useUtfEncoding: true
})

export const errorLogger: Middleware = () => (next) => (action) => {
  const isNotTwoFA =
    action.payload?.data?.code !== ErrorCodes.TwoFactorRequested &&
    action.payload?.code !== ErrorCodes.TwoFactorRequested
  const isNotAccountSuspended =
    action.payload?.data?.code !== ErrorCodes.AccountSuspended

  if (
    (isRejectedWithValue(action) ||
      (action.payload && action.payload.success === false)) &&
    isNotTwoFA &&
    isNotAccountSuspended
  ) {
    if (action.payload.status >= 500) {
      tracker.track(action.payload.data)

      toast({
        duration: 3000,
        render: () => (
          <ErrToast
            title={action.payload.data.message}
            description={action.payload.data.exceptionMessage}
          />
        )
      })
    } else if (action.payload.status >= 400) {
      tracker.track(action.payload.data)

      const errorDescription =
        ErrorCodeText.find((item) => item.code === action.payload.data.code)
          ?.message ??
        action.payload.data.message ??
        "There was an issue"

      toast({
        duration: 3000,
        render: () => (
          <ErrToast title={errorTitle} description={errorDescription} />
        )
      })
    } else {
      if (action.payload?.message) {
        tracker.track(action.payload?.message)
      }

      toast({
        duration: 3000,
        render: () => (
          <ErrToast
            title={errorTitle}
            description={action.payload?.message || "There was an issue"}
          />
        )
      })
    }
  }

  return next(action)
}
