// import { ReactComponent as TransactionsIcon } from "assets/svgs/icons/transactions.group.svg";

import { isUserHasState } from "@axle/web/src/components/util/isUserHasState"
import { States } from "@axle/web/src/models/enums/states"
import InfiniteScroll from "react-infinite-scroller"
import { Route, Routes, Navigate } from "react-router-dom"

import { RouteByPersonState } from "components/inline/RouteByPersonState"
import ScrollToTop from "components/inline/ScrollToTop"
import DashboardLayout from "components/layouts/Dashboard"
import OnboardingLayout from "components/layouts/Onboarding"
import PublicLayout from "components/layouts/Public"
import { ActivateCard } from "components/pages/ActivateCard"
import { ActivateFlowDirectDebit } from "components/pages/ActivateFlowDirectDebit"
import { SpendControls } from "components/pages/Budgeting/SpendControls"
import { ChangePassword } from "components/pages/ChangePassword/ChangePassword"
import { Connections } from "components/pages/Connections"
import DirectDebit from "components/pages/DirectDebit"
import { EnableTwoFA } from "components/pages/EnableTwoFA"
import { FreezeCard } from "components/pages/FreezeCard"
import { HelpCentre } from "components/pages/HelpCentre/HelpCentre"
import { MyCards } from "components/pages/MyCards"
import { Notifications } from "components/pages/Notifications"
import { OrderCard } from "components/pages/OrderCard"
import { Payments } from "components/pages/Payments"
import { AddPersonForm as AddPerson } from "components/pages/People/AddPersonForm/AddPersonForm"
import { Users } from "components/pages/People/Users"
import { PinCard } from "components/pages/Pin"
import { Profile } from "components/pages/Profile/Profile"
import { ReplaceCard } from "components/pages/ReplaceCard"
import { Settings } from "components/pages/Settings"
import { SetupTwoFA } from "components/pages/SetupTwoFA"
import { Statement } from "components/pages/Statement"
import { Transactions as TransactionsComp } from "components/pages/Transactions"
import { DownloadCSV } from "components/pages/Transactions/DownloadCSV/DownloadCSV"
import { UnfreezeCard } from "components/pages/UnfreezeCard"
import AuthenticatedProvider from "components/providers/Authenticated"
// import EventsProvider from "components/providers/Events"
import EventsProvider from "components/providers/Events/signalR"
import SessionPersistence from "components/providers/SessionPersistence"
import { FF__SPEND_CONTROLS } from "config/environment"
import { Roles } from "models/enums/roles"

import Maybe from "./MaybeRender"
import * as authRoutes from "./routes"

interface Props {
  Transactions?: typeof TransactionsComp
}

const AuthenticatedRouter = ({
  Transactions = TransactionsComp
}: Props): JSX.Element => {
  return (
    <SessionPersistence>
      <AuthenticatedProvider>
        <EventsProvider>
          <Routes>
            <Route
              path={`${authRoutes.onboarding}/*`}
              element={
                <OnboardingLayout>
                  <Routes>
                    <Route
                      path={authRoutes.ddSetupSegment}
                      element={
                        <Maybe>
                          {(resolved) => (
                            <DirectDebit person={resolved.person} />
                          )}
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.obHelpCentreSegment}
                      element={<HelpCentre />}
                    />
                    <Route
                      path={authRoutes.obOrderCardSegment}
                      element={
                        <Maybe>
                          {(resolved) => <OrderCard person={resolved.person} />}
                        </Maybe>
                      }
                    />
                  </Routes>
                </OnboardingLayout>
              }
            />
            <Route
              path={`${authRoutes.dashboard}/2fa/*`}
              element={
                <PublicLayout>
                  <Routes>
                    <Route
                      path={authRoutes.twoFAStartSegment}
                      element={
                        <Maybe>
                          {(resolved) => (
                            <EnableTwoFA person={resolved.person} />
                          )}
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.twoFAConfirmationSegment}
                      element={
                        <Maybe>
                          {(resolved) => (
                            <SetupTwoFA person={resolved.person} />
                          )}
                        </Maybe>
                      }
                    />
                  </Routes>
                </PublicLayout>
              }
            />
            <Route
              path={`${authRoutes.dashboard}/*`}
              element={
                <DashboardLayout>
                  <ScrollToTop />
                  <Routes>
                    <Route
                      index
                      element={
                        <Maybe>
                          {(resolved) => (
                            <RouteByPersonState person={resolved.person}>
                              <Transactions
                                enableDownload={false}
                                showPayments={resolved.person.roles.includes(
                                  Roles.Owner
                                )}
                                InfiniteScroller={InfiniteScroll}
                                title="Dashboard"
                                person={resolved.person}
                              />
                            </RouteByPersonState>
                          )}
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.transactionsSegment}
                      element={
                        <Maybe>
                          {(resolved) => {
                            return (
                              <Transactions
                                DownloadCSV={DownloadCSV}
                                InfiniteScroller={InfiniteScroll}
                                person={resolved.person}
                              />
                            )
                          }}
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.paymentsSegement}
                      element={
                        <Maybe>
                          {(resolved) => <Payments person={resolved.person} />}
                        </Maybe>
                      }
                    />

                    <Route
                      path={authRoutes.spendControlsSegment}
                      element={
                        <>
                          {FF__SPEND_CONTROLS && (
                            <Maybe
                              authorizedRoles={[
                                Roles.Administrator,
                                Roles.BudgetOwner,
                                Roles.Owner,
                                Roles.testAdminControl,
                                Roles.testOwnerControl
                              ]}
                            >
                              {() => <SpendControls />}
                            </Maybe>
                          )}
                        </>
                      }
                    />

                    <Route
                      path={authRoutes.peopleSegment}
                      element={<>{<Maybe>{() => <Users />}</Maybe>}</>}
                    />
                    <Route
                      path={authRoutes.peopleAddSegment}
                      element={
                        <>
                          {" "}
                          {
                            <Maybe>
                              {(resolved) => (
                                <AddPerson person={resolved.person} />
                              )}
                            </Maybe>
                          }
                        </>
                      }
                    />
                    <Route
                      path={authRoutes.connectionsSegment}
                      element={<Maybe>{() => <Connections />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.freezeCardSegment}
                      element={<Maybe>{() => <FreezeCard />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.unFreezeCardSegement}
                      element={<Maybe>{() => <UnfreezeCard />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.pinReminderSegment}
                      element={<Maybe>{() => <PinCard />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.orderCardSegment}
                      element={
                        <Maybe>
                          {(resolved) => <OrderCard person={resolved.person} />}
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.replaceCardSegment}
                      element={<Maybe>{() => <ReplaceCard />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.myCardsSegment}
                      element={<Maybe>{() => <MyCards />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.activateCardSegment}
                      element={
                        <Maybe>
                          {(resolved) =>
                            isUserHasState(
                              resolved.person,
                              States.MandateSetup
                            ) ? (
                                <ActivateCard />
                              ) : (
                                <Navigate
                                  replace
                                  to={authRoutes.activateFlowDDSetup}
                                />
                              )
                          }
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.activateFlowDDSetupSegment}
                      element={
                        <Maybe>{() => <ActivateFlowDirectDebit />}</Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.dashboardHelpCentreSegment}
                      element={<HelpCentre />}
                    />
                    <Route
                      path={`${authRoutes.statementSegment(":id")}`}
                      element={<Statement />}
                    />
                    <Route
                      path={authRoutes.changePasswordSegment}
                      element={<Maybe>{() => <ChangePassword />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.profileSegment}
                      element={
                        <Maybe>
                          {(resolved) => (
                            <Profile
                              person={resolved.person}
                              entity={resolved.entity}
                            />
                          )}
                        </Maybe>
                      }
                    />
                    <Route
                      path={authRoutes.settingsSegment}
                      element={<Maybe>{() => <Settings />}</Maybe>}
                    />
                    <Route
                      path={authRoutes.notificationSegment}
                      element={
                        <Maybe>
                          {(resolved) => (
                            <Notifications
                              InfiniteScroller={InfiniteScroll}
                              person={resolved.person}
                            />
                          )}
                        </Maybe>
                      }
                    />

                    <Route
                      path="*"
                      element={<Navigate to={authRoutes.dashboard} />}
                    />
                  </Routes>
                </DashboardLayout>
              }
            />
            <Route path="*" element={<Navigate to={authRoutes.dashboard} />} />
          </Routes>
        </EventsProvider>
      </AuthenticatedProvider>
    </SessionPersistence>
  )
}

export default AuthenticatedRouter
