import { Box, Heading, IBoxProps } from "native-base"

interface IReviewableFieldProps extends IBoxProps {
  label?: string
  value: string
}

export const ReviewableField = ({
  label,
  value,
  ...props
}: IReviewableFieldProps) => {
  return (
    <Box {...props}>
      {label && (
        <Heading size="sm" my="16px">
          {label}
        </Heading>
      )}

      <Box
        borderColor="warmGray.600"
        px="1rem"
        py="8px"
        borderBottomWidth="1px"
        borderTopWidth="1px"
        _text={{ color: "warmGray.600" }}
      >
        {value}
      </Box>
    </Box>
  )
}
