import { Dispatch, SetStateAction, useState } from "react"
import { Control, FieldError, useForm } from "react-hook-form"

import AddressDto from "models/address"
import Person from "models/person"
import { useOrderCardMutation as useOrderNewcard } from "network/card"
import { usePersonSummaryMutation } from "network/person"
import { useAppDispatch } from "store/hooks"
import { useEntity } from "store/hooks/entity"
import { usePersonHomeAddress } from "store/hooks/person"
import { setPerson } from "store/slices/person"

type page = "OrderCardPage" | "SuccessPage"
export interface useOrderCard {
  submitOrderCard: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>
  residentialAddress: AddressDto | null
  commercialAddress: AddressDto | null
  chosenAddressState: string
  setChosenAddressState: Dispatch<SetStateAction<string>>
  control: Control<FormValues>
  errors: {
    AddressId?: FieldError | undefined
    NameOnCard?: FieldError | undefined
  }
  isLoading: boolean
  isSuccess: boolean
  showPage: page
}
export interface FormValues {
  AddressId: number
  NameOnCard: string
}

export const useOrderCard = (person: Person): useOrderCard => {
  const [orderNewCard, { isLoading, isSuccess }] = useOrderNewcard()
  const { address: residentialAddress } = usePersonHomeAddress()
  const { entity } = useEntity()
  const commercialAddress = entity && entity.addresses[0]

  const [chosenAddressState, setChosenAddressState] =
    useState<string>("")
  const [personSummary] = usePersonSummaryMutation()
  const dispatch = useAppDispatch()
  const [showPage, setShowPage] = useState<page>("OrderCardPage")

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>()

  const handleNewCard = async (data: FormValues) => {
    const dataForOrderCard = {
      ...data,
      NameOnCard: person.fullName
    }

    await orderNewCard(dataForOrderCard).unwrap()
    const personDetailResponse = await personSummary().unwrap()
    personDetailResponse.success &&
      (dispatch(setPerson(personDetailResponse.data)),
      setShowPage("SuccessPage"))
  }

  const submitOrderCard = handleSubmit(handleNewCard)

  return {
    submitOrderCard,
    residentialAddress,
    commercialAddress,
    chosenAddressState,
    setChosenAddressState,
    control,
    errors,
    isLoading,
    isSuccess,
    showPage
  }
}

export const text = {
  title: "Order a new card",
  content_a: "Welcome to Cape. Let's get you started with a brand new card.",
  content_b: "Please let us know where we should send your card:",
  successText: "Please allow 5-7 business days for delivery."
}
