import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  Spacer,
  Text,
  Flex,
  Row,
  Column
} from "native-base"
import { Controller } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import heartImage from "assets/images/heart.png"
import ribbonSmileImage from "assets/images/ribbon-smile.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import CardAddressChooser from "components/inline/OrderCardAddressChooser"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { dashboard as dashboardRoute } from "components/layouts/Authorized/routes"
import Person from "models/person"

import { useOrderCard, text } from "./talons/useOrderCard"

export interface OrderNewcardModalProps {
  person: Person
}

export const OrderCard = ({ person }: OrderNewcardModalProps): JSX.Element => {
  const {
    submitOrderCard,
    residentialAddress,
    commercialAddress,
    chosenAddressState,
    setChosenAddressState,
    control,
    isLoading,
    isSuccess,
    showPage
  } = useOrderCard(person)

  const navigate = useNavigate()

  const page = {
    OrderCardPage: (
      <Flex flexDir={{ base: "column", lg: "row" }} alignItems={{ base: "center", lg: "flex-end" }} testID="order-card-form">
        <Column>
          <Text>{text.content_a}</Text>
          <Spacer py="16px" flexGrow="0" />
          <Heading fontWeight="900" width="100%" textAlign="left" size="sm">
            Name on Card
          </Heading>
          <Spacer py="4px" flexGrow="0" />

          <Input
            isDisabled={true}
            value={person.fullName}
            testID="name-on-card"
            type="text"
            color="warmGray.600"
          />

          <Spacer py="32px" flexGrow="0" />
          <Text>{text.content_b}</Text>
          <Spacer py="16px" flexGrow="0" />

          {commercialAddress && (
            <Controller
              name="AddressId"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange: controllerOnChange } }) => (
                <CardAddressChooser
                  residential={residentialAddress?.addressX}
                  commercial={commercialAddress.addressX}
                  value={chosenAddressState}
                  onChange={(newValue) => {
                    setChosenAddressState(newValue)
                    if (newValue === "residential") {
                      controllerOnChange(residentialAddress?.id)
                    } else {
                      controllerOnChange(commercialAddress.id)
                    }
                  }}
                />
              )}
            />
          )}

          <Row mt="48px">
            <Button
              flex="1"
              testID="back-button"
              onPress={() => navigate(-1)}
              disabled={isLoading}
              variant="outline"
            >
              Cancel
            </Button>

            <Spacer w="16px" flexGrow="0" />

            <Button
              flex="1"
              testID="order-card-submit"
              onPress={submitOrderCard}
              disabled={!chosenAddressState || isLoading || isSuccess}
              isLoading={isLoading}
            >
              Order new card
            </Button>
          </Row>
        </Column>

        <Box flex="1" alignItems="center" justifyContent="center">
          <Image
            mt="32px"
            width={{ base: "250px", sm: "250px", md: "250px", xl: "315px" }}
            height={{ base: "325px", sm: "325px", md: "325px", xl: "410px" }}
            src={heartImage}
            alt="heartImage"
          />
        </Box>
      </Flex>
    ),
    SuccessPage: (
      <Box
        minH="50vh"
        display="flex"
        flexDir={{ base: "column", xl: "row" }}
        justifyContent="space-between"
        alignItems={{ base: "center", lg: "flex-start" }}
      >
        <Box testID="success-modal">
          <Heading size="xl" isTruncated>
            Thank you!
          </Heading>
          <Text mt="32px" mb="16px">
            {"Your new card is on its way to "}
            {chosenAddressState === "commercial"
              ? commercialAddress?.addressX
              : residentialAddress?.addressX}
          </Text>
          <Text>{text.successText}</Text>

          <Button
            testID="close-button"
            w="150px"
            mt="48px"
            onPress={() => navigate(dashboardRoute)}
          >
            Close
          </Button>
        </Box>

        <Flex alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }}>
          <Image
            width={{ base: "200px", md: "286px", xl: "400px" }}
            height={{ base: "88px", md: "124px", xl: "176px" }}
            src={ribbonSmileImage}
            alt="ribbonSmile"
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box testID="order-card">
      <PageHeading  showBackBtn size="xl" testID="help-centre-header">
        {text.title}
      </PageHeading>

      <BoxCard>{page[showPage]}</BoxCard>
    </Box>
  )
}
