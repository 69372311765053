import "index.css"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { NativeBaseProvider, extendTheme as nbExtendTheme } from "native-base"
import React from "react"
import "react-native"
import { CookiesProvider } from "react-cookie"
import { render } from "react-dom"
import TagManager from "react-gtm-module"
import { Provider } from "react-redux"

import App from "components/App"
import LogglyProvider from "components/providers/Loggly/provider"
import {
  REACT_APP_USE_MOCK,
  REACT_HIDE_INJECTION_WARNING,
  GTM
} from "config/environment"
import Fonts from "font"
import nbthemeOptions from "newTheme"
import { store } from "store"
import themeOptions from "theme"

import { worker } from "./mocks/browser"

if (REACT_APP_USE_MOCK) {
  worker.start()
}

const breakpoints = createBreakpoints({
  sm: "480px", // 480px
  md: "768px", // 768px
  lg: "992px", // 992px
  xl: "1280px", // 1280px
  "2xl": "1536px" // 1536px
})

const nBreakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280
}

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  ...themeOptions,
  breakpoints,
  fonts: {
    heading: "CircularStdBlack",
    body: "CircularStdBook"
  }
})

const nbTheme = nbExtendTheme({
  ...nbthemeOptions,
  nBreakpoints,
  fonts: {
    heading: "CircularStdMedium",
    body: "CircularStdBook"
  }
})

const logglyOptions = {
  key: true,
  session_id: "axle-session-id",
  useDomainProxy: false,
  inputUrl: "",
  sendConsoleErrors: true
}

// Add warning to mitigate against JavaScript injection attacks
if (REACT_HIDE_INJECTION_WARNING) {
  const logLines = [
    "Do not paste anything that you do not fully trust into this window.",
    "Scammers might get you to run code that can steal your sensitive information.",
    "Cape will never ask you to enter anything in this window."
  ]
  console.log(
    "%c" + "WARNING",
    "color:" + theme.colors.red[300] + ";font-size:5rem;font-family:sans-serif;"
  )
  logLines.forEach((log) =>
    console.log(
      "%c" + log,
      "color:" +
      theme.colors.gray[400] +
      ";font-size:1.5rem;font-family:sans-serif;"
    )
  )
}

const tagManagerArgs = {
  gtmId: GTM
}

TagManager.initialize(tagManagerArgs)

render(
  <LogglyProvider options={logglyOptions} providers={{}}>
    <CookiesProvider>
      <Provider store={store}>
        <NativeBaseProvider theme={nbTheme}>
          <ChakraProvider theme={theme}>
            <>
              <Fonts />
              <App />
            </>
          </ChakraProvider>
        </NativeBaseProvider>
      </Provider>
    </CookiesProvider>
  </LogglyProvider>,
  document.getElementById("root")
)
