import { CardStatus } from "./enums/card-status"

export interface EntityCardDto {
  id: number
  entityId: number
  code: string
  cardId: number
  externalReference: string
  createdAt: string
  cardDetails: string
}

export interface CardDto {
  id: number
  status: CardStatus
  name: string
  truncatedPan: string
  expiryDate: string
  token?: string
}

// Converts a CardDto into a summary - helper for transition to summary endpoints
export const cardFromLegacyCard = (card: CardDto): CardDto => {
  const { id, status, name, truncatedPan, expiryDate, token } = card
  return {
    id,
    status: status ?? CardStatus.Failed,
    name,
    truncatedPan,
    expiryDate,
    token: token ?? ""
  }
}
