import { reducer as authApiReducer } from "@axle/web/src/network/auth"
import { reducer as cardApiReducer } from "@axle/web/src/network/card"
import { reducer as connectionsApiReducer } from "@axle/web/src/network/connections"
import { reducer as entityApiReducer } from "@axle/web/src/network/entity"
// import { reducer as logglyApiReducer } from "network/loggly"
import { reducer as limitsApiReducer } from "@axle/web/src/network/limits"
import { reducer as notificationApiReducer } from "@axle/web/src/network/notification"
import { reducer as notificationsApiReducer } from "@axle/web/src/network/notifications"
import { reducer as oauthApiReducer } from "@axle/web/src/network/oauth"
import { reducer as personApiReducer } from "@axle/web/src/network/person"
import { reducer as receiptsApiReducer } from "@axle/web/src/network/receipts"
import { reducer as signUpApiReducer } from "@axle/web/src/network/signUp"
import { reducer as spendControlsApiReducer } from "@axle/web/src/network/spendControls"
import { reducer as statementApiReducer } from "@axle/web/src/network/statement"
import { reducer as transactionsApiReducer } from "@axle/web/src/network/transactions"
import { reducer as twoFAAPIReducer } from "@axle/web/src/network/twoFA"
import { reducer as verificationApiReducer } from "@axle/web/src/network/verification"
import authSlice from "@axle/web/src/store/slices/auth"
import cardsSlice from "@axle/web/src/store/slices/cards"
import currentCardSlice from "@axle/web/src/store/slices/currentCard"
import currentCardIndexSlice from "@axle/web/src/store/slices/currentCardIndex"
import entitySlice from "@axle/web/src/store/slices/entity"
import jwtTokenValidReducer from "@axle/web/src/store/slices/jwtTokenValid"
import personSlice from "@axle/web/src/store/slices/person"
import spendControlsSlice from "@axle/web/src/store/slices/spendControls"
import transactionsSlice from "@axle/web/src/store/slices/transactions"
import unreadNotificationCountSlice from "@axle/web/src/store/slices/unreadNotificationCount"
import { Reducer, AnyAction, combineReducers } from "redux"

interface RootState {
  auth: ReturnType<typeof authSlice>
  person: ReturnType<typeof personSlice>
  entity: ReturnType<typeof entitySlice>
  currentCard: ReturnType<typeof currentCardSlice>
  currentCardIndex: ReturnType<typeof currentCardIndexSlice>
  cards: ReturnType<typeof cardsSlice>
  spendControls: ReturnType<typeof spendControlsSlice>
  transactions: ReturnType<typeof transactionsSlice>
  unreadNotiCount: ReturnType<typeof unreadNotificationCountSlice>
  authApi: ReturnType<typeof authApiReducer>
  oauthApi: ReturnType<typeof oauthApiReducer>
  receiptsApi: ReturnType<typeof receiptsApiReducer>
  signUpApi: ReturnType<typeof signUpApiReducer>
  spendControlsApi: ReturnType<typeof spendControlsApiReducer>
  statementApi: ReturnType<typeof statementApiReducer>
  personApi: ReturnType<typeof personApiReducer>
  cardApi: ReturnType<typeof cardApiReducer>
  connectionsApi: ReturnType<typeof connectionsApiReducer>
  transactionsApi: ReturnType<typeof transactionsApiReducer>
  twoFAApi: ReturnType<typeof twoFAAPIReducer>
  verificationApi: ReturnType<typeof verificationApiReducer>
  notificationsApi: ReturnType<typeof notificationsApiReducer>
  notificationApi: ReturnType<typeof notificationApiReducer>
  limitsApi: ReturnType<typeof limitsApiReducer>
  // logglyApi: ReturnType<typeof logglyApiReducer>
  entityApi: ReturnType<typeof entityApiReducer>
  jwtTokenValid: ReturnType<typeof jwtTokenValidReducer>
}

const rootReducer = combineReducers<RootState>({
  auth: authSlice,
  person: personSlice,
  entity: entitySlice,
  currentCard: currentCardSlice,
  currentCardIndex: currentCardIndexSlice,
  cards: cardsSlice,
  spendControls: spendControlsSlice,
  transactions: transactionsSlice,
  unreadNotiCount: unreadNotificationCountSlice,
  authApi: authApiReducer,
  oauthApi: oauthApiReducer,
  receiptsApi: receiptsApiReducer,
  signUpApi: signUpApiReducer,
  spendControlsApi: spendControlsApiReducer,
  statementApi: statementApiReducer,
  personApi: personApiReducer,
  cardApi: cardApiReducer,
  connectionsApi: connectionsApiReducer,
  transactionsApi: transactionsApiReducer,
  twoFAApi: twoFAAPIReducer,
  verificationApi: verificationApiReducer,
  notificationsApi: notificationsApiReducer,
  notificationApi: notificationApiReducer,
  limitsApi: limitsApiReducer,
  // logglyApi: logglyApiReducer,
  entityApi: entityApiReducer,
  jwtTokenValid: jwtTokenValidReducer
})

const resettableRootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "reset") {
    state = {} as RootState
  }
  return rootReducer(state, action)
}

export type AppState = ReturnType<typeof resettableRootReducer>

export default resettableRootReducer
