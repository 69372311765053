import { useToast } from "@chakra-ui/react"
import * as React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { useLoginUser } from "components/hooks/useLoginUser"
import { dashboard as dashboardRoute } from "components/layouts/Authorized/routes"
import { twoFA } from "components/layouts/Public/routes"
import { FF__TWO_FA } from "config/environment"
import { ErrorCodes } from "models/enums/error-codes"
import { useLoginMutation, useStartLoginMutation } from "network/auth"

import type { LoginRequest } from "network/auth"


export interface useLogin {
  username: string | null
  guid: string | null
  wizard: string
  forgotPasswordFlow: boolean
  setForgotPasswordFlow: React.Dispatch<React.SetStateAction<boolean>>
  handleChange: (value: string, name: string) => void
  startClickHandler: () => Promise<void>
  passwordClickHandler: () => Promise<void>
  isLoading: boolean
  startIsLoading: boolean
}

export const useLogin = (): useLogin => {
  const navigate = useNavigate()
  const { loginUser } = useLoginUser()
  const userAgent = window.navigator.userAgent
  const twoFAEnabled = FF__TWO_FA
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get("redirect")

  const [formState, setFormState] = React.useState<LoginRequest>({
    username: "",
    password: "",
    userAgent: userAgent
  })
  const [wizard, setWizard] = React.useState<string>("start")
  const [forgotPasswordFlow, setForgotPasswordFlow] =
    React.useState<boolean>(false)
  const [guid, setGuid] = React.useState<string | null>(null)
  const [username, setUsername] = React.useState<string | null>(null)

  const [startLogin, { isLoading: startIsLoading }] = useStartLoginMutation()
  const [login, { isLoading }] = useLoginMutation()

  const toast = useToast()
  React.useEffect(() => {
    return () => {
      setWizard("start")
      setGuid(null)
      setUsername(null)
      setForgotPasswordFlow(false)
      setFormState({
        username: "",
        password: "",
        userAgent: ""
      })
    }
  }, [])

  const handleChange = (value: string, name: string) => {
    setFormState((prev) => ({ ...prev, [name]: value }))
  }

  const startClickHandler = async () => {
    if (formState.username === "") return

    const { data } = await startLogin(formState.username).unwrap()
    setGuid(data.guid)
    setUsername(data.username)

    if (data.provider === "Cape") {
      setWizard("password")
    } else {
      setWizard("oauth")
    }
  }

  const passwordClickHandler = async () => {
    const response = await login(formState).unwrap()

    if (response.token) {
      const jwtFromBackend = response.token
      loginUser(jwtFromBackend)

      toast.closeAll()
      redirect
        ? navigate(`${dashboardRoute}/${redirect}`)
        : navigate(dashboardRoute)
    }

    if (
      response.code === ErrorCodes.TwoFactorRequested &&
      response.data &&
      twoFAEnabled
    ) {
      const { email, mobile, communicationType } = response.data

      navigate(twoFA, {
        state: {
          username: formState.username,
          emailTruncated: email,
          phoneTruncated: mobile,
          communicationType: communicationType
        }
      })
    }
  }

  return {
    username,
    guid,
    wizard,
    forgotPasswordFlow,
    setForgotPasswordFlow,
    handleChange,
    startClickHandler,
    passwordClickHandler,
    isLoading,
    startIsLoading
  }
}

export const text = {
  heading: "Hello. Sign in to access Cape",
  ForgotPasswordLink: "Forgot Password?",
  nextBtn: "Next",
  signInBtn: "Sign in",
  noAccContext: "Don't have an account?  ",
  signUpHere: "Sign up here"
}
