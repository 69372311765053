import { useNavigate } from "react-router-dom"

import { twoFAConfirmation } from "components/layouts/Authorized/routes"
import { munger } from "components/util/munge"
import PersonDto from "models/person"
import { usePersonSummaryMutation } from "network/person"
import { useSetTwoFAMutation } from "network/twoFA"
import { useRequestVerificationMutation } from "network/verification"
import { useAppDispatch } from "store/hooks"
import { setPerson } from "store/slices/person"

export interface useTwoFA {
  lastFourPhoneNumber: string,
  mungedEmail: string,
  isLoading: boolean,
  handleSubmit: (type: number) => Promise<void>
  isLoadingVerification: boolean
}

export const useEnableTwoFA = (person: PersonDto) => {
  const [setTwoFA, { isLoading }] = useSetTwoFAMutation()
  const [getPersonSummary] = usePersonSummaryMutation()
  const [requestVerification, { isLoading: isLoadingVerification }] = useRequestVerificationMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const abreviatedPhoneNumber = person.mobile.slice(person.mobile.length - 4)
  const mungedEmail = munger(person.email)

  const handleSubmit = async (type: number) => {
    const response = await setTwoFA(type).unwrap()
    const emailEncoded = encodeURIComponent(person.email)
    const personSummaryResponse = await getPersonSummary().unwrap()

    personSummaryResponse.success && dispatch(setPerson(personSummaryResponse.data))

    response.success && await requestVerification({ username: emailEncoded, communicationType: type }).unwrap()
    response.success && navigate(twoFAConfirmation)
  }

  return {
    lastFourPhoneNumber: abreviatedPhoneNumber,
    mungedEmail,
    handleSubmit,
    isLoading,
    isLoadingVerification
  }
}
