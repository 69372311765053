import { Box, Column, Heading, Row, Spacer, Text } from "native-base"

import { ReactComponent as ApproveIcon } from "assets/svgs/icons/transaction-status-approve.svg"
import { ReactComponent as DeclineIcon } from "assets/svgs/icons/transaction-status-decline.svg"
import { ReactComponent as PendingIcon } from "assets/svgs/icons/transaction-status-pending.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { Category } from "models/spendControls"
import { useExpensePolicies } from "store/hooks/spendControls"

interface OwnerAuthority {
  owner: string
  category: string[]
}

const Policy = () => {
  const { expensePolicies } = useExpensePolicies()

  // const [expensePolicieslsLoading, setExpensePolicieslsLoading] =
  //   useState<boolean>(false)

  // const dispatch = useAppDispatch()

  // const loadExpensePolicies = async () => {
  //   setExpensePolicieslsLoading(true)
  //   const getPolicyResults = await getExpensePolicies().unwrap()
  //   dispatch(setExpensePolicies(getPolicyResults.data))
  // }

  // useEffect(() => {
  //   !expensePolicieslsLoading && loadExpensePolicies()
  // }, [isLoading, isSuccess, expensePolicies])

  let approvedCategories = [] as Category[]
  let blockedCategories = [] as Category[]
  const ownerAuthority = [] as OwnerAuthority[]

  if (expensePolicies) {
    expensePolicies.categories.items.map((entityCategory) => {
      entityCategory.categories.items.map((category) => {
        category.allow
          ? (approvedCategories = [...approvedCategories, category])
          : (blockedCategories = [...blockedCategories, category])

        category.authorisedBudgetOwners.items.map(({ name }) => {
          let exist = false

          ownerAuthority.map(({ owner }, index) => {
            if (owner === name) {
              ownerAuthority[index].category.push(category.name)
              exist = true
            }
          })

          if (!exist) {
            ownerAuthority.push({ owner: name, category: [category.name] })
          }
        })
      })
    })
  }

  return (
    <BoxCard
      h={{ base: "unset", md: "100%" }}
      testID="policy-builder"
      maxH={{ base: "unset", md: "60vh" }}
      overflowY="scroll"
      pb="10rem"
    >
      <Column>
        <Heading size="sm">Expense Policy</Heading>
        <Spacer h="1rem" />

        <Box>
          <Text>
            {
              "As you set your company spend rules, we’ll build your expense policy here."
            }
          </Text>
          <Spacer h="1rem" />
          <Text>
            {
              "This policy will be shared with your employees so they know what can and can’t be paid for on their Cape card."
            }
          </Text>
        </Box>

        <Spacer h="1rem" />

        {expensePolicies && (
          <Box>
            <Text mb="8px">{"1. Approved spend categories:"}</Text>

            {approvedCategories.map((item, index) => (
              <Row key={`approve-cat-${index}`} alignItems="center" mb="8px">
                <ApproveIcon transform="scale(0.6)" />
                <Text> {item.name}</Text>
              </Row>
            ))}

            <Spacer h="1rem" />

            <Text mb="8px">{"2. Out of policy spend categories"}</Text>
            {blockedCategories.map((item, index) => (
              <Row key={`blocked-cat-${index}`} alignItems="center" mb="8px">
                <DeclineIcon transform="scale(0.6)" />
                <Text> {item.name}</Text>
              </Row>
            ))}

            <Spacer h="1rem" />

            <Text mb="8px">
              {"3. Budget owners with authority to edit spend controls:"}
            </Text>

            {ownerAuthority.map(({ owner, category }, index) => {
              return (
                <Box key={`owner-${index}`} mb="8px">
                  <Row alignItems="center">
                    <PendingIcon transform="scale(0.6)" />
                    <Text>{owner}</Text>
                  </Row>

                  <Row flexWrap="wrap">
                    <Text ml="1.5rem">{"Can Edit:"}</Text>
                    {category.map((categoryName, catIndex) => (
                      <Text ml="1.5rem" key={`catIndex-${catIndex}`}>
                        {`${categoryName}`}
                      </Text>
                    ))}
                  </Row>
                </Box>
              )
            })}
          </Box>
        )}
      </Column>
    </BoxCard>
  )
}

export default Policy
