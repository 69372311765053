import { useContext, useState } from "react"
import {
  useForm,
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  FieldError
} from "react-hook-form"

import { currentCardContext } from "components/layouts/Authorized/MaybeRender"
import { useRetrievePinMutation } from "network/card"

interface FormValues {
  token: string
}

interface UseRetrievePin {
  isSuccess: boolean
  isLoading: boolean
  handleRetrievePin: SubmitHandler<FormValues>
  handleSubmit: UseFormHandleSubmit<FormValues>
  control: Control<FormValues>
  pin: string | null
  errors: Record<string, FieldError>
  page: "PinForm" | "SuccessPage"
}

const useRetrievePin = (): UseRetrievePin => {
  const [pin, setPin] = useState<string | null>(null)
  const [retrievePin, { isLoading, isSuccess }] = useRetrievePinMutation()

  const { card: currentCard } = useContext(currentCardContext)

  const [page, setPage] = useState<"PinForm" | "SuccessPage">("PinForm")

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      token: ""
    }
  })

  const handleRetrievePin: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault()

    const cardId = currentCard.id

    const retrievePinSuccess = await retrievePin({
      token: data.token,
      cardId
    }).unwrap()

    retrievePinSuccess.success &&
      (setPin(retrievePinSuccess.data), setPage("SuccessPage"))
  }

  return {
    isSuccess,
    isLoading,
    handleRetrievePin,
    handleSubmit,
    control,
    pin,
    errors,
    page
  }
}

export const text = {
  pinText:
    "For security purposes never tell anybody, or let anybody see your PIN.",
  retrivePinText:
    "Simply fill in your card token number (this can be found above the magnetic strip on the back of your card) and we'll send you a reminder:",
  pinErrorText:
    "Token is required and must be 9 digit characters or numbers. It is found on the back of your card."
}

export default useRetrievePin
