import { Text, Row, useBreakpointValue, useToken } from "native-base"

import { ReactComponent as ApproveIcon } from "assets/svgs/icons/transaction-status-approve.svg"
import { ReactComponent as DeclineIcon } from "assets/svgs/icons/transaction-status-decline.svg"
import { ReactComponent as PaymentIcon } from "assets/svgs/icons/transaction-status-payment.svg"
import { ReactComponent as PendingIcon } from "assets/svgs/icons/transaction-status-pending.svg"
import { formatPrice } from "components/util/format-price"
import TransactionDto from "models/transactions"

interface useTableRow {
  isPayment: boolean
  amountSign: string
  status: JSX.Element
  isDifferentCurrency: boolean
  cardNumber: string | undefined
  avaliableCredit: string
  balance: string
  conversionRate: string | 0 | undefined
  exchageRate: string | 0 | undefined
  crossAmount: boolean
  transactionAmount: string
  displayBalance: boolean
}

enum StatusEnum {
  Payment = "Payment",
  Pending = "Pending",
  Declined = "Declined",
  Approved = "Approved",
  Reversed = "Reversed"
}

const statusComponent = (status: string | undefined) => {
  const [aproveColor] = useToken("colors", ["primary.600"])

  const iconScale = useBreakpointValue({ base: "scale(0.5)", lg: "scale(0.8)" })

  const statusArray = [
    {
      status: StatusEnum.Approved,
      icon: <ApproveIcon transform={iconScale} fill={aproveColor} />
    },
    {
      status: StatusEnum.Pending,
      icon: <PendingIcon transform={iconScale} />
    },
    {
      status: StatusEnum.Declined,
      icon: <DeclineIcon transform={iconScale} />
    },
    {
      status: StatusEnum.Payment,
      icon: <PaymentIcon transform={iconScale} />
    },
    {
      status: StatusEnum.Reversed,
      icon: <DeclineIcon transform={iconScale} />
    }
  ]

  const icon = statusArray.find((cur) => cur.status === status)?.icon

  return (
    <Row justifyContent="end" alignItems="center">
      <Text
        mr="0.5rem"
        display={{ base: "none", sm: "block" }}
        fontSize={{ base: "xs", lg: "md" }}
      >
        {status}
      </Text>
      {icon}
    </Row>
  )
}

const getPrice = (number: number | undefined): string => {
  const { currency } =
    number !== undefined ? formatPrice(number) : { currency: "" }
  return currency
}

const useTableRow = (transaction: TransactionDto): useTableRow => {
  const isDifferentCurrency =
    transaction.transactionCurrency !== "AUD" &&
    transaction.transactionCurrency !== null
  const amountSign = transaction.direction ?? "-"
  const crossAmount =
    transaction.displayStatus === StatusEnum.Declined ||
    transaction.displayStatus === StatusEnum.Reversed
      ? true
      : false
  const isPayment = transaction.displayStatus === StatusEnum.Payment
  const status = statusComponent(transaction.displayStatus)
  const cardNumber = transaction.cardDetails?.slice(0, 4)
  const avaliableCredit = getPrice(transaction.available)
  const balance = getPrice(transaction.balance)
  const transactionAmount = getPrice(transaction.amount)
  const conversionRate = getPrice(transaction.conversionRate)
  const exchageRate = getPrice(transaction.amountLocalCurrency)
  const displayBalance = transaction.displayStatus !== StatusEnum.Pending
  return {
    isPayment,
    amountSign,
    status,
    isDifferentCurrency,
    cardNumber,
    avaliableCredit,
    balance,
    conversionRate,
    exchageRate,
    crossAmount,
    transactionAmount,
    displayBalance
  }
}

export default useTableRow
