import { DateTime } from "luxon"
import * as React from "react"
import {
  FieldError,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  Control
} from "react-hook-form"
import { useParams } from "react-router-dom"

import { useSetPasswordMutation } from "network/auth"

export interface PasswordInputProps {
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface PasswordResetInputs {
  newPassword: string
  confirmPassword: string
  dateOfBirth: string
}

export interface useResetPassword {
  handleClick: (data: PasswordResetInputs) => Promise<void>
  isSuccess: boolean
  register: UseFormRegister<PasswordResetInputs>
  getValues: UseFormGetValues<PasswordResetInputs>
  submitResetPassword: (
    e?: React.BaseSyntheticEvent | undefined
  ) => Promise<void>
  errors: Record<string, FieldError>
  isDirty: boolean
  isValid: boolean
  isSubmitting: boolean
  control: Control<PasswordResetInputs>
}

type Params = {
  guid: string
}

export const useResetPassword = (): useResetPassword => {
  const { guid } = useParams<keyof Params>() as Params
  const [setPassword, { isSuccess }] = useSetPasswordMutation()

  const {
    handleSubmit,
    register,
    getValues,
    reset,
    control,
    formState: { errors, isSubmitting, isDirty, isValid }
  } = useForm<PasswordResetInputs>({
    mode: "onSubmit",
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
      dateOfBirth: ""
    }
  })

  const handleClick = async (data: PasswordResetInputs) => {
    const { newPassword, dateOfBirth } = data
    const dob = DateTime.fromFormat(dateOfBirth, "dd/MM/yyyy").toISODate()

    await setPassword({
      Guid: guid,
      Password: newPassword,
      DateOfBirth: dob
    }).unwrap()
    reset()
  }

  const submitResetPassword = handleSubmit(handleClick)

  return {
    submitResetPassword,
    isSuccess,
    handleClick,
    register,
    getValues,
    errors,
    isSubmitting,
    isDirty,
    isValid,
    control
  }
}
