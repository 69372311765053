import { DateTime } from "luxon"

export const getCurrentDate = (): string =>
  new Date().toISOString().split("T")[0]

// export const getTomorrow = (): string => {
//   const today = new Date()
//   const tomorrow = new Date(today)
//   tomorrow.setDate(tomorrow.getDate() + 1)
//   return tomorrow.toISOString().split("T")[0]
// }

export const getNumericDate = (date: string): string =>
  DateTime.fromISO(date).toFormat("d LLL yyyy")

export const getNumericNextDate = (date: string): string =>
  DateTime.fromISO(date).plus({ days: 1 }).toFormat("d LLL yyyy")

export const getSlashNumericDate = (date: string): string =>
  DateTime.fromISO(date).toFormat("D")

export const getISODate = (date: string): string =>
  DateTime.fromFormat(date, "dd/MM/yyyy").toISODate()

export const getISODateWithTStamp = (date: string): string =>
  DateTime.fromFormat(date, "dd/MM/yyyy").toISO()

export const getISOLastMinuteDateWithTStamp = (date: string): string =>
  DateTime.fromFormat(date, "dd/MM/yyyy").plus({ day: 1 }).minus({ millisecond: 1 }).toISO()

export const getDateFromISO = (date: string) =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)

export const getTimeFromISO = (date: string) =>
  DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)
