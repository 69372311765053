import { Platform } from "@axle/web/src/models/platform"
import prepareHeaders from "@axle/web/src/network/util/prepare-headers"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { EXPENSE_API_BASE } from "./apiNamespaces"
import ApiResponse, { ApiArrayData } from "./util/api-response"

interface RedirectUrlData { redirectUrl: string }

export interface XeroAccount {
  name: string
  externalRef: string
}

type accountName = string
type platformName = string

export interface XeroSelectAccount {
  externalRef: string
}

export const connectionsApi = createApi({
  reducerPath: "connectionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: EXPENSE_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    platforms: builder.mutation<
      ApiResponse<ApiArrayData<Platform>>,
      void
    >({
      query: () => ({
        url: "platforms",
        method: "GET"
      })
    }),
    generateXeroUrl: builder.mutation<ApiResponse<RedirectUrlData>, void>({
      query: (body) => ({
        url: "platform/xero/connect",
        method: "GET",
        body
      })
    }),
    disconnectXero: builder.mutation<void, platformName>({
      query: (body) => ({
        url: "platform/disconnect",
        method: "POST",
        body // eg "xero" lowercase
      })
    }),
    fetchXeroAccounts: builder.mutation<ApiResponse<ApiArrayData<XeroAccount>>, void>({
      query: () => ({
        url: "platform/xero/accounts",
        method: "GET"
      })
    }),
    postXeroAccountSelect: builder.mutation<void, XeroSelectAccount>({
      query: (body) => ({
        url: "platform/xero/account",
        method: "POST",
        body
      })
    }),
    postXeroAccountCreate: builder.mutation<void, accountName>({
      query: (body) => ({
        url: "platform/xero/account",
        method: "PUT",
        body
      })
    }),
    postXeroSync: builder.mutation<void, void>({
      query: () => ({
        url: "platform/xero/sync",
        method: "POST"
      })
    })
  })
})

export const {
  usePlatformsMutation,
  useDisconnectXeroMutation,
  useGenerateXeroUrlMutation,
  useFetchXeroAccountsMutation,
  usePostXeroAccountSelectMutation,
  usePostXeroAccountCreateMutation,
  usePostXeroSyncMutation,
  reducer,
  middleware
} = connectionsApi
