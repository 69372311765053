export interface XeroConnectionStatusDto {
  id: number
  name: XeroConnectionStatus
}

export enum XeroConnectionStatus {
  AwaitingAccountSelection = "AwaitingAccountSelection",
  AwaitingAuthorisation = "AwaitingAuthorisation",
  Connected = "Connected",
  Disconnected = "Disconnected",
  InitialConnectionError = "InitialConnectionError",
  NotConnected = "NotConnected",
  PermissionGranted = "PermissionGranted"
}
