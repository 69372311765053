import { ReactComponent as LogoutIcon } from "@axle/web/src/assets/svgs/icons/logout.svg"
import { ReactComponent as MycardIcon } from "@axle/web/src/assets/svgs/icons/my-card.svg"
import { ReactComponent as PolicyIcon } from "@axle/web/src/assets/svgs/icons/policyIcon.svg"
import { ReactComponent as ProfileIcon } from "@axle/web/src/assets/svgs/icons/profile.svg"
import { useLogoutUser } from "@axle/web/src/components/hooks/useLogoutUser"
import { profile as profileRoute, myCards as myCardRoute } from "@axle/web/src/components/layouts/Authorized/routes"
import NotificationLink from "@axle/web/src/components/pages/Notifications/NotificationLink"
import { usePerson } from "@axle/web/src/store/hooks/person"
import {
  Row,
  ChevronLeftIcon,
  Heading,
  IHeadingProps,
  Pressable,
  Menu,
  Button,
  Spacer,
  ChevronDownIcon,
  Text,
  useToken,
  Box
} from "native-base"
import { useNavigate } from "react-router-dom"

interface IPageHeadingProps extends IHeadingProps {
  showBackBtn?: boolean
}

const PageHeading = ({ children, showBackBtn, ...props }: IPageHeadingProps): JSX.Element => {
  const { person } = usePerson()
  const navigate = useNavigate()
  const [iconColor] = useToken("colors", ["warmGray.600"])
  const { logoutUser } = useLogoutUser()

  const iconSize = {
    width: "16px",
    height: "16px"
  }

  return (
    <Row
      testID="page-heading"
      flexWrap="wrap-reverse"
      alignItems={{ base: "start", lg: "center" }}
      mb={{ base: 1, md: 6 }}
      mt={{ base: 5, md: 0 }}
    >
      <Row alignItems="center" mb={4} minW={{ base: 0, md: 460 }} flex="1">
        <Box minW={{ base: 5, md: 12 }}>
          {showBackBtn && (
            <Pressable testID="page-head-back-button" onPress={() => navigate(-1)}>
              <ChevronLeftIcon size="lg" color="warmGray.600" />
            </Pressable>
          )}
        </Box>
        <Heading
          flex="1"
          textAlign={{ base: "center", md: "start" }}
          fontSize={{ base: "38px", md: "48px" }}
          fontFamily="GTPressuraPro"
          numberOfLines={1}
          {...props}
        >
          {children}
        </Heading>
      </Row>

      <Row ml="auto" mb={4} display={{ base: "none", md: "flex" }}>
        <NotificationLink />
        <Spacer w={4} />
        <Menu
          w={260}
          trigger={(triggerProps) => {
            return (
              <Button w={260} testID="user-menu" {...triggerProps}>
                <Row alignItems="center">
                  <Text color="tertiary.50" numberOfLines={1} w={180}>
                    {person?.fullName}
                  </Text>

                  <Spacer w={8} />
                  <ChevronDownIcon ml="auto" color="tertiary.50" size="xs" />
                </Row>
              </Button>
            )
          }}
        >
          <Menu.Item p={4}>
            <Row alignItems="center" justifyContent="space-between">
              Company Policies
              <PolicyIcon {...iconSize} fill={iconColor} />
            </Row>
          </Menu.Item>
          <Menu.Item testID="menu-mycard-link" p={4} onPress={() => navigate(myCardRoute)}>
            <Row alignItems="center" justifyContent="space-between">
              Your Cards
              <MycardIcon {...iconSize} fill={iconColor} />
            </Row>
          </Menu.Item>

          <Menu.Item testID="menu-profile-link" p={4} onPress={() => navigate(profileRoute)}>
            <Row alignItems="center" justifyContent="space-between">
              Your Profile
              <ProfileIcon {...iconSize} fill={iconColor} />
            </Row>
          </Menu.Item>

          <Menu.Item testID="menu-sign-out" p={4} onPress={() => logoutUser()}>
            <Row alignItems="center" justifyContent="space-between">
              Log Out
              <LogoutIcon {...iconSize} fill={iconColor} />
            </Row>
          </Menu.Item>
        </Menu>
      </Row>
    </Row>
  )
}

export default PageHeading
