// https://learn.microsoft.com/en-us/javascript/api/@microsoft/signalr/messagetype?view=signalr-js-latest
//

// import * as signalR from "@microsoft/signalr"

import {
  HubConnectionBuilder,
  HubConnection,
  HttpTransportType,
  LogLevel
} from "@microsoft/signalr"
import { CookieStorage } from "cookie-storage"
import { ReactNode, createContext, useEffect, useState, useContext } from "react"

import { eventsEndpoint } from "config/endpoints"
import Notification from "models/notification"

declare global {
  interface Window {
    signalrMock: HubConnection
  }
}

interface Props {
  children: ReactNode
}

interface Props {
  children: ReactNode
}

type SignlRContextProps = {
  notification?: Partial<Notification> | null
  messageCount: number
}

const context = createContext<SignlRContextProps>({} as SignlRContextProps)
export const SignlRProvider = context.Provider

export default function EventsProvider({ children }: Props): JSX.Element {
  const cookieStorage = new CookieStorage()
  const jwt = cookieStorage.getItem("jwt") || ""
  const username = cookieStorage.getItem("username") || ""
  const [notification, setNotification] =
    useState<Partial<Notification> | null>()
  const [messageCount, setMessageCount] = useState<number>(0)
  const [connection, setConnection] = useState<HubConnection | null>(null)


  useEffect(() => {
    const newConnection = window.signalrMock || new HubConnectionBuilder()
      .withUrl(eventsEndpoint, {
        accessTokenFactory: () => jwt,
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build()
    setConnection(newConnection)
  }, [])

  useEffect(() => {
    connection &&
      connection.start().then(() => {
        let ticker = 0
        connection.on("ReceiveEvent", (event) => {
          if (event.data.username === username) {
            setNotification(event.data)
            ticker++
            setMessageCount(ticker)
          }
        })
      })
  }, [connection])

  return (
    <SignlRProvider value={{ notification, messageCount }}>
      {children}
    </SignlRProvider>
  )
}

export function receiveSignalrEvents(callback: (data: Partial<Notification>) => void) {
  const events = useContext(context)

  useEffect(() => {
    events.notification && callback(events.notification)
  }, [events.messageCount])
}
