import EntityUsageDto from "@axle/web/src/models/entity"
import prepareHeaders from "@axle/web/src/network/util/prepare-headers"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { LIMITS_API_BASE } from "./apiNamespaces"
import ApiResponse from "./util/api-response"

export interface SetLimitsRequest {
  amount: number
  ledgerId: number
  ledgerAccountName: string
  ledgerDetails: string
  notes: string
  userName: string
}

export const limits = createApi({
  reducerPath: "limitsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: LIMITS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    setLimits: builder.mutation<
      ApiResponse<EntityUsageDto>,
      SetLimitsRequest[]
    >({
      query: (limits: SetLimitsRequest[]) => ({
        url: "",
        method: "PUT",
        body: limits
      })
    })
  })
})

export const { useSetLimitsMutation, reducer, middleware } = limits
