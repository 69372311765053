import {
  Flex,
  FormControl,
  Heading,
  Box,
  Link,
  Input,
  Button,
  Text,
  Select,
  Column,
  Row,
  Spacer
} from "native-base"
import { Controller } from "react-hook-form"

import { ReactComponent as PencilEdit } from "assets/svgs/icons/pencil-edit.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { ReviewableField } from "components/inline/ReviewableField"
import { ProfileHeader } from "components/pages/Profile/ProfileHeader"
import { countryData } from "components/util/country-data"
import {
  isPostCode,
  isEmail,
  isTelephone
} from "components/util/form-validators"
import { FF__UPDATE_PROFILE } from "config/environment"
import { EntityDto } from "models/entity"
import Person from "models/person"

import AddressFormInput from "./AddressFormInput"
import ContactDetailFormInput from "./ContactDetailFormInput"
import { useProfile } from "./talons/useProfile"

const profileUpdateableEnabled = FF__UPDATE_PROFILE

interface ProfileProps {
  profileUpdateable?: boolean
  person: Person
  entity: EntityDto
}

interface CopyTextProps {
  isCompanyDetails: boolean
}

const CopyText = ({ isCompanyDetails }: CopyTextProps) => (
  <Text m="1rem 0 3.5rem">
    {`For any changes required to your${
      isCompanyDetails ? " company" : ""
    } details, please contact us at `}
    <Link
      fontSize="md"
      textDecoration="underline"
      href="mail: support@getcape.io"
    >
      support@getcape.io
    </Link>
    {" or on 1300 062 273"}
  </Text>
)

export const Profile = ({
  person,
  entity,
  profileUpdateable = profileUpdateableEnabled
}: ProfileProps): JSX.Element => {
  const {
    personalDetails: { fullName, dateOfBirth },
    updateHandler,
    handleSubmit,
    handleSubmitContactDetails,
    contactDetailsUpdateHandler,
    reset,
    resetContactDetails,
    getContactDetails,
    legalName,
    tradingName,
    businessAddress,
    isAddressDisabled,
    setIsAddressDisabled,
    isContactDetailsDisabled,
    setIsContactDetailsDisabled,
    isSubmitting,
    isSubmittingContactDetails,
    errors,
    errorsContactDetails,
    control,
    controlContactDetails
  } = useProfile(person, entity)

  return (
    <Box>
      <ProfileHeader />
      <Flex
        flexWrap="wrap"
        flexDirection={{ base: "column", lg: "row" }}
        testID="profile-page"
      >
        <BoxCard flex={{ base: "unset", lg: "1" }} pb="5rem">
          <Column>
            <Heading size="md" marginBottom="1rem">
              Personal overview
            </Heading>
            <CopyText isCompanyDetails={false} />

            <Box marginBottom="2rem">
              <ReviewableField label="Name" value={`${fullName}`} />
            </Box>
            <Box marginBottom="2rem">
              <ReviewableField label="Date of birth" value={dateOfBirth} />
            </Box>

            <Box marginBottom="2rem">
              <Row
                justifyContent="space-between"
                textAlign="start"
                fontWeight={500}
                opacity="1"
                my="1rem"
              >
                <Heading size="sm" fontFamily={"CircularStdMedium"}>
                  Personal Address
                </Heading>
                {profileUpdateable && (
                  <Link
                    display="flex"
                    alignItems="center"
                    testID="address-form-edit"
                    onPress={() => setIsAddressDisabled(false)}
                  >
                    <Text mr="0.5rem">Edit</Text>
                    <PencilEdit />
                  </Link>
                )}
              </Row>

              <Box
                borderTopWidth={isAddressDisabled ? "1px" : "opx"}
                borderColor="gray.400"
              >
                <AddressFormInput
                  control={control}
                  name="address"
                  errors={errors.address}
                  testID="address-form-address"
                  placeholder="Address"
                  isReadOnly={isAddressDisabled}
                />
                <AddressFormInput
                  control={control}
                  name="city"
                  errors={errors.city}
                  testID="address-form-city"
                  placeholder="city"
                  isReadOnly={isAddressDisabled}
                />
                <AddressFormInput
                  control={control}
                  name="region"
                  errors={errors.region}
                  testID="address-form-region"
                  placeholder="region"
                  isReadOnly={isAddressDisabled}
                />

                <FormControl isInvalid={"country" in errors}>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange, value } }) =>
                      isAddressDisabled ? (
                        <Box
                          borderColor="gray.400"
                          px="1rem"
                          py="8px"
                          borderBottomWidth="1px"
                          _text={{ color: "gray.400" }}
                        >
                          {
                            countryData.find((item) => item.code === value)
                              ?.name
                          }
                        </Box>
                      ) : (
                        <Select
                          mb="20px"
                          testID="country-field"
                          placeholder="Select country"
                          selectedValue={value}
                          onValueChange={(country) => {
                            onChange(country)
                          }}
                        >
                          {countryData.map(({ name, code }) => (
                            <Select.Item
                              key={code}
                              label={name}
                              value={code}
                            ></Select.Item>
                          ))}
                        </Select>
                      )
                    }
                  />
                </FormControl>

                <AddressFormInput
                  control={control}
                  name="postcode"
                  errors={errors.postcode}
                  testID="address-form-postcode"
                  placeholder="Post code"
                  isReadOnly={isAddressDisabled}
                  validate={(value) => isPostCode(value)}
                />

                {!isAddressDisabled && (
                  <Row>
                    <Button
                      testID="address-form-cancel"
                      flex="1"
                      maxW="210px"
                      onPress={() => {
                        setIsAddressDisabled(true)
                        reset()
                      }}
                      isLoading={isSubmitting}
                      variant="outline"
                    >
                      Cancel
                    </Button>
                    <Spacer p="8px" flexGrow="0" />

                    <Button
                      testID="address-form-submit"
                      flex="1"
                      maxW="210px"
                      isLoading={isSubmitting}
                      onPress={handleSubmit(updateHandler)}
                    >
                      Submit
                    </Button>
                  </Row>
                )}
              </Box>
            </Box>

            <Box marginBottom="2rem">
              <Row
                justifyContent="space-between"
                textAlign="start"
                fontWeight={500}
                opacity="1"
                py="1rem"
              >
                <Heading size="sm" fontFamily={"CircularStdMedium"}>
                  Contact Details
                </Heading>
                {profileUpdateable && (
                  <Link
                    display="flex"
                    alignItems="center"
                    testID="contact-form-edit"
                    onPress={() => setIsContactDetailsDisabled(false)}
                  >
                    <Text mr="0.5rem">Edit</Text>
                    <PencilEdit />
                  </Link>
                )}
              </Row>

              <Box
                borderTopWidth={isContactDetailsDisabled ? "1px" : "opx"}
                borderColor="gray.400"
              >
                <ContactDetailFormInput
                  control={controlContactDetails}
                  name="email"
                  errors={errorsContactDetails.email}
                  testID="contact-email"
                  placeholder="Email"
                  isReadOnly={isContactDetailsDisabled}
                  validate={(email) => isEmail(email)}
                />

                <FormControl
                  isRequired
                  isInvalid={"mobile" in errorsContactDetails}
                >
                  {isContactDetailsDisabled ? (
                    <Box
                      testID="contact-mobile-box"
                      borderColor="gray.400"
                      px="1rem"
                      py="8px"
                      borderBottomWidth="1px"
                      _text={{ color: "gray.400" }}
                    >
                      {`${getContactDetails("countryCode")} ${getContactDetails(
                        "mobile"
                      )}`}
                    </Box>
                  ) : (
                    <Row>
                      <Box flex="1">
                        <Controller
                          control={controlContactDetails}
                          name={"mobile"}
                          rules={{
                            required: "This field is required",
                            validate: (value) => {
                              const mobileCountryCode = countryData.find(
                                (item) =>
                                  item.dial_code ===
                                  getContactDetails("countryCode")
                              )?.code

                              return mobileCountryCode
                                ? isTelephone(value, mobileCountryCode)
                                : "Please enter a valid country code"
                            }
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              testID="contact-mobile-input"
                              onBlur={onBlur}
                              onChangeText={(val) => onChange(val)}
                              value={value}
                              px="25px"
                              placeholder="0000 000 000"
                              leftElement={<Box w="44px" />}
                            />
                          )}
                        />
                        <Controller
                          control={controlContactDetails}
                          name="countryCode"
                          render={({
                            field: {
                              onChange: onCodeChange,
                              onBlur: onCodeBlur,
                              value: codeValue
                            }
                          }) => (
                            <Input
                              testID="mobile-country-code-input"
                              position="absolute"
                              onBlur={onCodeBlur}
                              onChangeText={(countryCode) =>
                                onCodeChange(countryCode)
                              }
                              value={codeValue}
                              ml="16px"
                              leftElement={<Box>{"("}</Box>}
                              rightElement={<Box>{")"}</Box>}
                              backgroundColor="none"
                              borderColor="transparent"
                              px="5px"
                              w="44px"
                              borderWidth="0"
                              placeholder="+61"
                              _stack={{
                                style: { borderWidth: 0 }
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Row>
                  )}

                  <Box minH="20px">
                    <FormControl.ErrorMessage testID="contact-mobile-error">
                      {errorsContactDetails.mobile &&
                        errorsContactDetails.mobile.message}
                    </FormControl.ErrorMessage>
                  </Box>
                </FormControl>

                {!isContactDetailsDisabled && (
                  <Row>
                    <Button
                      flex="1"
                      maxW="210px"
                      testID="contact-form-cancel"
                      onPress={() => {
                        setIsContactDetailsDisabled(true)
                        resetContactDetails()
                      }}
                      isLoading={isSubmittingContactDetails}
                      variant="outline"
                    >
                      Cancel
                    </Button>

                    <Spacer p="8px" flexGrow="0" />
                    <Button
                      flex="1"
                      maxW="210px"
                      isDisabled={!profileUpdateable}
                      testID="contact-form-submit"
                      isLoading={isSubmittingContactDetails}
                      onPress={handleSubmitContactDetails(
                        contactDetailsUpdateHandler
                      )}
                    >
                      Submit
                    </Button>
                  </Row>
                )}
              </Box>
            </Box>
          </Column>
        </BoxCard>
        <Spacer p="8px" flexGrow="0" />
        <BoxCard flex={{ base: "unset", lg: "1" }} pb="5rem">
          <Column>
            <Heading size="md" mb="1rem">
              Company overview
            </Heading>
            <CopyText isCompanyDetails={true} />
            <Box marginBottom="2rem">
              <ReviewableField label="Company name" value={legalName} />
            </Box>
            <Box marginBottom="2rem">
              <ReviewableField label="Trading name" value={tradingName} />
            </Box>
            {businessAddress && (
              <ReviewableField
                label="Registered business address"
                value={businessAddress.addressX}
              />
            )}
          </Column>
        </BoxCard>
      </Flex>
    </Box>
  )
}
