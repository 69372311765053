import { peopleAdd as personsAddRoute } from "@axle/web/src/components/layouts/Authorized/routes"
import EntityUsageDto from "@axle/web/src/models/entity"
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber"
import {
  HStack,
  Button,
  Badge,
  Text,
  Divider,
  Box,
  Flex,
  Heading,
  FormControl,
  Input,
  Row,
  Pressable,
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon
} from "native-base"
import { useEffect, useState } from "react"
import { Controller, useForm, useFieldArray } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { ReactComponent as DollarIcon } from "assets/svgs/icons/dollar.svg"
import { ReactComponent as Edit } from "assets/svgs/icons/edit.svg"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { formatPrice } from "components/util/format-price"
import { useSetLimitsMutation } from "network/limits"

import usePersons, { FormValues, EntityPerson } from "./talons/usePersons"

interface UsersFormProps {
  setIsOpenEdit: React.Dispatch<React.SetStateAction<boolean>>
  isOpenEdit: boolean
  entityPerson: EntityPerson[]
  companyLimit: number
  setEntityPerson: React.Dispatch<React.SetStateAction<EntityPerson[]>>
  setEntityUsageStats: (data: EntityUsageDto) => void
}

export const sortPersonList = ({
  sortBy,
  isAscending,
  entityPerson
}: {
  sortBy: "balance" | "limit" | "name" | "pending"
  isAscending: boolean
  entityPerson: EntityPerson[]
}): EntityPerson[] => {
  switch (sortBy) {
  case "name":
    return entityPerson.sort((a, b) => {
      const fa = a.name.toLowerCase()
      const fb = b.name.toLowerCase()
      if (fa < fb) {
        return isAscending ? -1 : 1
      }
      if (fa > fb) {
        return isAscending ? 1 : -1
      }
      return 0
    })

  case "balance":
    return entityPerson.sort((a, b) => {
      return isAscending ? b.balance - a.balance : a.balance - b.balance
    })
  case "pending":
    return entityPerson.sort((a, b) => {
      return isAscending ? b.pending - a.pending : a.pending - b.pending
    })
  case "limit":
    return entityPerson.sort((a, b) => {
      return isAscending ? b.limit - a.limit : a.limit - b.limit
    })
  }
}

export const UsersForm = ({
  setIsOpenEdit,
  isOpenEdit,
  entityPerson,
  companyLimit,
  setEntityPerson,
  setEntityUsageStats
}: UsersFormProps) => {
  const navigate = useNavigate()
  const [setLimits, { isLoading }] = useSetLimitsMutation()
  const { savingLimits, setSavingLimits } = usePersons()
  const phoneUtil = PhoneNumberUtil.getInstance()

  const { control, watch } = useForm<FormValues>({
    defaultValues: { formPersons: entityPerson }
  })
  const { replace } = useFieldArray({
    control,
    name: "formPersons"
  })

  const watchResult = watch("formPersons")
  const getStringToNumber = (value: string) => (value ? parseFloat(value) : 0)
  const totalLimits = watchResult.reduce(
    (total: number, field: EntityPerson) =>
      (total += getStringToNumber(field.limitInput)),
    0
  )
  const isLimitInvalid = (limitInput: string, balance: number) =>
    getStringToNumber(limitInput) < balance
  const isTotalLimitExceed = totalLimits > companyLimit
  const hasLimitError = !!watchResult.find((item) =>
    isLimitInvalid(item.limitInput, item.balance)
  )

  const onSave = async () => {
    if (!isTotalLimitExceed && !hasLimitError) {
      setSavingLimits(true)
      const newEntityPerson = watchResult.map((person) => ({
        ...person,
        limit: getStringToNumber(person.limitInput)
      }))

      const filterPersons = newEntityPerson.filter(
        (person, index) => entityPerson[index].limit !== person.limit
      )
      const limits = filterPersons.map((item) => ({
        ledgerId: item.id,
        amount: getStringToNumber(item.limitInput),
        ledgerAccountName: "",
        ledgerDetails: "",
        notes: "",
        userName: ""
      }))

      const newLimits = await setLimits(limits).unwrap()
      setEntityUsageStats(newLimits.data)
      setEntityPerson(newEntityPerson)
      setIsOpenEdit(false)
      setSavingLimits(false)
    }
  }

  interface SortingMethod {
    sortBy: "balance" | "limit" | "name" | "pending"
    isAscending: boolean
  }

  const [sortingMethod, setSortingMethod] = useState<SortingMethod>({
    sortBy: "name",
    isAscending: true
  })

  useEffect(() => {
    const newList = [...sortPersonList({ ...sortingMethod, entityPerson })]
    replace([...newList])
  }, [entityPerson, sortingMethod])

  const handelSort = (sortBy: "balance" | "limit" | "name" | "pending") => {
    const isAscending =
      sortBy === sortingMethod.sortBy ? !sortingMethod.isAscending : true
    setSortingMethod({ sortBy, isAscending })
  }

  const TableSortHeading = ({
    label,
    sortBy,
    testID,
    alignSelf = "flex-start"
  }: {
    label: string
    sortBy: "balance" | "limit" | "name" | "pending"
    testID: string
    alignSelf?: string
  }) => {
    const isActive = sortBy === sortingMethod.sortBy

    return (
      <Pressable
        testID={testID}
        onPress={() => handelSort(sortBy)}
        isDisabled={isOpenEdit}
      >
        <Row alignItems="center" alignSelf={alignSelf}>
          <Heading size="sm">{label}</Heading>
          {!isOpenEdit &&
            (isActive ? (
              sortingMethod.isAscending ? (
                <ChevronDownIcon mx="8px" color="warmGray.600" size="xs" />
              ) : (
                <ChevronUpIcon mx="8px" color="warmGray.600" size="xs" />
              )
            ) : (
              <MinusIcon mx="8px" color="warmGray.600" size="xs" />
            ))}
        </Row>
      </Pressable>
    )
  }

  return (
    <>
      <BoxCard key="people-dashboard" testID="people-list-dashboard">
        <Box position={{ base: "sticky", lg: "initial" }} top={{ base: "86px", lg: "unset" }} zIndex={100} bg="white" pt={{ base: "16px", lg: "unset" }}>
          {entityPerson && isOpenEdit ? (
            <Flex
              w="100%"
              flexDir={{ base: "column", lg: "row" }}
              alignItems={{ base: "flex-start", lg: "center" }}
              justifyContent="space-between"
              pb={{ base: "2", lg: "16px" }}
              borderRadius="12px"
              px={{ base: "16px", md: "unset" }}
            >
              <HStack alignItems="center" mb={{ base: "2", lg: "none" }}>
                <Text bold color="warmGray.500" fontSize="14px" mr="2">
                  Editing
                </Text>
                <Edit color="warmGray.500" width="14px" height="14px" />
              </HStack>
              <Box mb={{ base: "4", lg: "none" }}>
                <FormControl isInvalid={isTotalLimitExceed || hasLimitError}>
                  <Text>
                    Total limit allocated:{" "}
                    <strong>{formatPrice(totalLimits).currency}</strong>
                  </Text>
                  <FormControl.ErrorMessage testID="total-limit-error" >
                    {isTotalLimitExceed
                      ? "Your combined limits exceed your total credit limit."
                      : "Limits can’t be lower than an outstanding balance. Please Increase the limits or make a payment."}
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>
              <HStack
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                w={{ base: "100%", lg: "unset" }}
              >
                <Button
                  disabled={isLoading}
                  testID="cancel-edit-button"
                  w="120px"
                  mr={{ base: "none", lg: "10px" }}
                  colorScheme="warmGray"
                  marginBottom={{ base: "16px", sm: "0" }}
                  variant="outline"
                  onPress={() => {
                    setIsOpenEdit(false)
                    replace(entityPerson)
                  }}
                  isLoading={savingLimits}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  variant="solid"
                  testID="save-edit-button"
                  mb={{ base: "16px", sm: "0" }}
                  w="120px"
                  onPress={onSave}
                  isLoading={savingLimits}
                >
                  Save
                </Button>
              </HStack>
            </Flex>
          ) : (
            <HStack
              display="flex"
              flexWrap="wrap"
              px={{ base: "16px", md: "unset" }}
              justifyContent={{ base: "space-between", lg: "space-between" }}
              alignItems="center"
              w={{ base: "100%", lg: "unset" }}
            >
              <Pressable testID="edit-limits-button" onPress={() => {
                setIsOpenEdit(true)
              }}>
                <HStack alignItems="center" mb={{ base: "2", lg: "16px" }}>
                  <Text bold color="primary.700" fontSize="14px" mr="2">
                    Edit limits
                  </Text>
                  <Edit color="primary.700" width="14px" height="14px" />
                </HStack>
              </Pressable>
              <Button
                testID="add-persons-button"
                w="120px"
                mb="16px"
                onPress={() => {
                  navigate(personsAddRoute)
                }}
              >
                Add Person
              </Button>
            </HStack>
          )}
        </Box>

        <HStack display={{ base: "none", lg: "flex" }} mt="6">
          <Box w="25%">
            <TableSortHeading label="Name" sortBy="name" testID="name-header" />
          </Box>

          <Box w="15%">
            <TableSortHeading
              label="Balance"
              sortBy="balance"
              testID="balance-header"
            />
          </Box>
          <Box w="15%">
            <TableSortHeading
              label="Pending"
              sortBy="pending"
              testID="pending-header"
            />
          </Box>
          <Box w="15%">
            <TableSortHeading
              label="Limit"
              sortBy="limit"
              testID="limit-header"
              alignSelf="center"
            />
          </Box>
          <Box w="15%">
            <Heading size="sm" textAlign="center">Email</Heading>
          </Box>
          <Box w="15%">
            <Heading size="sm" textAlign="right">Mobile</Heading>
          </Box>
        </HStack>

        <Divider mt="4" mb="6" />
        <>
          {entityPerson?.map((person, index) => (
            <Flex
              flexDir={{ base: "column", lg: "row" }}
              w={{ base: "100%", lg: "calc(100% + 96px)" }}
              key={index}
              testID={`entity-person-${person.userId}`}
              alignItems="center"
              bg="warmGray.100"
              py="16px"
              px={{ base: "16px", lg: "48px" }}
              mb="24px"
              ml={{ base: "unset", lg: "-48px" }}
            >
              <Box
                w={{ base: "100%", lg: "25%" }}
                flexDir="row"
                alignItems="center"
                pr={{ base: "0", lg: "50px" }}
                justifyContent={{ base: "space-between", lg: "flex-start" }}
              >
                <Text
                  fontSize="md"
                  testID="person-name-field"
                >
                  {person.name}
                </Text>
                {person.userId ? (
                  <Badge ml="auto" colorScheme="success">
                    Active
                  </Badge>
                ) : (
                  <Badge ml="auto" colorScheme="warning">
                    Pending
                  </Badge>
                )}
              </Box>

              <Box
                w={{ base: "100%", lg: "15%" }}
                display="flex"
                flexDir="row"
                alignItems="center"
                justifyContent={{ base: "space-between", lg: "flex-start" }}
                mt={{ base: "4", lg: "none" }}
              >
                <Text
                  fontSize="md"
                  color="warmGray.600"
                  display={{ base: "block", lg: "none" }}
                >
                  Balance
                </Text>
                <Text fontSize="md" color="warmGray.600">
                  {formatPrice(person.balance).currency}
                </Text>
              </Box>

              <Divider
                my="2"
                w="100%"
                display={{ base: "block", lg: "none" }}
              />

              <Box
                w={{ base: "100%", lg: "15%" }}
                display={{ base: "none", lg: "block" }}
              >
                <Text fontSize="md" color="warmGray.600">
                  {formatPrice(person.pending).currency}
                </Text>
              </Box>

              <Flex
                w={{ base: "100%", lg: "15%" }}
                flexDir="row"
                alignItems="center"
                justifyContent={{ base: "space-between", lg: "center" }}
              >
                <Text
                  fontSize="md"
                  color="warmGray.600"
                  display={{ base: "block", lg: "none" }}
                >
                  Limit
                </Text>
                {isOpenEdit ? (
                  <FormControl
                    isInvalid={isLimitInvalid(
                      watchResult[index].limitInput,
                      watchResult[index].balance
                    )}
                    testID={`formPersons-${index}-form`}
                    pl={{ base: "unset", lg: "6px" }}
                    pr={{ base: "46px", lg: "6px" }}
                  >
                    <Controller
                      control={control}
                      name={`formPersons.${index}.limitInput`}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          testID={`formPersons-${index}-input`}
                          size="sm"
                          color={"warmGray.600"}
                          ml={{ base: "2", lg: "none" }}
                          onChangeText={(val) => {
                            const patternNumber = val
                              .split(/ /)[0]
                              .replace(/[^\d.]/g, "")
                            onChange(patternNumber)
                          }}
                          value={value.toString()}
                          keyboardType="numeric"
                          leftElement={
                            <Box pl="1rem">
                              <DollarIcon height="18px" width="10px" />
                            </Box>
                          }
                          justifyContent="start"
                        />
                      )}
                    />
                    <FormControl.ErrorMessage
                      position="absolute"
                      w={{ base: "70%", lg: "1000%" }}
                      ml={{ base: "16", lg: "none" }}
                      top="42px"
                      overflowX="auto"
                      testID={`formPersons-${index}-error`}
                    >
                      Increase this limit to at least{" "}
                      {formatPrice(watchResult[index].balance).currency} or make
                      a payment.
                    </FormControl.ErrorMessage>
                  </FormControl>
                ) : (
                  <Text fontSize="md" color="warmGray.600">
                    {formatPrice(person.limit).currency}
                  </Text>
                )}
              </Flex>

              <Box
                w={{ base: "100%", lg: "15%" }}
                display={{ base: "none", lg: "block" }}
                textAlign="center"
              >
                {person.email ? (
                  <Text fontSize="md" color="warmGray.600">
                    {person.email}
                  </Text>
                ) : (
                  <Text fontSize="md">TBC</Text>
                )}
              </Box>

              <Box
                w={{ base: "100%", lg: "15%" }}
                display={{ base: "none", lg: "block" }}
                textAlign="right"
              >
                {person.mobile ? (
                  <Text fontSize="md" color="warmGray.600">
                    {phoneUtil.format(
                      phoneUtil.parseAndKeepRawInput(person.mobile, "AU"),
                      PhoneNumberFormat.NATIONAL
                    )}
                  </Text>
                ) : (
                  <Text fontSize="md">TBC</Text>
                )}
              </Box>
            </Flex>
          ))}
        </>
      </BoxCard>
    </>
  )
}
