import { formatPrice } from "components/util/format-price"
export interface copyTextObject {
  balanceZeroResponse: string;
  minimumPaymentResponse: string;
  patternInvalidResponse: string;
  maxPaymentResponse: (maxPayment: number) => string;
}

export const copyText: copyTextObject = {
  balanceZeroResponse: "You have no outstanding balance to repay.",
  minimumPaymentResponse: "The minimum amount you can repay is $1.00. Please increase your repayment amount.",
  patternInvalidResponse: "Please enter a valid amount",
  maxPaymentResponse: (maxPayment: number) => `This payment is greater than your balance. You can only repay a maximum of ${formatPrice(maxPayment).currency}. This may include inflight payments.`
}
