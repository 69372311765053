import Notification from "@axle/web/src/models/notification"
import prepareHeaders from "@axle/web/src/network/util/prepare-headers"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"


import { NOTIFICATIONS_API_BASE } from "./apiNamespaces"

interface GetNotification_Request {
  id?: number
  ledgerId?: number
  Page: number
  PageSize: number
}

export interface GetNotification_Response {
  success: boolean
  data: {
    total: number
    items: Notification[]
  }
}

export const notifications = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: NOTIFICATIONS_API_BASE,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    getNotifications: builder.mutation<
      GetNotification_Response,
      GetNotification_Request
    >({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data
      })
    })
  })
})

export const { useGetNotificationsMutation, reducer, middleware } = notifications
