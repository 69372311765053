import { Box, Button, Heading, Row, Text, Select, Link, Flex, Image, Spacer } from "native-base"
import { useEffect, useRef, useState } from "react"
import { Pressable } from "react-native"

import intersectImg from "assets/images/intersect.png"
import ribbonImg from "assets/images/ribbon.png"
import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { Currency } from "components/inline/Currency"
import PageHeading from "components/inline/PageHeading/PageHeading"
import { CardRules } from "models/enums/cardRule-Type"

import ReadOnlyTab from "./ReadOnlyTab"
import useReadOnlySpendControl from "./talons/useReadOnlySpendControl"

const ReadOnlySpendControl = () => {
  const { limit, entityList } = useReadOnlySpendControl()
  const [showTabIndex, setShowTabIndex] = useState<number>(0)
  const [showpage, setShowPage] = useState<string>("control")

  useEffect(() => {
    setShowTabIndex(0)
  }, [showpage])

  const LimitRow = ({ value, label, testID, isCurrency }: { value?: string; label: string; testID?: string; isCurrency?: boolean }) => {
    return (
      <Row p="1rem" bg="gray.50" mb="1rem" testID={testID}>
        <Box flex="3" justifyContent="center">
          <Text>{label}</Text>
        </Box>
        <Box flex="2" justifyContent="center" alignItems="center">
          {value && isCurrency ? (
            <Currency fontFamily="CircularStdMedium" amount={parseInt(value, 10)} isDecimalRequired />
          ) : (
            <Text fontFamily="CircularStdMedium">{value ? value : "No limit set"}</Text>
          )}
        </Box>
      </Row>
    )
  }

  const tabRef = useRef<HTMLDivElement[]>([])
  const tabParentRef = useRef<HTMLDivElement>(null)

  return (
    <Box testID="readOnlyControl">
      <PageHeading>Spend Controls</PageHeading>
      <BoxCard mb="16px">
        <Flex flexDir={{ base: "column", md: "row" }}>
          <Box flex="3" pr="2rem">
            <Text color="gray.400" mb="16px">
              As an account owner, you can apply spend controls to your company cards.
            </Text>
            <Text color="gray.400" mb="16px">
              These rules can be used to prevent employees from spending on non- d items, to reduce out of policy spend.
            </Text>
            <Text color="gray.400" mb="16px">
              Any spend controls currently in place will be shown below. These will be applied to every card in your company.
            </Text>
          </Box>
          <Box flex="1">
            <Heading size="xs">Want to set or update your rules?</Heading>
            <Text color="gray.400" my="1rem">
              Call us on 1300 062 273 and we’ll do this for you.
            </Text>
            <Link flex="1" href="tel:1300062273">
              <Button h="2rem" px="2rem" w="unset">
                Call us
              </Button>
            </Link>
          </Box>
        </Flex>
      </BoxCard>

      <Box flex="1" w={{ base: "unset", sm: "240px" }} testID="select-page">
        <Select borderRadius="5px" onValueChange={(v) => setShowPage(v)} selectedValue={showpage} mb="16px">
          <Select.Item label="Merchant rules" value="control" />
          <Select.Item label="Transaction limits" value="limit" />
        </Select>
      </Box>

      {showpage === "limit" && (
        <BoxCard testID="limit-page" pb="0" pr="0">
          <Flex flexDir={{ base: "column", lg: "row" }} justifyContent="space-between">
            <Flex flex="1" maxW={{ base: "100%", xl: "50%" }} pr={{ base: "24px", lg: "32px" }}>
              <Heading size="sm" color="gray.400" mb="16px">
                Transaction Value controls
              </Heading>
              <Text color="gray.400" mb="16px">
                Limit how much an employee can spend in a single transaction, within a day or over a week.
              </Text>

              <LimitRow
                isCurrency
                value={limit && limit[CardRules.MaxSpendPerTransaction]}
                label={"Maximum spend per transaction"}
                testID={"transactionSpendLimit"}
              />

              <LimitRow isCurrency value={limit && limit[CardRules.MaxSpendPerDay]} label="Maximum spend per day" testID={"daySpendLimit"} />

              <LimitRow isCurrency value={limit && limit[CardRules.MaxSpendPerWeek]} label="Maximum spend per week" />

              <Heading size="sm" color="gray.400" my="16px">
                Transaction counts
              </Heading>
              <Text color="gray.400" mb="16px">
                Limit how often an employee can use their card
              </Text>

              <LimitRow value={limit && limit[CardRules.MaxCountPerDay]} label="Total transactions per day" />
              <LimitRow value={limit && limit[CardRules.MaxCountPerWeek]} label="Total transactions per week" />
              <LimitRow value={limit && limit[CardRules.MaxCountPerMonth]} label="Total transactions per month" />
            </Flex>

            <Spacer p="16px" flexGrow="0" />

            <Flex justifyContent="end" alignItems="end">
              <Image
                h={{ base: "300px", xl: "360px" }}
                w={{ base: "320px", xl: "380px" }}
                bottom="0"
                src={intersectImg}
                alt="intersectImg"
                resizeMode="contain"
              />
            </Flex>
          </Flex>
        </BoxCard>
      )}

      {showpage === "control" && (
        <Box testID="control-page">
          <Row>
            {entityList.map((item, index) => {
              return (
                <Box
                  flex={{ base: "1", md: "unset" }}
                  key={`tab-${index}`}
                  bg={index === showTabIndex ? "white" : "gray.100"}
                  px="12px"
                  pt="10px"
                  pb="20px"
                  mb="-10px"
                >
                  <Pressable
                    testID={`tab-${index}`}
                    onPress={() => {
                      setShowTabIndex(index)
                      const pos = tabRef.current[index].getBoundingClientRect().width * index
                      tabParentRef.current?.scrollTo({
                        left: pos,
                        behavior: "smooth"
                      })
                    }}
                    key={`${item.category}`}
                  >
                    <Box>{item.category}</Box>
                  </Pressable>
                </Box>
              )
            })}
          </Row>

          <BoxCard pb="0" pr="0">
            <Flex flexDir={{ base: "column", lg: "row" }} justifyContent="space-between">
              <Row overflow="hidden" ref={tabParentRef} testID="tab-parernt" flex="1" maxW={{ base: "100%", xl: "50%" }} pb="32px">
                {entityList.map((item, index) => (
                  <Box w="100%" minH="50vh" ref={(ref: HTMLDivElement) => (tabRef.current[index] = ref)} key={`page-${item.category}`}>
                    <ReadOnlyTab data={item} />
                  </Box>
                ))}
              </Row>

              <Flex justifyContent="end" alignItems="end">
                <Image
                  h={{ base: "240px", xl: "300px" }}
                  w={{ base: "280px", xl: "340px" }}
                  bottom="0"
                  src={ribbonImg}
                  alt="ribbonImg"
                  resizeMode="contain"
                />
              </Flex>
            </Flex>
          </BoxCard>
        </Box>
      )}
    </Box>
  )
}

export default ReadOnlySpendControl
