import { useContext } from "react"
import { useNavigate } from "react-router-dom"

import { currentCardContext } from "components/layouts/Authorized/MaybeRender"
import {
  useSuspendCardMutation,
  useActivatingCardSagaMutation
} from "network/card"
import { useAppDispatch } from "store/hooks"
import { updateCard } from "store/slices/cards"
import { setCurrentCard } from "store/slices/currentCard"

import { cardFromLegacyCard } from "../../../../models/card"

interface useFreezeCard {
  isSuccess: boolean
  isLoading: boolean
  cardIsLoading: boolean
  handleFreezeCard: (cardId: number) => Promise<void>
}

const useFreezeCard = (): useFreezeCard => {
  const [suspendCard, { isLoading, isSuccess }] = useSuspendCardMutation()
  const [getCardDetails, { isLoading: cardIsLoading }] =
    useActivatingCardSagaMutation()

  const { index: currentCardIndex } = useContext(currentCardContext)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleFreezeCard = async (cardId: number) => {
    const freezeCard = await suspendCard({ id: cardId }).unwrap()
    if (freezeCard.success) {
      const cardDetails = await getCardDetails({
        id: cardId
      }).unwrap()

      cardDetails.data &&
        (dispatch(setCurrentCard(cardFromLegacyCard(cardDetails.data))),
        dispatch(
          updateCard({
            cardIndex: currentCardIndex,
            card: cardFromLegacyCard(cardDetails.data)
          })
        ))
      navigate(-1)
    }
  }

  return {
    isSuccess,
    isLoading,
    cardIsLoading,
    handleFreezeCard
  }
}

export default useFreezeCard
