import { Global } from "@emotion/react"

// eslint-disable-next-line
const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'CircularStdBlack';
        font-weight: bold;
        src: local('CircularStdBlack'), url(/fonts/CircularStdBlack.woff) format('woff');
      }
      @font-face {
        font-family: 'CircularStdBook';
        font-weight: normal;
        src: local('CircularStdBook'), url(/fonts/CircularStdBook.woff) format('woff');
      }
      @font-face {
        font-family: 'GTPressuraPro';
        font-weight: normal;
        src: local('GTPressuraPro'), url(/fonts/GTPressuraProBold.woff) format('woff');
      }
      @font-face {
        font-family: 'CircularStdMedium';
        font-weight: normal;
        src: local('CircularStdMedium'), url(/fonts/CircularStdMedium.woff) format('woff');
      }
      @font-face {
        font-family: 'CircularStdBold';
        font-weight: normal;
        src: local('CircularStdBold'), url(/fonts/CircularStdBold.woff) format('woff');
      }
      `}
  />
)

export default Fonts
