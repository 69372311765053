import { Box, Text } from "native-base"

import { LimitChangeDto } from "models/notification"
import { usePerson } from "store/hooks/person"

const ChangeLimit = ({ data }: { data: LimitChangeDto }) => {

  const { person } = usePerson()
  return (
    <Box mb="10rem" testID="change-limit-template">
      <Text mb="16px" >
        Hi {person.first},
      </Text>

      <Text mb="16px" >
        We’re letting you know that your account owner has changed your Cape
        charge card limit.
      </Text>

      <Text mb="16px" >
        {`Your new limit is $${data.limit}.`}
      </Text>

      <Text mb="16px" >
        This change will come into effect immediately.
      </Text>

      <Text >Thanks,</Text>
      <Text >Team Cape</Text>
    </Box>
  )
}

export default ChangeLimit
