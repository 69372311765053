import {
  Box,
  Button,
  Pressable,
  Flex,
  Heading,
  Image,
  Popover,
  Text,
  Spacer,
  Spinner,
  useBreakpointValue
} from "native-base"

import intersectImage from "assets/images/intersect.png"
import { CommunicationType } from "models/enums/communication-type"
import PersonDto from "models/person"

import { useEnableTwoFA } from "./talons/useEnableTwoFA"

export interface PaymentsProps {
  person: PersonDto
}

export const EnableTwoFA = ({ person }: PaymentsProps): JSX.Element => {
  const {
    lastFourPhoneNumber,
    mungedEmail,
    handleSubmit,
    isLoadingVerification
  } = useEnableTwoFA(person)

  const phoneButtonText = `To my mobile number ending in ${lastFourPhoneNumber}`
  const emailButtonText = `To my email address ${mungedEmail}`

  return <Box
    width="100%"
    maxWidth={"960px"}
    marginX={"auto"}
    marginY={"1rem"}
    testID="twoFAStart"
  >
    <Box marginX={4} bg="white" p={6} borderRadius="6px">
      <>
        <Heading position={"relative"} fontSize={"3xl"} maxWidth="600px">
          We need you to Setup two-factor authentication
          <Box
            position="absolute"
            bottom="15px"
            marginLeft="14px"
          >
            <Popover trigger={triggerProps => <Button
              {...triggerProps}
              m={0}
              p={0}
              width="20px"
              height="20px"
              backgroundColor={"gray.400"}
              _text={{
                fontSize: "12px"
              }}
              _hover={{
                backgroundColor: "gray.400",
                borderColor: "gray.400",
                _text: {
                  color: "white"
                }
              }}
            >
              ?
            </Button>}
            >
              <Popover.Content
                accessibilityLabel="What is two-factor authentication?"
                w={"100%"}
                maxWidth={"400px"}
              >
                <Popover.Arrow background="gray.400" borderColor="gray.400" />
                <Popover.CloseButton
                  background={"white"}
                  borderRadius="50%"
                  padding="3px"
                />
                <Popover.Header
                  borderBottomWidth={0}
                  shadow={0}
                  backgroundColor="gray.400"
                  pt={12}
                  px={6}
                  _text={{ color: "yellow.200" }}
                >
                  What is two-factor authentication?
                </Popover.Header>
                <Popover.Body
                  backgroundColor="gray.400"
                  px={6}
                  _text={{ color: "white" }}
                >
                  Right now, we ask you for one piece of secure information when you login to Cape - your password.
                  Introducing two-factor authentication means we’ll ask for a second factor to verify that it’s really you trying to access your account.
                  Adding this extra layer of security helps us keep your account safe.
                  <Spacer my="14px" />
                  To begin with, this second factor will be unique code that we send to you.
                </Popover.Body>
              </Popover.Content>
            </Popover>
          </Box>
        </Heading>
        <Flex flexDirection={useBreakpointValue({ base: "column", md: "row" })} justifyContent="space-between" flexWrap="wrap">

          <Box maxWidth="650px" width="100%">
            <Box my="40px">
              <Text mb="10px">To enable two-factor authentication, we’ll send a unique code to you, via email or sms, that you’ll enter on screen.</Text>
              <Text >Once enabled, we’ll get you to repeat this action periodically, when you login, or each time that you perform certain actions in your Cape account.</Text>
            </Box>
            <Box my="25px">
              <Heading fontSize="md" mb="10px">How would you like to receive your code?</Heading>
              <Text >We’ll save this as your default authentication method.</Text>
            </Box>
            <Pressable
              testID="phoneTwoFA"
              width="fit-content"
              paddingX="12px"
              paddingY="8px"
              backgroundColor="tertiary.800"
              borderRadius="8px"
              onPress={() => handleSubmit(CommunicationType.Sms)}
            >
              {isLoadingVerification ? <Spinner /> : <Box
                _text={{
                  color: "#fff",
                  fontSize: "14px"
                }}
              >
                {phoneButtonText}
              </Box>}
            </Pressable>
            <Spacer m="5px"></Spacer>
            <Pressable
              testID="emailTwoFA"
              width="fit-content"
              paddingX="12px"
              paddingY="8px"
              backgroundColor="tertiary.800"
              borderRadius="8px"
              onPress={() => handleSubmit(CommunicationType.Email)}
            >
              {isLoadingVerification ? <Spinner /> : <Box
                _text={{
                  color: "#fff",
                  fontSize: "14px"
                }}
              >
                {emailButtonText}
              </Box>}
            </Pressable>
          </Box>
        </Flex>
      </>
      <Flex testID="burt" alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }} mt="16px">
        <Image
          width={{ base: "266px", md: "300px", xl: "400px" }}
          height={{ base: "250px", md: "286px", xl: "380px" }}
          src={intersectImage}
          alt="intersectImage"
        />
      </Flex>
    </Box>
  </Box>
}
