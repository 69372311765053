import { Factory } from "fishery"

import LedgerDto, { EntityLedgerDto, LedgerCardDto } from "models/ledger"

export const LedgerCardFactory = Factory.define<LedgerCardDto>(({ sequence }) => ({
  id: sequence,
  ledgerId: sequence,
  ledgerDetails: `ledgerDetails ${sequence}`,
  cardId: sequence,
  cardDetails: `cardDetails ${sequence}`
}))

export const EntityLedgerFactory = Factory.define<EntityLedgerDto>(({ sequence }) => ({
  id: sequence,
  userId: sequence,
  name: `ledger-${sequence}`,
  externalReference: `externalReference ${sequence}`,
  limit: 5000,
  balance: 10000,
  available: 3300,
  pending: 0,
  usage: 3000,
  email: "test@getcape.io",
  mobile: "0422222222"
}))

export const LedgerFactory = Factory.define<LedgerDto>(({ sequence }) => ({
  id: sequence,
  amount: 123.50,
  limit: 456.50,
  balance: 789.50,
  accountName: `accountName ${sequence}`,
  accountNumber: "12345678",
  goCardlessReference: "gcr"
}))

export default LedgerFactory
