import { Text, Flex, View, Pressable } from "native-base"
import { useState } from "react"
// import { StyleSheet, Dimensions } from "react-native"
import { TabView, SceneMap } from "react-native-tab-view"


export type RoutesState = { key: string; title: string }[]

export interface TabViewExampleProps {
  routesState: RoutesState
  renderScene: ReturnType<typeof SceneMap>
}

const TabForm = ({ routesState, renderScene }: TabViewExampleProps) => {
  const [index, setIndex] = useState(0)
  const [routes] = useState(routesState)

  const renderTabBar = (props: {
    navigationState: {
      index: number
      routes: Array<{ key: string; title: string }>
    }
  }) => {
    return (
      <View style={{ flexDirection: "row", paddingTop: 10, maxWidth: 550 }}>
        {props.navigationState.routes.map((route, i) => {

          return (
            <Pressable key={i} onPress={() => setIndex(i)}>
              <Flex bg={index === i ? "#FFFFFF" : "#E5E5E5"} w="200px" height="50px" justifyContent="center" alignItems="center" borderColor="#000" borderStyle="solid" borderWidth="1px">
                <Text bold>{route.title}</Text>
              </Flex>
            </Pressable>
          )
        })}
      </View>
    )
  }

  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      renderTabBar={renderTabBar}
    />
  )
}

export default TabForm
