// DD Request (Bank Account)
export const ddRequestHeading = "Direct Debit Set up"
export const ddRequestSubheading =
  "Please add your company Bank Account in order to set-up a monthly Direct Debit to settle your charge card."
export const noAccAvailable = "No accounts available."
export const addAccountButton = "Set-up your Direct Debit now"

// DD Details
export const ddDetailsHeading = "Set up a Direct Debit Request with Cape"
export const ddDetailsSubHeading =
  "Enter your company bank account details below to set up your Direct Debit"

export const accountHolderValidationMessage =
  "Please enter the name of the account holder."
export const emailValidationMessage = "Please enter a valid email address."

export const accountNumberValidationMessage =
  "Your account number should be 6 to 10 digits long"
export const bsbNumberValidationMessage = "Your BSB should be 6 digits long"

export const signatoryConsentCopy =
  "If more than one person is required to authorise Direct Debits Requests on this account you should obtain the authorisation of all required parties before completing this form. By clicking 'Set up Direct Debit Request' you are confirming you have obtained this authorisation."

export const signatoryValidationMessage =
  "Please tick the box if you are authorised or there are not multiple signatories."

export const ddDetailsButton = "Set up Direct Debit request"

// DD Confirmation
export const ddConfirmationHeading = "Direct Debit Request"
export const ddConfirmationSubHeading =
  "Creditor information: Cape Pty Ltd. Direct Debit User ID: 488299. \"Cape\" will appear on your bank statement"

export const ddrConsentCopy =
  "By signing and/or providing us with a valid instruction in respect to your Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements between you and Merchant Name as set out in this Request and in your Direct Debit Service Agreement. You agree to execute this document by electronic signature and you are aware that by electronically signing this document you are executing a legally binding document."
export const ddrConsentValidation =
  "You must check the box above to confirm that you agree to this direct debit request"

// Thanks
export const thanksHeading =
  "Great news, your Direct Debit has been successfully set-up."
// export const thanksSubHeading = "View your Direct Debit Mandate and Service Agreement"
export const mandateDownloadLink =
  "View your Direct Debit Mandate and Service Agreement"

export const thanksButton = "Next"
