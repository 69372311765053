import {
  Box,
  Flex,
  Image,
  Button,
  Heading,
  Input,
  Spacer,
  Text
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import intersectImage from "assets/images/intersect.png"
import { BoxCard } from "components/inline/BoxCard"
import { ddSetup as ddSetupRoute } from "components/layouts/Authorized/routes"

export const ActivateFlowDirectDebit = () => {
  const navigate = useNavigate()

  return (
    <BoxCard>
      <Flex
        flexDir={{ base: "column", lg: "row" }}
        data-testid="dd-container"
        minH="50vh"
        justifyContent="space-between"
        alignItems={{ base: "center", lg: "flex-start" }}
      >
        <Box mb="2rem" w={{ base: "100%", lg: "80%" }} maxW="40rem">
          <Box data-testid="activate-dd">
            <Heading fontFamily="GTPressuraPro">Set up Direct Debit mandate before activating your card</Heading>
            <Text mt="6" >
              Please add your company Bank Account in order to set-up a monthly Direct Debit to settle your charge card
            </Text>

            <Box mt="6">
              <Heading variant="h3">Existing accounts</Heading>
              <Input
                data-testid="noAccAvailable"
                p="1.5rem"
                my="1rem"
                readOnly
                value={"No account available"}
                variant={"review"}
                borderColor="gray.300"
              />
            </Box>

            <Spacer h="1rem" />
            <Button
              w={{ base: "100%", lg: "30%" }}
              minW="15rem"
              mt="6"
              h="10"
              data-testid="activate-flow-set-up-dd"
              onClick={() => {
                localStorage.setItem("ddsetupInitWizard", "details")
                navigate(ddSetupRoute)
              }}
              fontSize="sm"
            >
              Set-up your Direct Debit now
            </Button>
          </Box>
        </Box>
        <Flex data-testid="burt" alignItems="center" justify="center" alignSelf={{ base: "unset", md: "flex-end" }} mt="16px">
          <Image
            width={{ base: "250px", md: "300px", xl: "400px" }}
            height={{ base: "250px", md: "300px", xl: "400px" }}
            src={intersectImage}
            alt="intersectImage"
          />
        </Flex>
      </Flex>
    </BoxCard>
  )
}
