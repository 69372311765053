import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { statement } from "components/layouts/Authorized/routes"
import { getDisplayTitle } from "models/enums/event-type"
import NotificationDto from "models/notification"
import { useSetReadNotificationMutation } from "network/notification"
import { usePersonSummaryMutation } from "network/person"
import { useAppDispatch } from "store/hooks"
import { setUnreadNotiCount } from "store/slices/unreadNotificationCount"

interface useNotificationRow {
  handleViewStatement: () => Promise<void>
  handleView: () => Promise<void>
  displayTitle: string|undefined
  isLoading: boolean
}

const useNotificationRow = (
  notification: NotificationDto,
  setTemplateData: React.Dispatch<
    React.SetStateAction<NotificationDto | undefined>
  >
): useNotificationRow => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [fistRead, setFirstRead] = useState<boolean>(
    notification.readAt ? false : true
  )

  const [setReadNotification, { isLoading: notificationsLoading }] =
    useSetReadNotificationMutation()
  const [getPersonSummary, { isLoading: personSummaryLoading }] =
    usePersonSummaryMutation()

  const handleViewStatement = async () => {
    if (fistRead) {
      const response = await setReadNotification({
        eventId: notification.id
      }).unwrap()

      const personSummaryResponse = await getPersonSummary().unwrap()

      personSummaryResponse.success &&
        dispatch(
          setUnreadNotiCount(personSummaryResponse.data.unreadNotifications)
        )

      personSummaryResponse.success &&
        response.success &&
        (setFirstRead(false),
        navigate(statement(`${notification.statementId}`)))
    } else {
      navigate(statement(`${notification.statementId}`))
    }
  }

  const handleView = async () => {
    if (fistRead) {
      const response = await setReadNotification({
        eventId: notification.id
      }).unwrap()
      const personSummaryResponse = await getPersonSummary().unwrap()
      personSummaryResponse.success &&
        dispatch(
          setUnreadNotiCount(personSummaryResponse.data.unreadNotifications)
        )
      personSummaryResponse.success &&
        response.success &&
        (setFirstRead(false), setTemplateData(notification))
    } else {
      setTemplateData(notification)
    }
  }

  const displayTitle = getDisplayTitle(notification.type)

  const isLoading = personSummaryLoading || notificationsLoading

  return {
    handleViewStatement,
    handleView,
    displayTitle,
    isLoading
  }
}
export default useNotificationRow
