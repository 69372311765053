export const enum CardRules {
  MaxCountPerMonth = 1,
  MaxCountPerWeek = 2,
  MidBlacklist = 3,
  MaxCountPerDay = 4,
  MaxSpendPerWeek = 5,
  MaxSpendPerTransaction = 6,
  MccWhitelist = 7,
  MaxSpendPerLifetime = 8,
  MccBlacklist = 9,
  MaxCountPerLifetime = 10,
  MidWhitelist = 11,
  MaxSpendPerDay = 12,
  MaxSpendPerMonth = 13
}
