import { AXLE_BUILD, AXLE_VERSION } from "@axle/web/src/config/environment"
import {
  Box,
  Container,
  Flex,
  Text
} from "native-base"
import { ReactElementLike } from "prop-types"


// import styles from "./Base.module.css"

const versionString = AXLE_VERSION
const appBuildString = AXLE_BUILD

interface BaseProps {
  header?: ReactElementLike | null,
  content?: ReactElementLike | null,
  footer?: ReactElementLike | null,
  version?: string,
  appBuild?: string
}

export default function Base({ header, content, footer, version = versionString, appBuild = appBuildString }: BaseProps): JSX.Element {
  return (
    <Box
      width={"100%"}
      height="100vh"
      maxWidth="1920px"
      margin="auto"
      bg="tertiary.50"
      testID="Base"
    >
      {appBuild && version &&
        <Flex width="100%" justify="center" align="end" pt="0.2rem" position="absolute"><Text testID="build-string" fontSize="lg" color="gray.200">{`${appBuild} - ${version}`}</Text></Flex>
      }
      <Container
        p="0px"
        maxWidth="100%"
        w="100%"
        bg="tertiary.50"
        zIndex="unset"
      >{header}</Container>
      <Container
        p="0px"
        maxWidth="100%"
        bg="tertiary.50"
        position="initial"
      >{content}</Container>
      <Container
        p="0px"
        maxWidth="100%"
        bg="tertiary.50"
      >{footer}</Container>
    </Box>
  )
}
