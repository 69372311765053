import IconLink, { IconLinkProps } from "@axle/web/src/components/inline/IconLink"
import { Box, Text } from "native-base"


interface links extends IconLinkProps {
  hide?: boolean | null
  hideIcon?: boolean | null
  iconColor?: string
  href?: string | null
  isExternal?: boolean | null
}
interface MenuList {
  menuLinks: links[]
}

const MenuList: React.FC<MenuList> = (props: MenuList): JSX.Element => {
  const { menuLinks } = props

  return (
    <>
      {menuLinks
        .filter((item) => !!item.hide === false)
        .map((item, index) => (
          <Box key={index} mb="14px">
            {item.icon && !!item.hideIcon === false && (
              <IconLink
                key={item.path}
                fontSize="14px"
                padding="1rem 0"
                color="warmGray.600"
                {...item}
              />
            )}
            {(!!item.hideIcon === true || !item.icon) && (
              <Text
                fontSize="14px"
                color="warmGray.600"
                fontWeight="500"
              >
                {item.text}
              </Text>
            )}
          </Box>
        ))}
    </>
  )
}

export default MenuList
