import { ReactComponent as LockIcon } from "@axle/web/src/assets/svgs/icons/lock.svg"
import { ReactComponent as MycardIcon } from "@axle/web/src/assets/svgs/icons/my-card.svg"
import { ReactComponent as FreezeCardIcon } from "@axle/web/src/assets/svgs/icons/snow.svg"
import BoxCard from "@axle/web/src/components/inline/BoxCard/NbBoxCard"
import CardsNav from "@axle/web/src/components/inline/CardVisual/CardsNav"
import MenuList from "@axle/web/src/components/inline/MenuList"
import PageHeading from "@axle/web/src/components/inline/PageHeading/PageHeading"
import {
  allDepsContext,
  currentCardContext
} from "@axle/web/src/components/layouts/Authorized/MaybeRender"
import {
  pinReminder as pinReminderRoute,
  freezeCard as freezeCardRoute,
  unFreezeCard as unFreezeCardRoute,
  replaceCard as replaceCardRoute
} from "@axle/web/src/components/layouts/Authorized/routes"
import useMyCard from "@axle/web/src/components/pages/MyCards/talons/useMyCard"
import { Box, Heading, Flex } from "native-base"
import { useContext } from "react"



export const MyCards = (): JSX.Element => {
  const { cards, hasCurrentCard } = useContext(allDepsContext)
  const {
    card: currentCard,
    index: currentCardIndex,
    cardActivated,
    cardSuspending,
    cardReactivating,
    cardActivating
  } = useContext(currentCardContext)



  const {
    userHasCardAndIsActive,
    cardIsSuspended,
    cardPinText,
    cardFreezingText,
    cardUnfreezingText,
    cardActivatingText
  } = useMyCard()

  return (
    <Box>
      <PageHeading testID="my-cards-header">My Cards</PageHeading>
      {hasCurrentCard ? (
        <BoxCard
          testID="my-cards"
          display="flex"
          flexDirection="column"
        >
          <Heading
            textAlign={{ base: "center", md: "left" }}
            mb="32px"
            fontSize="24px"
          >
            Card Actions
          </Heading>
          <Flex
            maxWidth={{ md: "180px", lg: "360px" }}
            width="100%"
            flexDir={{ base: "column", md: "row" }}
            alignItems={{ base: "center", md: "flex-start" }}
          >
            <CardsNav
              showManageLink={false}
              cards={cards}
              currentCard={currentCard}
              cardIndex={currentCardIndex}
            />
            <Flex flexDir="column" h="138px" alignItems="flex-start" ml={{ base: "unset", md: "32px" }} mt={{ base: "32px", md: "unset" }}>
              <MenuList
                menuLinks={[
                  {
                    testID: "card-suspending",
                    text: cardFreezingText,
                    hide: !cardSuspending
                  },
                  {
                    testID: "card-unfreezing",
                    text: cardUnfreezingText,
                    hide: !cardReactivating
                  },
                  {
                    testID: "card-activating",
                    text: cardActivatingText,
                    hide: !cardActivating
                  },
                  {
                    icon: <LockIcon />,
                    path: pinReminderRoute,
                    testID: "pin-reminder",
                    text: cardPinText,
                    hide: cardSuspending || cardReactivating || cardActivating,
                    hideIcon: !cardActivated
                  },
                  {
                    icon: <FreezeCardIcon />,
                    text: "Freeze card",
                    testID: "freeze-card-link",
                    path: freezeCardRoute,
                    hide:
                      !cardActivated ||
                      cardSuspending ||
                      cardReactivating ||
                      cardActivating
                  },
                  {
                    icon: <FreezeCardIcon />,
                    path: unFreezeCardRoute,
                    text: "Unfreeze card",
                    testID: "unfreeze-card-link",
                    hide: !cardIsSuspended
                  },
                  {
                    icon: <MycardIcon />,
                    text: "Report card lost or stolen",
                    testID: "lost-card-link",
                    path: replaceCardRoute,
                    hide: !userHasCardAndIsActive && cardSuspending
                  }
                ]}
              />
            </Flex>
          </Flex>
        </BoxCard>
      ) : (
        <Heading>User has no cards</Heading>
      )}
    </Box>
  )
}
