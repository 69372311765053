import { ReactComponent as EarPhoneIcon } from "assets/svgs/icons/ear-phone.svg"
import { ReactComponent as DashboardIcon } from "assets/svgs/icons/home.svg"
import { ReactComponent as MycardIcon } from "assets/svgs/icons/my-card.svg"
import { ReactComponent as PaymenyIcon } from "assets/svgs/icons/payment.svg"
import { ReactComponent as PeopleIcon } from "assets/svgs/icons/people.svg"
import { ReactComponent as SettingsIcon } from "assets/svgs/icons/settings.svg"
import { ReactComponent as SpendControlIcon } from "assets/svgs/icons/spendControlIcon.svg"
import { ReactComponent as TransactionIcon } from "assets/svgs/icons/transactions.svg"
import {
  dashboard as dashboardRoute,
  transactions as transactionsRoute,
  myCards as myCardsRoute,
  payments as paymentRoute,
  people as peopleRoute,
  dashboardHelpCentre as dashboardHelpCentreRoute,
  settings as settingsRoute,
  spendControls as spendControlRoute
} from "components/layouts/Authorized/routes"
import { FF__SPEND_CONTROLS } from "config/environment"
import { Roles } from "models/enums/roles"
import { usePerson } from "store/hooks/person"

interface btnLinkprops {
  testID: string
  path: string
  icon: JSX.Element
  text: string
}

const iconSizes = {
  width: "20px",
  height: "20px"
}

const peopleLink: btnLinkprops = {
  testID: "people-link",
  path: peopleRoute,
  icon: <PeopleIcon {...iconSizes} />,
  text: "People"
}

const dashboardLink: btnLinkprops = {
  testID: "dashboard-link",
  path: dashboardRoute,
  icon: <DashboardIcon {...iconSizes} />,
  text: "Dashboard"
}

const spendControlLink: btnLinkprops = {
  testID: "dashboard-spendControls-link",
  path: spendControlRoute,
  icon: <SpendControlIcon {...iconSizes} />,
  text: "Spend Controls"
}
const transactionLink: btnLinkprops = {
  testID: "dashboard-transactions-link",
  path: transactionsRoute,
  icon: <TransactionIcon {...iconSizes} />,
  text: "Transactions"
}

const myCardLink: btnLinkprops = {
  testID: "my-cards-link",
  path: myCardsRoute,
  icon: <MycardIcon {...iconSizes} />,
  text: "My Cards"
}

const paymentLink: btnLinkprops = {
  testID: "payment-link",
  path: paymentRoute,
  icon: <PaymenyIcon {...iconSizes} />,
  text: "Payments"
}

const helpCenterLink: btnLinkprops = {
  testID: "dashboard-help-center-link",
  path: dashboardHelpCentreRoute,
  icon: <EarPhoneIcon {...iconSizes} />,
  text: "Help Centre"
}

const settingLink: btnLinkprops = {
  testID: "settings-link",
  path: settingsRoute,
  icon: <SettingsIcon {...iconSizes} />,
  text: "Settings"
}

interface useDashboard {
  navList: btnLinkprops[]
  bottomNavList: btnLinkprops[]
}

const useDashboard = (): useDashboard => {
  const { person } = usePerson()

  const baseList = [dashboardLink]

  let navList = [...baseList]

  // Add shown Dashboard iconlink base on user role
  const roleNavList = {
    [Roles.Administrator]: [transactionLink, myCardLink],
    [Roles.Owner]: [transactionLink, myCardLink, paymentLink],
    [Roles.Customer]: [transactionLink, myCardLink]
  }

  roleNavList[Roles.Owner].unshift(peopleLink)
  FF__SPEND_CONTROLS && roleNavList[Roles.Owner].push(spendControlLink)

  // Add all the iconlink required by role
  person &&
    person.roles.map((role) => {
      switch (role) {
      case Roles.Administrator:
        navList = [...navList, ...roleNavList[Roles.Administrator]]
        break

      case Roles.Owner:
        navList = [...navList, ...roleNavList[Roles.Owner]]
        break

      case Roles.Customer:
        navList = [...navList, ...roleNavList[Roles.Customer]]
        break

      default:
        break
      }
    })

  //  Remove Duplicated in nav List
  navList = navList.filter(
    (item, index, self) =>
      index === self.findIndex((selfItem) => selfItem.testID === item.testID)
  )

  const bottomNavList = [helpCenterLink, settingLink]

  return {
    navList,
    bottomNavList
  }
}

export default useDashboard
