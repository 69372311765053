import {
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Input,
  Menu,
  Pressable,
  SearchIcon,
  Spinner,
  Text,
  useToken
} from "native-base"
import { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import { stringToBase64 } from "components/util/base64"
import EntityPersonDto from "models/entityPerson"
import { Roles } from "models/enums/roles"
import { usePersonsMutation } from "network/entity"
import { usePerson } from "store/hooks/person"

interface SearchPeopleProps {
  InfiniteScroller: typeof InfiniteScroll
  value: string
  setCurrentLedger: (
    value: React.SetStateAction<{
      id: number
      name: string
    }>
  ) => void
  handleFreshSearch: (ledgerId: number) => Promise<void>
}

const SearchPeople = ({
  value,
  setCurrentLedger,
  InfiniteScroller,
  handleFreshSearch
}: SearchPeopleProps) => {
  const scrollerRef = useRef<HTMLDivElement | null>(null)

  const [lightGray] = useToken("colors", ["gray.100"])
  const [getEntityPersons] = usePersonsMutation()

  const { person } = usePerson()
  const [isPeopleListOpen, setIsPeopleListOpen] = useState<boolean>(false)
  const [personsList, setPersonsList] = useState<EntityPersonDto[]>(
    [] as EntityPersonDto[]
  )
  const [searchTerm, setSearchTerm] = useState<string>("")

  const [curPage, setCurPage] = useState<number>(0)

  const [hasMore, setHasMore] = useState<boolean>(false)

  const pageSize = 10
  const loadPersonsList = async (page: number) => {
    const personsResults = await getEntityPersons({
      page: page,
      pageSize: pageSize,
      searchTerm: stringToBase64(searchTerm)
    }).unwrap()

    setCurPage(page)

    const curDataSize = personsResults.data.items.length + personsList.length
    curDataSize < personsResults.data.total ? setHasMore(true) : false

    page > 0
      ? setPersonsList([...personsList, ...personsResults.data.items])
      : setPersonsList(personsResults.data.items)
  }

  useEffect(() => {
    person.roles.includes(Roles.Owner) && loadPersonsList(0)
    setCurPage(0)
    return () => {
      setPersonsList([])
    }
  }, [searchTerm])

  // debounce
  let timeOut: NodeJS.Timeout
  const handleSearch = (text: string) => {
    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      setSearchTerm(text.toLocaleLowerCase())
    }, 300)
  }

  const handleScroll = () => {
    setHasMore(false)
    const nextPage = curPage + 1
    loadPersonsList(nextPage)
  }

  return (
    <Menu
      borderTopRadius="0"
      borderBottomRadius="8px"
      pt="0"
      bg="white"
      shadow="none"
      borderColor="gray.200"
      borderWidth="1px"
      borderTopWidth="0"
      w="260px"
      isOpen={isPeopleListOpen}
      onOpen={() => {
        setIsPeopleListOpen(true)
        setSearchTerm("")
      }}
      onClose={() => {
        setIsPeopleListOpen(false)
      }}
      closeOnSelect={false}
      trigger={(triggerProps) => (
        <Pressable
          testID="people-dropDown"
          accessibilityLabel="More options menu"
          {...triggerProps}
        >
          <Input
            testID="people-display-field"
            pl="1rem"
            value={value}
            w="260px"
            isReadOnly
            rightElement={isPeopleListOpen ? <ChevronUpIcon mr="1rem" /> : <ChevronDownIcon mr="1rem" />}
            borderBottomLeftRadius={isPeopleListOpen ? "0" : "8px"}
            borderBottomRightRadius={isPeopleListOpen ? "0" : "8px"}
            borderBottomWidth={isPeopleListOpen ? "0" : "1px"}
          />
        </Pressable>
      )}
    >
      <Box
        testID="people-search-scroll"
        maxH="10rem"
        overflowY="scroll"
        ref={scrollerRef}
      >
        <InfiniteScroller
          pageStart={0}
          threshold={25}
          hasMore={hasMore}
          getScrollParent={() => scrollerRef.current}
          useWindow={false}
          initialLoad={false}
          data-testid="search-people-scroller"
          loadMore={() => handleScroll()}
          loader={
            <Box key={0} testID="search-people-loading">
              {hasMore && <Spinner />}
            </Box>
          }
        >
          <Menu.Item flexDir="row" alignItems="center">
            <Input
              testID="people-search"
              size="xs"
              h="30px"
              placeholder="Search people"

              _stack={{
                style: { borderColor: lightGray }
              }}
              onChangeText={(value) => {
                handleSearch(value)
              }}
              leftElement={<SearchIcon color="gray.300" size="md" ml="16px" />}
            />
          </Menu.Item>
          {personsList
            .filter(({ isUser, ledgerId }) => isUser && !!ledgerId)
            .map(({ name, ledgerId: ledgerId }) => (
              <Menu.Item
                flexDir="row"
                alignItems="center"
                textValue={`${ledgerId}`}
                key={`${ledgerId}`}
                onPress={() => {
                  setIsPeopleListOpen(false)
                  setCurrentLedger({ id: ledgerId, name: name })
                  handleFreshSearch(ledgerId)
                }}
              >
                <Text
                  textAlign="left"

                  testID="people-dropDown-item"
                >
                  {name}
                </Text>
              </Menu.Item>
            ))}
        </InfiniteScroller>
      </Box>
    </Menu>
  )
}

export default SearchPeople
