import EntityPersonDto from "@axle/web/src/models/entityPerson"

import { EntityPersonFactory } from "mocks/factories/entityPerson"
import { ApiArrayData } from "network/util/api-response"

export const getEntityPersonsFx = (): {
  success: boolean
  data: ApiArrayData<EntityPersonDto>
} => ({
  success: true,
  data: {
    total: 3,
    items: [EntityPersonFactory.build({ name: "Ryan Pritch", userId: 1 }), EntityPersonFactory.build({ name: "Julian Guppy", userId: 2, isUser: false }), EntityPersonFactory.build({ name: "John Penny", userId: 3, isUser: false })]
  }
})
