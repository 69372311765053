import {
  Box,
  FormControl,
  InputGroup,
  IInputProps,
  Pressable,
  Text,
  Input,
  Row,
  useToken,
  Flex
} from "native-base"
import { useState } from "react"
import { FieldError } from "react-hook-form"

import { ReactComponent as ApproveIcon } from "assets/svgs/icons/approve.svg"
import { ReactComponent as ExclamationIcon } from "assets/svgs/icons/exclamation.svg"
import { ReactComponent as EyeCloseIcon } from "assets/svgs/icons/eye-close.svg"
import { ReactComponent as EyeOpenIcon } from "assets/svgs/icons/eye-open.svg"
import { ReactComponent as DeclineIcon } from "assets/svgs/icons/transaction-status-decline.svg"
import { isPassword } from "components/util/form-validators"

interface PasswordInputProps extends IInputProps {
  label?: string
  name: string
  errors?: Record<string, FieldError>
  showRules?: boolean
}

export const PasswordInput = ({
  label,
  name,
  errors,
  showRules = false,
  onChangeText,
  ...props
}: PasswordInputProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false)

  const [aproveGreen, gray] = useToken("colors", ["green.400", "gray.300"])

  const showPassword = (
    <Box width="36px">
      <Pressable
        testID="show-hide-icon"
        bg="transparent"
        onPress={() => setShow(!show)}
      >
        {show ? (
          <EyeCloseIcon data-testid="hide-icon" />
        ) : (
          <EyeOpenIcon data-testid="show-icon" />
        )}
      </Pressable>
    </Box>
  )

  const [inputPassword, setInputPassword] = useState<string>()

  const {
    minLengthPass,
    includeUppercase,
    includeLowercase,
    includeNumber,
    includeSpecial,
    minTestPass
  } = isPassword(inputPassword)

  const handleTextChange = (text: string) => {
    setInputPassword(text)
    onChangeText && onChangeText(text)
  }

  const handleIcon = (
    approve: boolean,
    mustPass: boolean,
    isInvalid: boolean
  ) => {
    if (mustPass) {
      return approve ? (
        <ApproveIcon fill={aproveGreen} transform="scale(0.8)" />
      ) : isInvalid ? (
        <DeclineIcon fill={gray} transform="scale(0.8)" />
      ) : (
        <ExclamationIcon fill={gray} />
      )
    } else {
      return approve ? (
        <ApproveIcon fill={aproveGreen} transform="scale(0.8)" />
      ) : (
        <ExclamationIcon fill={gray} />
      )
    }
  }

  const rulesList = [
    {
      rule: "Be between 8-32 characters",
      approveState: minLengthPass,
      testID: "minLengthPass-testID",
      mustPass: true
    },
    {
      rule: "Have at least 3 of the following:",
      approveState: minTestPass,
      testID: "minTestPass-testID",
      mustPass: true
    },
    {
      rule: "An uppercase character",
      approveState: includeUppercase,
      testID: "includeUppercase-testID",
      mustPass: false
    },
    {
      rule: "A lowercase character",
      approveState: includeLowercase,
      testID: "includeLowercase-testID",
      mustPass: false
    },
    {
      rule: "A number",
      approveState: includeNumber,
      testID: "includeNumber-testID",
      mustPass: false
    },
    {
      rule: "A special character",
      approveState: includeSpecial,
      testID: "includeSpecial-testID",
      mustPass: false
    }
  ]

  const isInvalid = !!errors?.[name]

  return (
    <FormControl mb={"0.5rem"} isInvalid={isInvalid}>
      {label && (
        <FormControl.Label mb="8px">
          {label}
        </FormControl.Label>
      )}
      <InputGroup>
        <Input
          onChangeText={(text) => {
            handleTextChange(text)
          }}
          type={show ? "text" : "password"}
          rightElement={showPassword}
          {...props}
        />
      </InputGroup>

      {showRules ? (
        <Box pl="5px">
          <Text p="8px" pb="3px">
            Password must:
          </Text>

          {rulesList.map(({ rule, approveState, testID, mustPass }) => (
            <Row
              alignItems="center"
              key={rule}
              testID={`${testID}-${approveState}`}
            >
              <Flex
                w="25px"
                h="1rem"
                alignItems="center"
                justifyContent="center"
              >
                {handleIcon(approveState, mustPass, isInvalid)}
              </Flex>

              <Text
                ml="4px"
                color={
                  mustPass
                    ? approveState
                      ? "green.400"
                      : isInvalid
                        ? "red.400"
                        : "gray.300"
                    : approveState
                      ? "green.400"
                      : "gray.300"
                }
              >
                {rule}
              </Text>
            </Row>
          ))}
        </Box>
      ) : (
        <FormControl.ErrorMessage _text={{ fontSize: "14px" }}>
          {errors?.[name] && errors[name].message}
        </FormControl.ErrorMessage>
      )}
    </FormControl>
  )
}
