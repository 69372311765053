import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import type { RootState } from "store"
export type unreadCount = number

const slice = createSlice({
  name: "unreadNotiCount",
  initialState: 0,
  reducers: {
    setUnreadNotiCount: (
      state,
      { payload: unreadNotiCount }: PayloadAction<number>
    ) => (state = unreadNotiCount)
  }
})

export const { setUnreadNotiCount } = slice.actions
export default slice.reducer

export const selectUnreadNotiCount = (state: RootState): number => {
  return state.unreadNotiCount
}


