import "./datepicker.css"

import { DateTime } from "luxon"
import { Box, Input, IInputProps, Menu, Pressable, useToken } from "native-base"
import { useState } from "react"
import { DayPicker } from "react-day-picker"

import { ReactComponent as DatePickIcon } from "assets/svgs/icons/datePick.svg"

interface DatePick extends IInputProps {
  value: string
  selectYear?: boolean
}

const DatePick = ({
  value,
  selectYear = false,
  onChangeText: setDate,
  ...props
}: DatePick): JSX.Element => {
  const [showCalander, setShowCalander] = useState<boolean>(false)
  const [hover, setHover] = useState<boolean>(false)
  const today = new Date().getFullYear()
  const [hover_dpColor, dpColor] = useToken("colors", ["primary.600", "gray.200"])

  const closePopper = () => {
    setShowCalander(false)
  }

  const handleDaySelect = (date: Date | undefined) => {
    date && setDate && setDate(DateTime.fromJSDate(date).toFormat("dd/MM/yyyy"))
    closePopper()
  }

  const handleInput = (date: string) => {
    const patternDate = date.split(/ /)[0].replace(/[^\d/]/g, "")
    setDate && setDate(patternDate)
    setShowCalander(false)
  }

  const validateDate = () => {
    setDate && !DateTime.fromFormat(value, "dd/MM/yyyy").isValid && setDate("")
  }

  return (
    <Menu
      testID="calMenu"
      closeOnSelect={false}
      isOpen={showCalander}
      onClose={() => {
        setShowCalander(false)
      }}
      trigger={(triggerProps) => (
        <Box {...triggerProps} flex="1">
          <Input
            zIndex={1}
            onChangeText={(text) => handleInput(text)}
            onBlur={() => {
              validateDate()
            }}
            placeholder="DD/MM/YYYY"
            value={value}
            {...props}
            pr="40px"

          />

          <Pressable
            zIndex={2}
            position="absolute"
            right="0"
            px="1rem"
            py="14px"
            onHoverIn={() => setHover(true)}
            onHoverOut={() => setHover(false)}
            accessibilityLabel="open calander"
            onPress={() => setShowCalander(true)}
            testID="openCalander"
          >
            <DatePickIcon fill={hover ? hover_dpColor : dpColor} />
          </Pressable>
        </Box>
      )}
    >
      <Menu.Item
        flexDir="row"
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor="gray.100"
        p="0"
        m="0"
      >
        <Box position="sticky" zIndex="2" testID="calendar" bg="white">
          <DayPicker
            mode="single"
            onSelect={handleDaySelect}
            fromYear={1900}
            toYear={today}
            captionLayout={selectYear ? "dropdown" : "buttons"}
          />
        </Box>
      </Menu.Item>
    </Menu>
  )
}

export default DatePick
