import { IButtonProps, ITextProps, Text, Flex, useToken, Pressable, Spacer } from "native-base"
import { useState, cloneElement } from "react"

export interface IIconButtonProps extends IButtonProps {
  text?: string
  icon?: JSX.Element
  color?: string
  iconColor?: string
  activeColor?: string
  isActive?: boolean
}

const IconButton = ({
  text,
  color = "warmGray.600",
  activeColor = "primary.600",
  iconColor,
  icon,
  onHoverIn,
  onHoverOut,

  isActive = false,
  ...props
}: IIconButtonProps): JSX.Element => {
  const [isHovered, setIsHover] = useState<boolean>(false)

  const [activeIconColor, baseIconColor] = useToken("colors", [activeColor, iconColor || color])

  const customIcon =
    icon &&
    cloneElement(icon, {
      fill: isHovered || isActive ? activeIconColor : baseIconColor,
      width: "20px",
      height: "20px"
    })

  const textProps = (): ITextProps => {
    const { fontSize = "lg", fontWeight, fontStyle, fontFamily = "CircularStdMedium" } = { ...props }
    return { fontSize, fontWeight, fontStyle, fontFamily }
  }

  return (
    <Pressable
      display="flex"
      flexDir="row"
      justifyContent="flex-start"
      onHoverIn={() => {
        onHoverIn && onHoverIn()
        setIsHover(true)
      }}
      onHoverOut={() => {
        onHoverOut && onHoverOut()
        setIsHover(false)
      }}
      p="0"
      m="0"
      {...props}
    >
      <Flex flexDirection="row" alignItems="center" py={2}>
        {customIcon}
        {customIcon && text && <Spacer w={4} flexGrow="0" />}

        {text && (
          <Text pt={1} {...textProps()} color={isHovered || isActive ? activeColor : color}>
            {text}
          </Text>
        )}
      </Flex>
    </Pressable>
  )
}

export default IconButton
