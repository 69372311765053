import {
  Box,
  Button,
  Heading,
  Input,
  Spacer,
  FormControl,
  Pressable,
  Spinner,
  Text
} from "native-base"
import { Controller } from "react-hook-form"

import { CommunicationType } from "models/enums/communication-type"

import { useTwoFAForm } from "./talons/useTwoFAForm"

interface twFAForm {
  emailTruncated: string
  email: string
  phoneTruncated: string
  communicationType: number
  timeoutInterval?: number
  callback?: () => undefined | void
}

export const TwoFAForm = ({ email, emailTruncated, phoneTruncated, communicationType, callback = undefined, timeoutInterval = 600 }: twFAForm) => {
  const {
    control,
    handleSubmit: verifyHandleSubmit,
    submit,
    isVerifying,
    submitForNewCode,
    isLoadingOTPCode,
    timerTime,
    isRunning,
    phoneButtonText,
    emailButtonText,
    timerText
  } = useTwoFAForm(
    email,
    phoneTruncated,
    emailTruncated,
    communicationType,
    timeoutInterval,
    callback
  )

  return <>
    <Text >{timerText}</Text>
    <Text color={isRunning ? "yellow.500" : "red.400"}>{timerTime}</Text>
    <Spacer marginY={5} />
    <FormControl testID="TwoFAForm">
      <Controller
        control={control}
        name="OTPCode"
        render={({ field: { onChange, value } }) => (<Input
          type="text"
          testID="otpCodeInput"
          placeholder="Enter code"
          onSubmitEditing={verifyHandleSubmit(submit)}
          onChangeText={(val) => onChange(val)}
          maxWidth={"550px"}
          value={value}
        />)}
      />
      <Spacer my={"10px"} />
      <Button
        testID="otpCodeSubmit"
        maxWidth="209px"
        onPress={verifyHandleSubmit(submit)}
        isLoading={isVerifying}
      >
        Submit
      </Button>
    </FormControl>
    <Spacer my={"40px"} />
    <Heading size="md">Need another code?</Heading>
    <Spacer my={"18px"} />
    <Pressable
      testID="phoneTwoFA"
      width="fit-content"
      paddingX="12px"
      paddingY="8px"
      backgroundColor="warmGray.600"
      borderWidth="2px"
      borderColor="transparent"
      borderRadius="20px"
      onPress={() => submitForNewCode(CommunicationType.Sms)}
    >
      {isLoadingOTPCode ? <Spinner /> : <Box
        _text={{
          color: "#fff",
          fontSize: "14px"
        }}
      >
        {phoneButtonText}
      </Box>}
    </Pressable>
    <Spacer m="5px"></Spacer>
    <Pressable
      testID="emailTwoFA"
      width="fit-content"
      paddingX="12px"
      paddingY="8px"
      backgroundColor="warmGray.600"
      borderWidth="2px"
      borderColor="transparent"
      borderRadius="20px"
      onPress={() => submitForNewCode(CommunicationType.Email)}
    >
      {isLoadingOTPCode ? <Spinner /> : <Box
        _text={{
          color: "#fff",
          fontSize: "14px"
        }}
      >
        {emailButtonText}
      </Box>}
    </Pressable>
  </>
}
