import { Box, useStyleConfig, forwardRef, chakra, ControlBoxProps } from "@chakra-ui/react"

export const BoxCard: React.FC<ControlBoxProps> = chakra(forwardRef(({
  variant,
  children,
  ...props
}, ref): JSX.Element | null => {
  const styles = useStyleConfig("BoxCard", { variant })

  // Pass the computed styles into the `__css` prop
  return (
    <Box sx={styles} {...props} ref={ref}>
      {children}
    </Box>
  )
}))
