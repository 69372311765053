import { Box, FormControl, Input } from "native-base"
import { Control, Controller, FieldError, Validate } from "react-hook-form"

import { ContactDetailsInputs } from "./talons/useProfile"

interface AddressFormInputProps {
  control: Control<ContactDetailsInputs, object>
  errors: FieldError | undefined
  name: keyof ContactDetailsInputs
  isReadOnly?: boolean
  label?: string
  testID?: string
  placeholder?: string
  isRequired?: boolean
  validate?: Record<string, Validate<string>> | Validate<string> | undefined
}
const AddressFormInput = ({
  control,
  errors,
  name,
  testID,
  placeholder,
  isReadOnly,
  validate
}: AddressFormInputProps) => {
  return (
    <FormControl isInvalid={errors ? true : false}>
      <Controller
        control={control}
        name={name}
        rules={{ required: "This field is required", validate }}
        render={({ field: { onChange, onBlur, value } }) =>
          isReadOnly ? (
            <Box
              testID={`${testID}-box`}
              borderColor="gray.400"
              px="1rem"
              py="8px"
              borderBottomWidth="1px"
              _text={{ color: "gray.400" }}
            >
              {value}
            </Box>
          ) : (
            <Input
              testID={`${testID}-input`}
              onBlur={onBlur}
              onChangeText={(val) => onChange(val)}
              value={value}
              px="16px"
              placeholder={placeholder}
            />
          )
        }
      />
      {!isReadOnly && (
        <Box minH="20px">
          <FormControl.ErrorMessage testID={`${testID}-error`}>
            {errors && errors.message}
          </FormControl.ErrorMessage>
        </Box>
      )}
    </FormControl>
  )
}

export default AddressFormInput
