import { useAppSelector } from "@axle/web/src/store/hooks"
import { selectCurrentCard } from "@axle/web/src/store/slices/currentCard"
import { useMemo } from "react"


import { CardDto } from "../../models/card"


export const useCurrentCard = (): { currentCard: CardDto|null } => {
  const currentCard = useAppSelector(selectCurrentCard)

  return useMemo(() => ({ currentCard }), [currentCard])
}
