import { CookieStorage } from "cookie-storage"

export default function prepareHeaders(headers: Headers): Headers {
  const cookieStorage = new CookieStorage()
  const jwt = cookieStorage.getItem("jwt")
  const username = cookieStorage.getItem("username")

  if (jwt) {
    headers.set("Authorization", `Bearer ${jwt}`)
  }

  if (username) {
    headers.set("username", username)
  }

  headers.set("Content-Type", "application/json")

  return headers
}

