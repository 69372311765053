import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  VStack
} from "@chakra-ui/react"
import { useState } from "react"
import { RemoveScroll as RemoveScroll } from "react-remove-scroll"

import { ReactComponent as EarPhoneIcon } from "assets/svgs/icons/ear-phone.svg"
import { ReactComponent as HamburgerIcon } from "assets/svgs/icons/hamburger.svg"
import IconLink from "components/inline/IconLink"
import SignOut from "components/inline/SignOut"
import { obHelpCentre as helpCentreRoute } from "components/layouts/Authorized/routes"

interface Props {
  windowObject?: Window
}

export default function BurgerNav({
  windowObject = window
}: Props): JSX.Element {
  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const openMenuHandler = () => {
    windowObject.scrollTo(0, 0)
    setMenuOpen(true)
  }

  const svgSize = {
    width: "2.25rem",
    height: "2.25rem"
  }

  return (
    <Box data-testid="burger-menu" display={{ base: "block", sm: "none" }}>
      <Menu
        strategy="fixed"
        closeOnSelect={true}
        onOpen={() => openMenuHandler()}
        onClose={() => setMenuOpen(false)}
        isOpen={menuOpen}
      >
        <MenuButton
          data-testid="burger-nav-button"
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="burger"
          ml="12px"
          _hover={{ bg: "warmGray.200" }}
          _expanded={{ bg: "warmGray.200" }}
        />
        <MenuList
          border="none"
          backgroundColor="tertiary.50"
          zIndex="3"
          width="100vw"
          height="100vh"
        >
          <RemoveScroll enabled={menuOpen}>
            <VStack
              maxHeight={"83vh"}
              pb="30vh"
              overflow={"scroll"}
              spacing="3rem"
            >
              <IconLink
                testID="help-center-nav-button"
                path={helpCentreRoute}
                icon={<EarPhoneIcon {...svgSize} />}
                text="Help Centre"
                onFocus={() => setMenuOpen(false)}
              />

              <SignOut
                fontSize="1rem"
                svgWidth={svgSize.width}
                svgHeight={svgSize.height}
              />
            </VStack>
          </RemoveScroll>
        </MenuList>
      </Menu>
    </Box>
  )
}
