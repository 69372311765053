import EntityUsageDto from "@axle/web/src/models/entity"
import { EntityLedgerDto } from "@axle/web/src/models/ledger"
import { useEffect, useState } from "react"

import { useUsageMutation } from "network/entity"

export interface EntityPerson extends EntityLedgerDto {
  limitInput: string  // this string is mainly handle when the input is 0, if it's number it will be NaN
}
export interface FormValues {
  formPersons: EntityPerson[]
}

interface UsePeople {
  companyLimit: number
  allocated: number
  unallocated: number
  currentBalance: number
  pending: number
  available: number
  entityPerson: EntityPerson[]
  isOpenEdit: boolean
  setSavingLimits: React.Dispatch<React.SetStateAction<boolean>>
  savingLimits: boolean
  setIsOpenEdit: React.Dispatch<React.SetStateAction<boolean>>
  setEntityPerson: React.Dispatch<React.SetStateAction<EntityPerson[]>>
  setEntityUsageStats: (data: EntityUsageDto) => void
}

const usePersons = (): UsePeople => {
  const [getUsage] = useUsageMutation()
  const [companyLimit, setCompanyLimit] = useState<number>(0)
  const [allocated, setAllocated] = useState<number>(0)
  const [unallocated, setUnallocated] = useState<number>(0)
  const [currentBalance, setCurrentBalance] = useState<number>(0)
  const [pending, setPending] = useState<number>(0)
  const [available, setAvailable] = useState<number>(0)
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false)
  const [entityPerson, setEntityPerson] = useState<EntityPerson[]>([])
  const [savingLimits, setSavingLimits] = useState<boolean>(false)

  function setEntityUsageStats(data: EntityUsageDto) {
    setCompanyLimit(data.allocation)
    setAllocated(data.allocated)
    const unallocatedFromRes = data.allocation - data.allocated
    setUnallocated(unallocatedFromRes)
    setCurrentBalance(data.totalBalance)
    setPending(data.totalPending)

    const availableInAllLedgers = data.ledgers && data.ledgers.length !== 0 ? data.ledgers.reduce((total: number, ledger: EntityLedgerDto) => total += ledger.available, 0) : 0
    setAvailable(availableInAllLedgers)
  }

  useEffect(() => {
    (async () => {
      const response = await getUsage().unwrap()

      if (response.data.ledgers) {
        const mergeArray = response.data.ledgers.map(person => {
          return {
            ...person,
            limitInput: person.limit.toString()
          }
        })
        setEntityPerson(mergeArray)
      }

      setEntityUsageStats(response.data)
    })()

    return () => {
      setCompanyLimit(0)
      setAllocated(0)
      setUnallocated(0)
      setCurrentBalance(0)
      setPending(0)
      setAvailable(0)
      setEntityPerson([])
      setIsOpenEdit(false)
    }
  }, [getUsage])

  return {
    companyLimit,
    allocated,
    unallocated,
    currentBalance,
    pending,
    available,
    entityPerson,
    isOpenEdit,
    savingLimits,
    setSavingLimits,
    setIsOpenEdit,
    setEntityPerson,
    setEntityUsageStats
  }
}

export default usePersons
