import { Box, FormControl, Input } from "native-base"
import { Control, Controller, FieldError, Validate } from "react-hook-form"

import { FormValue } from "./useAddPersonForm"

interface FormInputProps {
  control: Control<FormValue, object>
  errors: FieldError | undefined
  name: keyof FormValue
  label?: string
  testID?: string
  placeholder?: string
  isRequired?: boolean
  validate?: Record<string, Validate<string>> | Validate<string> | undefined
}
const FormInput = ({
  control,
  errors,
  name,
  label,
  testID,
  placeholder,
  isRequired,
  validate
}: FormInputProps) => {
  return (
    <FormControl isRequired={isRequired} isInvalid={errors ? true : false}>
      <FormControl.Label>{label}</FormControl.Label>
      <Controller
        control={control}
        name={name}
        rules={{ required: "This field is required", validate }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            testID={testID}
            onBlur={onBlur}
            onChangeText={(val) => onChange(val)}
            value={value}
            px="16px"
            placeholder={placeholder}
          />
        )}
      />
      <Box minH="20px">
        <FormControl.ErrorMessage testID={`${testID}-error`}>
          {errors && errors.message}
        </FormControl.ErrorMessage>
      </Box>
    </FormControl>
  )
}

export default FormInput
