import { DateTime } from "luxon"
import { Box, Flex, Heading, Spinner, Text, Row, ChevronDownIcon, Pressable } from "native-base"
import { useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useNavigate } from "react-router-dom"

import BoxCard from "components/inline/BoxCard/NbBoxCard"
import { CardDetails } from "components/inline/CardDetails"
import PageHeading from "components/inline/PageHeading/PageHeading"
import TableRow from "components/inline/TableRow/TableRow"
import { twoFAStart } from "components/layouts/Authorized/routes"
import { FF__TRANSACTIONS_DOWNLOAD, FF__TWO_FA } from "config/environment"
import { Roles } from "models/enums/roles"
import PersonDto from "models/person"

import { DownloadCSV as DownloadCSVComponent } from "./DownloadCSV/DownloadCSV"
import SearchForm from "./Search/SearchForm"
import SearchPeople from "./Search/SearchPeople"
import useTransactions from "./talons/useTransactions"

export interface TransactionsProps {
  InfiniteScroller: typeof InfiniteScroll
  title?: string
  enableDownload?: boolean
  enableTwoFA?: boolean
  showPayments?: boolean
  person: PersonDto
  DownloadCSV?: typeof DownloadCSVComponent
}
export const Transactions = ({
  InfiniteScroller,
  title = "Transactions",
  enableDownload = FF__TRANSACTIONS_DOWNLOAD,
  enableTwoFA = FF__TWO_FA,
  person,
  DownloadCSV
}: TransactionsProps): JSX.Element => {
  const {
    transactions,
    isLoading,
    handleSearch,
    downloadParams,
    handleScroll,
    handleFreshSearch,
    hasMoreTransactions,
    showAvailableCredit,
    setshowAvailableCredit,
    isSearching,
    pendingTransactionList,
    totalPending,
    currentLedger,
    setCurrentLedger,
    setIsHover,
    isHovered
  } = useTransactions()

  const scrollerRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const isTransaction = transactions.length > 0
  const isLoadingAndNoTransaction = isLoading && transactions.length === 0

  if (!person.isTwoFactor && enableTwoFA) {
    navigate(twoFAStart)
  }

  let date: string | undefined
  let month: string | undefined

  const pendingList = pendingTransactionList.length > 0 && (
    <Box>
      <Flex flexDirection={{ base: "column", lg: "row" }} flex="1" bg="warmGray.100" my="1rem" px={{ base: "24px", md: "48px" }} py="1rem">
        <Heading flex="1" fontSize={{ base: "xs", sm: "sm" }}>
          Pending Transactions
        </Heading>

        <Row flex="3" alignItems="center">
          <Text>
            {pendingTransactionList.length + "  "}
            {pendingTransactionList.length > 1 ? "transactions" : "transaction"}
          </Text>
          <Text ml="auto">Total pending</Text>
          <Text pl="1rem" fontWeight="900">
            {totalPending}
          </Text>
        </Row>
      </Flex>

      {pendingTransactionList.map((transaction, index) => {
        return <TableRow key={`PendingList-${index}`} transaction={transaction} showDate={true} showMonth={false} showAvailableCredit={showAvailableCredit} />
      })}
    </Box>
  )

  const transactionList = transactions
    .filter((transaction) => transaction.displayStatus !== "Pending")
    .map((transaction, index) => {
      const currentTransactionDate = transaction.createdAt && DateTime.fromISO(transaction.createdAt).toFormat("dL")
      const currentTransactionMonth = transaction.createdAt && DateTime.fromISO(transaction.createdAt).toFormat("LLLL")
      const showMonth = month !== currentTransactionMonth
      const showDate = date !== currentTransactionDate
      date = currentTransactionDate
      month = currentTransactionMonth

      return <TableRow key={`transactionList-${index}`} transaction={transaction} showDate={showDate} showMonth={showMonth} showAvailableCredit={showAvailableCredit} />
    })

  return (
    <Box testID="transactions">
      <PageHeading testID="transactions-header">{title === "Transactions" ? "Transactions" : `Welcome, ${person.first}`}</PageHeading>

      {title !== "Transactions" && <CardDetails />}

      <SearchForm enableDownload={enableDownload} downloadParams={downloadParams} DownloadCSV={DownloadCSV} handleSearch={handleSearch} />

      <BoxCard testID="transactions-content" px="0">
        {person.roles.includes(Roles.Owner) && (
          <Flex ml={{ base: "24px", md: "48px" }} mb="1rem" alignItems={{ base: "start", md: "center" }} flexDir={{ base: "column", md: "row" }}>
            <Heading size="sm" mr="16px" mb={{ base: "16px", md: "0" }}>
              View:
            </Heading>
            <SearchPeople
              InfiniteScroller={InfiniteScroller}
              value={currentLedger.name}
              setCurrentLedger={setCurrentLedger}
              handleFreshSearch={handleFreshSearch}
            />
          </Flex>
        )}

        <Box ref={scrollerRef}>
          <Row alignItems="center" py="32px" px={{ base: "24px", md: "48px" }}>
            <Heading flex="3" size="xs">
              Merchant
            </Heading>
            <Heading
              flex="1"
              size="xs"
              textAlign="center"
              display={{ base: "none", lg: "flex" }}
            >
              Card ending
            </Heading>
            <Heading
              flex="1"
              justifyContent="end"
              size="xs"
              display={{ base: "none", lg: "flex" }}
            >
              Status/Type
            </Heading>
            <Heading
              flex="1"
              size="xs"
              textAlign="end"
              px={{ base: "0", lg: "1.5rem" }}
            >
              Amount
            </Heading>
            <Row
              flex="1"
              justifyContent="end"
              display={{ base: "none", lg: "flex" }}
            >
              <Pressable
                testID="showBalanceBtn"
                onPress={() => setshowAvailableCredit(!showAvailableCredit)}
                onHoverIn={() => {
                  setIsHover(true)
                }}
                onHoverOut={() => {
                  setIsHover(false)
                }}
              >
                <Row alignItems="center">
                  <Text fontWeight="bold" color={isHovered ? "primary.800" : "primary.600"} mr="2px">
                    {showAvailableCredit ? "Available Credit" : "Balance"}
                  </Text>
                  <ChevronDownIcon size="xs" color={isHovered ? "primary.800" : "primary.600"} mt="2px" />
                </Row>
              </Pressable>
            </Row>
          </Row>
          <Box>
            <Box>
              {isLoadingAndNoTransaction ? (
                <Box>
                  <Box>
                    <Spinner />
                  </Box>
                </Box>
              ) : isTransaction ? (
                <InfiniteScroller
                  pageStart={0}
                  threshold={25}
                  hasMore={hasMoreTransactions}
                  getScrollParent={() => scrollerRef.current}
                  useWindow={true}
                  initialLoad={false}
                  data-testid="transactions-scroller"
                  loadMore={() => handleScroll()}
                  loader={
                    <Box key={0} testID="transactions-loading">
                      {isTransaction && <Spinner />}
                    </Box>
                  }
                >
                  {pendingList}
                  {transactionList}
                </InfiniteScroller>) : (
                <Row flex="1" p="1rem">
                  <Text>
                    {isSearching
                      ? "Your search did not match any transactions"
                      : "There are currently no transactions on this account."}
                  </Text>
                </Row>
              )}
            </Box>
          </Box>
        </Box>
      </BoxCard>
    </Box>
  )
}
