import logo from "@axle/web/src/assets/svgs/public-logo-white.svg"
import { Box, Button, Flex, Menu, Row, Image, Column, VStack, CloseIcon, Heading } from "native-base"
import { RemoveScroll as RemoveScroll } from "react-remove-scroll"
import { useNavigate } from "react-router-dom"

import { ReactComponent as HamburgerIcon } from "assets/svgs/icons/hamburger.svg"
import IconButton from "components/inline/IconButton"
import SignOut from "components/inline/SignOut"
import NotificationLink from "components/pages/Notifications/NotificationLink"
import { usePerson } from "store/hooks/person"

import useBurgerNav, { BtnLinkprops } from "./talons/useBurgerNav"

const BurgerNav = (): JSX.Element => {
  const { personNavList, navList, menuOpen, setMenuOpen } = useBurgerNav()

  const navigate = useNavigate()

  const svgSize = {
    width: "2.25rem",
    height: "2.25rem"
  }

  const { person } = usePerson()

  const renderList = (list: BtnLinkprops[]): JSX.Element[] => {
    const buttonList = list.map((btn) => {
      const { ...btnprops } = btn
      return (
        <IconButton
          key={btnprops.path}
          onPress={() => {
            navigate(btnprops.path)
            setMenuOpen(false)
          }}
          {...btnprops}
          pb={6}
          iconColor="primary.600"
        />
      )
    })

    return buttonList
  }

  return (
    <Column position="fixed" top="0" left="0" w="100%" display={{ base: "flex", md: "none" }} testID="burger-menu">
      <Row alignItems="center" bg="primary.600" p={4} w="100%">
        <Image height="54px" width="50px" src={logo} alt="logo" resizeMode="contain" mr="auto" />

        <Row alignItems="center">
          <NotificationLink showIcon />

          <Button w={12} testID="burger-nav-button" bg="transparent" onPress={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <CloseIcon style={{ fontSize: 20, color: "tertiary.50" }} /> : <HamburgerIcon />}
          </Button>
        </Row>
      </Row>

      <Menu
        isOpen={menuOpen}
        trigger={(trigger) => <Box testID="point" {...trigger} />}
        _backdrop={{ display: "none" }}
        w="100vw"
      >
        <Menu.Item backgroundColor="white" isDisabled>
          <RemoveScroll enabled={menuOpen}>
            <VStack h={"90vh"} overflow={"scroll"} testID="burger-menu">
              <Flex flexDir="column" pb={25} px={4} pt={4}>
                <Heading fontSize="xl" pb={6}>
                  {person?.fullName}
                </Heading>

                {renderList(personNavList)}
                <Heading fontSize="xl" pb={6}>
                  Company
                </Heading>

                {renderList(navList)}

                <SignOut color="warmGray.600" fontSize="lg" svgWidth={svgSize.width} svgHeight={svgSize.height} />
              </Flex>
            </VStack>
          </RemoveScroll>
        </Menu.Item>
      </Menu>
    </Column>
  )
}

export default BurgerNav
